// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragdroparea {
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  border: dashed 2px #bbb;
  border-radius: 3px;
  position: relative;
  margin: 0 auto;
  padding-top: 7px;
  padding-bottom: 15px;
  background-color: #fafafa;
}
.dragdroparea input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.dragdroparea label {
  color: white;
  width: 183px;
  height: 30px;
  line-height: 20px;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: var(--linkColour);
  padding: 5px 10px;
}
.dragdroparea h3 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--textColour);
}

#hideDragDrop {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 2;
}

.block {
  min-width: 100%;
}

.fileover {
  cursor: grabbing !important;
}

.cloud-upload {
  font-size: 30px;
  color: var(--linkColour);
}

#fileDropRef {
  cursor: pointer !important;
}

.close-box {
  cursor: pointer;
}
.close-box:hover {
  color: rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./projects/common/components/dragdropfiles/dragdropfiles.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;EACA,qBAAA;EACA,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,oBAAA;EACA,yBAAA;AACF;AACE;EACE,UAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;AACJ;AAEE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mCAAA;EACA,iBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,wBAAA;AADJ;;AAKA;EAEE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;AAHF;;AAOA;EACE,eAAA;AAJF;;AAOA;EACE,2BAAA;AAJF;;AAOA;EACE,eAAA;EACA,wBAAA;AAJF;;AAOA;EAEE,0BAAA;AALF;;AAQA;EAEE,eAAA;AANF;AAQE;EACE,yBAAA;AANJ","sourcesContent":[".dragdroparea {\n  width: 100%;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n  text-align: center;\n  border: dashed 2px #bbb;\n  border-radius: 3px;\n  position: relative;\n  margin: 0 auto;\n  padding-top: 7px;\n  padding-bottom: 15px;\n  background-color: #fafafa;\n\n  input {\n    opacity: 0;\n    position: absolute;\n    z-index: 2;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n  }\n\n  label {\n    color: white;\n    width: 183px;\n    height: 30px;\n    line-height: 20px;\n    border-radius: 8px;\n    font-size: 0.875rem;\n    font-weight: 500;\n    background-color: var(--linkColour);\n    padding: 5px 10px;\n  }\n\n  h3 {\n    font-size: 1rem;\n    font-weight: 400;\n    color: var(--textColour);\n  }\n}\n\n#hideDragDrop {\n\n  position: absolute;\n  top: 20px;\n  right: 30px;\n  z-index: 2;\n\n}\n\n.block {\n  min-width: 100%;\n}\n\n.fileover {\n  cursor: grabbing !important;\n}\n\n.cloud-upload {\n  font-size: 30px;\n  color: var(--linkColour);\n}\n\n#fileDropRef {\n\n  cursor: pointer !important;\n}\n\n.close-box {\n\n  cursor: pointer;\n\n  &:hover {\n    color: rgba(0, 0, 0, 0.5);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
