// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-bar {
  width: 100%;
  background-color: var(--primaryButtonColour);
  height: 60px;
  cursor: pointer;
  font-weight: 600;
  overflow-y: hidden;
  z-index: 9999;
}
.mobile-bar .text {
  padding-top: 5px;
  font-size: 0.6rem;
  line-height: 0.6rem;
}
.mobile-bar .icon {
  padding-top: 10px;
  font-size: 0.9rem;
  line-height: 0.9rem;
}
.mobile-bar .item {
  border-right: 1px solid #4a4a4a;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/common/app-mobile-bar/app-mobile-bar.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;EACA,4CAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;AAAF;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAIE;EACE,+BAAA;AAFJ","sourcesContent":[".mobile-bar {\n\n  width: 100%;\n  background-color: var(--primaryButtonColour);\n  height: 60px;\n  cursor: pointer;\n  font-weight: 600;\n  overflow-y: hidden;\n  z-index: 9999;\n\n  .text {\n    padding-top: 5px;\n    font-size: 0.6rem;\n    line-height: 0.6rem;\n  }\n\n  .icon {\n    padding-top: 10px;\n    font-size: 0.9rem;\n    line-height: 0.9rem;\n  }\n\n  .item {\n    border-right: 1px solid #4a4a4a;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
