import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {BaseSearchDTO, PlatformDTO} from '../global/interfaces';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class PlatformService {

  private serviceUrl = '/api/platform';

  constructor(private apiClient: ApiService, private data: DataService) { }

  create(dto: PlatformDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({ url, data: dto}) as Promise<any>;
  }

  patch(dto: PlatformDTO, previousDTO: PlatformDTO = {}): Promise<any> {

    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.id}`;
    return this.apiClient.patch({ url, data: patch}) as Promise<any>;
  }

  delete(platformId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${platformId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  search(searchDTO?: BaseSearchDTO): Promise<any> {

    let url = `${this.data.apiUrl}${this.serviceUrl}s`;

    if (searchDTO != null && searchDTO.ignoreCache) {
      url += "?uniq=" + Math.floor(Math.random() * 10000);
    }

    return this.apiClient.get({url}) as Promise<any>;
  }
}
