import {BaseDTO} from './_baseDTO.interface';

export interface PurchaseDealDTO extends BaseDTO {
  dealId: number;
  advertId: string;
  customerId: string;
  vehicleId: string;
  primaryImageURL: string;
  primaryImageId: string;
  purchaseDate: Date;
  makeName: string;
  modelName: string;
  plateName: string;
  fuelTypeName: string;
  transmissionTypeName: string;
  odometer: number;
  bidAmt: number;
}
