import {BaseIntDTO} from "../_baseIntDTO.interface";
import {FaultCategoryDTO} from "./fault-category.dto.interface";
import {FaultCheckTypeCategoryDTO} from "./fault-check-type-category.dto.interface";

export interface FaultCheckTypeDTO extends BaseIntDTO {
  name?: string;
  description?: string;
  faultCheckTypeCategories?: FaultCheckTypeCategoryDTO[];
  isDefault?: boolean;
}
