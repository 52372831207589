import {Component} from '@angular/core';
import {AppComponent} from "../../app/app.component";
import {DataService} from "../../../services";

@Component({
  templateUrl: './contact-us-wrapper.component.html',
  styleUrls: ['./contact-us-wrapper.component.css'],
})

export class ContactUsWrapperComponent {

  globals = AppComponent.globals;

  constructor(
    data: DataService
  ) {

  }

  map = {
    lat: parseFloat("51.68749628175348"),
    lng: parseFloat("-0.04487011464722376"),
    label: ''
  };
}
