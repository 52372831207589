import {Injectable} from '@angular/core';
import * as moment from 'moment';

// import * as rg4js from 'raygun4js';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6
}

@Injectable()
export class LogService {
  level: LogLevel = LogLevel.All;
  logWithDate = true;
  componentName: string;

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private shouldLog(level: LogLevel): boolean {
    let ret = false;
    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, data: any[]) {
    if (this.shouldLog(level)) {
      const entry = new LogEntry();
      entry.componentName = `(${this.componentName || "No Name"}) `;
      entry.message = msg;
      entry.level = level;
      entry.logWithDate = this.logWithDate;
      entry.data = data;

      const colBase = "color: ";
      let col = "";

      switch (level) {
        case LogLevel.Debug:
          col = "#152";
          break;
        case LogLevel.Info:
          col = "blue";
          break;
        case LogLevel.Warn:
          col = "orange";
          break;
        case LogLevel.Error:
          col = "red";
          break;
        case LogLevel.Fatal:
          col = "red";
          break;
        default:
          col = "black";
          break;
      }

      const errorMsg = entry.buildLogString();

      if (!data) {
        console.log("%c" + errorMsg, colBase + col);
      } else {
        console.log("%c" + errorMsg, colBase + col, data);
      }

      if (level === LogLevel.Error || level === LogLevel.Fatal) {

        try {
          throw new Error(msg);
        } catch (e) {
          // rg4js('send', e);
        }
      }
    }
  }
}

export class LogEntry {
  // Public Properties
  componentName = "";
  entryDate: Date = new Date();
  message = "";
  level: LogLevel = LogLevel.All;
  data: any[] = [];
  logWithDate = true;

  buildLogString(): string {
    let ret = "";

    if (this.logWithDate) {
      ret += moment().format("HH:mm") + " ";
    }

    ret += this.componentName;
    ret += "[" + LogLevel[this.level] + "]";
    ret += " " + this.message;
    // if (this.data.length) {
    //   ret += " - Data: " + this.formatParams(this.data);
    // }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(",");

    // Is there at least one object in the array?
    if (params.some(p => typeof p === "object")) {
      ret = "";

      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ",";
      }
    }

    return ret;
  }
}
