import {UserCustomer} from "./user-customer-interface";
import {BaseUser} from "./base-user-interface";

export interface User extends BaseUser {
  contactId?: string;
  customerId?: string;
  customerStatusId?: number;
  platformId?: number;
  customerName?: string;
  isSeller?: boolean;
  isBuyer?: boolean;
  isSupplier?: boolean;
  customers?: UserCustomer[];
  contactAddressId: string;
  contactPostcode?: string;
  contactPhone?: string;
  isAuctioneer?: boolean;
  name?: string;
}
