import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";

@Injectable()
export class FileReaderService {
  constructor(private http: HttpClient) {}

  public getExternalDataFile(fileName: string): Promise<any> {
    const headers = new HttpHeaders({ "X-Skip-Interceptor": "True" });
    headers.append("Content-Type", "application/text; charset=UTF-8");

    return this.http
      .get(`assets/data/${fileName}`, {
        responseType: "text",
        headers,
      })
      .toPromise();
  }
}
