import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import {AppComponent} from '../components';

@Pipe({
  name: 'sortPipe',
})
export class SortPipe implements PipeTransform {

  transform(array: any, field: string, desc: boolean = false, isDate: boolean = false): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    if (desc) {
      array.sort(function (a, b) {
        if (isDate) {
          return new Date(b[field]).getDate() - new Date(a[field]).getDate();
        } else {
          return b[field] - a[field]
        }
      });
    }
    else {
      if (isDate) {
        array.sort(function(a, b) {
          return new Date(a[field]).getDate() - new Date(b[field]).getDate();
        });
      } else {
        array.sort(function(a, b) {
          return a[field] - b[field]
        });
      }
    }

    return array;
  }
}
