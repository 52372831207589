import {Injectable} from '@angular/core';
import {DataService} from "./data.service";
import {ApiService} from '../global/services/index';

@Injectable()
export class ContactRoleService {

  private serviceUrl = '/api/ContactRoles';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

}
