import {Injectable} from "@angular/core";
import {
  ScanConfigDTO,
  ScanCustomerDTO,
  ScanFieldDTO,
  ScanPreviewDTO,
  ScanSampleResponseDTO, ScanServiceDTO,
  ScanStageDTO,
  ScanStyleDTO
} from "../../../../global/interfaces";
import {BaseSearchDTO, ScanStatsDTO} from "../../../../global/interfaces";
import {ApiService} from "../../../../global/services";
import {DataService} from "../../../../services";

@Injectable()
export class ScanningService {

  private serviceUrl = "/api/scan";

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  /* ------ SCAN SAMPLE --- */
  public getScanSamples(dto?: BaseSearchDTO): Promise<ScanSampleResponseDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/samples`;
    return this.apiClient.get({url}, {query: dto}) as Promise<ScanSampleResponseDTO>;
  }

  /* ------ SCAN PREVIEW --- */

  public requestScanPreview(dto?: ScanPreviewDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/requestPreview`;
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  public requestScan(dto?: ScanPreviewDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/scanCustomer/${dto.scanCustomerId}/scheduleScan`;
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  /* ----- SCAN QUEUES ------ */

  public getScanQueues(dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/queues`;
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  /* ----- SCAN FIELD ------ */

  public getScanFields(dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/fields`;
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  public postScanField(x: ScanFieldDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/field`;
    return this.apiClient.post({url, data: x}) as Promise<any>;
  }

  public patchScanField(x, y): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/field/` + x;
    return this.apiClient.patch({url, data: y}) as Promise<any>;
  }

  public deleteScanField(x: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/field/` + x;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  /* ----- SCAN CUSTOMER ------ */

  public getScanCustomer(x: number, dto?: BaseSearchDTO): Promise<ScanCustomerDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/customer/` + x;
    return this.apiClient.get({url}, {query: dto}) as Promise<ScanCustomerDTO>;
  }

  public getScanCustomers(dto: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/customers`;
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  public postScanCustomer(x: ScanCustomerDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/customer`;
    return this.apiClient.post({url, data: x}) as Promise<any>;
  }

  public patchScanCustomer(x, y): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/customer/` + x;
    return this.apiClient.patch({url, data: y}) as Promise<any>;
  }

  public deleteScanCustomer(x: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/customer/` + x;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  /* ----- SCAN STYLE ------ */

  public getScanStyles(dto?: BaseSearchDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/styles`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: dto}) as Promise<ScanStyleDTO[]>;
  }

  public getScanStyle(scanStyleId: number, dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/style/${scanStyleId}`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: dto}) as Promise<ScanStyleDTO[]>;
  }

  public clearErrors(x: { clearType: string, clearValue: number, scanFieldId: number }): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/clearErrors`;
    return this.apiClient.post({url, data: x}) as Promise<ScanStyleDTO[]>;
  }

  public postScanStyle(x: ScanStyleDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/style`;
    return this.apiClient.post({url, data: x}) as Promise<any>;
  }

  public patchScanStyle(x: number, y): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/style/` + x;
    return this.apiClient.patch({url, data: y}) as Promise<any>;
  }

  public deleteScanStyle(x: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/style/` + x;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  /* ----- SCAN STAGE ------ */

  public getScanStages(dto?: BaseSearchDTO): Promise<any> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/stages`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: dto}) as Promise<ScanStageDTO[]>;
  }

  public getScanStage(scanStageId: number, dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stage/${scanStageId}`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: dto}) as Promise<ScanStageDTO>;
  }

  public patchScanStage(scanStageId: number, y): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stage/` + scanStageId;
    return this.apiClient.patch({url, data: y}) as Promise<any>;
  }

  public addScanStage(dto?: ScanStageDTO): Promise<ScanStageDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stage`;
    return this.apiClient.post({url, data: dto}) as Promise<ScanStageDTO>;
  }

  public deleteScanStage(x: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stage/` + x;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  /* ----- SCAN SERVICE ------ */

  public getScanServices(dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/services`;
    return this.apiClient.get({url}, {query: dto}) as Promise<ScanServiceDTO[]>;
  }

  public postScanService(x: ScanFieldDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/service`;
    return this.apiClient.post({url, data: x}) as Promise<any>;
  }

  public deleteScanService(x: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/service/` + x;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public patchScanService(x: number, y): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/service/` + x;
    return this.apiClient.patch({url, data: y}) as Promise<any>;
  }

  /* ------ SCAN CONFIG ----- */

  public setScanConfig(dto: ScanConfigDTO) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stage/` + dto.scanStageId + "/config";
    return this.apiClient.post({url, data: dto}) as Promise<any>;
  }

  public getScanStats(): Promise<ScanStatsDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/stats`;
    return this.apiClient.get({url}) as Promise<ScanStatsDTO>;
  }

  public refreshScanStats(): Promise<ScanStatsDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/updateStats`;
    return this.apiClient.get({url}) as Promise<ScanStatsDTO>;
  }

  viewCache(scanQueueId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/queue/${scanQueueId}/viewCache`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public getScanVehicles(dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/vehicles`;

    dto = dto || {};

    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  /* ERRORS */

  public getScanErrorSummary(dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/errorSummary`;
    dto = dto || {};
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }

  public getScanErrorDetail(dto?: BaseSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/errorDetail`;
    dto = dto || {};
    return this.apiClient.get({url}, {query: dto}) as Promise<any>;
  }
}
