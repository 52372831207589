import { ValuationLookupTypeEnum } from "../../../../uinspect/src/app/global/enums/index";
import {BaseDTO, BaseSearchDTO} from "../../../../uinspect/src/app/global/interfaces/index";


export interface IValuationNode extends BaseDTO {
  pillar1?: number;
  makeName?: string;
  pillar2?: number;
  modelName?: string;
  pillar3?: number;
  ageName?: string;
  pillar4?: number;
  derivName?: string;
  pillar5?: number;
  lookupType?: ValuationLookupTypeEnum;
  lookupId?: string; // can be a pointer to a table or a composite key
  lookupName?: string; // the name of the lookup via the id (i.e. Toyota for Make)
  addValue?: number; // if not null then ignore multiplier and add this value to current amount
  multiplier?: number;
  noQuote?: boolean;
  callForQuote?: boolean;
}

export interface IValuationNodeSearchDTO extends BaseSearchDTO
{
  filters?: {
    valuationProfileId?: string;
  };
}
