import {Injectable} from '@angular/core';
import {DataService, URLService} from "../../../services";
import {ApiService} from '../../../global/services';
import {
  AdminExtLeadDTO,
  AdminExtLeadSearchDTO,
  AdminExtLeadVehicleDTO,
  TypedSearchResultDTO, TypedValidatedResultDTO
} from '../../../global/interfaces';

@Injectable()
export class AdminExtLeadService {

  constructor(
    public urlService: URLService,
    private apiClient: ApiService,
    private data: DataService,
  ) {
  }

  parseFile(json: string): Promise<AdminExtLeadDTO[]> {
    const url = `${this.data.apiUrl}/api/ext-lead/parse`;
    return this.apiClient.post({url, data: {json}}) as Promise<AdminExtLeadDTO[]>;
  }

  getLeads(query: { component?: string, filters?: { dealerName?: string, vehicle?: string, postcode?: string } }) {
    console.log("QUERY ", query);
    const url = `${this.data.apiUrl}/api/ext-leads`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<AdminExtLeadDTO>>;
  }

  getLeadVehicles(query: { filters?: { extLeadId?: string } }) {
    const url = `${this.data.apiUrl}/api/ext-lead/vehicles`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<AdminExtLeadVehicleDTO>>;
  }

  getLead(extLeadId: string, query: AdminExtLeadSearchDTO) {
    const url = `${this.data.apiUrl}/api/ext-lead/` + extLeadId;
    return this.apiClient.get({url}, {query}) as Promise<TypedValidatedResultDTO<AdminExtLeadDTO>>;
  }

  patchLead(extLeadId: string, patch: any[]): Promise<AdminExtLeadDTO> {
    const url = `${this.data.apiUrl}/api/ext-lead/${extLeadId}`;
    return this.apiClient.patch({url, data: patch}) as Promise<AdminExtLeadDTO>;
  }
}
