// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.larger-checkbox {
  transform: scale(1.5, 1.5);
}

.expired {
  font-weight: 600;
  color: var(--errorColour);
}

.table-striped .current-lot {
  background-color: var(--highlightedRowBackgroundColour) !important;
}

.price-input {
  width: 110px;
}

.lotno-cell {
  white-space: nowrap;
}

.make-model {
  font-weight: bold;
  line-height: 16px;
}

.fuel-trans {
  line-height: 16px;
}

.clock-style {
  color: var(--errorColour);
  cursor: pointer;
}

.unlot {
  color: #c00;
  border-radius: 3px;
  display: inline-block;
  padding: 3px 4px;
}
.unlot:hover {
  color: #fff;
  background-color: #c00;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 4px;
}

.action-button {
  width: 130px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/sale-view/sale-view.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,gBAAA;EACA,yBAAA;AACF;;AAEA;EACE,kEAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAIA;EACE,iBAAA;EACA,iBAAA;AADF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EAAe,yBAAA;EAA2B,eAAA;AAE1C;;AAAA;EACE,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,gBAAA;AAGF;AADE;EACE,WAAA;EACA,sBAAA;AAGJ;;AACA,2CAAA;AACA;EACE,sDAAA;AAEF;;AACA;EACE,sBAAA;EACA,kBAAA;EACA,qHAAA;EAEA,YAAA;AACF;;AAEA;EAAiB,YAAA;EAAc,gBAAA;AAG/B","sourcesContent":[".larger-checkbox {\n  transform: scale(1.5, 1.5);\n}\n\n.expired {\n  font-weight: 600;\n  color: var(--errorColour);\n}\n\n.table-striped .current-lot {\n  background-color: var(--highlightedRowBackgroundColour) !important;\n}\n\n.price-input {\n  width: 110px;\n}\n\n.lotno-cell {\n  white-space: nowrap;\n}\n\n\n\n.make-model {\n  font-weight: bold;\n  line-height: 16px;\n}\n.fuel-trans {\n  line-height: 16px;\n}\n\n.clock-style { color: var(--errorColour); cursor: pointer; }\n\n.unlot {\n  color: #c00;\n  border-radius: 3px;\n  display: inline-block;\n  padding: 3px 4px;\n\n  &:hover {\n    color: #fff;\n    background-color: #c00;\n  }\n}\n\n/* Animate items as they're being sorted. */\n.cdk-drop-list-dragging .cdk-drag {\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\n}\n\n.cdk-drag-preview {\n  box-sizing: border-box;\n  border-radius: 4px;\n  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),\n  0 3px 14px 2px rgba(0, 0, 0, 0.12);\n  padding: 4px;\n}\n\n.action-button { width: 130px; text-align: left; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
