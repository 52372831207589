// Think of MessageAreas as areas within the messageGroup (audience)

export enum MessageAreaEnum {
  Bids = 0,
  Adverts,
  Sales,
  User,
  Contacts,
  InMails,
  Global,
  Auctioneer
}
export const MessageAreaName = [
  "Bids",
  "Adverts",
  "Sales",
  "User",
  "Contacts",
  "InMails",
  "Global",
  "Auctioneer"
];
