import {BaseSearchDTO} from "../baseSearchDTO.interface";
import {ContactActionTypeEnum} from "../../../../enums";

export interface WhosWhoAdminSearchDTO extends BaseSearchDTO {
  filters?: {
    customerId?: string;
    assignedTo?: string;
    daysAgo?: number;
    contactAction?: ContactActionTypeEnum;
    notLoggedInDaysAgo?: number;
    notAdvertisedDaysAgo?: number;
  };
}
