export interface SaveProspectNoteDTO {
  advertId: string;
  prospectId?: string;
  brokerageId?: string;
  prospectActionId: number;
  note?: string;
  prospectContactId: string;
  manuallyAdded: boolean;

  // the contact of the user who created the history record
  contactId: string;
  reminderDateTime?: Date;
}
