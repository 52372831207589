import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {compare} from "fast-json-patch";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces";
import {UInspectAnswerSearchDTO} from "../../interfaces/DTOs/UInspect/API";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {UInspectAnswerDTO} from "../../interfaces/DTOs/UInspect";

@Injectable()
export class UInspectAnswerService {

  private serviceUrl = '/api/uinspect/answer';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
    private toast: ToastService,
  ) {
    // this.logging.componentName = "UInspectAnswerService";
  }

  public search(searchDTO?: UInspectAnswerSearchDTO): Promise<SearchResultDTO> {
    const dto = JSON.stringify(searchDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: dto}) as Promise<SearchResultDTO>;
  }

  public get(id: number, query: UInspectAnswerSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  public add(dto: UInspectAnswerDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public put(data: UInspectAnswerDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}/api/uinspect/${data.uInspectId}/question/${data.uInspectQuestionId}/answer`;

    return this.apiClient.put({url, data}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: number, patchData: UInspectAnswerDTO, previous: UInspectAnswerDTO = {}): Promise<ValidatedResultDTO> {
    const data = compare(previous, patchData);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: number): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }
}
