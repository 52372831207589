// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stack-list {
  display: flex;
  flex-wrap: wrap;
}

.list-container {
  flex: 1 1 200px;
  flex-direction: column;
}

.filter-container {
  flex: 1 0 24px;
  flex-direction: column;
}

.bidder-number {
  font-weight: 500;
  width: 50px;
  font-size: 0.75rem;
  display: inline-block;
  padding: 2px 4px;
  background-color: var(--primaryButtonColour);
  color: #fff;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/attendees/admin-attendees.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,sBAAA;AACF;;AAEA;EACE,cAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,4CAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".stack-list {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.list-container {\n  flex: 1 1 200px;\n  flex-direction: column;\n}\n\n.filter-container {\n  flex: 1 0 24px;\n  flex-direction: column;\n}\n\n.bidder-number {\n  font-weight: 500;\n  width: 50px;\n  font-size: 0.75rem;\n  display: inline-block;\n  padding: 2px 4px;\n  background-color: var(--primaryButtonColour);\n  color: #fff;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
