import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {AttribDTO, AttribSearchDTO, AttribvalDTO, VehicleAttribDTO} from '../global/interfaces/index';
import {DataService} from "./data.service";
import {ApiService} from '../global/services/index';
import {} from "../global/interfaces";
import {} from "../global/services";
import {} from "../global/enums";

@Injectable()
export class AttribService {

  private serviceUrl = '/api/Attrib';
  private attribvalUrl = '/api/Attribval';
  private vehicleAttribsUrl = '/api/vehicleattribs';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  getAll(search?: AttribSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: search}) as Promise<any>;
  }

  get(attribId?: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${attribId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  create(attrib: AttribDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: attrib}) as Promise<any>;
  }

  patch(dto: AttribDTO): Promise<any> {

    const patch = compare({}, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  delete(attribId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${attribId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  getAttribvals(attribId: number): Promise<AttribvalDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${attribId}/attribvals`;
    return this.apiClient.get({url}) as Promise<AttribvalDTO[]>;
  }

  createAttribval(attribval: AttribvalDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.attribvalUrl}`;
    return this.apiClient.post({url, data: attribval}) as Promise<any>;
  }

  patchAttribval(dto: AttribDTO, previousDTO: AttribDTO = {}): Promise<any> {
    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.attribvalUrl}/${dto.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  deleteAttribval(attribId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.attribvalUrl}/${attribId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  createVehicleAttrib(vehicleAttrib: VehicleAttribDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.vehicleAttribsUrl}`;
    return this.apiClient.post({url, data: vehicleAttrib}) as Promise<any>;
  }

  deleteVehicleAttrib(vehicleAttribId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.vehicleAttribsUrl}/${vehicleAttribId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
