import {CustomerGroupDTO} from "./customerGroupDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {AdminTaskDTO} from "./adminTaskDTO.interface";
import {CustomerAttribDTO} from "./customerAttribDTO.interface";
import {BaseCustomerDTO} from "./baseCustomerDTO.interface";
import {CustomerTypeEnum} from "../../enums/index";
import {CustomerInternalInfoDTO} from "./customerInternalInfoDTO.interface";

export interface CustomerDTO extends BaseCustomerDTO {
  billingCustomer?: CustomerDTO;
  parentCustomer?: CustomerDTO;
  customerGroup?: CustomerGroupDTO;
  adminTasks?: AdminTaskDTO[];
  primaryContact?: ContactDTO;
  customerAttribs?: CustomerAttribDTO[];
  customerType?: CustomerTypeEnum;
  customerInternalInfo?: CustomerInternalInfoDTO;
}
