import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'mileage',
})
export class MileagePipe implements PipeTransform {

  transform(
    value: number,
  ): string | null {

    if (value < 1000) {
      return value.toString();
    }
    else {
      return Math.floor(value / 1000) + "k";

    }
  }
}
