// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fa-times-circle {
  font-size: 1.2rem;
}

td {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/maintenance/platform/platform.component.scss"],"names":[],"mappings":"AAAA;EAAmB,iBAAA;AAEnB;;AADA;EAAK,gBAAA;AAKL","sourcesContent":[".fa-times-circle { font-size: 1.2rem; }\ntd { font-weight: 400; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
