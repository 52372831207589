import {StatusDTO} from './statusDTO.interface';
import {AdvertDTO} from "./advertDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {BidStatusEnum} from '../../enums/bid-status.enum';
import {CustomerDTO} from "./customerDTO.interface";
import {SaleDTO} from "./Sale/saleDTO.interface";
import {OfferDTO} from "./offerDTO.interface";
import {BidTypeEnum} from "../../enums/bid-type.enum";

export interface BidDTO extends BaseDTO {
  bidGuid?: string;
  expires?: string;
  bidAmt?: number;
  bidStatus?: BidStatusEnum;
  advertId?: string;
  contactId?: string;
  customerId?: string;
  buyItNow?: boolean;
  autoBid?: boolean;
  bidPlaced?: Date;
  acceptanceSent?: boolean;
  outbidSent?: boolean;
  advert?: AdvertDTO;
  contact?: ContactDTO;
  customer?: CustomerDTO;
  offersRemaining?: number;
  sale?: SaleDTO;
  offer?: OfferDTO;
  hasNegotiation?: boolean;
  bidType?: BidTypeEnum;
  bidderName?: string;
  isOffer?: boolean;
}
