import {Injectable} from '@angular/core';
import {ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {ApiService} from "../api.service";
import {
  ValuationAttributeDTO,
  ValuationPillarDTO, ValuationProfileDTO,
  ValuationRequestDTO,
  ValuationSearchDTO
} from "../../interfaces/DTOs/Valuations/index";
import {DataServiceInterface} from "../interfaces/index";

@Injectable(
  {providedIn: 'root'}
)
export class AdminValuationService {

  private serviceUrl = '/api/valuation';
  private profileUrl = '/api/valuationProfile';

  constructor(private apiClient: ApiService, private data: DataServiceInterface) {
  }

  getLookupAttributes(): Promise<ValuationAttributeDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/lookupAttributes`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<ValuationAttributeDTO[]>;
  }

  addPillarNode(pillarDTO: ValuationPillarDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}/api/valuationPillar`;
    return this.apiClient.post({url, data: pillarDTO}) as Promise<ValidatedResultDTO>;
  }

  deleteValuationNode(id): Promise<boolean> {
    const url = `${this.data.apiUrl}/api/valuationNode/${id}`;
    return this.apiClient.delete({url}) as Promise<boolean>;
  }

  setNodeValue(profile, pillar1, pillar2, pillar3, pillar4, pillar5, valuationType, valuationId, data): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}/api/valuationNode/${profile}/` +
      `${pillar1 ? pillar1 : 0}/` +
      `${pillar2 ? pillar2 : 0}/` +
      `${pillar3 ? pillar3 : 0}/` +
      `${pillar4 ? pillar4 : 0}/` +
      `${pillar5 ? pillar5 : 0}/` +
      `${valuationType}/${valuationId}`;

    return this.apiClient.put({url, data}) as Promise<ValidatedResultDTO>;
  }

  search(searchDTO: ValuationSearchDTO): Promise<ValuationProfileDTO[]> {

    const url = `${this.data.apiUrl}/api/valuationProfiles`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: searchDTO}) as Promise<ValuationProfileDTO[]>;
  }

  addProfile(profileDTO: ValuationProfileDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.profileUrl}`;
    return this.apiClient.post({url, data: profileDTO}) as Promise<ValidatedResultDTO>;
  }


  calculateValuation(request: ValuationRequestDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/calculate`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: request}) as Promise<ValidatedResultDTO>;
  }


  makeProfilePrimary(id: string): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.profileUrl}/${id}/makePrimary`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  patchProfile(profileId: string, patch) {
    const url = `${this.data.apiUrl}${this.profileUrl}/${profileId}`;
    return this.apiClient.patch({url, data: patch}) as Promise<ValidatedResultDTO>;
  }
}
