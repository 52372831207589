import {ScanVehicleDTO} from "./scanVehicleDTO.interface";
import {ScanStageDTO} from "./scanStageDTO.interface";
import {ScanQueueDTO} from "./scanQueueDTO.interface";
import {ScanFieldDTO} from "./scanFieldDTO.interface";
import {ScanStyleDTO} from "./scanStyleDTO.interface";
import {ScanCustomerDTO} from "./scanCustomerDTO.interface";
import {RemarqCustomerDTO} from "./remarqCustomerDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanErrorDTO extends BaseIntDTO {
  customer: RemarqCustomerDTO;
  customerId: string;
  scanCustomer: ScanCustomerDTO;
  scanCustomerId: number;
  scanStyle: ScanStyleDTO;
  scanStyleId: number;
  scanFunction: string;
  scanVehicle: ScanVehicleDTO;
  scanVehicleGuid: string;
  scanVehicleId: number;
  scanField: ScanFieldDTO;
  scanFieldId: number;
  scanQueue: ScanQueueDTO;
  scanQueueId: number;
  scanStage: ScanStageDTO;
  scanStageId: number;
  reason: string;
  cleared: boolean;
}
