import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import { TaxDTO } from '../global/interfaces';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class TaxService {

  private serviceUrl = '/api/tax';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  search(): Promise<TaxDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}es`;
    return this.apiClient.get({url}) as Promise<TaxDTO[]>;
  }

  create(tax: TaxDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: tax}) as Promise<any>;
  }

  delete(id: number): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  patch(taxDTO: TaxDTO, previousDTO: TaxDTO = {}): Promise<any> {

    const patch = compare(previousDTO, taxDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${taxDTO.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }
}
