import {Component, OnInit} from '@angular/core';
import {AdvertService, WatchlistService} from '../../../services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {User, WatchlistAdvertDTO, WatchlistDTO} from "../../../global/interfaces";
import {LoggerService, UserService} from "../../../global/services";
import {} from "../../../global/enums";

@Component({
  selector: 'app-my-watchlist',
  templateUrl: './my-watchlist.component.html',
  styleUrls: ['./my-watchlist.component.scss']
})
export class MyWatchlistComponent implements OnInit {

  constructor(public watchlistService: WatchlistService,
              private advertService: AdvertService,
              private userService: UserService,
              private formBuilder: UntypedFormBuilder,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  watchlist: WatchlistAdvertDTO[];
  user: User;
  isLoading: boolean;
  fg: UntypedFormGroup;

  async ngOnInit() {
    this.isLoading = true;

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.watchlistService.loadWatchlistItems(this.user.contactId).then((watchlist) => {

      this.fg = this.formBuilder.group({keywords: new UntypedFormControl('')});

      // get all adverts from the watchlist
      this.watchlistService.getWatchlistWithAdverts().then((listwithAdverts) => {
        this.logger.info("WATCHLIST: ", listwithAdverts);

        this.watchlist = listwithAdverts;
        this.isLoading = false;
      });
    });
  }

  searchAdverts() {
    this.logger.info("Search for: ", this.fg.controls['keywords'].value);
  }

  haveValidWatchlistItems() {

    return this.watchlist.some(item => item.advert != null);
  }
}
