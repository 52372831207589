// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  justify-content: space-around;
  align-items: flex-start;
}

.stats-item {
  flex: 1;
  width: 100%;
}

canvas {
  display: block;
  width: 100% !important;
  padding: 5px;
}

.stats-detail-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.chart-container {
  max-height: 340px;
  overflow-y: auto;
}

.chart-canvas {
  max-height: 340px;
  width: 100%;
}

.detail-chart-container {
  max-height: 240px;
  overflow-y: auto;
}

.detail-chart-canvas {
  max-height: 500px;
  width: 100%;
}

.flex-item {
  flex: 1;
  margin: 10px;
  position: relative;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */
  border-radius: 5px; /* Rounded corners for a smoother look */
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/admin-call-records/admin-call-records.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,6BAAA;EACA,uBAAA;AACF;;AAEA;EACE,OAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,OAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,wCAAA,EAAA,uBAAA;EACA,kBAAA,EAAA,wCAAA;AACF","sourcesContent":[".stats-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 12px;\n  justify-content: space-around;\n  align-items: flex-start;\n}\n\n.stats-item {\n  flex: 1;\n  width: 100%;\n}\n\ncanvas {\n  display: block;\n  width: 100% !important;\n  padding: 5px;\n}\n\n.stats-detail-container {\n  display: flex;\n  justify-content: space-around;\n  flex-direction: row;\n}\n\n.chart-container {\n  max-height: 340px;\n  overflow-y: auto;\n}\n\n.chart-canvas {\n  max-height: 340px;\n  width: 100%;\n}\n\n.detail-chart-container {\n  max-height: 240px;\n  overflow-y: auto;\n}\n\n.detail-chart-canvas {\n  max-height: 500px;\n  width: 100%;\n}\n\n.flex-item {\n  flex: 1;\n  margin: 10px;\n  position: relative;\n  padding: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle drop shadow */\n  border-radius: 5px; /* Rounded corners for a smoother look */\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
