import {VehicleTypeEnum} from "../../../enums/index";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {UInspectSectionDTO, UInspectSectionSyncDTO} from "./uinspect-section-dto.interface";

export interface UInspectFormatDTO extends BaseIntDTO {

  vehicleTypeId?: VehicleTypeEnum;
  title?: string;
  description?: string;
  disabled?: boolean;
  default?: boolean;
  vehicleType?: VehicleTypeEnum; // some sections aren't relevant to all cars (i.e. Rear Seats)
  sections?: UInspectSectionDTO[];
  startSectionId?: number;
}

export interface UInspectFormatSyncDTO extends UInspectFormatDTO {

  sections?: UInspectSectionSyncDTO[];
  synced?: boolean;
}
