import {BidDTO} from "../bidDTO.interface";

export interface InternalBidInfoDTO {
  mb?: BidDTO;
  fa?: boolean;
  tb?: BidDTO;
  bs?: number;
  lb?: BidDTO;
  bl?: number; // Bids left
}
