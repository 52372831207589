import {BaseDTO} from './_baseDTO.interface';
import {BidStatusEnum} from '../../enums/bid-status.enum';
import {ContactDTO} from './contactDTO.interface';
import {CustomerDTO} from './customerDTO.interface';
import {SaleDTO} from './Sale/saleDTO.interface';
import {OfferDTO} from './offerDTO.interface';
import {AdvertSummaryDTO} from './API/advertSummaryDTO';

export interface BidSummaryDTO extends BaseDTO {
  expires?: string;
  bidAmt?: number;
  bidStatus?: BidStatusEnum;
  advertId?: string;
  contactId?: string;
  customerId?: string;
  buyItNow?: boolean;
  autoBid?: boolean;
  bidPlaced?: Date;
  acceptanceSent?: boolean;
  outbidSent?: boolean;
  advert?: AdvertSummaryDTO;
  contact?: ContactDTO;
  customer?: CustomerDTO;
  isOffer?: boolean;
  offersRemaining?: number;
  sale?: SaleDTO;
  offer?: OfferDTO;
}
