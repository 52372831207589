// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.news {
  margin-left: 0px;
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0px !important;
}
ul.news li {
  font-size: 0.75rem;
  line-height: 1.1rem;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
ul.news li:not(:last-child) {
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
  border-bottom: 1px solid #eee;
}
ul.news li:last-child {
  padding-bottom: 2px !important;
  margin-bottom: 2px !important;
}
ul.news li span {
  position: relative;
  font-weight: 300;
  text-align: justify;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/dashboard/industry-news/industry-news.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,6BAAA;AAAF;AAEE;EAEE,kBAAA;EACA,mBAAA;EACA,2BAAA;EACA,4BAAA;EACA,4BAAA;EACA,6BAAA;AADJ;AAGI;EACE,8BAAA;EACA,6BAAA;EACA,6BAAA;AADN;AAGI;EACE,8BAAA;EACA,6BAAA;AADN;AAII;EAEE,kBAAA;EACA,gBAAA;EACA,mBAAA;AAHN","sourcesContent":["ul.news {\n\n  margin-left: 0px;\n  padding-left: 0px;\n  list-style-type: none;\n  margin-bottom: 0px !important;\n\n  li {\n\n    font-size: 0.75rem;\n    line-height: 1.1rem;\n    margin-left: 0px !important;\n    margin-right: 0px !important;\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n\n    &:not(:last-child) {\n      padding-bottom: 5px !important;\n      margin-bottom: 5px !important;\n      border-bottom: 1px solid #eee;\n    }\n    &:last-child {\n      padding-bottom: 2px !important;\n      margin-bottom: 2px !important;\n    }\n\n    span {\n\n      position: relative;\n      font-weight: 300;\n      text-align: justify;\n\n    }\n\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
