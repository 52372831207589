import {BaseSearchDTO} from "./baseSearchDTO.interface";
import {ContactActionTypeEnum} from "../../../enums";

export interface ContactActionSearchDTO extends BaseSearchDTO {
  filters?: {
    customerId?: string;
    contactId?: string;
    statusId?: number;
    contactAction?: ContactActionTypeEnum;
    daysAgo?: number;
  };
}
