import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AttribService} from '../../../../services';
import {StatusService} from '../../../../services';
import {MdbTablePaginationComponent, ModalDirective} from "ng-uikit-pro-standard";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AttribDTO, AttribvalDTO} from "../../../../global/interfaces";
import {HelpersService} from "../../../../global/services";
import {StatusEnum, VehicleTypeEnum} from "../../../../global/enums";

@Component({
  selector: 'app-main',
  templateUrl: './attribval.component.html',
  styleUrls: ['./attribval.component.scss', '../../../../../../../common/components/app-side-menu/app-side-menu.component.scss'],
  providers: []
})
export class AttribvalMaintComponent implements OnInit {

  mdbTablePagination: MdbTablePaginationComponent;

  @ViewChild('attribvalModal') attribvalModal: ModalDirective;

  public statusOptions: {}[];
  public statusName: string[] = [];
  public showDeleteConfirm: boolean;
  public attribId: number;
  public attribvalIdToDelete: number;

  constructor(
    private attribService: AttribService,
    private statusService: StatusService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private helpersService: HelpersService
  ) {


  }

  attrib: AttribDTO;
  attribvalList: AttribvalDTO[] = [];
  vehicleTypeOptions: {}[] = [];
  vehicleTypeName: string[] = [];
  isLoading: boolean;
  submitted: boolean;
  isEdit = false;
  form: UntypedFormGroup;

  async ngOnInit() {

    this.attribId = this.route.snapshot.params.attribId;

    this.attribService.get(this.attribId).then((attrib) => {
      this.attrib = attrib;
    });

    await this.initForm();
    this.loadAttribvals();
    this.loadStatuses();
    this.loadVehicleTypes();
  }

  async loadAttrib() {


  }

  async initForm() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      vehicleTypeId: new UntypedFormControl(),
      attribvalName: new UntypedFormControl(),
      attribvalCode: new UntypedFormControl(),
      sortOrder: new UntypedFormControl(),
      statusId: new UntypedFormControl(''),
    });

  }

  async loadAttribvals() {

    this.attribService.getAttribvals(this.attribId).then(attribvals => {
      this.attribvalList = attribvals;
    });
  }

  addAttribval() {

    this.form.patchValue(({
      id: '',
      vehicleTypeId: '',
      attribvalName: '',
      attribvalCode: '',
      attribId: this.attribId,
      sortOrder: 0,
      statusId: StatusEnum.Active
    }));

    this.isEdit = false;

    // show modal that allows location name entry
    this.attribvalModal.show();
  }

  editAttribval($event: MouseEvent, attribvalDTO: AttribvalDTO) {

    this.form.patchValue(({
      ...attribvalDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.attribvalModal.show();
  }

  async saveAttribval() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const attribvalDTO = this.form.value;

    attribvalDTO.attribId = this.attribId;

    if (this.isEdit) {
      await this.attribService.patchAttribval(attribvalDTO);
    } else {
      delete attribvalDTO.id;
      await this.attribService.createAttribval(attribvalDTO);
    }

    this.attribvalModal.hide();
    await this.loadAttribvals();
  }

  async loadStatuses() {

    const statuses = this.helpersService.getNamesAndValues(StatusEnum);

    this.statusOptions = statuses.map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadVehicleTypes() {

    const vehicleTypes = this.helpersService.getNamesAndValues(VehicleTypeEnum);

    this.vehicleTypeOptions = vehicleTypes.map(vehicleType => {
      this.vehicleTypeName[vehicleType.value] = vehicleType.name;
      return {label: vehicleType.name, value: vehicleType.value};
    });
  }

  deleteAttribval(event, attribval: AttribvalDTO) {

    event.stopPropagation();
    this.attribvalIdToDelete = attribval.id;
    this.showDeleteConfirm = true;
  }

  hideModal() {
    this.attribvalModal.hide();
  }

  async confirmDelete() {

    await this.attribService.deleteAttribval(this.attribvalIdToDelete);
    this.attribvalModal.hide();
    await this.loadAttribvals();
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }

}

