import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
  AbstractControl, FormArray, FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {CountryService, URLService} from '../../../../services';
import {Router} from "@angular/router";
import {MDBModalService, ModalDirective, ToastService} from "ng-uikit-pro-standard";
import {AddressService} from "../../../../services";
import {compare} from "fast-json-patch";
import {AddressDTO, User} from "../../../../global/interfaces";
import {ContactService, HelpersService, UserService} from "../../../../global/services";
import {} from "../../../../global/enums";
import {GlobalConstants} from "../../../../global/shared";
import {} from "../../../../global/enums";
import {NgbdSortableHeader} from "../../../../global/directives";

export function postcodeValidator(postcodePattern: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = postcodePattern.test(control.value);
    return valid ? null : { invalidPostcode: { value: control.value } };
  };
}

@Component({
  selector: 'app-customer-addresses',
  templateUrl: './customer-addresses.component.html',
  styleUrls: ['./customer-addresses.component.scss'],
  providers: []
})
export class CustomerAddressesComponent implements OnInit, OnDestroy {

  @ViewChild("addressModal", {static: true}) addressModal: ModalDirective;
  @ViewChild('cannotDeleteModal') cannotDeleteModal: ModalDirective;

  public addressForm: UntypedFormGroup;
  public showDeleteConfirm = false;
  public showCannotDelete = false;
  private editAddress: AddressDTO;
  public isMobile = GlobalConstants.IsMobile;

  constructor(
    private addressService: AddressService,
    private countryService: CountryService,
    private router: Router,
    private helpersService: HelpersService,
    private contactService: ContactService,
    public url: URLService,
    public userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private modalService: MDBModalService,
    private toastService: ToastService) {
  }

  isLoading: boolean;

  public countrySelect: any[] = [];
  public currentAddressId?: string;
  public addresses: AddressDTO[] = [];
  public temp: {} = false;
  public searchTerm: string;
  public deleteAddressId: string = null;
  public deleteRow: number = null;
  public user: User;
  public newAddress = false;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  mobile = true;

  async ngOnInit() {

    this.isLoading = true;

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });


    this.initForm();


  }

  initForm() {
    this.addressForm = this.formBuilder.group({
      addressName: ['', Validators.required],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      addressLine3: [''],
      addressCounty: [''],
      addressPostcode: ['', [Validators.required, postcodeValidator(/^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/i)]],
      addressCountry: [''],
      countryId: [1, Validators.required],
    });

    this.loadAddresses();
    this.loadCountries();
  }

  get postcode() {
    return this.addressForm.get('addressPostcode');
  }
  get addressName() {
    return this.addressForm.get('addressName');
  }
  get addressLine1() {
    return this.addressForm.get('addressLine1');
  }


  loadCountries() {

    this.countryService.search().then((result) => {
      this.countrySelect = result.map(x => {
        return {label: x.countryName, value: x.id};
      }).sort();
    });
  }

  loadAddresses() {

    this.isLoading = true;

    this.addressService.getCustomerAddresses(this.user.customerId).then((result) => {
      this.addresses = result;

      this.temp = true;
      this.isLoading = false;
    });
  }

  submit() {
  }

  showCreateAddress() {

    this.currentAddressId = null;
    this.initForm();
    this.newAddress = true;
    this.addressModal.show();
  }

  showEditAddress(address: AddressDTO) {

    this.currentAddressId = address.id;
    this.addressForm.patchValue(address);
    this.editAddress = this.addressForm.value;
    this.newAddress = false;
    this.addressModal.show();
  }

  submitForm() {

    if (this.addressForm.invalid) {
      this.toastService.error("Please fill out all required fields", "Error", {opacity: 0.98});
      this.markAllControlsTouched(this.addressForm);
      return;
    }

    if (this.newAddress) {
      this.createAddress();
    } else {

      this.updateAddress();
    }
  }

  markAllControlsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAllControlsTouched(control);  // Recursively mark controls in nested FormGroups
      } else if (control instanceof FormArray) {
        control.controls.forEach((nestedControl) => {
          if (nestedControl instanceof FormGroup) {
            this.markAllControlsTouched(nestedControl);
          } else {
            nestedControl.markAsTouched({ onlySelf: true });
          }
        });
      }
    });
  }

  createAddress() {

    let addressDTO = Object.assign(this.addressForm.value);

    addressDTO.CustomerId = this.user.customerId;

    this.addressService.createAddress(addressDTO).then(() => {

      this.addressModal.hide();
      this.loadAddresses();
    });
  }

  updateAddress() {

    let addressDTO = Object.assign(this.addressForm.value);

    addressDTO.CustomerId = this.user.customerId;

    let patch = compare(this.editAddress, addressDTO);

    this.addressService.patchAddress(this.currentAddressId, patch).then(() => {

      this.addressModal.hide();
      this.loadAddresses();
    });
  }

  deleteAddress(rowId, address: AddressDTO) {

    if (address.isPrimary) {

      this.cannotDeleteModal.show();
      return false;
    }

    this.deleteAddressId = address.id;
    this.deleteRow = rowId;
    this.showDeleteConfirm = true;
  }

  confirmDelete() {

    this.addressService.deleteAddress(this.deleteAddressId)
      .then((result) => {

        this.addresses.splice(this.deleteRow, 1);

      });
  }

  onSubmit() {

  }

  ngOnDestroy() {
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }

  makePrimaryAddress(id: string) {

    this.addresses.forEach((x) => {
      x.isPrimary = (x.id === id);
    });

    this.addressService.makePrimaryAddress(id, this.user.contactId).then(async (x) => {

      await this.contactService.patch(this.user.contactId, {primaryAddressId: id});

    });

  }
}
