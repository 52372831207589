export interface DVLAVehicleDataDTO {
  registrationNumber: string // i.e. "WA68VSG"
  co2Emissions: number // i.e. 141
  engineCapacity: number // i.e. 1991
  markedForExport: boolean
  fuelType: string // i.e. "PETROL"
  motStatus: string; // i.e. "No details held by DVLA" or "Valid"
  revenueWeight: number // i.e. 1965
  colour: string // i.e. "BLACK"
  make: string // i.e. "MERCEDES-BENZ"
  typeApproval: string // i.e. "M1"
  yearOfManufacture: number // i.e. 2018
  taxDueDate?: Date // i.e. "2020-10-08"
  taxStatus: string // i.e. "Untaxed"
  dateOfLastV5CIssued?: Date // i.e. "2020-10-06"
  wheelplan: string // i.e. "2 AXLE RIGID BODY"
  monthOfFirstRegistration: string // i.e. "2018-09"
  errorStatus: string // null or empty if no errors (this is for mapping from DVLAData model)
}
