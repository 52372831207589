export enum AttribEnum {
  vehicle_grade = 1,
  vat_status = 2,
  v5_status = 3,
  nsf_tyre_dept = 4,
  nsr_tyre_depth = 5,
  osr_type_depth = 6,
  osf_tyre_dept = 7,
  spare_tyre_depth = 8,
  media_category = 9,
  notification_delivery_requested = 10,
  notification_collection_requested = 11,
  notification_lot_suspended = 12,
  notification_bid_outbid = 13,
  notification_proxy_outbid = 14,
  customer_reject_reason = 16,
  impersonate_user = 17,
  crm_display_format = 18,
  crm_allocation_type = 19
}
