import {BaseDTO} from "../../_baseDTO.interface";

export interface AdvertOfInterestDTO extends BaseDTO {
  description?: string;
  headline?: string;
  endDateTime?: Date;
  vehicle: VehicleOfInterestDTO;
}

export interface VehicleOfInterestDTO {
  vrm?: string;
  colour?: string;
  doors?: number;
  odometer?: number;
  owners?: number;
  primaryImageURL?: string;
}
