export enum UserRolesEnum {
  Admin = "ADMIN",
  AuctionAdmin = "AUCTION_ADMIN",
  Seller = "SELLER",
  Buyer = "BUYER",
  Supplier = "SUPPLIER",
  SuperAdmin = "GOD",
  CRMAdmin = "CRM_ADMIN",
  CRMUser = "CRM_USER",
  CRMOperator = "CRM_OPERATOR",
  CRMManager = "CRM_MANAGER",
  CRMAccounts = "CRM_ACCOUNTS",
}
