import {Injectable} from '@angular/core';
import {LoggerService} from "./index";
import {VehicleLookupInfoDTO} from "../interfaces/DTOs/API";
import {ApiService} from "./api.service";
import {DataServiceInterface} from "./interfaces/data.service.interface";
import {DVLAVehicleDataDTO, ScanImageDTO, ScanVehicleSearchResultDTO} from '../interfaces/DTOs';

@Injectable()
export class VehicleLookupService {
  private serviceUrl = '/api/vehiclelookup';

  constructor(private apiClient: ApiService, private data: DataServiceInterface,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  getVehicleInfo(vrm: string, odometer?: number): Promise<VehicleLookupInfoDTO> {

    const url: string = `${this.data.apiUrl}${this.serviceUrl}/${vrm}/${odometer}`;
    this.logger.debug("Fetching vehicle data 2, URL: ", url);

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<VehicleLookupInfoDTO>;
  }

  getScanVehicleImages(scanVehicleGuid: string): Promise<ScanImageDTO[]> {

    const url: string = `${this.data.apiUrl}/api/scanVehicle/scanImages/` + scanVehicleGuid;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<ScanImageDTO[]>;
  }

  getVehicleWithScanCheck(vrm: string): Promise<ScanVehicleSearchResultDTO[]> {

    const url: string = `${this.data.apiUrl}/api/scanVehicle/scanCheck/` + vrm;
    this.logService.debug("Fetching vehicle data 3, URL: ", url);

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<ScanVehicleSearchResultDTO[]>;
  }

  getVehicleWithoutScanCheck(vrm: string): Promise<ScanVehicleSearchResultDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${vrm}`;
    this.logger.debug("Fetching vehicle data 3, URL: ", url);

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<ScanVehicleSearchResultDTO[]>;
  }

  public getDVLADataLookup(vrm: string): Promise<DVLAVehicleDataDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/dvla/${vrm}`;
    return this.apiClient.get({url}) as Promise<DVLAVehicleDataDTO>;
  }
}
