import {Injectable} from '@angular/core';
import {LoggerService} from "../logger.service";
import {UserService} from "./user.service";
import {fetchAuthSession, getCurrentUser} from "aws-amplify/auth";

@Injectable()
export class CognitoService {

  constructor(
    public userService: UserService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  async updateSession(callback, stepname?: string): Promise<any> {

    const cognitoUser = await getCurrentUser().catch(() => { });

    console.log("COGNITO USER ", cognitoUser);

    if (cognitoUser) {

      const session = await fetchAuthSession({forceRefresh: true})
        .then(() => {
          console.log("session refreshed");
        })
        .catch(() => {
        });
    }
  }
}
