import {AppraisalItemDTO} from './appraisal-itemDTO.interface';
import {BaseDTO} from "./_baseDTO.interface";

export interface AppraisalDTO extends BaseDTO
{
  vehicleId: string;
  vehicleType?: number;
  appraisedBy: string;
  appraisalDate: Date;
  appraisalRef: string;
  appraisalItems: AppraisalItemDTO[];
}
