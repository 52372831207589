import {AttribvalDTO} from "../attribvalDTO.interface";
import {VehicleDTO} from "./vehicleDTO.interface";
import {AttribDTO} from "../attribDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface VehicleAttribDTO extends BaseIntDTO {
  vehicleId: string;
  attribId: number;
  attribvalId?: number;
  attribInt?: number;
  attribChar?: string;
  attribDateTime?: string;
  attribDecimal?: number;
  attrib?: AttribDTO;
  attribval?: AttribvalDTO;
  vehicle?: VehicleDTO;
  updated?: Date;
}
