import {AdviewDTO} from "./adviewDTO.interface";

export interface CustomerAdviewTotalsDTO {
  customerId: string;
  customerName: string;
  customerEmail: string;
  contactName: string;
  contactPhone1: string;
  adviewCount: number;
  latestAdviewDate?: Date;
  adviews?: AdviewDTO[]; // lazy load when customer is selected
  isOpen?: boolean; // used in UI
}
