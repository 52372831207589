import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {URLService} from "../../../../services";
import {User} from "../../../../global/interfaces";
import {ContactService, LoggerService, UserService} from "../../../../global/services";
import {} from "../../../../global/enums";


@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css'],
})
export class ContactEditComponent implements OnInit, OnDestroy {

  submitted = false;
  editForm: UntypedFormGroup;
  createMode = false;
  user: User;
  contactId: string = null;
  deleteContactId: string = null;

  constructor(
    private contactService: ContactService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private currentRoute: ActivatedRoute,
    private url: URLService,
    private router: Router,
    private logService: LoggerService
  ) {

    this.contactId = this.currentRoute.snapshot.params.contactId;

    if (this.contactId === undefined) {
      this.createMode = true;
    }
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  async ngOnInit() {

    this.editForm = this.fb.group({
      contactName: ['', Validators.required],
      email: ['', Validators.email],
      phone1: [''],
      phone2: [''],
      roles: [''],
    }, {validator: this.formValidator()});

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    if (this.contactId !== undefined) {

      this.contactService
        .getContact(this.contactId)
        .then((result) => {

          this.editForm.patchValue(({
            contactName: result.contactName,
            email: result.email,
            phone1: result.phone1,
            phone2: result.phone2,
          }));
        });
    }
  }


  formValidator(): ValidatorFn {

    return (control: UntypedFormGroup): ValidationErrors | null => {

      const errors: ValidationErrors = {};

      if (control.get('contactName').invalid) {
        errors.noContactName = true;
      }

      if (control.get('email').invalid) {
        errors.invalidEmail = true;
      }

      if (control.get('phone1').invalid) {
        errors.invalidPhone1 = true;
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  get f() {
    return this.editForm.controls;
  }

  get err() {
    return this.editForm.errors;
  }

  onSubmit() {

    this.submitted = true;

    if (this.editForm.invalid) {
      return;
    }

    const contactDTO = this.editForm.value;
    contactDTO.customerId = this.user.customerId;

    if (this.createMode) {

      this.createContact(contactDTO);


    } else {

      this.saveContactChanges(this.contactId, contactDTO);
    }

  }


  createContact(contactDTO: any) {

    this.contactService.createContact(contactDTO).then(
      (response) => {
        this.router.navigate([this.url.contacts(true)]);
      },
      err => this.logger.error(err)
    );
  }

  saveContactChanges(contactId: string, contactDTO: any) {

    this.contactService.patch(contactId, contactDTO).then(

      response => {

        this.router.navigate([this.url.contacts(true)]);
      },
      err => this.logger.error(err)
    );
  }

  ngOnDestroy() {
  }
}
