import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoggerService} from "../../../../global/services";
import {MechanicalFaultsService} from "../../../../services";
import {
  FaultCategoryDTO,
  FaultItemDTO,
  FaultStatusDTO, TypedValidatedResultDTO, ValidatedResultDTO,
  VehicleFaultCheckDTO,
  VehicleFaultCheckItemDTO
} from "../../../../global/interfaces";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {formatDate} from "@angular/common";
import {Subscription} from "rxjs";
import {StatusEnum} from "../../../../global/enums";

@Component({
  selector: 'app-vehicle-mechanical-faults',
  templateUrl: './vehicle-mechanical-faults.component.html',
  styleUrls: ['./vehicle-mechanical-faults.component.scss']
})
export class VehicleMechanicalFaultsComponent implements OnInit, OnDestroy {

  constructor(private logService: LoggerService,
              private mechanicalFaultService: MechanicalFaultsService,
              private fb: UntypedFormBuilder) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @Input() vehicleId: string;

  faultCategories: FaultCategoryDTO[] = [];
  vehicleFaultCheck: VehicleFaultCheckDTO;
  faultStatuses: FaultStatusDTO[] = [];

  form: UntypedFormGroup;
  formSub: Subscription;
  activeTab = 0;
  selectedCategory: FaultCategoryDTO = null;

  async ngOnInit() {

    // load vehicle fault check
    await this.CreateOrGetLatestFaultCheck(this.vehicleId)
      .then((res) => {

        if (res != null && res.isValid) {

          this.vehicleFaultCheck = res.dto;
          this.initialiseForm();
        }
      });

    // load fault template items
    this.mechanicalFaultService.getCheckType(this.vehicleFaultCheck.faultCheckTypeId, {
      component: 'vehicle-mechanical-faults',
      filters: {
        statusId: StatusEnum.Active,
        faultCheckTypeId: this.vehicleFaultCheck.faultCheckTypeId
      }
    }).then(res => {

      const faultCheckTypeCategories = res.dto.faultCheckTypeCategories;

      this.faultCategories = faultCheckTypeCategories.map((x) => {
        return x.faultCategory;
      });

      const cats = this.sortedCategories(this.faultCategories);

      this.selectedCategory = cats[this.activeTab];

      // find equivalent vehicleFaultCheckItem and map faultStatusId
      this.mechanicalFaultService.mapCategoryItems(this.faultCategories, this.vehicleFaultCheck);
    });

    this.mechanicalFaultService.searchFaultStatuses({
      component: "vehicle-mechanical-faults",
      filters: {statusId: StatusEnum.Active}
    }).then(res => {
      this.faultStatuses = res.results;
    });
  }


  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  initialiseForm() {
    this.form = this.fb.group({
      inspectDate: new UntypedFormControl(formatDate(this.vehicleFaultCheck.inspectDate, 'yyyy-MM-dd', 'en'), {updateOn: 'blur'}),
      odometer: new UntypedFormControl(this.vehicleFaultCheck.odometer, {updateOn: 'blur'})
    });

    this.formSub = this.form.valueChanges.subscribe(async () => {
      await this.faultCheckChanged();
    });

  }

  async onFaultItemStatusChanged(event: FaultItemDTO) {


    // insert or update the record for this vehicle
    const vehicleFaultCheckItem = {
      faultStatusId: event.faultStatusId,
      vehicleFaultCheckId: this.vehicleFaultCheck.id,
      faultItemId: event.id,
      freeText: event.freeText
    } as VehicleFaultCheckItemDTO;

    await this.mechanicalFaultService.updateVehicleFaultCheckItem(this.vehicleFaultCheck.id, event.id, vehicleFaultCheckItem);
  }


  async faultCheckChanged() {
    const dto = {
      ...this.vehicleFaultCheck,
      ...this.form.value
    } as VehicleFaultCheckDTO;

    // save current values to vehicle fault check
    await this.mechanicalFaultService.updateVehicleFaultCheck(
      this.vehicleFaultCheck.id,
      dto,
      this.vehicleFaultCheck);
  }

  sortedCategories(faultCategories: FaultCategoryDTO[]) {

    return faultCategories.sort((a, b) => {
      if (a.sequence < b.sequence) {
        return -1;
      }
      if (a.sequence > b.sequence) {
        return 1;
      }
      return 0;
    });
  }

  CreateOrGetLatestFaultCheck(vehicleId: string): Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>> {

    return this.mechanicalFaultService.getLatestVehicleFaultCheck(vehicleId).then(res => {

      if (res != null && res.isValid === true) {

        return Promise.resolve(res);

      } else {
        return this.mechanicalFaultService.createVehicleFaultCheck(vehicleId).then((res2) => {

          return Promise.resolve(res2);
        });
      }
    });
  }
}
