import {Injectable} from '@angular/core';

@Injectable()
export class ImageService {

  constructor() { }

  public convertFileToDataURL(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        resolve('');
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = reader.result.toString();
        resolve(text);

      };

      reader.readAsDataURL(file);
    });
  }

  public removeFileTypePrefix(dataURL: string) {
    return dataURL.substring(dataURL.indexOf(',') + 1);
  }

  public sizedImageUrl(imageUrl: string, maxWidth: number, maxHeight: number) {

    let append = "";

    // Round to the nearest 100 so we don't have a squillion different size formats
    if (maxWidth && maxWidth > 0) {
      maxWidth = Math.ceil(maxWidth / 100) * 100;
    }
    if (maxHeight && maxHeight > 0) {
      maxHeight = Math.ceil(maxHeight / 100) * 100;
    }

    if (maxHeight || maxWidth) {

      append = "?tr=";

      if (maxHeight) {
        append += "h-" + maxHeight;

        if (maxWidth) {
          append += ",";
        }
      }

      if (maxWidth) {
        append += "w-" + maxWidth;
      }

      imageUrl = imageUrl + append;
    }

    return imageUrl;
  }
}
