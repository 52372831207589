import {MotItemDTO} from './motItemDTO.interface';

export interface MotHistoryDTO {
  vrm: string;
  completedDate?: Date;
  passed: boolean;
  expiryDate?: Date;
  odometerValue?: number;
  odometerUnit?: number;
  testNumber?: string;
  dvlaId?: string;
  motItems?: MotItemDTO[];
  issue?: boolean; // processed in UI to highlight mileage issues
}
