import {EventEmitter, Injectable} from '@angular/core';
import {
  AdvertEventEnum,
  InMailFolderEnum,
  MovementEventTypeEnum,
  SoldStatusEnum,
  VehicleEventEnum
} from '../global/enums/index';
import {BillDTO, MessageDTO, SaleDTO, SavedSearchDTO, SearchDTO} from '../global/interfaces/index';
import {ValuationEventTypeEnum} from "../global/enums";
import {ContactSearchEventEnum} from "../enums/contact-search-event.enum";

@Injectable()

export class EventService {

  public VehicleSearchEvent: EventEmitter<string> = new EventEmitter<string>();
  public BidEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();
  public AdvertEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();
  public SaleEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();
  public UserEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();

  // Synonym for AdminEvent
  public AuctioneerEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();

  public InMailEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();
  public ContactEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();
  public GlobalEvent: EventEmitter<MessageDTO> = new EventEmitter<MessageDTO>();
  public SignalRConnected: EventEmitter<any> = new EventEmitter<any>();

  // ALL OF THE BELOW SHOULD USE ONE OF THE ABOVE EVENTS (e.g. ContactEvent)

  public ContactSearchEvent: EventEmitter<{ event: ContactSearchEventEnum, data?: any }> = new EventEmitter<any>();
  public ShowAssigneeDialogEvent: EventEmitter<any> = new EventEmitter<any>();

  public SaveSearchEvent: EventEmitter<{ savedSearchDTO: SavedSearchDTO, searchDTO: SearchDTO, isProfile: boolean }>
    = new EventEmitter<{ searchDTO: SearchDTO, savedSearchDTO: SavedSearchDTO; isProfile: boolean }>();

  public ValuationEvent: EventEmitter<{ eventType: ValuationEventTypeEnum, object?: any }> = new EventEmitter<{
    eventType: ValuationEventTypeEnum;
    object?: any
  }>();

  public MovementEvent: EventEmitter<{ eventType: MovementEventTypeEnum, object?: any }> = new EventEmitter<{
    eventType: MovementEventTypeEnum;
    object?: any
  }>();

  // INMAIL
  public ReloadInMail: EventEmitter<InMailFolderEnum> = new EventEmitter<InMailFolderEnum>();

  // ADVERT
  public AdvertActionEvent: EventEmitter<{ eventType: AdvertEventEnum, object?: any }> = new EventEmitter<{
    eventType: AdvertEventEnum;
    object?: any
  }>();

  // VEHICLE
  public VehicleActionEvent: EventEmitter<{ eventType: VehicleEventEnum, object?: any }> = new EventEmitter<{
    eventType: VehicleEventEnum;
    object?: any
  }>();

  // MISC
  public ReloadStockTab: EventEmitter<SoldStatusEnum> = new EventEmitter<SoldStatusEnum>();
  public ShowSaleProfileSuggestedVehicles: EventEmitter<SaleDTO> = new EventEmitter<SaleDTO>();
  public PayInvoice: EventEmitter<BillDTO> = new EventEmitter<BillDTO>();
  public InvoicePaid: EventEmitter<{ reference: string, paidDate: Date }> = new EventEmitter<{
    reference: string,
    paidDate: Date
  }>();
}

export enum AdvertEvent {

}
