import {Component, Input, OnInit} from '@angular/core';
import {AdvertViewVehicleValuationDTO} from "../../../../global/interfaces";
import {VehicleService} from "../../../../services";

@Component({
  selector: 'app-vehicle-valuation',
  templateUrl: './vehicle-valuation.component.html',
  styleUrls: ['./vehicle-valuation.component.scss'],
  providers: []
})
export class VehicleValuationComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  private _advertId: string;
  @Input('advertId') set advertId(value: string) {
    this._advertId = value;
    if (value) {
      this.loadInfo();
    }
  }

  get advertId(): string {
    return this._advertId;
  }

  info: AdvertViewVehicleValuationDTO;

  constructor(private vehicleService: VehicleService) {
  }

  ngOnInit(): void {
  }

  loadInfo() {
    this.vehicleService.getValuationDataByAdvert(this.advertId, true).then(res => {
      this.info = res;
    });
  }
}
