export class BaseSearchDTO {
  includes?: string[]; // Deprecated
  order?: { column: string, descending?: boolean }[];
  component?: string;
  offset?: number;
  limit?: number;
  ignoreCache?: boolean;
  filters?: object = {};
  countOnly? = false;
  uniqueId?: number;
  useCache? = false;
}

