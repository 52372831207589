import {ContactDTO} from '../contactDTO.interface';
import {NegotiationStateEnum} from '../../../enums/negotiation-state.enum';
import {NegotiationNoteDTO} from './negotiationNoteDTO.interface';
import {BaseDTO} from '../_baseDTO.interface';

export interface NegotiationDTO extends BaseDTO {
  advertId: string;
  contactId: string;
  contact: ContactDTO;
  state: NegotiationStateEnum;
  sleepUntil?: Date;
  negotiationNotes?: NegotiationNoteDTO[];
}
