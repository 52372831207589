import {Injectable} from '@angular/core';
import {OfferDTO} from '../../../global/interfaces';
import {DataService} from "../../../services/index";
import {ApiService} from "../../../global/services";

@Injectable()
export class AdminOfferService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  submitAdminOffer(formData: OfferDTO) {
    const url = `${this.data.apiUrl}/api/bid/${formData.bidGuid}/counterOffer`;
    return this.apiClient.put({url, data: formData}) as Promise<any>;
  }
}
