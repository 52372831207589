import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {StatusEnum} from '../../../global/enums';
import {User} from '../../../global/interfaces';
import {CognitoService, ContactService, UserService} from '../../../global/services';
import {URLService} from "../../../services";
import {AppComponent} from '../../index';
import {updateUserAttributes} from 'aws-amplify/auth';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private contactService: ContactService,
    private cognitoService: CognitoService,
    public userService: UserService,
    private redirect: URLService
  ) {

    this.user = this.userService.CurrentUser;

    if (this.user.isLoggedIn) {
      this.registerUser();
    } else {
      this.redirect.homepage();
    }
  }

  globals = AppComponent.globals;
  registrationForm: UntypedFormGroup;
  customerTypeForm: UntypedFormGroup;
  submitted = false;
  pleaseWait = false;
  applied = false;
  pageLoaded = false;
  user: User;
  step = 1;

  async ngOnInit() {

  }

  registerUser() {

    this.registrationForm = new UntypedFormGroup({
      tsandcs: new UntypedFormControl('', Validators.compose([Validators.requiredTrue])),
      contactName: new UntypedFormControl(this.user?.name, Validators.compose([Validators.required])),
      telephoneNumber: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/)])),
      companyName: new UntypedFormControl('', Validators.compose([Validators.required]))
    });

    this.customerTypeForm = new UntypedFormGroup({
      buyer: new UntypedFormControl(false),
      seller: new UntypedFormControl(false),
    });


    if (this.user.contactId && this.user.customerId) {
      this.redirect.userPending();
      return false;
    }

    this.pageLoaded = true;

    this.contactService.createUsingCognito().then((tmpContact) => {
      this.cognitoService.updateSession({}, "STEP 1").then((data) => {
      });
    });
  }

  get f() {
    return this.registrationForm.controls;
  }

  get ctf() {
    return this.customerTypeForm.controls;
  }

  validPhoneNumber(phoneNumber: string) {
    return phoneNumber.match(
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
    );
  }

  onNextStep() {

    this.registrationForm.controls.telephoneNumber.markAsDirty();
    this.registrationForm.controls.companyName.markAsDirty();
    this.registrationForm.controls.contactName.markAsDirty();
    this.registrationForm.controls.tsandcs.markAsDirty();

    if (this.registrationForm.valid) {
      this.step = 2;
    }
  }

  onPrevStep() {
    this.step = 1;
  }

  onSubmit() {

    if (this.registrationForm.valid && this.customerTypeForm.valid) {

      this.userService.loadCurrentUser().then(() => {

        this.user = this.userService.CurrentUser;

        this.CreateCustomer();

      });

      this.pleaseWait = true;
    }
  }

  CreateCustomer() {

    const phoneNumber = this.registrationForm.value.telephoneNumber.replace(/^0/g, '+44').replace(' ', '');

    // contactName: this.registrationForm.value.contactName, // We should be able to take this from the user object
    updateUserAttributes({userAttributes: {phone_number: phoneNumber}}).then(() => {

    });

    this.contactService.initContactCustomer({
      id: this.user.contactId,
      contactName: this.registrationForm.value.contactName, // We should be able to take this from the user object
      phone1: this.registrationForm.value.telephoneNumber,
      email: this.user.email,
      statusId: StatusEnum.Pending,
      isPrimary: true,
      customer: {
        customerName: this.registrationForm.value.companyName,
        isBuyer: this.customerTypeForm.value.buyer,
        isSeller: this.customerTypeForm.value.seller,
        statusId: StatusEnum.Pending
      },
    }).then((response) => {


      // We've created new records so we need to fetch them from the server
      this.cognitoService.updateSession({}, "STEP 1").then((data) => {
        this.redirect.userPending();
      });
    });
  }

  validForm() {
    return this.registrationForm.invalid == false && (this.ctf.seller.value == true || this.ctf.buyer.value == true);
  }

  toggleSell() {
    this.customerTypeForm.patchValue({seller: !this.customerTypeForm.value.seller});
  }

  toggleBuy() {
    this.customerTypeForm.patchValue({buyer: !this.customerTypeForm.value.buyer});
  }
}
