import {Component, Input, OnInit} from '@angular/core';
import {BidService} from "../../../services";
import {BidDTO, BidSearchDTO, CustomerDTO, SearchResultDTO, User} from '../../../global/interfaces';
import {BidSearchViewEnum} from "../../../global/enums";
import {AdminUrlService, AdminWhosWhoService} from "../services";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {IOption} from "ng-uikit-pro-standard";
import { UserService } from '../../../global/services';
import { EMPTY_GUID } from 'projects/common/shared';

@Component({
  selector: 'app-offers-search',
  templateUrl: './offers-search.component.html',
  styleUrls: ['./offers-search.component.scss']
})
export class OffersSearchComponent implements OnInit {

  @Input() title: string;
  isBusy: boolean = false;
  currentUser: User;

  constructor(private bidService: BidService,
              public adminUrl: AdminUrlService,
              private fb: UntypedFormBuilder,
              private userService: UserService,
              private whosWhoService: AdminWhosWhoService) {

    this.filterForm = this.fb.group({
      daysAgo: new UntypedFormControl(7),
      assignedTo: new UntypedFormControl(EMPTY_GUID)
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.search();
    });
  }

  searchResult: SearchResultDTO;

  page = 1;
  count = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;

  offers: BidDTO[];
  filterForm: UntypedFormGroup;
  assignedOptions: Array<IOption>;

  daysAgoOptions = [
    {label: "All Dates", value: 365},
    {label: "Last 7 days", value: 7},
    {label: "Last 30 days", value: 30},
    {label: "Last 90 days", value: 90},
  ];

  ngOnInit(): void {
    this.fetchAssignees();
    this.fetchOffers();

    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
    });
  }

  fetchAssignees() {
    this.whosWhoService.getSiteAdmins({component: 'whos-who'}, true).then((res) => {
      this.assignedOptions = this.whosWhoService.getAssignedOptions(res.results);
    });
  }

  fetchOffers() {
    const dto = {
      component: 'admin-bids-view',
      filters: {
        bidViewTypeEnum: BidSearchViewEnum.All,
        ...this.filterForm?.value
      },
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize
    } as BidSearchDTO;

    this.isBusy = true;

    this.bidService.search(dto).then(result => {
      this.offers = result.results;
      this.isBusy = false;
    });
  }

  onTableDataChange(page: number) {
    // fetch records using page as offset
    this.page = page;
    this.fetchOffers();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchOffers();
  }

  search() {
    this.fetchOffers();
  }

  filterToMe() {
    if (this.filterForm.value.assignedTo !== this.currentUser.contactId) {
      this.filterForm.patchValue({assignedTo: this.currentUser.contactId});
    } else {
      this.filterForm.patchValue({assignedTo: EMPTY_GUID});
    }
  }

  assignedToName(item: CustomerDTO) {
    if (item.customerInternalInfo?.assignedTo != null && this.assignedOptions.length > 0) {
      return this.assignedOptions.find(x => x.value === item.customerInternalInfo?.assignedTo)?.label;
    }
  }

  protected readonly EMPTY_GUID = EMPTY_GUID;
}
