import {Component, Input, OnInit} from '@angular/core';
import {LoggerService} from "../../../../../global/services";
import {MechanicalFaultsService} from "../../../../../services";
import {
  FaultCategoryDTO, FaultItemDTO,
  VehicleFaultCheckDTO,
} from "../../../../../global/interfaces";
import {ActivatedRoute} from "@angular/router";
import {StatusEnum} from "../../../../../global/enums";

@Component({
  templateUrl: './fault-report-view.component.html',
  styleUrls: ['./fault-report-view.component.scss']
})
export class FaultReportViewComponent implements OnInit {

  faultStatuses: any[] = [];
  faultItems: FaultItemDTO[];

  constructor(private logService: LoggerService,
              private mechanicalFaultService: MechanicalFaultsService,
              private route: ActivatedRoute) {

    this.vehicleFaultCheckId = this.route.snapshot.params.vehicleFaultCheckId;
    this.logger.log("VEHICLE CHECK ID: ", this.vehicleFaultCheckId);
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @Input() vehicleFaultCheckId: string;

  vehicleFaultCheck: VehicleFaultCheckDTO;
  faultCategories: FaultCategoryDTO[];

  async ngOnInit() {
    // load vehicle fault check
    await this.mechanicalFaultService.getVehicleFaultCheck(this.vehicleFaultCheckId, {component: "fault-report-view"})
      .then(res => {

        this.vehicleFaultCheck = res.dto;

        this.faultCategories = res.dto?.faultCheckType?.faultCheckTypeCategories.map(x => x.faultCategory);

        this.faultCategories.forEach((cat) => {
          cat.faultItems.forEach((item) => {
            const fci = this.vehicleFaultCheck.faultCheckItems.find(x => x.faultItemId === item.id);
            item.faultStatus = fci?.faultStatus;
            item.freeText = fci?.freeText;
          });
        });

        this.faultStatuses = this.vehicleFaultCheck.faultCheckItems
          .reduce((statuses, item) => {
            if (!statuses.find(status => status.id === item.faultStatus.id)) {
              statuses.push(item.faultStatus);
            }
            return statuses;
          }, []);

        this.faultStatuses.unshift({  // add untested status
          id: 1,
          name: 'Untested',
          description: 'Fault not tested'
        });
      });
  }

  sortCategories(faultCategories: FaultCategoryDTO[]) {
    return faultCategories.sort((a, b) => {
      return a.sequence - b.sequence;
    });
  }

  sortItems(faultItems: any) {
    return faultItems.filter(x => x.statusId == StatusEnum.Active).sort((a, b) => {
      return a.sequence - b.sequence;
    });
  }

  buildFaultStatusList(faultCategories: FaultCategoryDTO[]) {

    console.log("FCS ", faultCategories);

    let tmpList = {};

    faultCategories.forEach(x => {
      x.faultItems.forEach(y => {
        const exists = y.faultStatusId in this.faultStatuses;
        if (!exists) {
          tmpList[y.faultStatusId] = (y.faultStatus == null) ? {
            id: 1,
            name: 'Untested',
            description: 'Fault not tested'
          } : y.faultStatus;
        }
      });
    });

    this.faultStatuses = Object.values(tmpList);
  }

  sortedCategories(faultCategories: FaultCategoryDTO[]) {

    return faultCategories.sort((a, b) => {
      if (a.sequence < b.sequence) {
        return -1;
      }
      if (a.sequence > b.sequence) {
        return 1;
      }
      return 0;
    });
  }
}
