import {HttpHeaders} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {SFVehicle} from "../interfaces/smart fleet/sfvehicle.interface";
import {ExternalSettingsService} from "../shared/externalsettings.service";
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class SFApiService {
  private serviceUrl: string;

  constructor(
    private apiClient: ApiService,
    private data: DataService,
    private appSettingsService: ExternalSettingsService
  ) {
    console.log("App settings: ", this.appSettingsService);
    this.appSettingsService.getSettings().then((settings) => {
      this.serviceUrl = settings.smartfleetServiceURL;
    });
  }

  public importVehicle(vrm: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/importVehicle`;

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    };
    const requestOptions = {
      headers: new HttpHeaders(headers),
    };

    const data = JSON.stringify(vrm);
    return this.apiClient.post({url, data}) as Promise<any>;
  }

  public getVehicleData(vrm: string): Promise<SFVehicle> {
    return this.getFromInternalAPI(vrm);
  }

  private getFromInternalAPI(vrm: string): Promise<SFVehicle> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/getVehicle`;

    const req = `${url}/${vrm}`;

    console.log("Getting vehicle from smartfleet: ", this.serviceUrl);

    return this.apiClient.get({url: req}) as Promise<SFVehicle>;
  }
}
