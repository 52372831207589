import {Injectable} from '@angular/core';
import {DataService, URLService} from "../../../services";
import {ApiService} from '../../../global/services';
import {AdminExtLeadDTO, AdminExtLeadVehicleDTO, TypedSearchResultDTO} from '../../../global/interfaces';

@Injectable()
export class AdminExtLeadService {

  constructor(
    public urlService: URLService,
    private apiClient: ApiService,
    private data: DataService,
  ) {
  }

  parseFile(json: string): Promise<AdminExtLeadDTO[]> {
    const url = `${this.data.apiUrl}/api/ext-lead/parse`;
    return this.apiClient.post({url, data: { json }}) as Promise<AdminExtLeadDTO[]>;
  }

  getLeads() {
    const url = `${this.data.apiUrl}/api/ext-leads`;
    return this.apiClient.get({url}) as Promise<TypedSearchResultDTO<AdminExtLeadDTO>>;
  }

  getLeadVehicles(id: string) {
    const url = `${this.data.apiUrl}/api/ext-lead/${id}/vehicles`;
    return this.apiClient.get({url}) as Promise<TypedSearchResultDTO<AdminExtLeadVehicleDTO>>;
  }
}
