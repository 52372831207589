import {Directive, Input} from '@angular/core';
import {NgModel} from "@angular/forms";

@Directive({
  selector: "[ngModelWatch]"
})
export class NgModelWatchDirective {
  @Input("ngModel")
  set update(value: any) {
    this.ngModel.update.emit(value);
  }

  constructor(private ngModel: NgModel) {}
}

