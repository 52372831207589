import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {URLService} from '../../../../services';
import {MDBModalService, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {ContactDTO, InviteDTO, User} from "../../../../global/interfaces";
import {ContactService, CustomerService, LoggerService, UserService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {} from "../../../../global/enums";
import {NgbdSortableHeader} from "../../../../global/directives";
import {GlobalConstants} from "../../../../global/shared";
import {updatePassword, type UpdatePasswordInput} from 'aws-amplify/auth';
import {error} from "protractor";

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent implements OnInit, OnDestroy {

  @ViewChild("newContactModal", {static: true}) newContactModal: ModalDirective;
  @ViewChild("editContactModal", {static: true}) editContactModal: ModalDirective;
  @ViewChild("changePasswordModal", {static: true}) changePasswordModal: ModalDirective;

  newContactForm: UntypedFormGroup;
  showDeleteConfirm = false;

  constructor(
    private customerService: CustomerService,
    private contactService: ContactService,
    private router: Router,
    public url: URLService,
    public userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private modalService: MDBModalService,
    private logService: LoggerService,
    private toastService: ToastService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  toastOpts: { opacity: 0.98 };

  isLoading: boolean;

  public contacts: ContactDTO[] = [];
  public temp: {} = false;
  public searchTerm: string;
  public showNewCustomer = false;
  public deleteContactId: string = null;
  public deleteRow: number = null;
  public user: User;
  public inviteContactDTO: InviteDTO;
  public roleChoices: any[] = [];


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  editContactForm: any;
  isMobile = GlobalConstants.IsMobile;
  sendingInvite = false;

  public StatusEnum = StatusEnum;
  editingContact: ContactDTO;
  saving = false;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  errorText: string;

  deleteBodyText: string;

  async ngOnInit() {

    this.newContactForm = this.formBuilder.group({
      contactName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl("", Validators.required),
      roles: new UntypedFormControl(null, Validators.required)
    });

    this.editContactForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      roles: new UntypedFormControl("", Validators.required),
      contactName: new UntypedFormControl(),
      phone1: new UntypedFormControl(),
      phone2: new UntypedFormControl(),
      email: new UntypedFormControl(),
    });

    await this.userService.loadCurrentUser().then(() => {

      this.user = this.userService.CurrentUser;

      this.roleChoices = this.fetchRoleChoices(this.user);

    });

    this.isLoading = true;

    this.loadContacts();
  }

  loadContacts() {

    this.customerService.getContacts(this.user.customerId, {component: 'contact-search'}).then((result) => {

      this.contacts = result.results;
      this.temp = true;
      this.isLoading = false;
    });
  }

  submit() {
  }

  fetchRoleChoices(u: User) {

    let choices: any[] = [];

    u.roles.forEach((x) => {
      const y = {value: x, label: x};
      choices.push(y);
    });

    return choices;

  }

  get ecf() {
    return this.editContactForm.controls;
  }

  get ncf() {
    return this.newContactForm.controls;
  }

  getRoles(roles) {
    return roles.join(', ');
  }

  showCreateContact() {
    this.newContactForm.reset();
    const roles = this.roleChoices.map((role) => {
      return role.value;
    });
    this.newContactForm.patchValue({roles: roles});
    this.newContactModal.show();
  }

  showEditContact(contactRef: ContactDTO) {

    this.editingContact = contactRef;
    const roles = contactRef.roles.map(x => x.roleName);

    this.editContactForm.patchValue(contactRef);
    this.editContactForm.patchValue({roles});
    this.editContactModal.show();
  }

  createContact() {

    if (!this.newContactForm.valid) {
      return;
    }

    this.saving = true;

    this.inviteContactDTO = Object.assign(this.newContactForm.value);

    this.contactService.inviteContact(this.inviteContactDTO).then(() => {

      this.newContactModal.hide();
      this.saving = false;
      this.loadContacts();

    });
  }

  deleteContact(event, rowId, contactId) {

    event.preventDefault();
    event.stopPropagation();

    const contact = this.contacts.find(x => x.id == contactId);
    if (contact) {
      this.deleteBodyText = `Are you sure you want to delete the user '${contact.email}'? \nThis will mean they are unable to access the system.`;
    }

    this.deleteContactId = contactId;
    this.deleteRow = rowId;
    this.showDeleteConfirm = true;
  }

  confirmDelete() {

    console.log(`Delete contactId '${this.deleteContactId}' for customer '${this.user.customerId}'`)
    //return;

    this.contactService
      .delete(this.deleteContactId, this.user.customerId)
      .then((result) => {

        this.contacts.splice(this.deleteRow, 1);

      });
  }

  onSubmit() {

  }

  ngOnDestroy() {
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }

  async saveContact() {

    const updateObject = Object.assign(this.editContactForm.value);

    const contactObject = {
      email: updateObject.email,
      contactName: updateObject.contactName,
      phone1: updateObject.phone1,
      phone2: updateObject.phone2,
    };

    const roles = updateObject.roles;

    const result = await this.contactService.patch(updateObject.id, contactObject);

    await this.contactService.setContactRoles(updateObject.id, roles);

    this.loadContacts();
    this.editContactModal.hide();
  }

  toggleActivation() {
    const contactObject = {
      statusId: this.editingContact.statusId == StatusEnum.Active ? StatusEnum.Paused : StatusEnum.Active
    };

    this.contactService.patch(this.editingContact.id, contactObject).then(contact => {
      this.editContactModal.hide();
      this.loadContacts();
    });
  }

  canSave() {

    return (this.saving === false && this.ncf.roles?.value && this.ncf.roles.value.length > 0);
  }

  pendingInvite(contact: ContactDTO) {
    return contact?.invite?.statusId != StatusEnum.Deleted && contact.statusId == StatusEnum.Pending;
  }

  async saveChangePassword({oldPassword, newPassword}: UpdatePasswordInput) {
    this.errorText = "";
    try {
      await updatePassword({oldPassword, newPassword});
    } catch (err) {
      this.errorText = err.message;
      console.log(err.message);
    }
  }

  showChangePasswordModal() {
    this.changePasswordModal.show();
  }

  userIsMe() {
    return this.ecf.id.value == this.user.contactId;
  }

  resendInvite(event: MouseEvent, id: string) {
    event.preventDefault();
    event.stopPropagation();

    this.sendingInvite = true;

    this.contactService.resendInvite(id).then(() => {
      this.toastService.success("Invitation Resent", "Success");
      this.sendingInvite = false;
    }).catch(error => {
      this.toastService.error("Could not send invitation", "Error Resending Invitation", this.toastOpts);
      this.sendingInvite = false;
    })
  }
}
