// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav .collapsible .card-body li a {
  font-size: 0.8rem !important;
}

.side-nav .nav-line {
  border-top: 1px solid #4e4f53;
}

.selected {
  background-color: var(--sideNavHoverBackgroundColour) !important;
}

.side-nav .collapsible .card .card-body li a {
  background-color: #f7f7f7;
}
.side-nav .collapsible a h5 {
  color: var(--textColour) !important;
}
.side-nav .collapsible a h5 a:hover {
  font-weight: 500 !important;
}
.side-nav .collapsible .nav-item {
  color: var(--sideNavTextColour);
}
.side-nav .collapsible .nav-item a {
  font-weight: 400;
}
.side-nav .collapsible .nav-item:hover {
  background-color: var(--sideNavHoverBackgroundColour);
  color: var(--textColour);
}
.side-nav .collapsible .nav-item:hover .card-body li {
  font-weight: 500 !important;
}

.menu-badge {
  padding-right: 14px;
}

.badge-danger {
  background-color: var(--errorColour);
  color: #fff;
  display: inline-block;
  padding: 0 0.4em;
  line-height: 18px;
  border-radius: 9px;
  min-width: 18px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/common/components/app-side-menu/app-side-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,gEAAA;AACF;;AAIE;EACE,yBAAA;AADJ;AAKE;EACE,mCAAA;AAHJ;AAII;EACE,2BAAA;AAFN;AAME;EAME,+BAAA;AATJ;AAKI;EACE,gBAAA;AAHN;AAQI;EACE,qDAAA;EACA,wBAAA;AANN;AAQM;EACE,2BAAA;AANR;;AAaA;EACE,mBAAA;AAVF;;AAaA;EACE,oCAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AAVF","sourcesContent":[".side-nav .collapsible .card-body li a {\n  font-size: 0.8rem !important;\n}\n\n.side-nav .nav-line {\n  border-top: 1px solid #4e4f53;\n}\n\n.selected {\n  background-color: var(--sideNavHoverBackgroundColour) !important;\n}\n\n.side-nav .collapsible {\n\n  .card .card-body li a {\n    background-color: #f7f7f7;\n\n  }\n\n  a h5 {\n    color: var(--textColour) !important;\n    a:hover {\n      font-weight: 500 !important;\n    }\n  }\n\n  .nav-item {\n\n    a {\n      font-weight: 400;\n    }\n\n    color: var(--sideNavTextColour);\n\n    &:hover {\n      background-color: var(--sideNavHoverBackgroundColour);\n      color: var(--textColour);\n\n      .card-body li {\n        font-weight: 500 !important;\n      }\n    }\n\n  }\n}\n\n.menu-badge {\n  padding-right: 14px;\n}\n\n.badge-danger {\n  background-color: var(--errorColour);\n  color: #fff;\n  display: inline-block;\n  padding: 0 0.4em;\n  line-height: 18px;\n  border-radius: 9px;\n  min-width: 18px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
