// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-icon {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
}

.report-grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 20px;
}

.key {
  border: 2px solid #333;
  border-radius: 10px;
  padding: 10px;
}

.fault-status-item {
  padding-right: 25px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-mechanical-faults/vehicle-fault-report/fault-report-view.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;AACF;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EAEE,mBAAA;EACA,iBAAA;AAAF","sourcesContent":[".status-icon {\n  width: 20px;\n  height: 20px;\n  vertical-align: text-bottom;\n}\n\n.report-grid {\n  display: grid;\n  grid-template-columns: repeat(2, 50%);\n  grid-gap: 20px;\n}\n\n.key {\n  border: 2px solid #333;\n  border-radius: 10px;\n  padding: 10px;\n}\n\n.fault-status-item {\n\n  padding-right: 25px;\n  font-weight: bold;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
