import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SaleService, URLService} from '../../../../../services';
import {Router} from '@angular/router';
import {SaleDTO} from "../../../../../global/interfaces";
import {UserService} from "../../../../../global/services";
import {LiveBiddingStatusEnum} from "../../../../../global/enums";

@Component({
  selector: 'app-widget-upcoming-sales',
  templateUrl: './widget-upcoming-sales.component.html',
  styleUrls: ['./widget-upcoming-sales.component.scss']
})
export class WidgetUpcomingSalesComponent implements OnInit {

  constructor(
    private userService: UserService,
    private url: URLService,
    private saleService: SaleService, private router: Router) {
  }

  @Output() saleCountChange = new EventEmitter<number>();
  @Input() parentComponent: string;

  isLoading: boolean;
  sales: SaleDTO[] = [];
  liveBiddingStatus = LiveBiddingStatusEnum;

  ngOnInit(): void {

    this.loadSales();
  }

  viewSale(saleId: string) {
    this.url.sale(saleId);
  }

  loadSales() {

    this.isLoading = true;

    this.saleService.getSales({ component: this.parentComponent }).then(result => {

      this.sales = result.sales.splice(0, 5);
      this.isLoading = false;
      this.saleCountChange.emit(this.sales.length);

      this.sales.forEach(x => {
        x.isLive = (x.liveBiddingStatus == LiveBiddingStatusEnum.Live || x.liveBiddingStatus == LiveBiddingStatusEnum.Paused)
      });

    });
  }
}
