import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces/index";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API/index";
import {DocumentDTO, DocumentSearchDTO} from "../../interfaces/DTOs/Documents/index";

@Injectable()
export class DocumentService {

  private serviceUrl = '/api/document';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
  ) {
  }

  search(searchDTO?: DocumentSearchDTO): Promise<SearchResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}s`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: searchDTO}) as Promise<any>;
  }

  create(data: DocumentDTO): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    return this.apiClient.post({url, data, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  patch(id, dto: DocumentDTO, previousDTO= {}): Promise<any> {
    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }
}

