import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {from, Observable} from 'rxjs';
import {URLService} from "./url.service";
import {AuthGuardServiceInterface} from '../global/services/interfaces';
import {StatusEnum, UserRolesEnum} from '../global/enums';
import {User} from '../global/interfaces';
import {fetchAuthSession} from 'aws-amplify/auth';

@Injectable()
export class RoleGuardService implements AuthGuardServiceInterface {

  constructor(
    public router: Router,
    private url: URLService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return fetchAuthSession().then((authUser) => {

        const user = this.userInfo(authUser);

        // Do we check the customer status here ??

        if (user.statusId !== StatusEnum.Active) {
          this.saveDeepLink(state);
          return false;
        }

        // If we have no roles required, just that we are logged in
        if (!route.data.roles) {

          return true;

        } else if (route.data.roles && route.data.roles.some(r => user.roles.includes(r))) {

          return true;
        }

        this.url.dashboard();
        return false;
      }
    ).catch(() => {

      this.saveDeepLink(state);

      return false;
    });
  }

  public getTokenObservable() {
    return from(this.getTokenPromise());
  }

  public saveDeepLink(route: any) {

    localStorage.setItem('lastDeeplinkPath', route.url);

    this.url.login();

    return false;
  }

  public getTokenPromise() {

    return fetchAuthSession().then((authUser) => {
      return authUser?.tokens.idToken.toString();
    });
  }

  userInfo(user: any) {

    const tokenPayload = user?.tokens?.idToken?.payload;

    // console.log("USER ", user);
    // console.log("TOKEN PAYLOAD ", tokenPayload);

    const u: User = {
      isLoggedIn: true,
      userName: user?.username, // NOTE: Internal Cognito Username for lookups, not display
      name: tokenPayload?.name, // Persons name "John Smith"
      email: tokenPayload?.email,
      roles: tokenPayload.userRoles?.split(","),
      customers: [],
      customerId: null,
      contactAddressId: null,
      impersonator: null,
      contactPostcode: null
    };

    if (tokenPayload.customers != null) {

      const customers = JSON.parse(tokenPayload.customers);

      if (customers != null) {

        this.setUserInfoFromToken(u, customers);
        this.setContactRoles(u);
      }

      if ('impersonate' in tokenPayload) {

        u.impersonator = u.contactId;
        const impersonate = JSON.parse(tokenPayload.impersonate);

        console.log("** Impersonate: ", tokenPayload)


        this.setUserInfoFromToken(u, JSON.parse(impersonate.customers));
        u.roles = impersonate.userRoles?.split(",");
        u.name = impersonate.contactName;
        this.setContactRoles(u);
      }
    }

    return u;
  }

  setUserInfoFromToken(u: User, customers) {

    u.customers = [];

    for (const customer of customers) {

      u.customers.push({
        customerId: customer.customerId,
        customerName: customer.customerName,
        customerStatusId: customer.customerStatusId,
        contactId: customer.contactId,
        statusId: customer.contactStatusId,
        contactPostcode: customer.contactPostcode,
        contactPhone: customer.contactPhone
      });

      u.customerId = customer.customerId;
      u.customerStatusId = customer.customerStatusId;
      u.customerName = customer.customerName;

      u.contactId = customer.contactId;
      u.statusId = customer.contactStatusId;
      u.contactAddressId = customer.contactAddressId;
      u.contactPostcode = customer.contactPostcode;
      u.contactPhone = customer.contactPhone;
    }

  }

  setContactRoles(user: User) {

    user.isSeller = this.HasRole(user, [UserRolesEnum.Seller]);
    user.isBuyer = this.HasRole(user, [UserRolesEnum.Buyer]);
    user.isAdmin = this.HasRole(user, [UserRolesEnum.Admin]);
    user.isSupplier = this.HasRole(user, [UserRolesEnum.Supplier]);
    user.isAuctioneer = this.HasRole(user, [UserRolesEnum.AuctionAdmin]);
    user.isSuperAdmin = this.HasRole(user, [UserRolesEnum.SuperAdmin]);

  }

  HasRole(user: User, roles: string[]): boolean {

    if (!user?.roles) {
      return false;
    }

    return (roles.some(r => user.roles.includes(r)));
  }
}

