import {VehicleLookupInfoDTO} from "../API/index";
import {LeadVehicleDTO} from "../../../../crm/src/app/interfaces/index";

export interface ValuationResultDTO {
  // UInspect Reference
  externalRef: string;
  leadId: string;
  vehicleInfo: VehicleLookupInfoDTO;
  steps?: string[];
  log?: string[];
  resultValue: number;
  gradeValues?: number[];
}
