import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {
  MDBSpinningPreloader,
  WavesModule,
  ToastModule,
  DropdownModule, ButtonsModule, TabsModule, TooltipModule, ModalModule,
} from 'ng-uikit-pro-standard';
import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {environment} from "../environments/environment";
import {IndexRoutingModule} from './modules/index-routing.module';
import {IndexModule} from './modules/index.module';
import {AppComponent} from './components';
import {CommonModule, DatePipe} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CookieService} from 'ngx-cookie-service';
import {NgsgModule} from "ng-sortgrid";
import {RouteReuseStrategy} from '@angular/router';
import localeEnGb from '@angular/common/locales/en-GB';
import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {LocaleService, DataService, RoleGuardService, URLService} from "./services";
import {AuthGuardServiceInterface, DataServiceInterface, URLServiceInterface} from './global/services/interfaces';
import {ApiService, LoggerService} from './global/services';
import {CustomRouteReuseStrategy} from './global/utils';
import {HttpModule, RemarqLoggerModule} from './global/modules';
import {AutofocusDirective, NgbdSortableHeader} from './global/directives';

registerLocaleData(localeEnGb);

@NgModule({
  declarations: [],
  imports: [
    HttpModule.forRoot({environment}),
    ToastModule.forRoot(),
    DropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    WavesModule,
    ReactiveFormsModule,
    FormsModule,
    IndexRoutingModule,
    IndexModule,
    HttpClientModule,
    CommonModule,
    NgsgModule,
    RemarqLoggerModule,
  ],
  providers: [
    {provide: AuthGuardServiceInterface, useExisting: RoleGuardService},
    {provide: DataServiceInterface, useExisting: DataService},
    {provide: URLServiceInterface, useExisting: URLService},
    ApiService,
    CookieService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    {provide: LOCALE_ID, useValue: "en-GB"},
    MDBSpinningPreloader,
    NgbdSortableHeader,
    AutofocusDirective,
    Title,
    Meta,
    LoggerService,
    LocaleService,
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: []
})

export class AppModule {
}
