import {Component, OnInit} from '@angular/core';
import {URLService} from '../../../../../services';
import {Router} from '@angular/router';
import {User, VehicleDTO} from "../../../../../global/interfaces";
import {CustomerService, LoggerService, UserService} from "../../../../../global/services";

@Component({
  selector: 'app-seller-hub-vehicles-sold',
  templateUrl: './seller-hub-vehicles-sold.component.html',
  styleUrls: ['./seller-hub-vehicles-sold.component.scss']
})
export class SellerHubVehiclesSoldComponent implements OnInit {

  constructor(
    private userService: UserService,
    private url: URLService,
    private customerService: CustomerService,
    private router: Router,
    private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading: boolean;
  vehicles: VehicleDTO[] = [];
  user: User;

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.loadVehicles();
  }

  viewVehicle(vehicleId: string) {

    this.url.advertView(vehicleId);
  }

  loadVehicles() {
    this.isLoading = true;

    const contactId = this.user.contactId;
    const customerId = this.user.customerId;

    this.customerService.getVehicles(customerId, ["make", "model", "deriv"]).then(result => {

      this.vehicles = result.splice(0, 5);
      this.isLoading = false;

      this.logger.info("Loaded vehicles: ", this.vehicles);
    });
  }
}
