import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ConfirmationDialogComponent} from '../components/confirmation-dialog';
import {MDBModalService} from 'ng-uikit-pro-standard';

export interface DialogButtonAction {
  label: string;
  action: boolean;
}

@Injectable(
  {providedIn: 'root'}
)
export class HelpersService {

  constructor(private modalService: MDBModalService) {
  }

  getNamesAndValues<T extends number>(e: any) {
    return this.getNames(e).map(n => ({name: n, value: e[n] as T}));
  }

  getNamesAndValuesAsObject<T extends number>(e: any, splitOnCaps: boolean = false): {
    [key: number]: { name: string, value: number }
  } {
    return this.getNames(e).map(n => ({name: splitOnCaps ? n.replace(/([A-Z])/g, ' $1').trim() : n, value: e[n] as T}));
  }

  getLabelsAndValues<T extends number>(e: any, splitOnCaps: boolean = false) {
    return this.getNames(e).map(n => ({
      label: splitOnCaps ? n.replace(/([A-Z])/g, ' $1').trim() : n,
      value: e[n] as T
    }));
  }

  getNames(e: any) {
    return this.getObjValues(e).filter(v => typeof v === 'string') as string[];
  }

  getValues<T extends number>(e: any) {
    return this.getObjValues(e).filter(v => typeof v === 'number') as T[];
  }

  getSelectList<T extends number, U>(e: any, stringConverter: (arg: U) => string) {
    const selectList = new Map<T, string>();
    this.getValues(e).forEach(val => selectList.set(val as T, stringConverter(val as unknown as U)));
    return selectList;
  }

  getSelectListAsArray<T extends number, U>(e: any, stringConverter: (arg: U) => string) {
    return Array.from(this.getSelectList(e, stringConverter), value => ({
      value: value[0] as T,
      presentation: value[1]
    }));
  }

  getValuesByIndex(e: any) {
    let r = [];
    Object.keys(e).forEach((x) => {
      r[x] = e[x];
    });
    return r;
  }

  getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map(k => e[k]);
  }

  confirmationDialog(title: string, body: string, trueAction: string, falseAction: string, isDanger = false) {
    const buttonAction1 = {label: falseAction, action: false} as DialogButtonAction;
    const buttonAction2 = {label: trueAction, action: true} as DialogButtonAction;

    const subject = new Subject<boolean>();

    const modal = this.modalService.show(ConfirmationDialogComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      containerClass: 'right',
      animated: true
    });
    modal.content.subject = subject;
    modal.content.title = title;
    modal.content.buttonActions = [buttonAction1, buttonAction2];
    modal.content.body = body;
    modal.content.isDanger = isDanger;

    return subject.asObservable();
  }

  parseAdvertNote(note: string) {

    const exists = note.match(/^(SMS:|Emailed:|Enquiry:|Called:)/);

    if (!exists) {
      return '<i class="contact-type fas fa-comment-alt"></i> ' + note;
    }

    note = note.replace(/^SMS:/, '<i class="contact-type fa fa-mobile"></i> ');
    note = note.replace(/^Emailed:/, '<i class="contact-type fa fa-envelope"></i> ');
    note = note.replace(/^Enquiry:/, '<i class="contact-type fa fa-question-circle"></i> ');
    note = note.replace(/^Called:/, '<i class="contact-type fa fa-phone-volume"></i> ');

    return note;
  }
}
