export enum AdvertSearchFieldEnum
{
  Unset,
  Make = 1,
  Model = 2,
  Deriv = 3,
  Fuel = 4,
  Transmission = 5,
  BodyType = 6,
  Mileage = 7,
  Plate = 8,
  Capacity = 9,
  Age = 10,
  Doors = 11,
  VATStatus = 12,
  Price = 13,
  SaleType,
  CustomerType,
  Colour,
  VehicleType,
  Vendor,
  CatStatus
}
