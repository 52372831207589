import {ContactDTO} from './contactDTO.interface';
import {AttribDTO} from './attribDTO.interface';
import {AttribvalDTO} from './attribvalDTO.interface';

export interface ContactAttribDTO {
  contactId?: string;
  contact?: ContactDTO;
  attribId?: number;
  attrib?: AttribDTO;
  attribvalId?: number;
  attribval?: AttribvalDTO;
  attribInt?: number;
  attribChar?: string;
  attribDatetime?: Date;
  attribDecimal?: number;
  attribBool?: boolean;
}
