import {BaseIntDTO} from "../_baseIntDTO.interface";
import {CommsTemplateDTO} from "./commsTemplateDTO";
import {BaseSearchDTO} from "../API/index";
import {CommsEventDTO} from "./commsEventDTO";
import {CustomerDTO} from "../customerDTO.interface";

export interface CommsHistoryDTO extends BaseIntDTO {
  commsTemplateId: number;
  commsTemplate: CommsTemplateDTO;
  uniqueId: string;
  responseId: number;
  responseMessage: string;
  responseStatus: number;
  recipientAddress: string;
  bccAddress: string;
  subject: string;
  body: string;
  dateSent: Date;
  modelId: string;
}

export interface CommsHistorySearchDTO extends BaseSearchDTO {
  filters?: {
    commsEventId?: number;
    id?: number;
  };
}
