// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attention-box h2 {
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -1px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/customer/customer-addresses/customer-addresses.component.scss"],"names":[],"mappings":"AAAA;EAEE,eAAA;EACA,gBAAA;EACA,oBAAA;AAAF","sourcesContent":[".attention-box h2 {\n\n  font-size: 26px;\n  font-weight: 500;\n  letter-spacing: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
