import {AdvertDTO} from "../advertDTO.interface";
import {BaseDTO} from "../_baseDTO.interface";
import {BidStatusEnum} from '../../../enums/bid-status.enum';
import {OfferDTO} from "../offerDTO.interface";

export interface BuyNowBidDTO extends BaseDTO {
  expires?: string;
  bidAmt?: number;
  bidStatus?: BidStatusEnum;
  advert?: AdvertDTO;
  offer?: OfferDTO;
}
