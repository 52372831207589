// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.narrow-container {
  max-width: 480px;
  margin: 0 auto;
}

.howdoyou {
  font-weight: 600;
  margin-bottom: 30px;
}

.tell-us-more {
  height: max(500px, var(--centeringHeight));
}

.thank-you {
  height: max(240px, var(--centeringHeight));
}

.howbox {
  border: 1.5px solid #dfdfdf;
  border-radius: 4px;
  font-weight: 400;
  padding: 15px;
}
.howbox:hover {
  background-color: #F1F7FD;
}
.howbox.chosen {
  border: 1.5px solid var(--linkColour);
  background-color: #F1F7FD;
}

.letsget {
  padding-top: 20px;
  padding-bottom: 20px;
}

.letsget, .tsandcs {
  font-weight: 400;
}

.inline-error-message {
  font-weight: 500;
}

.ng-dirty input.ng-invalid {
  border: 2px solid var(--errorColour) !important;
}

.signup-field .label {
  font-weight: 500;
}
.signup-field input.form-control {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 1.1rem;
  padding: 0.5rem 0.75rem;
}
.signup-field input.form-control:focus {
  border: 2px solid var(--linkColour) !important;
}

.is-trader {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/home/register/register.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,0CAAA;AACF;;AAEA;EACE,0CAAA;AACF;;AAEA;EACE,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;AACF;AACE;EACE,yBAAA;AACJ;AAEE;EACE,qCAAA;EACA,yBAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,oBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,+CAAA;AADF;;AAME;EACE,gBAAA;AAHJ;AAME;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;AAJJ;AAMI;EACE,8CAAA;AAJN;;AASA;EACE,gBAAA;AANF","sourcesContent":[".narrow-container {\n  max-width: 480px;\n  margin: 0 auto;\n}\n\n.howdoyou {\n  font-weight: 600;\n  margin-bottom: 30px;\n}\n\n.tell-us-more {\n  height: max(500px, var(--centeringHeight))\n}\n\n.thank-you {\n  height: max(240px, var(--centeringHeight))\n}\n\n.howbox {\n  border: 1.5px solid #dfdfdf;\n  border-radius: 4px;\n  font-weight: 400;\n  padding: 15px;\n\n  &:hover {\n    background-color: #F1F7FD;\n  }\n\n  &.chosen {\n    border: 1.5px solid var(--linkColour);\n    background-color: #F1F7FD;\n  }\n}\n\n.letsget {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.letsget, .tsandcs {\n  font-weight: 400;\n}\n\n.inline-error-message {\n  font-weight: 500;\n}\n\n.ng-dirty input.ng-invalid {\n  border: 2px solid var(--errorColour) !important;\n}\n\n.signup-field {\n\n  .label {\n    font-weight: 500;\n  }\n\n  input.form-control {\n    margin-top: 5px;\n    margin-bottom: 15px;\n    font-size: 1.1rem;\n    padding: 0.5rem 0.75rem;\n\n    &:focus {\n      border: 2px solid var(--linkColour) !important;\n    }\n  }\n}\n\n.is-trader {\n  font-weight: 400;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
