export interface IOption {
  value?: string;
  label?: string;
  disabled?: boolean;
  group?: boolean;
  icon?: string;
}

export interface IOptionInt {
  value?: number;
  label?: string;
  disabled?: boolean;
  group?: boolean;
  icon?: string;
}
