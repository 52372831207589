import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Camera, CameraResultType} from '@capacitor/camera';
import {GlobalConstants} from "../../shared/index";
import {DataServiceInterface} from "../../services/interfaces/index";

@Component({
  selector: 'app-dragdropfiles',
  templateUrl: './dragdropfiles.component.html',
  styleUrls: ['./dragdropfiles.component.scss']
})
export class DragdropfilesComponent implements OnInit {

  // @HostBinding('class.fileover') fileOver: boolean;
  fileOver: boolean;
  dragDropLabel: string;
  uploading = false;

  public isDevice = GlobalConstants.IsDevice;

  @Output() fileDropped = new EventEmitter<any>();
  @Output() singleFileDropped = new EventEmitter<any>();
  @Output() dragDropClosed = new EventEmitter();
  @Input() filesUploading = false;
  @Input() noVideo = false;
  @Input() customLabel = "";
  @Input() disabled = false;
  @Input() showCloudLogo = false;
  @Input() acceptFileTypes = "image/x-png,image/gif,image/jpeg,image/webp,application/pdf";
  style: number = 1;

  block: boolean = true;

  constructor(private data: DataServiceInterface) {

    this.getDragDropLabel(false);

  }

  ngOnInit(): void {
  }

  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  getDragDropLabel(setAs: boolean) {

    this.dragDropLabel = (setAs) ? 'wait..' : 'Browse for file(s)';

  }

  fileBrowseHandler(event) {
    const files = event.target.files;
    this.fileDropped.emit(files);
  }

  async iOSUploadPicture() {

    // NOTE: If we want to upload multiple photos instead, use Camera.PickImages, but you won't be able to take a photo
    const cameraPhoto = await Camera.getPhoto({resultType: CameraResultType.DataUrl, quality: 75, width: 800});

    // Send the file
    await this.singleFileDropped.emit(cameraPhoto.dataUrl);
  }

  hideDragDrop(event) {
    event.stopPropagation();
    this.dragDropClosed.emit();
  }
}
