import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  subject: Subject<boolean>;

  public title: string;
  public body: string;
  public buttonActions = [{ label: 'Stay', action: false}, { label: 'Leave', action: true }];
  public isDanger: boolean;

  constructor(public modalRef: MDBModalRef) {}

  ngOnInit(): void {
  }

  action(index: number) {
    this.modalRef.hide();
    this.subject.next(this.buttonActions[index].action);
    this.subject.complete();
  }

  falseAction() {
    this.modalRef.hide();
    this.subject.next(false);
    this.subject.complete();
  }
}
