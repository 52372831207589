import {Directive, Input, Output, EventEmitter, ElementRef, HostListener} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {

  @Input() ignoreOutsideClasses?: string;
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target', '$event.path'])
  public onClick(target, targetElementPath: Array<any>) {

    let shouldIgnore = false;

    if (this.ignoreOutsideClasses) {

      const ignoreClasses = this.ignoreOutsideClasses.split(',');
      const clickedClasses = Array.from(target.classList);

      shouldIgnore = ignoreClasses.some(r => clickedClasses.includes(r));
    }

    const clickedInside = this.elementRef.nativeElement.contains(target)
      || targetElementPath?.find(e => e === this.elementRef.nativeElement);

    if (!clickedInside && !shouldIgnore) {

      this.clickOutside.emit();
    }
  }
}
