import {BaseDTO} from "../_baseDTO.interface";

export interface SelectDTO {
  id: number;
  label: string;
}

export interface SelectGUIDDTO {
  id: string;
  label: string;
}
