import {MediaTypeDTO} from './media-typeDTO.interface';
import {BaseDTO} from "./_baseDTO.interface";

export interface AppraisalMediaDTO extends BaseDTO
{
  mediaTypeId: number;
  appraisalItem: string;
  customerRef: string;
  mediaURL?: string;
  mediaType: MediaTypeDTO;
}
