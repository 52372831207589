import {CustomerDTO} from './customerDTO.interface';
import {CountryDTO} from "./countryDTO.interface";
import {BaseDTO} from "./";

export interface AddressDTO extends BaseDTO {
  countryId: number;
  addressName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressCounty: string;
  addressPostcode: string;
  customerId: string;
  isPrimary: boolean;
  country: CountryDTO;
  customer: CustomerDTO;
}
