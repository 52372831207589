import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-delete-confirm-modal',
  templateUrl: './delete-confirm-modal.component.html',
  styleUrls: ['./delete-confirm-modal.component.scss']
})
export class DeleteConfirmModalComponent implements OnInit {

  constructor() {
  }

  @ViewChild('deleteFrame') deleteFrame: ModalDirective;

  @Output() confirmDelete: EventEmitter<any> = new EventEmitter();
  @Output() cancelDelete: EventEmitter<any> = new EventEmitter();
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();

  @Input() bodyText: string;

  @Input('showDialog') set showDialog(value: boolean) {

    if (value) {
      this.openDialog();
    }
  }


  get showDialog(): boolean {
    return this.deleteFrame.isShown;
  }

  ngOnInit(): void {
  }

  openDialog() {
    if (!this.deleteFrame.isShown) {
      this.deleteFrame.show();
    }
  }

  deleteConfirmation() {

    // QUESTION: Doing this does not update the "showDialog" parameter, so when the user returns to this, deleteFrame() is still hidden

    this.deleteFrame.hide();

    // raise confirm delete event
    this.confirmDelete.emit();
  }

  cancelDeletion() {
    this.deleteFrame.hide();
    this.cancelDelete.emit();
  }

  modalClosedEvent() {
    this.modalClosed.emit(true);
  }
}
