import {Component, Input, OnInit} from '@angular/core';
import {URLService} from "../../../../../services";
import {AdvertDTO} from "../../../../../global/interfaces";
import {ImageService} from "../../../../../global/services";
import {SaleTypeEnum} from "../../../../../global/enums";
import * as moment from 'moment';

@Component({
  selector: 'app-widget-adverts-list',
  templateUrl: './widget-adverts-list.html',
  styleUrls: ['./widget-adverts-list.scss']
})
export class WidgetAdvertsListComponent implements OnInit {

  @Input() adverts: AdvertDTO[];
  saleTypeEnum = SaleTypeEnum;
  moment: any = moment;

  constructor(
    public url: URLService,
    public imageService: ImageService) {
  }

  ngOnInit(): void {
  }

}
