// 4	NSF Tyre Depth	nsf_tyre_depth
// 5	NSR Tyre Depth	nsr_tyre_depth
// 6	OSR Tyre Depth	osr_tyre_depth
// 7	OSF Tyre Depth	osf_tyre_depth
// 8	Spare Tyre Depth	spare_tyre_depth
export enum GGGEnum {
  Ready,
  GoingOnce,
  GoingTwice,
  Gone
}
