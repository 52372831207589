import {UInspectMediaSyncDTO} from "./uinspect-media-dto.interface";
import {UInspectQuestionDTO, UInspectQuestionSyncDTO} from "./uinspect-question-dto.interface";
import {FieldMappingEnum, MediaTypeEnum} from "../../../enums/index";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {UInspectSectionCompleteDTO, UInspectSectionCompleteSyncDTO} from "./uinspect-section-complete-dto.interface";
import {UInspectSectionTypeEnum} from "../../../enums/uinspect/index";

export interface UInspectSectionDTO extends BaseIntDTO {
  sectionType?: UInspectSectionTypeEnum;
  uInspectId?: string;
  uInspectFormatId?: number;
  title?: string;
  placeholderURL?: string;
  explanation?: string;
  displayOrder?: number;
  isInterior?: number;
  disabled?: boolean; // some sections aren't relevant to all cars (i.e. Rear Seats)
  mediaType?: MediaTypeEnum;
  minMedia?: number;
  maxMedia?: number;
  nextSectionId?: number;
  questions?: UInspectQuestionDTO[];
  sectionsComplete?: UInspectSectionCompleteDTO;
  continueURL?: string;
  internalLabel?: string;
  mappedTo?: FieldMappingEnum;
  mapToDocumentCategoryId?: number;
}

export interface UInspectSectionSyncDTO extends UInspectSectionDTO {
  medias?: UInspectMediaSyncDTO[];
  questions?: UInspectQuestionSyncDTO[];
  sectionComplete?: UInspectSectionCompleteSyncDTO;
  synced?: boolean;
}

// tslint:disable-next-line:no-empty-interface
export interface UInspectSectionWithAnswersDTO extends UInspectSectionSyncDTO {

  // Using UInspectSyncDTO can be misleading, so here's an alias for it

}
