import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {_deepClone} from 'fast-json-patch/module/helpers';
import {compare, deepClone} from 'fast-json-patch';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {
  AdvertDTO,
  AdvertSearchDTO,
  BidDTO,
  BrokerageDTO, ContactAdminDTO,
  ContactDTO, MessageDTO,
  NegotiationDTO,
  User
} from "../../../../global/interfaces";
import {HelpersService, LoggerService, ImageService, UserService} from "../../../../global/services";
import {
  AdvertEventEnum,
  AdvertStatusEnum, BidStatusEnum,
  BrokerageStateEnum, MessageAreaEnum, MessageMethodEnum, MessageTypeEnum,
  NegotiationStateEnum,
  ProspectActionEnum, SoldStatusEnum,
  StatusEnum
} from "../../../../global/enums";
import {
  AdminAdvertNoteService,
  AdminBrokerageService,
  AdminProspectService,
  AdvertService,
  EventService,
  LocaleService
} from '../../../../services';
import {CustomCurrencyPipe} from "../../../../pipes";
import {AdminNegotiationService, AdminOfferService, AdminWhosWhoService} from "../../services";
import * as moment from "moment";


@Component({
  templateUrl: './manage-negotiations.component.html',
  styleUrls: ['./manage-negotiations.component.scss'],
  providers: [CustomCurrencyPipe]
})
export class ManageNegotiationsComponent implements OnInit {
  private bidId: string;
  private currentUser: User;
  newBidContact: ContactDTO;
  brokerage: BrokerageDTO;
  statusNames: any = {};
  assignees: ContactAdminDTO[] = [];
  assignedOptions: { disabled: boolean; label: string; value: string }[];

  constructor(
    private activeRoute: ActivatedRoute,
    private advertService: AdvertService,
    private advertNoteService: AdminAdvertNoteService,
    private brokerageService: AdminBrokerageService,
    private whosWhoService: AdminWhosWhoService,
    private adminOfferService: AdminOfferService,
    private prospectService: AdminProspectService,
    public localeService: LocaleService,
    public imageService: ImageService,
    private negotiationService: AdminNegotiationService,
    private toast: ToastService,
    private helperService: HelpersService,
    private customCurrencyPipe: CustomCurrencyPipe,
    private logService: LoggerService,
    private eventService: EventService,
    private userService: UserService
  ) {

    this.statusNames = this.helperService.getValuesByIndex(StatusEnum);
    this.advertId = this.activeRoute.snapshot.params.advertId;

    this.eventService.ContactEvent.subscribe((eventData) => {
      this.processContactEvent(eventData.messageData);
    });

    this.eventService.AdvertEvent.subscribe((event) => {
      this.processAdvertEvent(event);
    });
  }

  logger = this.logService.taggedLogger(this.constructor?.name);
  advertId: string;
  liveOnly = true;
  advert: AdvertDTO;
  bidders: BidDTO[];
  loading = false;
  selectedNegotiation: NegotiationDTO;
  public NegotiationState = NegotiationStateEnum;
  public BidContact = {};

  @ViewChild('notesModal') notesModal: ModalDirective;
  negotiationNote: string;
  @ViewChild('viewNotesModal') viewNotesModal: ModalDirective;

  toastOpts = {opacity: 0.98};

  @ViewChild('bidModal') bidModal: ModalDirective;
  @ViewChild('offerModal') offerModal: ModalDirective;

  newBidAmount: any;
  doesExpire = false;
  expiresDate: any;
  expiresTime: any;
  newOfferAmount: any;
  doesOfferExpire: any;
  offerExpiresDate: any;
  offerExpiresTime: any;

  @ViewChild('assignToModal') assignToModal: ModalDirective;

  brokerageCopy: BrokerageDTO;
  brokerageStateOptions: any;
  brokerageStateEnum = BrokerageStateEnum;
  editing = false;

  brokerageStateId?: number;

  async ngOnInit() {

    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
    });

    this.loadAdvert().then();
    this.loadBrokerage().then();
    this.loadAssignees().then();

    this.brokerageStateOptions = this.helperService.getLabelsAndValues(BrokerageStateEnum, true);
  }

  async loadAdvert() {
    this.loading = true;

    const searchDTO = {
      component: "AdvertNegotiationView",
      filters: {excludeBidSummary: true}
    } as AdvertSearchDTO;

    const response = await this.advertService.getAdvert(this.advertId, searchDTO);

    console.log("RESPONSE ", response);

    this.advert = response.advert;
    this.selectedNegotiation = this.advert.negotiations && this.advert.negotiations.length > 0 ? this.advert.negotiations[0] : null;

    const x = this.negotiationService.topBidders(this.advert);
    this.bidders = x[0];
    this.BidContact = x[1];

    this.loading = false;
  }

  inactiveBrokerage() {
    return this.brokerage?.id == null || this.brokerage?.brokerageStateId !== BrokerageStateEnum.Active;
  }

  toggleLive(event: MouseEvent) {
    // filter negotiations
  }

  showNoteModal(negotiation: NegotiationDTO) {
    this.selectedNegotiation = negotiation;
    this.notesModal.show();
  }

  async addNote() {
    await this.negotiationService.addNote({
      negotiationId: this.selectedNegotiation.id,
      note: this.negotiationNote
    }).then(result => {
      this.selectedNegotiation.negotiationNotes.unshift(result);
    });

    this.notesModal.hide();
  }

  async sleepDateChanged(neg: NegotiationDTO, event) {
    // do nothing if advert is sold
    if (this.isSold()) {
      return;
    }

    this.logger.info("Setting sleep date for: ", neg, " - to: ", event);
    const negC = _deepClone(neg);
    negC.sleepUntil = null;
    const patch = compare(negC, neg);

    await this.negotiationService.patchNegotiation(neg.id, patch);
  }

  takeBid(bid: BidDTO) {
    // do nothing if advert is sold
    if (this.isSold()) {
      return;
    }

    const bidAmt = this.customCurrencyPipe.transform(bid.bidAmt);
    const msg = `
      <table style='border-spacing: 10px;'>
        <tr><td style='text-align: right;'>Accept:</td><td>&nbsp; <strong>${bidAmt}</td></tr>
        <tr><td style='text-align: right;'>bid from:</td><td>&nbsp;
        <strong>${bid.contact.customer?.customerName}</strong> -
        <strong>${bid.contact?.contactName || bid.contact.email}</strong>
        </td></tr>
        <tr><td style='text-align: right;'>on behalf vendor:</td><td>&nbsp;
        <strong>${this.advert.contact?.customer?.customerName}</strong>
        <strong>${this.advert.contact?.contactName || this.advert.contact.email}</strong>
        </td></tr>
      </table>
      <br>
      <table>
      <tr><td><strong>NOTE:</strong> This will <strong>END</strong> the listing.</td></tr>
      <tr><td>The bidder & vendor will be committed to the sale.</td></tr>
      </table>
      `;

    this.helperService.confirmationDialog("Confirm Take Bid", msg, 'Take Bid', 'Cancel')
      .subscribe(result => {
        if (result) {
          this.negotiationService.acceptBid(this.selectedNegotiation.id, bid.bidGuid).then((result2) => {
            if (result2) {
              this.loadAdvert().then();
            }
          });
        }
      });
  }

  soldStatusEnum = SoldStatusEnum;

  isSold() {
    return this.advert?.soldStatus === SoldStatusEnum.Sold;
  }

  createNewBid(contact: ContactDTO) {

    this.newBidContact = contact;
    this.newBidAmount = this.advert.reservePrice;
    this.bidModal.show();
  }

  checkForProspectThenSubmit() {

    this.bidModal.hide();

    const bidder = this.newBidContact;
    const expires = (this.expiresDate && this.expiresTime) ? (this.expiresDate + " " + this.expiresTime) : null;

    // check bid is appropriate (must be higher than current highest bid)
    if (this.newBidAmount <= this.advert.topBid?.bidAmt) {
      this.toast.error(`Bid must be higher than current highest bid of: ` +
        this.localeService.getCurrencySymbol() + this.advert.topBid.bidAmt, "Error", this.toastOpts);
      return;
    }

    console.log("** PREPARING PROSPECT BID");

    // This will auto-create prospect/brokerage records if required
    this.prospectService.submitProspectBid({
      advertId: this.advert.id,
      bidAmt: this.newBidAmount,
      statusId: StatusEnum.Active,
      bidStatus: BidStatusEnum.BID_WITHVENDOR,
      contactId: bidder.id,
      customerId: bidder.customerId,
      expires
    }).then(() => {

      console.log("** SAVING PROSPECT BID");

      // create a note describing this bid in the negotiation
      const bidAmt = this.customCurrencyPipe.transform(this.newBidAmount);
      const note = `Added bid ${bidAmt} on behalf of ${bidder.contactName || bidder.email}`;

      this.prospectService.saveProspectNote({
        brokerageId: this.brokerage.id,
        contactId: this.currentUser.contactId,
        advertId: this.advertId,
        prospectContactId: this.newBidContact.id,
        prospectActionId: ProspectActionEnum.Bid,
        manuallyAdded: true,
        note
      }).then(() => {

        console.log("** SAVED PROSPECT BID");

      }).finally(() => {
        this.loadAdvert().then();

        this.eventService.AdvertEvent.emit({
          messageData: {
            event: AdvertEventEnum.UpdateProspectList,
            advertId: this.advertId
          }
        });
        this.eventService.AdvertEvent.emit({
          messageData: {
            event: AdvertEventEnum.RefreshAdvertNotes,
            advertId: this.advertId
          }
        });
      });
    });
  }

  createOffer() {

    this.offerModal.hide();

    // check bid is appropriate (must be higher than current highest bid)
    if (this.newOfferAmount <= this.advert.currentPrice) {
      this.toast.error(`Offer must be less than the current price: ` +
        this.localeService.getCurrencySymbol() + this.advert.currentPrice, "Error", this.toastOpts);
      return;
    }

    const expires = (this.offerExpiresDate && this.offerExpiresTime) ? (this.offerExpiresDate + " " + this.offerExpiresTime) : null;

    this.adminOfferService.submitAdminOffer({
      expires,
      offerAmt: this.newOfferAmount,
      bidGuid: this.bidId,
      statusId: StatusEnum.Active,
      contactId: this.advert.contactId,
      customerId: this.advert.customerId
    }).then(() => {

      this.loadAdvert().then();

      // create a note describing this bid in the negotiation
      const offerAmt = this.customCurrencyPipe.transform(this.newOfferAmount);
      const note = `Added counter-offer ${offerAmt} on behalf of ${this.advert.contact?.customer?.customerName}`;

      this.prospectService.saveProspectNote({
        brokerageId: this.brokerage.id,
        contactId: this.currentUser.contactId,
        advertId: this.advertId,
        prospectContactId: this.newBidContact.id,
        prospectActionId: ProspectActionEnum.Bid,
        manuallyAdded: true,
        note
      }).then(() => {

        console.log("** SAVED PROSPECT BID");

      }).finally(() => {
        this.eventService.AdvertEvent.emit({
          messageData: {
            event: AdvertEventEnum.RefreshAdvertNotes,
            advertId: this.advertId
          }
        });
      });

    });
  }

  onNegotiationSelected(neg: any) {
    this.selectedNegotiation = neg;
    this.viewNotesModal.show();
  }

  doesExpireChanged() {

    if (this.doesExpire) {
      this.expiresDate = moment().add(1, "days").format("YYYY-MM-DD");
      this.expiresTime = moment().format("HH:mm");
    }

  }

  doesOfferExpireChanged() {

    if (this.doesOfferExpire) {
      this.offerExpiresDate = moment().add(1, "days").format("YYYY-MM-DD");
      this.offerExpiresTime = moment().format("HH:mm");
    }

  }

  startCounterOffer(bid: BidDTO) {

    this.bidId = bid.bidGuid;

    this.offerModal.show();
  }

  private processContactEvent(messageData: any) {

    if (messageData.addBid === true) {

      this.createNewBid(messageData.contact);
    }
  }

  brokerageName(id: number) {

    if (id == null) {
      return 'Status: Unknown';
    }

    return this.brokerageStateOptions?.find(x => x.value === id)?.label;
  }


  async loadBrokerage() {

    return this.brokerageService.getAdvertBrokerage(this.advertId, {component: 'brokerage'}).then(res => {

      if (res.isValid) {
        this.brokerage = res.dto;
        this.saveBrokerageCopy();
      } else {
      }
    });
  }

  saveBrokerageCopy() {
    this.brokerageCopy = {...this.brokerage};
  }

  async initBrokerage() {
    return this.brokerageService.create({
      advertId: this.advertId,
      statusId: StatusEnum.Active,
      brokerageStateId: BrokerageStateEnum.StatusUnknown
    }).then(res => {
      this.brokerage = res.dto;
      this.saveBrokerageCopy();
    });
  }

  async changeBrokerageState(event) {

    if (this.brokerage?.id == null) {
      await this.initBrokerage();
    }

    this.brokerage.brokerageStateId = event;

    // patch brokerage status
    const patch = compare({}, {brokerageStateId: event});

    this.brokerageService.patchBrokerage(this.brokerage?.id, patch).then(res => {

      this.editing = false;
      this.brokerage = res.dto;
      this.brokerageCopy = deepClone(res);

      this.advertNoteService.create(this.brokerage?.advertId, {
        contactId: this.userService.CurrentUser.contactId,
        note: 'Brokerage status changed to ' + this.brokerageName(this.brokerage?.brokerageStateId)
      }).then(() => {

        this.requestNewNotes();
      });
    });
  }

  changeBrokerageContact(contactId: string) {

    // patch brokerage status
    const patch = compare({}, {contactId});

    console.log("PATCH ", patch);

    this.brokerageService.patchBrokerage(this.brokerage?.id, patch).then(() => {
      this.requestNewNotes();
      this.loadBrokerage();
    });
  }

  requestNewNotes() {
    this.eventService.ContactEvent.emit({
      messageMethod: MessageMethodEnum.Message,
      messageArea: MessageAreaEnum.Contacts,
      messageType: MessageTypeEnum.ManageNegotiations,
      messageData: {fetchNotes: true}
    }); // stringified JSON object or plain text
  }

  assignToMe() {
    this.brokerage.contactId = this.currentUser.contactId;
    this.changeBrokerageContact(this.brokerage.contactId);
  }

  selectAssignee() {
    this.eventService.ShowAssigneeDialogEvent.emit();
  }

  confirmAssignee(assignee: ContactDTO) {
    this.changeBrokerageContact(assignee.id);

    this.advertNoteService.create(this.brokerage?.advertId, {
      contactId: this.userService.CurrentUser.contactId,
      note: 'Brokerage assignment changed to ' + assignee.contactName
    }).then(() => {

      this.loadBrokerage();
      this.requestNewNotes();
    });
  }

  isLeadingBid(bid: BidDTO) {

    return this.advert.topBidGuid === bid.bidGuid;

  }

  assignedTo(assignedTo: any) {
    return this.assignees.find(x => x.id === assignedTo)?.contactName ?? 'Unassigned';
  }

  async loadAssignees() {
    return this.whosWhoService.getSiteAdmins({component: 'whos-who'}, true).then((res) => {
      this.assignees = res.results;
      this.assignedOptions = this.whosWhoService.getAssignedOptions(res.results);
    });
  }

  private processAdvertEvent(event: MessageDTO) {

  }
}
