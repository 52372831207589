export enum BidViewTypeEnum {
  Active,
  Lost
}

export enum BidSearchViewEnum {
  All = 0,
  DealsAgreed,
  Pending,
  Rejected,
  Expired,
  OnHold,
  CounterOffer
}
