import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {User} from "../../interfaces/index";

export abstract class AuthGuardServiceInterface {
  public abstract canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean;
  public abstract getTokenObservable(): Observable<string>;
  public abstract getTokenPromise(): Promise<string>;
  public abstract userInfo(user: any): User;
}
