import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ProductService} from "../../../../services";
import {PlatformService, TaxService} from "../../../../services";
import {ProductDTO} from "../../../../global/interfaces";
import {HelpersService, LoggerService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
providers:[]
})
export class ProductComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helpersService: HelpersService,
    private productService: ProductService,
    private platformService: PlatformService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @ViewChild('productModal') productModal: ModalDirective;

  public statusName: string[] = [];
  products: ProductDTO[];
  statusOptions: {}[];
  platforms: any;

  form: UntypedFormGroup;
  isLoading = false;
  isEdit = false;
  productIdToDelete: number;
  submitted = false;

  showDeleteConfirm: boolean = false;

  async ngOnInit() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      productName: new UntypedFormControl(),
      productCode: new UntypedFormControl(1),
      statusId: new UntypedFormControl(''),
    });


    this.loadStatuses();
    this.loadProducts();
    this.loadPlatforms();
  }

  async loadStatuses() {
    const statuses = this.helpersService.getNamesAndValues(StatusEnum);
    this.statusOptions = statuses.filter(x => x.value != StatusEnum.Deleted).map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadPlatforms() {
    await this.platformService.search().then(result => {
      this.platforms = result.map(x => {
        return {label: x.platformName, value: x.id};
      });
    });
  }

  async loadProducts() {

    this.isLoading = true;

    await this.productService.search({ignoreCache: true}).then(result => {
      this.products = result;

      this.logger.info("PRODUCTS", result);

      this.isLoading = false;
    });
  }

  editProduct($event: MouseEvent, productDTO: ProductDTO) {

    this.form.patchValue(({
      ...productDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.productModal.show();

  }

  addProduct() {

    this.form.patchValue(({
      id: '',
      productName: '',
      productCode: '',
      statusId: StatusEnum.Active
    }));
    this.isEdit = false;

    // show modal that allows location name entry
    this.productModal.show();
  }

  async saveProduct() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const productDTO = this.form.value;
    productDTO.productCode = productDTO.productCode.replace(/^\w/, c => c.toUpperCase());

    if (this.isEdit) {
      await this.productService.patch(productDTO);
    } else {
      await this.productService.create(productDTO);
    }

    this.productModal.hide();
    await this.loadProducts();
  }

  hideModal() {
    this.productModal.hide();
  }

  deleteProduct(event, product: ProductDTO) {
    event.stopPropagation();
    this.productIdToDelete = product.id;
    this.showDeleteConfirm = true;
  }


  confirmDelete() {
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }
}
