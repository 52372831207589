import {Injectable} from '@angular/core';
import {AppComponent} from "../components/index";

@Injectable()
export class LocaleService {

  constructor() {
  }

  public getCurrencySymbol() {
    return AppComponent.globals.currencySymbol;
  }

  public getCurrencyCode() {
    return AppComponent.globals.currencyCode;
  }

  public getWriteOffPhrase() {
    return AppComponent.globals.writeOffPhrase;
  }
}
