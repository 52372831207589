import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {EventService, SearchService} from '../../../services';
import {SavedSearchDTO, SearchDTO, User} from "../../../global/interfaces";
import {LoggerService, UserService} from "../../../global/services";
import {StatusEnum, UpdateFrequencyEnum, UpdateMethodEnum} from "../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-saved-search-edit',
  templateUrl: './saved-search-edit.component.html',
  styleUrls: ['./saved-search-edit.component.scss']
})
export class SavedSearchEditComponent implements OnInit, OnDestroy {

  constructor(
    private userService: UserService,
    private searchService: SearchService,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @ViewChild('conditionItemModal') mdbModal: ModalDirective;

  // set this when editing an existing saved search
  @Input() savedSearchDTO: SavedSearchDTO;
  @Input() isNewRecord: boolean;

  public saveSearchData: UntypedFormGroup;
  private user: User;

  updateFrequencies: { label, value }[];

  searchDTO: SearchDTO;
  saveSearchEventSub;
  isProfile: boolean;

  async ngOnInit() {
    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.saveSearchEventSub = this.eventService.SaveSearchEvent.subscribe((event) => {
      this.isProfile = event.isProfile;
      this.searchDTO = event.searchDTO;
      this.savedSearchDTO = event.savedSearchDTO;

      this.openDialog();
    });

    this.saveSearchData = this.fb.group({
        searchName: ['', Validators.required],
        sendUpdates: [true],
        // tslint:disable-next-line:no-bitwise
        sendSMS: [''],
        // tslint:disable-next-line:no-bitwise
        sendEmail: [true],
        updateFrequency: [UpdateFrequencyEnum.immediate.toString()],
        maxVehicles: [null]
      }
    );

    this.updateFrequencies = this.searchService.getUpdateTypesForDropdown(UpdateFrequencyEnum)
      .map(x => {
        return {label: x.name, value: x.id};
      });
  }

  ngOnDestroy() {
    if (this.saveSearchEventSub) {
      this.saveSearchEventSub.unsubscribe();
    }
  }

  dialogCancel() {
    this.closeModal(false);
  }

  openDialog() {

    // create dto for saving a new search
    if (this.isNewRecord) {

      if (this.savedSearchDTO.searchName.length === 0) {
        this.savedSearchDTO.searchName = "Search-" + new Date().toLocaleString();
      }

      this.savedSearchDTO = {
        sendUpdates: true,
        searchName: this.savedSearchDTO.searchName,
        contactId: this.user.contactId,
        statusId: StatusEnum.Active,
        search: this.searchDTO,
        sendEmail: true,
        sendSMS: false,
        updateFrequency: UpdateFrequencyEnum.immediate,
      };

      this.logger.log("Created saved search: ", this.savedSearchDTO);

    } else {

      this.logger.log("Editing saved search: ", this.savedSearchDTO);
    }

    // patch values into form fields
    this.saveSearchData.patchValue(({
      searchName: this.savedSearchDTO.searchName,
      sendUpdates: this.savedSearchDTO.sendUpdates,
      // tslint:disable-next-line:no-bitwise
      sendSMS: this.savedSearchDTO.sendSMS,
      // tslint:disable-next-line:no-bitwise
      sendEmail: this.savedSearchDTO.sendEmail,
      updateFrequency: this.savedSearchDTO.updateFrequency.toString()
    }));

    this.mdbModal.show();
  }

  get formCtrls() {
    return this.saveSearchData.controls;
  }

  closeModal(update: boolean) {
    this.mdbModal.hide();
  }

  saveNamedSearch() {

    const savedSearch: SavedSearchDTO = {
      ...this.savedSearchDTO,
      contactId: this.user.contactId,
      statusId: StatusEnum.Active,
      searchName: this.saveSearchData.controls.searchName.value,
      sendUpdates: this.saveSearchData.controls.sendUpdates.value,
      updateFrequency: this.saveSearchData.controls.updateFrequency.value,
      sendEmail: this.saveSearchData.controls.sendEmail.value,
      sendSMS: this.saveSearchData.controls.sendSMS.value,
      isProfile: this.isProfile,
      maxVehicles: this.saveSearchData.controls.maxVehicles.value
    };

    this.logger.log("Current save search data: ", savedSearch);

    if (!this.isNewRecord) {
      // update existing
      this.searchService.updateNamedSearch(savedSearch).then(() => {
        this.logger.log("Updated named search '", savedSearch.searchName, "'");
        this.closeModal(true);
      }).catch((error) => {
        this.logger.error("Error saving named search: ", error.message);
      });
    } else {
      // create a SavedSearch object containing a Search object and post to controller
      this.searchService.saveNamedSearch(savedSearch).then(() => {
        this.logger.log("Saved named search '", savedSearch.searchName, "' to db");
        this.closeModal(true);
      }).catch((error) => {
        this.logger.error("Error saving named search: ", error.message);
      });
    }
  }
}
