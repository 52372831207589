import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CommsEventDTO, CommsEventSearchDTO, SearchResultDTO} from "../global/interfaces";
import {ApiService} from "../global/services";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root',
})
export class CommsEventService {

  url = '/api/commsEvent';
  entityTitle = 'Comms Event';

  constructor(private http: HttpClient, private apiClient: ApiService, private data: DataService) {
  }

  getCommsEvents(dto: CommsEventSearchDTO): Promise<SearchResultDTO> {

    const url = this.data.apiUrl + this.url + "s";

    return this.apiClient.get({url}, {query: dto});
  }

  deleteEvent(id) {

    const newUrl = this.data.apiUrl + this.url + "/" + id;

    return this.apiClient.delete({url: newUrl});
  }

  saveEvent(commsEvent: CommsEventDTO): Promise<CommsEventDTO> {

    let newUrl = this.data.apiUrl + this.url;

    if (commsEvent.id === 0 || commsEvent.id == null) {

      return this.apiClient.post({url: newUrl, data: commsEvent});

    } else {

      newUrl += "/" + commsEvent.id;
      return this.apiClient.put({url: newUrl, data: commsEvent});
    }
  }
}
