import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {URLService} from "../../../../services";
import {User} from "../../../../global/interfaces";
import {CustomerService, UserService} from "../../../../global/services";
import {} from "../../../../global/enums";

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css'],
})
export class CustomerEditComponent implements OnInit, OnDestroy {

  submitted = false;
  form: UntypedFormGroup;
  createMode = false;
  user: User;

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    private url: URLService,
    private fb: UntypedFormBuilder,
    private currentRoute: ActivatedRoute,
    private router: Router
  ) {

    const id = this.currentRoute.snapshot.params.id;

    if (id === undefined) {
      this.createMode = true;
    }

  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.form = this.fb.group({
      customerName: ['', Validators.required],
      email: ['', Validators.email],
      websiteUrl: [''],
    }, {validator: this.formValidator()});
  }


  formValidator(): ValidatorFn {

    return (control: UntypedFormGroup): ValidationErrors | null => {

      const errors: ValidationErrors = {};

      if (control.get('customerName').invalid) {
        errors.noCustomerName = true;
      }

      if (control.get('email').invalid) {
        errors.invalidEmail = true;
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  get f() {
    return this.form.controls;
  }

  get err() {
    return this.form.errors;
  }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.createMode) {

      this.customerService.createCustomer(this.form.value).then(() => {
      });

    } else {

      this.customerService.updateCustomer(this.form.value).then((x) => {
        this.router.navigate([this.url.dashboard(true)]);
      });
    }

  }

  ngOnDestroy() {
  }
}
