import {Injectable} from '@angular/core';
import {
  BidReplyDTO, OfferResponseDTO, OfferSearchDTO,
  SearchResultDTO,
  ValidatedResultDTO
} from '../global/interfaces';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class OfferService {

  private serviceUrl = '/api/offer';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  get(offerSearch: OfferSearchDTO): Promise<SearchResultDTO> {
    let url = `${this.data.apiUrl}/api/offers/`;
    url += '?query=' + encodeURIComponent(JSON.stringify(offerSearch));

    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }

  counterOffer(data: BidReplyDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${data.bidGuid}/counterOffer`;
    return this.apiClient.put({url, data}) as Promise<any>;

  }

  cancelOffer(offerId: string, offer) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${offerId}/cancel`;
    return this.apiClient.put({url,  data: offer }) as Promise<any>;
  }

  submitCounterOfferResponse(offerId: string, response: OfferResponseDTO): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${offerId}/response`;
    return this.apiClient.put({url, data: response}) as Promise<any>;
  }
}
