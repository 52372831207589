import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Capacitor} from '@capacitor/core';
import {Http, HttpOptions} from '@capacitor-community/http';
import {AuthGuardServiceInterface} from "./interfaces/index";
import {DataServiceInterface} from "./interfaces/index";
import {LoggerService} from '.';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient,
              private data: DataServiceInterface,
              private auth: AuthGuardServiceInterface,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  endImpersonation(): Promise<any> {
    const url = this.data.apiUrl + '/api/contact/endImpersonate';
    return this.get({url}) as Promise<any>;
  }

  get(options: HttpOptions, otherOptions?: { query?: any, InterceptHeader?: boolean }): Promise<any> {
    // IMPORTANT: IF YOU ARE MAKING A REQUEST TO EXTERNAL NON-REMARQ API
    // THEN YOU MUST ADD X-Skip-Interceptor HEADER TO THE REQUEST
    // OTHERWISE AUTH TOKENS GET SENT AND YOU GET PIPE ERRORS

    // If we have been passed in a DTO that should become a query string
    if (otherOptions?.query) {
      options.params = {query: JSON.stringify(otherOptions.query)};
    }

    if (!options.headers?.accept) {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers.accept = 'application/json';
    }

    if (Capacitor.isNativePlatform()) {

      return this.capacitorHttp('get', options);
    }

    const opts: object = options;

    return this.httpClient.get(options.url, opts).toPromise();
  }

  put(options: HttpOptions): Promise<any> {

    if (!options.headers?.accept) {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers.accept = 'application/json';
    }

    if (Capacitor.isNativePlatform()) {

      return this.capacitorHttp('put', options);
    }

    const opts: object = options;

    return this.httpClient.put(options.url, options.data, opts).toPromise();

  }

  post(options: HttpOptions): Promise<any> {

    // IMPORTANT: IF YOU ARE MAKING A REQUEST TO EXTERNAL NON-REMARQ API
    // THEN YOU MUST ADD X-Skip-Interceptor HEADER TO THE REQUEST
    // OTHERWISE AUTH TOKENS GET SENT AND YOU GET PIPE ERRORS

    if (!options.headers?.accept) {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers.accept = 'application/json';
    }

    if (Capacitor.isNativePlatform()) {

      this.logger.log('POSTING WITH CAPACITOR .. ');

      return this.capacitorHttp('post', options);
    }

    const opts: object = options;

    console.log("GOING TO ", options.url, " DATA ", options.data);

    return this.httpClient.post(options.url, options.data, opts).toPromise();
  }

  delete(options: HttpOptions): Promise<any> {

    if (Capacitor.isNativePlatform()) {

      return this.capacitorHttp('delete', options);
    }

    return this.httpClient.delete(options.url).toPromise();
  }

  patch(options: HttpOptions): Promise<any> {

    if (!options.headers?.accept) {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers.accept = 'application/json';
    }

    if (!('Content-Type' in options.headers)) {
      options.headers['Content-Type'] = 'application/json';
    }

    if (Capacitor.isNativePlatform()) {
      return this.capacitorHttp('patch', options);
    }

    const opts: object = options;

    return this.httpClient.patch(options.url, options.data, opts).toPromise();
  }

  capacitorHttp(method, request: HttpOptions) {

    return this.auth.getTokenPromise().then((jwt) => {

        request.method = method;

        // Add JWT to outgoing request
        if (request.headers && !request.headers['X-Skip-Interceptor']) {
          request.headers.Authorization = 'Bearer ' + jwt;
        }

        this.logger.log("REQUEST ", request);

        return Http.request(request).then((x) => x.data);
      }
    );
  }
}
