import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  templateUrl: './vehicle-view.component.html',
  styleUrls: ['./vehicle-view.component.scss'],
})
export class AdminVehicleViewComponent implements OnInit {

  public menuItems = [];

  ngOnInit(): void {

  }

}
