import {SaleDTO} from './saleDTO.interface';
import {ContactDTO} from '../contactDTO.interface';
import {BaseDTO} from '../_baseDTO.interface';

export interface SaleAttendeeDTO extends BaseDTO {
  saleId: string;
  sale?: SaleDTO;
  contactId: string;
  contact?: ContactDTO;
  paddleNumber: string;
}
