import {Component, OnInit} from '@angular/core';
import {AdminBrokerageService, AdvertService, URLService, VehicleService} from '../../../../services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {User, VehicleStatusCheckDTO, VehicleStatusSearchDTO} from "../../../../global/interfaces";
import {CustomerService, HelpersService, LoggerService, UserService} from "../../../../global/services";
import {AdvertStatusEnum, BrokerageStateEnum, SphLeadSourceEnum, StatusEnum} from "../../../../global/enums";
import {ToastService} from "ng-uikit-pro-standard";
import {BaseSearchDTO} from "projects/common/interfaces/DTOs/API/baseSearchDTO.interface";
import {AdminUrlService} from "../../services";

@Component({
  selector: 'app-vehicle-checker',
  templateUrl: './vehicle-checker.component.html',
  styleUrls: ['./vehicle-checker.component.scss']
})
export class AdminVehicleCheckerComponent implements OnInit {

  statusOptions: { label: string; value: number }[];
  statusName: {} = {};
  page = 1;
  pages = 1;

  constructor(
    private vehicleService: VehicleService,
    public url: URLService,
    private userService: UserService,
    private advertService: AdvertService,
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    private logService: LoggerService,
    private helperService: HelpersService,
    private toast: ToastService,
    private brokerageService: AdminBrokerageService,
    private adminURL: AdminUrlService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading: boolean;
  isBusy = false;
  vehicles: any[];
  vehicleSearchDTO: BaseSearchDTO = new BaseSearchDTO();
  currentUser: User;
  newVehicleData: any;
  gettingVehicleInfo = false;

  allowDelete = false;
  showDeleteConfirm = false;
  vehicleIdToDelete: string;
  isInOp = false;
  isRelisting = false;

  form: UntypedFormGroup;
  customers: any[];

  toastOpts: { opacity: 0.98 };

  public sphLeadSourceEnum = SphLeadSourceEnum;
  public AdvertStatusEnum = AdvertStatusEnum;

  showRelistable = false;

  searchDTO = {
    vrm: "",
    customerId: null,
    perPage: 10,
    page: 1
  } as VehicleStatusSearchDTO;

  showNoMOT = false;

  async ngOnInit() {

    this.isLoading = true;
    this.page = this.searchDTO.page;

    this.form = this.fb.group(
      {
        vrm: new UntypedFormControl(''),
        customerId: new UntypedFormControl()
      }
    );

    await this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
      if (this.currentUser.roles.findIndex(x => x == 'GOD')) {
        this.allowDelete = true;
      }
    });

    this.getStatuses();
    this.loadCustomers();
    this.loadVehicles().then(() => {
      this.isLoading = false;
    });
  }

  loadCustomers() {
    // load all customers into dropdown
    this.customerService.search({component: 'AdminCustomerList'}).then(result => {
      this.customers = result.customers.map(x => {
        return {value: x.id, label: x.customerName};
      });

      this.customers.unshift({value: null, label: "All Customers"});
    });
  }

  loadVehicles() {
    this.isBusy = true;

    this.logger.info("Loading Vehicles", this.searchDTO);

    // filter on re-list candidates if required
    this.searchDTO.relistable = this.showRelistable;
    this.searchDTO.noMOT = this.showNoMOT;

    return this.vehicleService.vehicleStatusCheck(this.searchDTO)
      .then((result: VehicleStatusCheckDTO[]) => {

        if (result.length > this.searchDTO.perPage) {
          if (this.page === this.pages) {
            this.pages++;
          }
          result.pop();
        }

        this.vehicles = result;
        this.isBusy = false;
      }).catch(err => {
        this.isBusy = false;
      });
  }

  deleteVehicle(vehicle: any) {
    this.logger.info("Delete vehicle clicked: ", vehicle);

    this.vehicleIdToDelete = vehicle.id;
    this.showDeleteConfirm = true;
  }

  getStatuses() {
    const statuses = this.helperService.getNamesAndValues(StatusEnum);

    this.statusOptions = statuses.map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  confirmDeleteVehicle() {
    this.logger.info("About to delete vehicle: ", this.vehicleIdToDelete);
    this.showDeleteConfirm = false;

    this.doDeleteVehicle(this.vehicleIdToDelete);
  }

  doDeleteVehicle(id) {
    this.isInOp = true;
    this.vehicleService.deleteVehicle(id).then(async result => {
      this.isInOp = false;
      await this.loadVehicles();

    }).catch(error => {
      this.logger.error("An error prevented deletion: ", error);
      this.isInOp = false;
    });
  }

  relistVehicle(vehicle: any) {
    // get the latest active advert for this vehicle and relist it
    this.isRelisting = true;
    this.advertService.relistAdvertByVehicle(vehicle.id).then(() => {
      this.isRelisting = false;
      this.loadVehicles();
    });
  }

  searchVehicles() {
    this.searchDTO.vrm = this.form.value.vrm;
    this.searchDTO.customerId = this.form.value.customerId;

    this.loadVehicles();
  }

  selectAll() {
    this.vehicles.forEach(v => {
      v.selected = true;
    });
  }

  selectNone() {
    this.vehicles.forEach(v => {
      v.selected = false;
    });
  }

  selectInverse() {
    this.vehicles.forEach(v => {
      v.selected = !v.selected;
    });
  }

  async deleteSelection() {
    const count = this.vehicles.filter(x => x.selected).length;
    if (count == 0) {
      return;
    }

    const msg = `Are you sure you want to delete ${count} vehicles?`;

    this.helperService.confirmationDialog("Confirm Delete", msg, 'Yes', 'No')
      .subscribe(result => {
        if (result) {
          this.doDelete();
        }
      });
  }

  async doDelete() {
    this.isInOp = true;

    for (const v of this.vehicles) {
      if (v.selected) {
        await this.vehicleService.deleteVehicle(v.id);
      }
    }

    this.isInOp = false;
    this.loadVehicles();
  }

  async motSelection() {
    this.isInOp = true;
    let updatedCount = 0;
    const totalSelected = this.vehicles.filter(v => v.selected).length;

    try {
      for (const v of this.vehicles) {
        if (v.selected) {
          try {
            await this.vehicleService.updateMOT(v.id);
            updatedCount++;
            console.log(`Updated MOT for vehicle ${v.id}. Progress: ${updatedCount}/${totalSelected}`);
          } catch (error) {
            console.error(`Error updating MOT for vehicle ${v.id}:`, error);
          }
        }
      }
    } catch (error) {
      console.error('An unexpected error occurred during MOT selection:', error);
    } finally {
      this.isInOp = false;
      console.log(`MOT selection completed. Updated ${updatedCount}/${totalSelected} vehicles.`);
      this.loadVehicles();
    }
  }

  fetchPage(page: any) {

    this.page = page;
    this.searchDTO.page = page;
    this.loadVehicles();
  }

  async updateProvenanceSelection() {
    this.isBusy = true;
    const vehicles = this.vehicles.filter(x => x.selected);

    await Promise.all(vehicles.map(vehicle => this.vehicleService.updateProvenanceData(vehicle.id)));

    this.isBusy = false;
    this.toast.info("Provenance updated", "Completed", this.toastOpts);
  }

  async relistSelected() {
    this.isBusy = true;
    const vehicles = this.vehicles.filter(x => x.selected);

    this.isRelisting = true;

    await Promise.all(vehicles.map(vehicle => this.advertService.republishAdvertByVehicle(vehicle.id)));

    this.toast.info("Vehicles relisted", "Completed", this.toastOpts);

    this.isRelisting = false;
    this.isBusy = false;
    await this.loadVehicles();
  }

  async onShowRelistableChange() {
    this.pages = 1;
    this.page = 1;
    await this.loadVehicles();
  }

  async onShowNoMOTChange() {
    this.pages = 1;
    this.page = 1;
    await this.loadVehicles();
  }


  async createBrokerage(vehicle: any) {
    this.isBusy = true;
    this.isInOp = true;

    const dto = {
      brokerageStateId: BrokerageStateEnum.StatusUnknown,
      advertId: vehicle.advertId,
      statusId: StatusEnum.Active,
    }

    this.brokerageService.create(dto).then(res => {
      this.adminURL.adminManageNegotiations(vehicle.advertId);

      this.isInOp = false;
      this.isBusy = false;
    });
  }
}
