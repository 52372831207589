import {Injectable} from '@angular/core';
import {
  AdvertDTO,
  CreateNegotiationDTO,
  NegotiationDTO,
  NegotiationNoteDTO
} from '../../../global/interfaces/index';
import {ApiService} from "../../../global/services/index";
import {DataService} from "../../../services/index";
import _ from 'lodash';

@Injectable()
export class AdminNegotiationService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  private serviceUrl = '/api/negotiation';

  createNegotiation(dto: CreateNegotiationDTO): Promise<NegotiationDTO> {
    return this.apiClient.post({url: `${this.data.apiUrl}${this.serviceUrl}`, data: dto}) as Promise<NegotiationDTO>;
  }

  addNote(note: NegotiationNoteDTO): Promise<NegotiationNoteDTO> {
    return this.apiClient.post({
      url: `${this.data.apiUrl}${this.serviceUrl}/${note.negotiationId}/note`,
      data: note
    }) as Promise<NegotiationNoteDTO>;
  }

  topBidders(advert: AdvertDTO) {

    const bidderNames: any[] = [];

    // group bidders by max bid
    const bidders = _.map( // map the groups
      _.groupBy(advert.bids, 'contactId'), // group by the contactId
      g => _.maxBy(g, 'bidAmt') // take the one with the highest bidAmt of each group
    );

    bidders.forEach(bidder => {
      // add bidder contact to map for easy access
      bidderNames[bidder.contactId] = bidder.contact;

      const neg = advert.negotiations.filter(x => x.contactId === bidder.contactId).length;
      bidder.hasNegotiation = neg > 0;
    });

    return [bidders, bidderNames];

  }

  patchNegotiation(negotiationId, patchData) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${negotiationId}`;
    return this.apiClient.patch({url, data: patchData}) as Promise<any>;
  }

  acceptBid(negotiationId, bidGuid) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${negotiationId}/acceptBid/${bidGuid}`;
    return this.apiClient.put({url, data: {}}) as Promise<any>;
  }

  getAdvertNegotiations(advertId: string): Promise<NegotiationDTO[]> {
    const url = `${this.data.apiUrl}/api/negotiations/${advertId}`;
    return this.apiClient.get({url}) as Promise<NegotiationDTO[]>;
  }
}
