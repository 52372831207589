import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanBatchDTO extends BaseIntDTO {

  finished: boolean;
  started: boolean;
  startedDate: Date;
  completed: boolean;
  completedDate: Date;
  priority: number;
}
