import {NGXLogger} from 'ngx-logger';

/**
 * Implements logger tagging
 * Is constructed via LoggerService.taggedLogger
 * or can be manually constructed by passing in the required args.
 */
export class TaggedLogger {
  /**
   * A list of existing loggers
   */
  static loggers: TaggedLogger[] = [];

  /**
   * Creates a new logger or reuses an existing logger
   * @param tag The tag to prefix messages with
   * @param logger The NGXLogger logger instance to use
   */
  static create(tag: string, logger: NGXLogger): TaggedLogger {
    const found = this.loggers.find((l) => l.tag === tag);

    if (found) return found;

    const newLogger = new TaggedLogger(tag, logger);
    this.loggers.push(newLogger);

    return newLogger;
  }

  /**
   * Returns message and tag formatted
   * @param tag The tag
   * @param message The message
   * @private
   */
  private static messageWithTag(tag: string, message: any) {
    return `[${tag}] ${message}`;
  }

  /**
   * Marked private to avoid manual construction
   * use TaggedLogger.create instead to ensure multiple loggers aren't created for this tag
   * @constructor
   * @private
   */
  private constructor(private tag: string, private logger: NGXLogger) {
    if(!logger) {
      const message = 'Tagged Logger depends on NGXLogger parameter';
      console.error(message, new Error(message));
    }

    if (!tag) {
      const message = 'Tagged Logger requires a tag';
      console.error(message, new Error(message));
    }
  }

  trace(message: any, ...additional: any[]) {
    this.logger.trace(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }

  debug(message: any, ...additional: any[]) {
    this.logger.debug(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }

  info(message: any, ...additional: any[]) {
    this.logger.info(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }

  log(message: any, ...additional: any[]) {
    this.logger.log(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }

  warn(message: any, ...additional: any[]) {
    this.logger.warn(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }

  error(message: any, ...additional: any[]) {
    this.logger.error(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }

  fatal(message: any, ...additional: any[]) {
    this.logger.fatal(TaggedLogger.messageWithTag(this.tag, message), ...additional);
  }
}

