import {ProductDTO} from './productDTO.interface';
import {TaxDTO} from './taxDTO.interface';

export interface OrderLineDTO {
  productId: string;
  productDTO: ProductDTO;
  currencyId: string;
  //currency: CurrencyDTO
  netAmt: number;
  taxAmt: number;
  grossAmt: number;
  externalReference: string;
  taxId: number;
  tax: TaxDTO;
}
