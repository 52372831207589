// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brokerage-block {
  background-color: white;
  min-height: 105px;
}

.broker-owner {
  font-weight: 500;
  line-height: 12px;
  padding-left: 10px;
}

.filter-box label {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0rem;
}

.brokerage {
  font-weight: 600;
  font-size: 0.8rem;
}
.brokerage.filter-box {
  padding: 10px 15px !important;
  margin-bottom: 10px;
}

.button-margin {
  margin-bottom: 6px;
}

.adminstatus-2 {
  color: var(--warningColour);
}

.adminstatus-3, .adminstatus-4 {
  color: var(--dangerColour);
}

.brokerage-status {
  display: inline-block;
  background-color: blue;
  min-width: 180px;
  text-align: center;
  color: #fff;
}
.brokerage-status.status-color-1 {
  background-color: #FFC107;
}
.brokerage-status.status-color-2 {
  background-color: var(--successColour);
}
.brokerage-status.status-color-3 {
  background-color: var(--dangerColour);
}

.last-updated {
  font-size: 0.675rem;
  display: inline-block;
  font-weight: 400;
  padding-left: 7px;
}

.brokerage-item {
  border-radius: var(--widgetBorderRadius);
  overflow: hidden;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/manage-brokerages/manage-brokerages.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF;AACE;EACE,6BAAA;EACA,mBAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,0BAAA;AAAF;;AAGA;EACE,qBAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AAAF;AAEE;EACE,yBAAA;AAAJ;AAGE;EACE,sCAAA;AADJ;AAIE;EACE,qCAAA;AAFJ;;AAMA;EACE,mBAAA;EACA,qBAAA;EACA,gBAAA;EACA,iBAAA;AAHF;;AAMA;EACE,wCAAA;EACA,gBAAA;EACA,mBAAA;AAHF","sourcesContent":[".brokerage-block {\n  background-color: white;\n  min-height: 105px;\n}\n\n.broker-owner {\n  font-weight: 500;\n  line-height: 12px;\n  padding-left: 10px;\n}\n\n.filter-box label {\n  font-size: 0.75rem;\n  font-weight: 500;\n  margin-bottom: 0rem;\n}\n\n.brokerage {\n  font-weight: 600;\n  font-size: 0.8rem;\n\n  &.filter-box {\n    padding: 10px 15px !important;\n    margin-bottom: 10px;\n  }\n}\n\n.button-margin {\n  margin-bottom: 6px;\n}\n\n.adminstatus-2 {\n  color: var(--warningColour);\n}\n\n.adminstatus-3, .adminstatus-4 {\n  color: var(--dangerColour);\n}\n\n.brokerage-status {\n  display: inline-block;\n  background-color: blue;\n  min-width: 180px;\n  text-align: center;\n  color: #fff;\n\n  &.status-color-1 {\n    background-color: #FFC107;\n  }\n\n  &.status-color-2 {\n    background-color: var(--successColour);\n  }\n\n  &.status-color-3 {\n    background-color: var(--dangerColour);\n  }\n}\n\n.last-updated {\n  font-size: 0.675rem;\n  display: inline-block;\n  font-weight: 400;\n  padding-left: 7px;\n}\n\n.brokerage-item {\n  border-radius: var(--widgetBorderRadius);\n  overflow: hidden;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
