import {BaseDTO} from "./_baseDTO.interface";

export interface AdminExtLeadDTO extends BaseDTO {
  source?: number;
  company: string;
  location: string;
  postcode: string;
  last30Days: number;

  liveAds?: number;
  lastAd?: string;
  cars: string;
}

export interface AdminExtLeadVehicleDTO extends BaseDTO {

  extLeadId: string;
  vehicle: string;
  mileage?: number;
  year?: number;

}
