import {Injectable, ViewChild} from '@angular/core';
import {BillDTO, CustomerOrderDTO} from '../global/interfaces/index';
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";
import {ModalDirective} from "ng-uikit-pro-standard";
import {InvoiceDetailDTO} from "../interfaces/DTOs/Payment/invoice-detail.dto.interface";
import {PaymentResultDTO} from "../interfaces/DTOs/Payment/payment-result.dto.interface";

@Injectable()
export class InvoiceService {

  @ViewChild('statusModal') statusModal: ModalDirective;

  private serviceUrl = '/api/invoice';
  private customerServiceUrl = '/api/customer';
  private orderServiceUrl = '/api/order';

  constructor(private data: DataService, private apiClient: ApiService) {
  }

  getCustomerInvoices(customerId: string): Promise<BillDTO[]> {
    const url = `${this.data.apiUrl}${this.customerServiceUrl}/${customerId}/invoices`;
    return this.apiClient.get({url}) as Promise<BillDTO[]>;
  }

  generateInvoice(order: CustomerOrderDTO): Promise<BillDTO> {
    const url = `${this.data.apiUrl}${this.orderServiceUrl}/${order.id}/generateInvoice`;
    return this.apiClient.get({url}) as Promise<BillDTO>;
  }

  getInvoices(searchDTO): Promise<any> {

    const dto = JSON.stringify(searchDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}, params: {query: dto}}) as Promise<any>;
  }

  getInvoiceDetails(billId: string): Promise<InvoiceDetailDTO>
  {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${billId}`;
    return this.apiClient.get({url}) as Promise<InvoiceDetailDTO>;
  }

  processInvoices(): Promise<any>
  {
    const url = `${this.data.apiUrl}${this.serviceUrl}/processInvoices`;
    return this.apiClient.get({url}) as Promise<BillDTO>;
  }

  makePayment(billId: string): Promise<PaymentResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${billId}/make-payment`;
    return this.apiClient.get({url}) as Promise<PaymentResultDTO>;
  }
}
