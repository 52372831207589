// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-update-warning {
  background-color: var(--dangerColour);
  color: whitesmoke;
  margin-bottom: 10px;
  padding: 4px 0px 4px 10px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
}

#mobile .container-left {
  padding-left: 0;
  padding-right: 0;
}

.left-side-tab {
  padding-top: 12px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/advert/advert-edit/advert-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".no-update-warning {\n  background-color: var(--dangerColour);\n  color: whitesmoke;\n  margin-bottom: 10px;\n  padding: 4px 0px 4px 10px;\n  font-size: 0.8rem;\n  font-weight: 500;\n  border-radius: 3px;\n}\n\n#mobile .container-left {\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.left-side-tab {\n  padding-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
