import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PlatformService} from "../../../../services";
import {PlatformDTO} from "../../../../global/interfaces";
import {HelpersService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  providers: []
})
export class PlatformComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private platformService: PlatformService,
    private helpersService: HelpersService
  ) {
  }

  @ViewChild('platformModal') platformModal: ModalDirective;

  platforms: PlatformDTO[];
  statusOptions: {}[];

  form: UntypedFormGroup;
  isLoading = false;
  isEdit = false;
  updateWait = false;
  platformIdToDelete: number;
  submitted = false;

  showDeleteConfirm: boolean = false;

  async ngOnInit() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(''),
      platformName: new UntypedFormControl(''),
      statusId: new UntypedFormControl(''),
    });

    this.loadStatuses();
    this.loadPlatforms();
  }

  async loadStatuses() {
    // create status options from enum
    const statuses = this.helpersService.getNamesAndValues(StatusEnum);

    this.statusOptions = statuses.filter(x => x.value != StatusEnum.Deleted).map(status => {
      return {label: status.name, value: status.value};
    });
  }

  async loadPlatforms() {
    this.platformService.search({ignoreCache: true}).then(result => {
      this.platforms = result;
    });
  }

  editPlatform($event: MouseEvent, platformDTO: PlatformDTO) {

    this.form.patchValue(({
      ...platformDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.platformModal.show();

  }

  addPlatform() {
    this.form.patchValue(({
      id: '',
      platformName: '',
      statusId: StatusEnum.Active,
    }));
    this.isEdit = false;

    // show modal that allows location name entry
    this.platformModal.show();
  }

  async savePlatform() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const platformDTO = this.form.value;

    this.updateWait = true;

    if (this.isEdit) {
      await this.platformService.patch(platformDTO);
    } else {
      await this.platformService.create(platformDTO);
    }
    this.updateWait = false;

    this.platformModal.hide();
    await this.loadPlatforms();
  }

  hideModal() {
    this.platformModal.hide();
  }

  deletePlatform(event, platform: PlatformDTO) {
    event.stopPropagation();
    this.platformIdToDelete = platform.id;
    this.showDeleteConfirm = true;
  }

  async confirmDelete() {

    await this.platformService.delete(this.platformIdToDelete);

    this.platformModal.hide();
    await this.loadPlatforms();
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }
}
