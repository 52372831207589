import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.html',
  styleUrls: ['./loading-spinner.scss']
})
export class LoadingSpinnerComponent {

  @Input() centreSpinner = false;
  @Input() spinnerOnly = false;
  @Input() loadingName = "";

  constructor() {
  }
}
