import {CustomerAdminDTO} from "./customerAdminDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {WhosWhoStatusEnum} from "../../enums/whos-who-status.enum";

export interface CustomerInternalInfoDTO extends BaseDTO {
  customerId?: string;
  customer?: CustomerAdminDTO;
  advertPlaced?: boolean;
  alertConfigured?: boolean;
  validEmail?: boolean;
  validWWW?: boolean;
  idPending?: boolean;
  assignedTo?: string;
  whosWhoStatus?: WhosWhoStatusEnum;
  dealLimit?: number;
  hasPaymentMethod?: boolean;
}
