import {ScanStyleDTO} from "./scanStyleDTO.interface";
import {ScanConfigDTO} from "./scanConfigDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {ScanContentTypeEnum} from "../../../enums";

export interface ScanStageDTO extends BaseIntDTO {

  stageName: string;
  nextScanStageId?: number;
  nextScanStage?: ScanStageDTO;
  createRecords?: string;
  scanStyle?: ScanStyleDTO;
  scanStyleId?: number;
  outputFieldId?: number;
  entryPoint?: boolean;
  previewEntryPoint?: number;
  previewUrl?: string;
  scanConfigs?: ScanConfigDTO[];
  contentType?: ScanContentTypeEnum;
}
