import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CountryService} from "../../../../services";
import {CountryDTO} from "../../../../global/interfaces";
import {HelpersService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  providers: []
})
export class CountryComponent implements OnInit {

  statusName: { [key: number]: string } = {};

  constructor(
    private formBuilder: UntypedFormBuilder, private countryService: CountryService, private helpersService: HelpersService
  ) {
  }

  @ViewChild('countryModal') countryModal: ModalDirective;

  countries: CountryDTO[];
  statusOptions: {}[];

  form: UntypedFormGroup;
  isLoading = false;
  isEdit = false;
  updateWait = false;
  countryIdToDelete: number;
  submitted = false;

  showDeleteConfirm = false;

  async ngOnInit() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(''),
      countryName: new UntypedFormControl(''),
      statusId: new UntypedFormControl(''),
    });

    this.loadStatuses();
    this.loadCountries();
  }

  async loadStatuses() {
    // create status options from enum
    const statuses = this.helpersService.getNamesAndValues(StatusEnum);

    // const statuses = Object.keys(StatusEnum).filter(key => isNaN(+key));
    this.statusOptions = statuses.filter(x => x.value !== StatusEnum.Deleted).map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadCountries() {
    this.countryService.search({ignoreCache: true}).then(result => {
      this.countries = result;
    });
  }

  editCountry($event: MouseEvent, countryDTO: CountryDTO) {

    this.form.patchValue(({
      ...countryDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.countryModal.show();

  }

  addCountry() {
    this.form.patchValue(({
      id: '',
      countryName: '',
      statusId: StatusEnum.Active,
    }));
    this.isEdit = false;

    // show modal that allows location name entry
    this.countryModal.show();
  }

  async saveCountry() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const countryDTO = this.form.value;

    this.updateWait = true;

    if (this.isEdit) {
      await this.countryService.patch(countryDTO);
    } else {
      await this.countryService.create(countryDTO);
    }
    this.updateWait = false;

    this.countryModal.hide();
    await this.loadCountries();
  }

  hideModal() {
    this.countryModal.hide();
  }

  deleteCountry(event, country: CountryDTO) {
    event.stopPropagation();
    this.countryIdToDelete = country.id;
    this.showDeleteConfirm = true;
  }

  async confirmDelete() {

    await this.countryService.delete(this.countryIdToDelete);

    this.countryModal.hide();
    await this.loadCountries();
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }
}
