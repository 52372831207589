import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../../../../global/interfaces";
import {UserService} from "../../../../global/services";
import {} from "../../../../global/enums";

@Component({
  selector: 'app-buyer-hub',
  templateUrl: './buyer-hub.component.html',
  styleUrls: ['./buyer-hub.component.scss'],
})
export class BuyerHubComponent implements OnInit, OnDestroy {

  public user: User;

  constructor(private userService: UserService) {
  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });
  }

  ngOnDestroy() {
  }
}
