import {Injectable} from '@angular/core';
import {AbstractControl, UntypedFormBuilder} from '@angular/forms';
import * as moment from 'moment';
import { LoggerService } from '../global/services/index';
import { ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class FormsService {

  constructor(private formBuilder: UntypedFormBuilder, private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  public decimalNumberPattern = "^[0-9]+(.[0-9]{0,2})?$";
  public numberPattern = "^[0-9]*$";

  dateFormat = "DD/MM/YYYY";


  toDate(formValue): Date {

    return moment(formValue, this.dateFormat).toDate();
  }

  dateValidator(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

      if (control.value == null) {
        return null;
      }

      const val = moment(control.value, 'YYYY-MM-DD', true);

      if (!val.isValid()) {
        return {invalidDate: true};
      }

      return null;
    };
  }
}
