import {BaseDTO} from "../../_baseDTO.interface";
import {ContactDTO} from "../../contactDTO.interface";

export interface CallRecordDTO extends BaseDTO {
  callDirection: string;
  date: Date;
  from: string;
  to: string;
  duration: string;
  inboundNumber: string;
  callCost: number;
  contactId?: string;
  contact?: ContactDTO;
}
