// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#productCode {
  text-transform: uppercase;
}

.fa-times-circle {
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/maintenance/country-product/country-product.component.scss"],"names":[],"mappings":"AAAA;EAAe,yBAAA;AAEf;;AAAA;EAAmB,iBAAA;AAInB","sourcesContent":["#productCode { text-transform: uppercase;}\n\n.fa-times-circle { font-size: 1.2rem; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
