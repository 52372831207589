import { BaseSearchDTO } from "../API";
import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface UInspectSectionCompleteDTO extends BaseIntDTO {
  uInspectId?: string;
  uInspectSectionId?: number;
  complete?: boolean;
}

export interface UInspectSectionCompleteSyncDTO extends UInspectSectionCompleteDTO {
  synced?: boolean;
}

export interface UInspectSectionCompleteSearchDTO extends BaseSearchDTO {
  filters?: {
    uInspectionId?: string,
  };
}
