import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdvertService, BidService, EventService, SaleService, SignalRService} from "../../../../services";
import {Subscription} from "rxjs";
import {AdvertDTO, AdvertViewDataDTO, MessageDTO, SaleDTO, User} from "../../../../global/interfaces";
import {LoggerService, UserService} from "../../../../global/services";
import {LiveBiddingStatusEnum, MessageAreaEnum, MessageTypeEnum} from "../../../../global/enums";

@Component({
  selector: 'app-sale-view',
  templateUrl: './sale-view.component.html',
  styleUrls: ['./sale-view.component.scss']
})
export class SaleViewComponent implements OnInit, OnDestroy {

  private saleEventSubscription: Subscription;
  private advertEventSubscription: Subscription;
  galleryImages: any[];
  liveBiddingStatusEnum = LiveBiddingStatusEnum;
  user: User;

  constructor(
    private advertService: AdvertService,
    private userService: UserService,
    private bidService: BidService,
    private saleService: SaleService,
    private eventService: EventService,
    private signalRService: SignalRService,
    private route: ActivatedRoute,
    private logService: LoggerService
  ) {

    this.saleId = this.route.snapshot.params.saleId;
    this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading = false;
  saleId: string;
  sale: SaleDTO;
  appraisal: any;
  public advert: AdvertDTO;

  ngOnInit() {


    this.saleEventSubscription = this.eventService.SaleEvent.subscribe((msg: MessageDTO) => {
      this.logger.info("INBOUND SALE MESSAGE");
      this.handleSaleEvent(msg);
    });

    this.advertEventSubscription = this.eventService.AdvertEvent.subscribe((msg: MessageDTO) => {
      this.logger.info("INBOUND ADVERT MESSAGE");
      this.handleAdvertEvent(msg);
    });

    this.signalRService.subscribeSaleGroup(this.saleId).then((x) => {

      this.signalRService.sendMessageToHub({
        messageArea: MessageAreaEnum.Sales,
        messageType: MessageTypeEnum.SaleUpdate,
        messageData: this.saleId
      });

      this.signalRService.sendMessageToHub({
        messageArea: MessageAreaEnum.Adverts,
        messageType: MessageTypeEnum.BidderSaleCurrentLot,
        messageData: this.saleId
      });
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.signalRService.unsubscribeSaleGroup(this.saleId).then(() => {
    });
  }

  handleSaleEvent(message: MessageDTO) {

    switch (message.messageType) {

      case MessageTypeEnum.SaleUpdate:

        const sale = message.messageData as SaleDTO;

        if (sale != null && sale.id != null) {
          this.sale = sale;
        }

        break;
    }
  }

  handleAdvertEvent(message: MessageDTO) {

    if (message.messageType == MessageTypeEnum.CurrentLotUpdate || message.messageType == MessageTypeEnum.BidderSaleCurrentLot) {

      this.advert = message.messageData as AdvertDTO;

      console.log("+++ MESSAGE: ", message);
      console.log("** ADVERT: ", this.advert);

      // if (this.advert.vehicle.appraisals.length > 0) {
      //   this.appraisal = this.advert.vehicle.appraisals[this.advert.vehicle.appraisals.length - 1];
      // }
      // else {
      //   this.appraisal = null;
      // }

      this.refreshGallery();

    }
  }

  refreshGallery() {

    this.galleryImages = [];

    this.sortImages(this.advert.vehicle.vehicleMedia).forEach(x => {
      this.galleryImages.push({
        small: x.mediaURL + "?tr=h-100",
        medium: x.mediaURL + "?tr=h-400",
        big: x.mediaURL
      });
    });

    const bullets = (this.galleryImages.length < 15);
  }

  sortImages(imgArray) {

    return imgArray.sort((a, b) => a.sequence > b.sequence ? 1 : a.sequence === b.sequence ? 0 : -1);

  }


  get canProxyBid() {

    return this.advertService.canProxyBid(this.advert);
  }
}
