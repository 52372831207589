import {BaseDTO} from "../_baseDTO.interface";

export interface UInspectMediaDTO extends BaseDTO {
  uInspectId?: string;
  uInspectQuestionId?: string;
  uInspectSectionId?: number;
  path?: string;
  description?: string;
  mediaURL?: string;
  position?: number;
}

export interface UInspectMediaSyncDTO extends UInspectMediaDTO {
  image?: string;
  mediaType?: string[];
  synced?: boolean;
  outlineURL?: string;
}

export interface NgxGalleryImageWithId {
  mediaInfo?: UInspectMediaSyncDTO;
  small?: string;
  medium?: string;
  big?: string;
  description?: string;
}
