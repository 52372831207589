import {BidsSummaryDTO} from "../../API";

export interface AdvertViewBidBoxDTO {
  statusId: number;
  customerId: string;
  endDateTime?: Date;
  saleId?: string;
  ggg?: number;
  acceptBids: boolean;
  currentPrice?: number;
  bidIncrement: number;
  reserveMet: boolean;
  bidCount?: number;
  topBidGuid?: string;
  soldStatus?: number;
  startPrice?: number;
  advertStatus: number;
  buyItNowPrice?: number;
  saleTypeId: number;
  currentAdvertId?: string;
  underwriteReservePrice?: number;
  makeName: string;
  modelName: string;
  derivName: string;
  plateName: string;
  transmissionTypeName: string;
  fuelTypeName: string;
  bidsSummary?: BidsSummaryDTO;
}
