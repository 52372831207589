import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AdvertService, SaleTypeService} from "../../../services";
import {AdvertDTO, AdvertSearchDTO, ContactDTO, User} from "../../../global/interfaces";
import {AdminUrlService, AdminWhosWhoService} from "../services";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";
import {HelpersService, UserService} from '../../../global/services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {IOption} from "ng-uikit-pro-standard";
import {SaleTypeName} from '../../../global/enums';
import { EMPTY_GUID } from 'projects/common/shared';

@Component({
  selector: 'app-advert-admin-view',
  templateUrl: './advert-admin-view.component.html',
  styleUrls: ['./advert-admin-view.component.scss']
})
export class AdvertAdminViewComponent implements OnInit {

  filterForm: UntypedFormGroup;
  currentUser: User;
  page = 1;
  count = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;

  adverts: AdvertDTO[];
  isBusy = false;
  moment = moment;
  saleTypes: { value: number, label: string }[] = [];

  @Input() title: string;

  assignedOptions: Array<IOption> = [];

  private _advertQuery: any;

  @Input('advertQuery') set advertQuery(value) {
    if (value && value != this._advertQuery) {
      this._advertQuery = value;
      this.fetchAdverts();
    }
  }

  get advertQuery() {
    return this._advertQuery;
  }

  constructor(
    private advertService: AdvertService,
    private whoswhoService: AdminWhosWhoService,
    private helperService: HelpersService,
    public adminUrl: AdminUrlService,
    private fb: UntypedFormBuilder,
    public userService: UserService,
    private route: ActivatedRoute) {

    this.filterForm = this.fb.group({
      assignedTo: new UntypedFormControl(EMPTY_GUID),
    });


    this.fetchAssignees();
  }

  saleTypeName = SaleTypeName;

  ngOnInit(): void {

    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
    });
    this.fetchAssignees();

    this.filterForm.valueChanges.subscribe(() => {
      this.search();
    });
  }

  fetchAdverts() {
    this.isBusy = true;

    const dto = {
      component: 'AdvertAdminView',
      filters: {
        ...this._advertQuery,
        ...this.filterForm.value,
      },
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize
    } as AdvertSearchDTO;

    this.advertService.getAdminAdverts(dto).then(res => {
      this.adverts = res.adverts;
      this.count = res.count;
      this.isBusy = false;
    }).catch(err => {
      this.isBusy = false;
    });
  }

  onTableDataChange(page: number) {
    // fetch records using page as offset
    this.page = page;
    this.fetchAdverts();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchAdverts();
  }

  private fetchAssignees() {

    this.whoswhoService.getSiteAdmins({}, true).then(res => {
      this.assignedOptions = res.results.map((x: ContactDTO) => {
        return {value: x.id, label: x.contactName};
      });
    }).catch(() => {
    });
  }

  search() {
    this.fetchAdverts();
  }

  assignedToChange(event: any) {
    this.search();
  }

  filterToMe() {
    if (this.filterForm.value.assignedTo !== this.currentUser.contactId) {
      this.filterForm.patchValue({assignedTo: this.currentUser.contactId});
    } else {
      this.filterForm.patchValue({assignedTo: EMPTY_GUID});
    }
  }

  assignedToName(item: AdvertDTO) {

    if (item.vehicle?.customer?.customerInternalInfo?.assignedTo != null && this.assignedOptions.length > 0) {
      return this.assignedOptions.find(x => x.value === item.vehicle.customer?.customerInternalInfo?.assignedTo)?.label;
    }
  }

  protected readonly EMPTY_GUID = EMPTY_GUID;
}
