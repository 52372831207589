import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";

@Injectable()
export class DashBoardService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  private serviceUrl = '/api/dashboard';

  public getContactStats(): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/buyerStats`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  public getNews(): Promise<any> {

    const url = `https://cw4mwzgrnijk7dqyrupzk3dh6q0fjrau.lambda-url.eu-west-2.on.aws`;
    return this.apiClient.post({
      url,
      headers: {accept: 'text/xml', 'content-type': 'text/xml', "X-Skip-Interceptor": "true"}
    }) as Promise<any>;
  }
}

