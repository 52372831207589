import {BaseIntDTO} from "../_baseIntDTO.interface";
import {CommsTemplateDTO} from "./commsTemplateDTO";
import {BaseSearchDTO} from "../API/index";

export interface CommsEventDTO extends BaseIntDTO {
  commsEventCode: string;
  title: string;
  description: string;
  mergeModel: number;
  mergeModelName: string;
  added: Date;
  updated: Date;
  active?: boolean;
  commsTemplates?: CommsTemplateDTO[];
}

export interface CommsEventSearchDTO extends BaseSearchDTO {
  filters?: {
  };
}
