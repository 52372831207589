import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'camelCasePipe'
})
export class CamelCasePipe implements PipeTransform {

  transform(
    value: string,
  ): string | null {

    if (!value) {
      return "";
    }

    return value.replace(/([a-z])([A-Z])/g, '$1 $2');

  }
}
