import {ScanStageDTO} from "./scanStageDTO.interface";
import {ScanCustomerDTO} from "./scanCustomerDTO.interface";
import {ScanBatchDTO} from "./scanBatchDTO.interface";
import {ScanVehicleDTO} from "./scanVehicleDTO.interface";
import {ScanSampleStageDTO} from "./scanSampleDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {VehicleTypeDTO} from "../vehicleTypeDTO";

export interface ScanQueueDTO extends BaseIntDTO {
  scanBatch: ScanBatchDTO;
  scanBatchId: number;
  scanUrl: string;
  priority: number;
  started: boolean;
  startedDate: string;
  finished: boolean;
  finishedDate: string;
  saveSample: boolean;
  scanVehicle: ScanVehicleDTO;
  scanVehicleId: number;
  scanVehicleGuid: string;
  scanCustomer: ScanCustomerDTO;
  scanCustomerId: number;
  scanStage: ScanStageDTO;
  scanStageId: number;
  vehicleType: VehicleTypeDTO;
  vehicleTypeId: number;
  scanSamples: ScanSampleStageDTO[];
}
