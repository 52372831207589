import {BidLimitedContactDTO} from "./bid-limited-contact.dto.interface";

export interface BidLimitedCustomerDTO {
  id: string;
  customerName: string;
  email: string;
  added?: Date;
  updated?: Date;
  primaryContact?: BidLimitedContactDTO;
}
