import {Injectable} from '@angular/core';
import {DataService, URLService} from "../../../services";
import {ApiService} from "../../../global/services";
import {
  WhosWhoAdminSearchDTO,
  ContactActionSearchDTO,
  SearchResultDTO
} from "../../../global/interfaces";

@Injectable()
export class AdminWatchlistService {

  private serviceUrl = '/api/watchlist';

  constructor(
    public urlService: URLService,
    private apiClient: ApiService,
    private data: DataService,
  ) {
  }

  search(query: ContactActionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/search`;
    return this.apiClient.get({url},{query}) as Promise<SearchResultDTO>;
  }

  getWatchlistTotals(dto: WhosWhoAdminSearchDTO): Promise<SearchResultDTO> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/summary`;
    url += '?query=' + encodeURIComponent(JSON.stringify(dto));
    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }
}
