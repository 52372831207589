import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nospaces',
})
export class NoSpacesPipe implements PipeTransform {

  transform(
    value: string, args?: any
  ): string | null {

    return value.replace(/ /g,'');
  }
}
