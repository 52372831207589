// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.key {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.75rem;
}
.key .status-item {
  display: inline-block;
  padding-right: 15px;
}
.key .icon {
  width: 20px;
  height: 20px;
}

.category-cell {
  padding: 5px 10px;
}
.category-cell > span {
  vertical-align: middle;
}

.fault-status img {
  filter: grayscale(1);
  margin-left: 15px;
  vertical-align: bottom;
  width: 20px;
  cursor: pointer;
}

.status-icon:not(.active).status-icon-1 {
  opacity: 0.35;
}
.status-icon:not(.active).status-icon-2 {
  opacity: 0.7;
}
.status-icon:not(.active).status-icon-3 {
  opacity: 0.7;
}
.status-icon:not(.active).status-icon-4 {
  opacity: 0.35;
}
.status-icon:not(.active).status-icon-5 {
  opacity: 0.25;
}

.fault-status.active img {
  filter: grayscale(0);
  opacity: 1;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fader {
  opacity: 0;
}

.fade-out {
  animation: fade-out 3s;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-mechanical-faults/vehicle-fault-category/vehicle-fault-category.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;AACF;;AAEA;EACE,gBAAA;EAAkB,mBAAA;EAClB,kBAAA;AAEF;AADE;EACE,qBAAA;EAAuB,mBAAA;AAI3B;AAFE;EACE,WAAA;EACA,YAAA;AAIJ;;AAAA;EACE,iBAAA;AAGF;AADE;EACE,sBAAA;AAGJ;;AACA;EACE,oBAAA;EACA,iBAAA;EACA,sBAAA;EACA,WAAA;EACA,eAAA;AAEF;;AAIE;EACE,aAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAOE;EACE,aAAA;AALJ;;AASA;EACE,oBAAA;EACA,UAAA;AANF;;AASA;EACE;IACE,UAAA;EANF;EAQA;IACE,UAAA;EANF;AACF;AASA;EACE,UAAA;AAPF;;AAUA;EACE,sBAAA;AAPF","sourcesContent":[".category-item:nth-child(odd) {\n  background-color: rgba(0, 0, 0, 0.02)\n}\n\n.key {\n  padding-top: 5px; padding-bottom: 5px;\n  font-size: 0.75rem;\n  .status-item {\n    display: inline-block; padding-right: 15px;\n  }\n  .icon {\n    width: 20px;\n    height: 20px;\n  }\n}\n\n.category-cell {\n  padding: 5px 10px;\n\n  & > span {\n    vertical-align: middle;\n  }\n}\n\n.fault-status img {\n  filter: grayscale(1);\n  margin-left: 15px;\n  vertical-align: bottom;\n  width: 20px;\n  cursor: pointer;\n}\n\n\n.status-icon:not(.active) {\n\n  &.status-icon-1 {\n    opacity: 0.35;\n  }\n\n  &.status-icon-2 {\n    opacity: 0.7;\n  }\n\n  &.status-icon-3 {\n    opacity: 0.7;\n  }\n\n  &.status-icon-4 {\n    opacity: 0.35;\n  }\n\n  &.status-icon-5 {\n    opacity: 0.25;\n  }\n}\n\n.fault-status.active img {\n  filter: grayscale(0);\n  opacity: 1;\n}\n\n@keyframes fade-out {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n.fader {\n  opacity: 0;\n}\n\n.fade-out {\n  animation: fade-out 3s;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
