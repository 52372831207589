// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sale-status {
  width: 70px;
  text-align: center;
  border: 2px solid #888;
  color: #888;
}
.sale-status.sale-status-1 {
  color: var(--successColour) !important;
  border-color: var(--successColour) !important;
}
.sale-status.sale-status-2 {
  color: #fff;
  border-color: var(--dangerColour) !important;
  background-color: var(--dangerColour) !important;
}

.sale-type {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  padding: 0 5px;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.sale-type.sale-type-1 {
  border-color: var(--buyNowColour);
  color: var(--buyNowColour);
}
.sale-type.sale-type-2 {
  border-color: var(--managedSaleColour);
  color: var(--managedSaleColour);
}
.sale-type.sale-type-3 {
  border-color: var(--timedSaleColour);
  color: var(--timedSaleColour);
}
.sale-type.sale-type-4 {
  border-color: var(--underwriteColour);
  color: var(--underwriteColour);
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/sale-search/sale-search.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;AACF;AACE;EACE,sCAAA;EACA,6CAAA;AACJ;AACE;EACE,WAAA;EACA,4CAAA;EACA,gDAAA;AACJ;;AAGA;EAEE,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;AADF;AAGE;EACE,iCAAA;EACA,0BAAA;AADJ;AAGE;EACE,sCAAA;EACA,+BAAA;AADJ;AAGE;EACE,oCAAA;EACA,6BAAA;AADJ;AAGE;EACE,qCAAA;EACA,8BAAA;AADJ","sourcesContent":[".sale-status {\n  width: 70px;\n  text-align: center;\n  border: 2px solid #888;\n  color: #888;\n\n  &.sale-status-1 {\n    color: var(--successColour) !important;;\n    border-color: var(--successColour) !important;\n  }\n  &.sale-status-2 {\n    color: #fff;\n    border-color: var(--dangerColour) !important;\n    background-color: var(--dangerColour) !important;\n  }\n}\n\n.sale-type {\n\n  border-width: 2px;\n  border-style: solid;\n  border-radius: 5px;\n  padding: 0 5px;\n  display: inline-block;\n  text-align: center;\n  width: 100%;\n\n  &.sale-type-1 {\n    border-color: var(--buyNowColour);\n    color: var(--buyNowColour)\n  }\n  &.sale-type-2 {\n    border-color: var(--managedSaleColour);\n    color: var(--managedSaleColour);\n  }\n  &.sale-type-3 {\n    border-color: var(--timedSaleColour);\n    color: var(--timedSaleColour);\n  }\n  &.sale-type-4 {\n    border-color: var(--underwriteColour);\n    color: var(--underwriteColour);\n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
