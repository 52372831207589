import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SaleService, URLService} from "../../../../services";
import {LoggerService, UserService} from "../../../../global/services";
import {User} from "../../../../global/interfaces/index";

@Component({
  selector: 'app-sale-search',
  templateUrl: './sale-search.component.html',
  styleUrls: ['./sale-search.component.scss']
})
export class SaleSearchComponent implements OnInit {
  private salesSubscription: any;

  logger = this.logService.taggedLogger(this.constructor?.name);

  constructor(
    private saleService: SaleService,
    private userService: UserService,
    private url: URLService,
    private router: Router,
    private logService: LoggerService) {
  }

  canAddVehicle = false;
  isLoading = false;
  sales: any[];
  user: User;

  async ngOnInit() {
    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
      this.canAddVehicle = this.user.isAdmin;

      this.logger.info("Current user: ", this.user);
    });

    // get all sales
    this.isLoading = true;
    this.salesSubscription = this.saleService.getSales().then((data: any) => {
      this.logger.debug("Sales returned data: ", data);

      if (data) {
        this.sales = data;
      }
      this.isLoading = false;
    });

  }

  downloadCatalogue(e, saleId: string) {

    // Prevent it bubbling up to click the entire riw
    e.stopPropagation();
  }

  visitSale(saleId: string) {

    if (this.user.isLoggedIn) {

      // Redirect to sale

      this.url.sale(saleId);

    } else {

      // Redirect to login page

    }
  }

  CreateAdvert(sale: any) {
    // create an advert and add it to the selected sale

  }
}
