// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #ccc;
}
.status.status-1 {
  color: var(--successColour);
  border-color: var(--successColour);
}
.status.status-2, .status.status-4 {
  color: var(--warningColour);
  border-color: var(--warningColour);
}
.status.status-3, .status.status-5 {
  color: var(--dangerColour);
  border-color: var(--dangerColour);
}

.role {
  font-size: 0.6rem;
  background-color: #ddd;
  font-weight: 600;
  padding: 3px 5px;
  border-radius: 5px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/site-admin/site-admin-search.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,oBAAA;EACA,gBAAA;EACA,yBAAA;EACA,sBAAA;AACF;AACE;EACE,2BAAA;EACA,kCAAA;AACJ;AAEE;EACE,2BAAA;EACA,kCAAA;AAAJ;AAGE;EACE,0BAAA;EACA,iCAAA;AADJ;;AAKA;EACE,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AAFF","sourcesContent":[".status {\n  display: inline-block;\n  padding: 0.25rem 0.5rem;\n  border-radius: 0.25rem;\n  font-size: 0.75rem;\n  line-height: 0.75rem;\n  font-weight: 700;\n  text-transform: uppercase;\n  border: 1px solid #ccc;\n\n  &.status-1 {\n    color: var(--successColour);\n    border-color: var(--successColour);\n  }\n\n  &.status-2, &.status-4 {\n    color: var(--warningColour);\n    border-color: var(--warningColour);\n  }\n\n  &.status-3, &.status-5 {\n    color: var(--dangerColour);\n    border-color: var(--dangerColour);\n  }\n}\n\n.role {\n  font-size: 0.6rem;\n  background-color: #ddd;\n  font-weight: 600;\n  padding: 3px 5px;\n  border-radius: 5px;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
