import {Injectable} from '@angular/core';
import {SearchResultDTO, TermsTemplateDTO, TermsTemplateSearchDTO} from '../global/interfaces';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";
import {compare} from "fast-json-patch";

@Injectable()
export class TermsTemplateService {

  private serviceUrl = '/api/terms';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  search(searchDTO?: TermsTemplateSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: searchDTO}) as Promise<any>;
  }

  create(template: TermsTemplateDTO): Promise<TermsTemplateDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: template}) as Promise<TermsTemplateDTO>;
  }

  patch(templateId: string, dto: TermsTemplateDTO, previousDTO: TermsTemplateDTO = {}) {
    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${templateId}`;
    return this.apiClient.patch({url, data: patch});
  }
}
