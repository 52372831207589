export interface CallStatsDTO {
  id: number;
  contactId?: string;
  contactName?: string;
  contactEmail?: string;
  direction: string;
  callDate: Date;
  extension: string;
  phoneNumber: string;
  totalCalls: number;
  totalDuration: number;
  totalCost: number;
}
