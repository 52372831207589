import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DataService, URLService} from '../../../services';
import {compare} from 'fast-json-patch';
import {AdvertSearchDTO} from "../../../global/interfaces";
import {ApiService} from "../../../global/services";
import {BidTypeEnum} from "../../../global/enums";

@Injectable()
export class AdminAdvertService {

  constructor(
    private router: Router,
    private apiClient: ApiService,
    private data: DataService,
    public urlService: URLService
  ) {
  }

  private serviceUrl = '/api/listing';

  sphAllSearch(advertSearchDTO: AdvertSearchDTO) {

    const url = '/api/sphAll?query=' + encodeURIComponent(JSON.stringify(advertSearchDTO));
    return this.apiClient.get({url: `${this.data.apiUrl}${url}`}) as Promise<any>;
  }

  setLotNums(updates: any[]) {
    const url = '/api/listing';
    return this.apiClient.post({url: `${this.data.apiUrl}${url}/setLotNums`, data: updates}) as Promise<any>;
  }

  setStartPrice(advertId: string, customerId: string, startPrice: number) {
    const url = '/api/listing';
    const patch = compare({}, {startPrice});
    return this.apiClient.patch({
      url: `${this.data.apiUrl}/api/customer/${customerId}/listing/${advertId}/startPrice`,
      data: patch
    }) as Promise<any>;
  }

  endListing(advertId: string) {
    const url = `/api/listing/${advertId}/end`;
    return this.apiClient.get({url: `${this.data.apiUrl}${url}`}) as Promise<any>;
  }

  changeBidIncrement(advertId: string, customerId: string, currentBidIncrement: number, increase: boolean) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}/changeBidIncrement`;

    const params = {
      customerId,
      currentBidIncrement,
      increase
    };

    return this.apiClient.post({url, data: params}) as Promise<any>;
  }

  silentBid(advertId: string, bidType: BidTypeEnum, bidAmt: number) {

    const url = `${this.data.apiUrl}/api/bid/offlineBid`;

    const params = {
      advertId,
      bidType,
      bidAmt,
    };

    return this.apiClient.post({url, data: params}) as Promise<any>;
  }
}
