// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stack-list {
  display: flex;
  flex-wrap: wrap;
}

.sale-list-container {
  flex: 1 0 200px;
  flex-direction: column;
}

.filter-container {
  flex: 1 0 24px;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/manage-auctions/manage-auctions.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAAe,eAAA;AAEjB;;AACA;EACE,eAAA;EAAiB,sBAAA;AAGnB;;AAAA;EACE,cAAA;EAAgB,sBAAA;AAIlB","sourcesContent":[".stack-list {\n  display: flex; flex-wrap: wrap;\n}\n\n.sale-list-container {\n  flex: 1 0 200px; flex-direction: column;\n}\n\n.filter-container {\n  flex: 1 0 24px; flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
