import {Component, OnInit} from '@angular/core';
import {BillDTO} from '../../../../global/interfaces';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {InvoiceService} from '../../../../services';
import {AdminUrlService} from '../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerService} from "../../../../global/services/index";

@Component({
  selector: 'app-unreconciled',
  templateUrl: './unreconciled.component.html',
  styleUrls: ['./unreconciled.component.scss']
})
export class UnreconciledComponent implements OnInit {

  constructor(
    private invoiceService: InvoiceService,
    private urlService: AdminUrlService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private logService: LoggerService) {
  }


  logger = this.logService.taggedLogger(this.constructor?.name);

  invoices: BillDTO[];
  form: UntypedFormGroup;

  page = 1;
  count = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;

  async ngOnInit() {
    this.form = this.formBuilder.group({
      invoiceNo: new UntypedFormControl('', []),
      customer: new UntypedFormControl('', []),
      fromDate: new UntypedFormControl('', []),
      toDate: new UntypedFormControl('', [])
    });

    this.fetchInvoices();
  }

  setFormValuesFromURL() {
    this.route.queryParams.subscribe(params => {

      this.logger.debug("Auction route activated: ", params);
      //this.selectedSaleId = params.saleId;
      //this.getAttendees(this.selectedSaleId);
    });
  }

  viewCustomer(customerId) {
    this.urlService.adminCustomerEdit(customerId);
  }

  fetchInvoices() {
    // create dto using form values and pagination
    const dto = this.form.value;
    dto.offset = (this.page - 1) * this.pageSize;
    dto.limit = this.pageSize;
    dto.unreconciledOnly = true;

    this.invoiceService.getInvoices(dto).then(result => {
      this.invoices = result.results;
      this.count = result.totalItems;

      this.logger.info("Fetched invoices: ", result);
    });
  }

  onTableDataChange(page: number) {
    // fetch records using page as offset
    this.page = page;
    this.fetchInvoices();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchInvoices();
  }
}
