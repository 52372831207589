// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sale-status {
  width: 70px;
  text-align: center;
  color: #fff;
}
.sale-status.sale-status-1 {
  background-color: var(--successColour) !important;
}
.sale-status.sale-status-2 {
  background-color: var(--dangerColour) !important;
}

.sale-type {
  border-radius: 13px;
  padding: 0 5px;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: #fff;
}
.sale-type.sale-type-1 {
  background-color: var(--buyNowColour);
}
.sale-type.sale-type-2 {
  background-color: var(--managedSaleColour);
}
.sale-type.sale-type-3 {
  background-color: var(--timedSaleColour);
}
.sale-type.sale-type-4 {
  background-color: var(--underwriteColour);
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/sale-search/sale-search.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,iDAAA;AACJ;AACE;EACE,gDAAA;AACJ;;AAGA;EAEE,mBAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AADF;AAGE;EACE,qCAAA;AADJ;AAGE;EACE,0CAAA;AADJ;AAGE;EACE,wCAAA;AADJ;AAGE;EACE,yCAAA;AADJ","sourcesContent":[".sale-status {\n  width: 70px;\n  text-align: center;\n  color: #fff;\n\n  &.sale-status-1 {\n    background-color: var(--successColour) !important;\n  }\n  &.sale-status-2 {\n    background-color: var(--dangerColour) !important;\n  }\n}\n\n.sale-type {\n\n  border-radius: 13px;\n  padding: 0 5px;\n  display: inline-block;\n  text-align: center;\n  width: 100%;\n  color: #fff;\n\n  &.sale-type-1 {\n    background-color: var(--buyNowColour);\n  }\n  &.sale-type-2 {\n    background-color: var(--managedSaleColour);\n  }\n  &.sale-type-3 {\n    background-color: var(--timedSaleColour);\n  }\n  &.sale-type-4 {\n    background-color: var(--underwriteColour);\n  }\n\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
