import {Injectable} from '@angular/core';

interface Options {
  customerId?: number;
  vehicleId?: number;
  appraisalId?: number;
  appraisalItemId?: number;
  appraisalMediaId?: number;
}

@Injectable()
export class MediaService {

  private serviceUrl = '/api/media';

}
