import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanImageDTO extends BaseIntDTO {

  url: string;
  sequence?: number;
  hosted: boolean;
  originalUrl: string;
  import: boolean;
}
