import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {compare} from "fast-json-patch";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces";
import {UInspectQuestionOptionSearchDTO} from "../../interfaces/DTOs/UInspect/API";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {UInspectQuestionOptionDTO} from "../../interfaces/DTOs/UInspect";

@Injectable()
export class UInspectQuestionOptionService {

  private serviceUrl = '/api/uinspect/question-option';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
    private toast: ToastService,
  ) {
    // this.logging.componentName = "UInspectQuestionOptionService";
  }

  public search(query?: UInspectQuestionOptionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  public get(id: number, query: UInspectQuestionOptionSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  public add(dto: UInspectQuestionOptionDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: string, newValue: UInspectQuestionOptionDTO, previousValue: UInspectQuestionOptionDTO = {}):
    Promise<ValidatedResultDTO> {
    const data = compare(previousValue, newValue);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: string): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }
}
