import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MdbTableDirective, MdbTablePaginationComponent} from 'ng-uikit-pro-standard';
import {PaginatedTableColumn} from '../../../interfaces/paginated-table-column.interface';

@Component({
  selector: 'app-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss']
})
export class PaginatedTableComponent implements OnInit, AfterViewInit {

  constructor(private cdRef: ChangeDetectorRef) { }

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  previous: any = [];

  _dataSource: any;
  @Input('dataSource') set dataSource(value: any) {
    this.mdbTable.setDataSource(value);
    this._dataSource = this.mdbTable.getDataSource();
    this.previous = this.mdbTable.getDataSource();
  };

  @Input() columnDefinitions: PaginatedTableColumn[];

  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  selectItem($event: MouseEvent, dataSourceElement: any) {
    this.itemSelected.emit(dataSourceElement);
  }
}
