import { BaseDTO } from "../_baseDTO.interface";
import {MakeDTO} from "../makeDTO.interface";
import {ModelDTO} from "../modelDTO.interface";
import {DerivDTO} from "../derivDTO.interface";
import {FuelTypeDTO} from "../fuelTypeDTO.interface";
import {TransmissionTypeDTO} from "../transmissionTypeDTO.interface";
import {VehicleColourDTO} from "../Vehicle/index";
import {BodyTypeDTO} from "../bodyTypeDTO.interface";
import {ValuationTypeEnum} from "../../../enums/valuation/index";
import {LeadVehicleDTO} from "../../../../crm/src/app/interfaces/index";
import {ContactDTO} from "../contactDTO.interface";
import { ValuationQuoteDataDTO } from "./valuation-quote-data-dto.interface";

export interface ValuationQuoteDTO extends BaseDTO {
  vrm: string;
  odometer: number;
  makeId?: number;
  make: MakeDTO;
  modelId?: number;
  model: ModelDTO;
  derivId?: number;
  deriv: DerivDTO;
  fuelTypeId?: number;
  fuelType: FuelTypeDTO;
  transmissionTypeId?: number;
  transmissionType: TransmissionTypeDTO;
  vehicleColourId?: number;
  vehicleColour: VehicleColourDTO;
  bodyTypeId?: number;
  bodyType: BodyTypeDTO;
  quotedValue?: number;
  quoteType: ValuationTypeEnum;
  leadVehicle: LeadVehicleDTO;
  contactId?: number;
  contact: ContactDTO;
  expires?: string;
  valuationQuoteData: ValuationQuoteDataDTO;
  contactNote?: string;
}
