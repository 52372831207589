import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {HelpersService} from "../../../../global/services";

@Component({
  selector: 'app-fault-check-maint',
  templateUrl: './fault-check-maint.component.html',
  styleUrls: ['./fault-check-maint.component.scss'],
  providers: []
})
export class FaultCheckMaintComponent implements OnInit {
  tabChosen: number = 3;

  constructor(
    private formBuilder: UntypedFormBuilder, private helpersService: HelpersService
  ) {
  }

  async ngOnInit() {

  }
}
