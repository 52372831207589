import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {compare} from "fast-json-patch";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces";
import {UInspectSectionCompleteDTO, UInspectSectionCompleteSearchDTO} from "../../interfaces/DTOs/UInspect";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";

@Injectable()
export class UInspectSectionCompleteService {

  private serviceUrl = '/api/uinspect/sectionComplete';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
    private toast: ToastService,
  ) {
    // this.logging.componentName = "UInspectSectionCompleteService";
  }

  public getSectionsComplete(id: string, query?: UInspectSectionCompleteSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}/sectionsComplete`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  public get(id: number, query: UInspectSectionCompleteSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  public add(dto: UInspectSectionCompleteDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public put(dto: UInspectSectionCompleteDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}/api/uinspect/${dto.uInspectId}/section/${dto.uInspectSectionId}/complete/${dto.complete}`;
    return this.apiClient.put({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: number, patchData: UInspectSectionCompleteDTO, previousValue: UInspectSectionCompleteDTO = {}):
    Promise<ValidatedResultDTO> {
    const data = compare(previousValue, patchData);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: number): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }
}
