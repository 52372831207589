// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}

.stat-cell .inner {
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}
.stat-cell .line1 {
  font-size: 2rem;
  line-height: 2.1rem;
  font-weight: 600;
}
.stat-cell .line2 {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.stat-cell .line3 {
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 300;
  letter-spacing: 0px;
}

.chart-title {
  padding: 8px;
  margin-top: 10px;
  font-weight: 500;
}

.pageheader {
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

.filter-section {
  display: flex;
  grid-gap: 12px;
}

.adminstatus-2 {
  color: var(--warningColour);
}

.adminstatus-3, .adminstatus-4 {
  color: var(--dangerColour);
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/dashboard/admin-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,2DAAA;AACF;;AAIE;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;AADJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AAHJ;AAME;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAJJ;;AAUA;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;AAPF;;AAUA;EACE,kBAAA;EACA,iBAAA;EACA,oBAAA;AAPF;;AAUA;EACE,sBAAA;EACA,uBAAA;AAPF;;AAUA;EACE,aAAA;EACA,cAAA;AAPF;;AAUA;EACE,2BAAA;AAPF;;AAUA;EACE,0BAAA;AAPF","sourcesContent":[".stats {\n  display: grid;\n  grid-gap: 10px;\n  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));\n}\n\n.stat-cell {\n\n  .inner {\n    text-align: center;\n    border-radius: 8px;\n    padding: 10px;\n    cursor: pointer;\n  }\n\n  .line1 {\n    font-size: 2rem;\n    line-height: 2.1rem;\n    font-weight: 600;\n  }\n\n  .line2 {\n    font-size: 1.1rem;\n    line-height: 1.1rem;\n    font-weight: 500;\n    letter-spacing: -0.5px;\n  }\n\n  .line3 {\n    font-size: 0.9rem;\n    line-height: 0.9rem;\n    font-weight: 300;\n    letter-spacing: -0px;\n  }\n\n}\n\n\n.chart-title {\n  padding: 8px;\n  margin-top: 10px;\n  font-weight: 500;\n}\n\n.pageheader {\n  text-align: center;\n  font-weight: bold;\n  padding-bottom: 10px;\n}\n\ncanvas {\n  width: 100% !important;\n  height: 100% !important;\n}\n\n.filter-section {\n  display: flex;\n  grid-gap: 12px;\n}\n\n.adminstatus-2 {\n  color: var(--warningColour);\n}\n\n.adminstatus-3, .adminstatus-4 {\n  color: var(--dangerColour);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
