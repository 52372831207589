// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mot-table td.mot-issue {
  background-color: var(--errorColour);
  font-weight: bold;
  color: ghostwhite;
  border-radius: 5px;
  padding-left: 5px;
}
.mot-table td.mot-col {
  font-size: 0.85rem;
  font-weight: 500;
}

.mot-table-inner td {
  font-size: 0.75rem;
  font-weight: 400;
}

.passed, .failed {
  font-weight: 600;
}

.passed {
  color: var(--successColour);
}

.failed {
  color: #c00;
}

.card .card-header {
  background-color: #000 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.col1 {
  width: 25%;
  font-weight: 700 !important;
}

.col2 {
  width: 25%;
  text-align: center;
}

.col3 {
  width: 25%;
  text-align: center;
}

.col4 {
  width: 25%;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-mot-history/vehicle-mot-history.component.scss"],"names":[],"mappings":"AAEE;EACE,oCAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAIE;EACE,kBAAA;EACA,gBAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,gBAAA;AAHF;;AAMA;EACE,2BAAA;AAHF;;AAMA;EACE,WAAA;AAHF;;AAUA;EACE,iCAAA;EACA,2BAAA;EACA,8BAAA;AAPF;;AAUA;EACE,UAAA;EACA,2BAAA;AAPF;;AAUA;EACE,UAAA;EACA,kBAAA;AAPF;;AAUA;EACE,UAAA;EACA,kBAAA;AAPF;;AAUA;EACE,UAAA;AAPF","sourcesContent":[".mot-table td {\n\n  &.mot-issue {\n    background-color: var(--errorColour);\n    font-weight: bold;\n    color: ghostwhite;\n    border-radius: 5px;\n    padding-left: 5px;\n  }\n\n  &.mot-col {\n    font-size: 0.85rem;\n    font-weight: 500\n  }\n}\n\n.mot-table-inner td {\n  font-size: 0.75rem;\n  font-weight: 400\n}\n\n.passed, .failed {\n  font-weight: 600;\n}\n\n.passed {\n  color: var(--successColour);\n}\n\n.failed {\n  color: #c00;\n}\n\n#mot-history-widget {\n\n}\n\n.card .card-header {\n  background-color: #000 !important;\n  padding-top: 0px !important;\n  padding-bottom: 0px !important;\n}\n\n.col1 {\n  width: 25%;\n  font-weight: 700 !important;\n}\n\n.col2 {\n  width: 25%;\n  text-align: center;\n}\n\n.col3 {\n  width: 25%;\n  text-align: center;\n}\n\n.col4 {\n  width: 25%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
