import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {compare} from 'fast-json-patch';
import {
  AdvertDTO,
  AttribDTO,
  ContactAttribDTO,
  ContactDTO,
  InviteDTO
} from '../../interfaces/DTOs';
import {ContactSearchDTO, SearchResultDTO} from "../../interfaces/DTOs/API";
import {AdvertOfInterestDTO} from "../../interfaces/DTOs/Components";
import {LoggerService} from "../index";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces/data.service.interface";
import {ContactInternalInfoDTO} from "../../interfaces/DTOs/contactInternalInfoDTO.interface";

@Injectable()
export class ContactService {

  private serviceUrl = '/api/contact';
  private customerServiceUrl = '/api/Customer';

  private apiClient: ApiService;

  constructor(
    private data: DataServiceInterface,
    apiClient: ApiService,
    private logService: LoggerService) {
    this.apiClient = apiClient;
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  search(query: ContactSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  loginFailed(email: string): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/login-failed`;
    return this.apiClient.post({url, data: {email}}) as Promise<SearchResultDTO>;
  }

  getContact(id: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/` + id;
    return this.apiClient.get({url}) as Promise<any>;
  }


  getRecentBidActivity(contactId: string, numberToReturn: number): Promise<AdvertDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/recentBids?return=${numberToReturn}`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<AdvertDTO[]>;
  }

  notifyLogin(id: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/` + id + '/notifyLogin';
    return this.apiClient.get({url}) as Promise<any>;
  }

  notifyLogout(id: string): Promise<any> {
    if (!id) {
      return Promise.resolve(false);
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + id + '/notifyLogout';
    return this.apiClient.get({url}) as Promise<any>;
  }

  getActiveBids(id: string) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}/activeBids`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  getLostBids(id: string) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}/lostBids`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  async setLogin(contactId) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + contactId + '/setLogin';
    await this.apiClient.get({url});
  }

  setContactRoles(contactId, roles): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + contactId + '/roles';

    return this.apiClient.put({url, data: {roles}}) as Promise<any>;
  }

  getAdvertsOfInterest(contactId: string): Promise<AdvertOfInterestDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/advertsOfInterest`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<AdvertOfInterestDTO[]>;
  }

  inviteContact(inviteContactDTO: InviteDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/invite`;
    return this.apiClient.post({url, data: inviteContactDTO}) as Promise<any>;
  }

  resendInvite(inviteId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/invites/${inviteId}/resend`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  getContactFromCognitoEmail(): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/fromCognitoEmail`;

    return (this.apiClient.get({url}) as Promise<any>).then((user) => {

      return user;
    });
  }

  async recentlyViewed(contactId: string) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/recentlyViewed`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }



  patch(contactId: string, contactData: ContactDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + contactId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    try {

      const blankDTO: ContactDTO = {};
      const patch = compare(blankDTO, contactData);

      return this.apiClient.patch({url, data: patch}) as Promise<any>;

    } catch (e) {
      this.logger.error(e);
    }

    return null;
  }

  delete(contactId: string, customerId: string): Promise<any> {

    const url = `${this.data.apiUrl}${this.customerServiceUrl}` + '/' + customerId + '/contact/' + contactId;

    return this.apiClient.delete({url}) as Promise<any>;
  }

  createContact(contactData: ContactDTO): Promise<any> {

    this.logger.log(contactData);

    const url = `${this.data.apiUrl}${this.serviceUrl}/createContact`;

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    try {

      return this.apiClient.post({url, data: contactData}) as Promise<any>;

    } catch (e) {
      this.logger.error(e);
    }

    return null;
  }

  createUsingCognito(): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/createContactUsingCognito`;

    try {

      return (this.apiClient.post({url}) as Promise<any>).then((contact: ContactDTO) => {

        return contact;

      });

    } catch (e) {
      this.logger.error(e);
    }

    return null;
  }

  /*

  These aren't used.. restore if required

  getContactAttrib(contactId: string, attribId: number, ignoreCache: boolean = false, storeCache: boolean = true):
    Promise<ContactAttribDTO> {

    return new Promise<ContactAttribDTO>((resolve, reject) => {

      const storeKey = "crm-display-format";

      if (!ignoreCache) {
        const response = localStorage.getItem(storeKey);

        if (response != null) {
          const contactAttribDTO = JSON.parse(response) as ContactAttribDTO;
          return resolve(contactAttribDTO);
        }
      }

      this.getContactAttribs({
        component: "lead-list",
        filters: {
          attribId,
          contactId,
        }
      }).then((results) => {

        const valueToStore = (results.totalItems > 0) ? {attribChar: results.results[0].attribChar} : {};

        if (storeCache) {
          localStorage.setItem(storeKey, JSON.stringify(valueToStore));
        }

        return resolve(valueToStore);
      });
    });
  }

  setContactAttrib(data: ContactAttribDTO) {
    const url = `${this.data.apiUrl}/api/contact/${data.contactId}/attrib/${data.attribId}`;
    return this.apiClient.put({url, data}) as Promise<SearchResultDTO>;
  }


  getContactAttribs(query: ContactAttribSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/contact/${query.filters.contactId}/attribs`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }
   */

  getNotificationPrefs(contactId): Promise<AttribDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/notificationPrefs`;
    return this.apiClient.get({url}) as Promise<AttribDTO[]>;
  }

  saveNotificationPref(contactId, contactAttribDTO: ContactAttribDTO): Promise<boolean> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/notificationPref`;
    return this.apiClient.post({url, data: contactAttribDTO}) as Promise<boolean>;
  }

  initContactCustomer(contactData: ContactDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/initContactCustomer`;

    try {

      const blankDTO: ContactDTO = {};
      const patch = compare(blankDTO, contactData);

      return this.apiClient.patch({url, data: patch}) as Promise<any>;

    } catch (e) {
      this.logger.error(e);
    }

    return null;
  }

  getInvite(id: string): Promise<InviteDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/invite/${id}`;
    return this.apiClient.get({url, headers: { "X-Skip-Interceptor": "True" }}) as Promise<InviteDTO>;
  }

  updateInvitation(id: string): Promise<boolean> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}/update-invitation`;
    return this.apiClient.get({url}) as Promise<boolean>;
  }
}
