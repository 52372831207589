// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pair-label {
  font-size: 0.6rem;
}

.prov-item {
  max-width: 75px;
  flex-grow: 1;
}

.unknown {
  background-color: #eee;
  font-weight: 500;
}

.provenance-status {
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  padding: 0 12px;
  width: 100%;
  text-align: center;
  border-radius: 12px;
}

.mw-80px {
  max-width: 80px;
}

.notOk {
  background-color: var(--dangerColour);
  color: #fff;
  font-weight: bold;
}

.notOk-finance {
  background-color: var(--warningColour);
  color: #fff;
  font-weight: bold;
}

.ok {
  font-weight: 500;
  background-color: var(--successColour);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/provenance-summary-widget/provenance-summary-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAKA;EACE,eAAA;EACA,YAAA;AAFF;;AAMA;EACE,sBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,qCAAA;EACA,WAAA;EACA,iBAAA;AAHF;;AAMA;EACE,sCAAA;EACA,WAAA;EACA,iBAAA;AAHF;;AAOA;EACE,gBAAA;EACA,sCAAA;EACA,WAAA;AAJF","sourcesContent":[".pair-label {\n  font-size: 0.6rem;\n}\n\n.pair-value {\n}\n\n.prov-item {\n  max-width: 75px;\n  flex-grow: 1;\n\n}\n\n.unknown {\n  background-color: #eee;\n  font-weight: 500;\n}\n\n.provenance-status {\n  font-size: 12px;\n  line-height: 24px;\n  display: inline-block;\n  padding: 0 12px;\n  width: 100%;\n  text-align: center;\n  border-radius: 12px;\n}\n\n.mw-80px {\n  max-width: 80px;\n}\n\n.notOk {\n  background-color: var(--dangerColour);\n  color: #fff;\n  font-weight: bold;\n}\n\n.notOk-finance {\n  background-color: var(--warningColour);\n  color: #fff;\n  font-weight: bold;\n}\n\n\n.ok {\n  font-weight: 500;\n  background-color: var(--successColour);\n  color: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
