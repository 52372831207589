// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lotNo {
  font-weight: 800;
}

/* Paused + Ended */
.biddingStatus-0, .biddingStatus-2 {
  opacity: 0.5;
  pointer-events: none;
}

.non-runner {
  background-color: var(--errorColour);
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 7px;
}

.sale-status-banner {
  padding: 5px 10px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.sale-status-banner.sale-paused-banner {
  background-color: var(--warning);
}
.sale-status-banner.sale-ended-banner {
  background-color: var(--errorColour);
  color: #fff;
}

.proxy-bid-header {
  font-weight: 500;
  background-color: var(--secondaryButtonColour);
  color: #fff;
  border-top-right-radius: calc(var(--widgetBorderRadius) - 1px);
  border-top-left-radius: calc(var(--widgetBorderRadius) - 1px);
  font-size: 0.85rem;
}

.line-1 {
  font-weight: 500;
}

.line-2 {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.saleName {
  font-weight: 500;
}

.sticky-description {
  position: sticky;
  top: var(--headerHeight);
  z-index: 999;
  background-color: var(--bgColour);
}

.enter-max-bid {
  font-size: 0.875rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/sale/sale-view/sale-view.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA,mBAAA;AACA;EACE,YAAA;EACA,oBAAA;AACF;;AAEA;EAEE,oCAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;;AAGA;EAEE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AADF;AAGE;EACE,gCAAA;AADJ;AAIE;EACE,oCAAA;EACA,WAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,8CAAA;EACA,WAAA;EACA,8DAAA;EACA,6DAAA;EACA,kBAAA;AAHF;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE,mBAAA;EACA,qBAAA;AAJF;;AAOA;EACE,gBAAA;AAJF;;AAOA;EACE,gBAAA;EACA,wBAAA;EACA,YAAA;EACA,iCAAA;AAJF;;AAOA;EACE,mBAAA;EACA,gBAAA;AAJF","sourcesContent":[".lotNo {\n  font-weight: 800;\n}\n\n/* Paused + Ended */\n.biddingStatus-0, .biddingStatus-2 {\n  opacity: 0.5;\n  pointer-events: none;\n}\n\n.non-runner {\n\n  background-color: var(--errorColour);\n  color: #fff;\n  font-size: 0.75rem;\n  font-weight: bold;\n  text-align: center;\n  margin-bottom: 7px;\n}\n\n.sale-status-banner {\n\n  padding: 5px 10px;\n  font-weight: 600;\n  border-radius: 5px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n\n  &.sale-paused-banner {\n    background-color: var(--warning);\n  }\n\n  &.sale-ended-banner {\n    background-color: var(--errorColour);\n    color: #fff;\n  }\n}\n\n.proxy-bid-header {\n  font-weight: 500;\n  background-color: var(--secondaryButtonColour);\n  color: #fff;\n  border-top-right-radius: calc(var(--widgetBorderRadius) - 1px);\n  border-top-left-radius: calc(var(--widgetBorderRadius) - 1px);\n  font-size: 0.85rem;\n}\n\n\n.line-1 {\n  font-weight: 500;\n}\n\n.line-2 {\n  font-size: 0.875rem;\n  line-height: 0.875rem;\n}\n\n.saleName {\n  font-weight: 500;\n}\n\n.sticky-description {\n  position: sticky;\n  top: var(--headerHeight);\n  z-index: 999;\n  background-color: var(--bgColour);\n}\n\n.enter-max-bid {\n  font-size: 0.875rem;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
