import {Injectable} from "@angular/core";
import {DataService} from "./data.service";
import { ApiService } from "../global/services";

@Injectable()
export class LambdaFunctionsService {
  private serviceUrl = "/api/lambda-functions";

  constructor(private apiClient: ApiService, private data: DataService) {}

  getEnhancedImage(imageURL: string) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/enhance-image?url=${imageURL}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  swapEnhancedImage(mediaId: string, enhancedImageURL: string) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/swap-to-enhanced`;

    const dto = {
      mediaId,
      enhancedImageURL
    }

    return this.apiClient.post({url, data: dto}) as Promise<any>;
  }
}
