import {CustomerMediaCategoryEnum, CustomerMediaSubCategoryEnum, MediaTypeEnum} from "../../../enums/index";

export interface CustomerMediaUploadDTO {
  customerId: string;
  mediaType: MediaTypeEnum[];
  mediaCategory: CustomerMediaCategoryEnum;
  mediaSubCategory: CustomerMediaSubCategoryEnum;
  mediaUpload: CustomerMediaUploadItemDTO;
}

export interface CustomerMediaUploadItemDTO {
  customerId: string;
  mediaType: MediaTypeEnum;
}

export interface CustomerMediaUploadResponseDTO {
  id: string;
  customerId: string;
  mediaType: MediaTypeEnum;
  mediaCategory: CustomerMediaCategoryEnum;
  mediaSubCategory: CustomerMediaSubCategoryEnum;
  added: Date;
  updated: Date;
  statusId: number;
  mediaURL: string;
}
