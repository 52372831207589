import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AddressService, SaleService, SaleTypeService, URLService} from "../../../../services";
import {AdminUrlService} from "../../services";
import * as moment from "moment";
import {SaleDTO, SaleSearchResultDTO, User} from "../../../../global/interfaces";
import {HelpersService, UserService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {BaseSearchDTO} from "projects/common/interfaces/DTOs/API/baseSearchDTO.interface";

@Component({
  selector: 'app-admin-sale-search',
  templateUrl: './sale-search.component.html',
  styleUrls: ['./sale-search.component.scss'],
  providers: []
})
export class AdminSaleSearchComponent implements OnInit {

  public searchForm = new UntypedFormGroup({
    saleName: new UntypedFormControl(""),
    saleTypeId: new UntypedFormControl(""),
    addressId: new UntypedFormControl(""),
    statusId: new UntypedFormControl(""),
    allSales: new UntypedFormControl(true)
  });

  public statusEnum = StatusEnum;
  public saleTypes: {}[] = [];
  public platforms: {}[] = [];
  public addresses: {}[] = [];
  public statuses: {}[] = [];
  private salesSubscription: any;

  constructor(
    private saleService: SaleService,
    private userService: UserService,
    private saleTypeService: SaleTypeService,
    private addressService: AddressService,
    private helpersService: HelpersService,
    private url: URLService,
    public adminUrl: AdminUrlService,
    private router: Router
  ) {

    // this.logger.componentName = "AdminSaleSearch";

    this.statuses = this.helpersService.getLabelsAndValues(StatusEnum);
  }

  saleSearchDTO: BaseSearchDTO = new BaseSearchDTO();
  isLoading = false;
  sales: SaleDTO[];
  user: User;

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
      // this.logger.info("User loaded, current user set", this.user);
    });

    // get all sales
    this.isLoading = true;

    await this.getAddresses();
    await this.getSaleTypes();

    this.saleSearch();

  }

  getAddresses() {
    this.addressService.getCustomerAddresses(this.user.customerId).then((data: any) => {
      if (data) {
        this.addresses = [{value: 0, label: 'Select Address'}];
        data.forEach((x) => {
          this.addresses.push({value: x.id, label: x.addressName});
        });
      }
    });
  }

  getSaleTypes() {

    const st = this.saleTypeService.getAll();
    this.saleTypes = [{value: '', label: 'Select Sale Type'}];
    st.forEach((x) => {
      this.saleTypes.push({value: x.id, label: x.saleTypeName});
    });

    // this.logger.debug("Sale Types", this.saleTypes);
  }

  saleSearch() {

    this.saleSearchDTO.filters = Object.assign(this.searchForm.value);

    this.saleSearchDTO.component = "AdminSaleSearch";
    this.salesSubscription = this.saleService.getSales(this.saleSearchDTO).then((data: SaleSearchResultDTO) => {

      if (data) {
        this.sales = data.sales.sort((a, b) => {
          return moment(a.saleStart).diff(moment(b.saleStart));
        });
      }
      this.isLoading = false;
    });
  }

  visitSale(id: string) {

    if (this.user.isLoggedIn) {

      // Redirect to sale
      this.url.sale(id, false);

    } else {

      // Redirect to login page

    }
  }
}
