import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanErrorSummaryDTO extends BaseIntDTO {
  scanServiceId: string;
  scanServiceName: string;
  scanStyleId: number;
  scanStyleName: string;
  customerCount: number;
  errorCount: number;
  lastError: string;
}
