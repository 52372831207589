export interface CommsTemplatePreviewResponseDTO {
  success: string;
  subject: string;
  body: string;
  imageUrl: string;
  recipientAddress: string;
  uniqueId: string;
  bccAddress: string;
  hash: object;
}
