// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.menu-logo-text {
  font-size: 48px;
}
.menu-logo-text-loggedin {
  font-size: 32px;
}

.search-text-input {

  background-color: var(--navSearchBgColour);
  color: var(--navSearchTextColour);
  border: 0;

}

.search-submit {

  background-color: var(--colour5);
  border: 0px solid transparent !important;
  color: var(--colour3);
  cursor: pointer;

}
.search-text-input::placeholder {

  color: var(--colour3);

}

nav {
  background-color: var(--navbarBgColour) !important;
}

nav .nav-link, nav {
  color: var(--navbarTextColour);
}

nav .nav-link:hover, .navbar-light .navbar-nav .show>.nav-link, nav {
  color: var(--navbarTextHoverColour);
}

nav .navbar-brand, nav .navbar-brand a, .navbar-brand a:visited {
  font-weight: bold;
  color: var(--colour3) !important;
  cursor: pointer;
}

nav .navbar-brand a {
  color: inherit;
  text-decoration: inherit;
}

.navbar-light .navbar-toggler {
  color: var(--navbarTextHoverColour);
}

.search-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-box {
  max-width: 752px;
}

:focus {
  outline-width: 0 !important;
}

.dropdown-item {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./projects/common/components/privacy-policy/privacy-policy.component.css"],"names":[],"mappings":";AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;AAEA;;EAEE,0CAA0C;EAC1C,iCAAiC;EACjC,SAAS;;AAEX;;AAEA;;EAEE,gCAAgC;EAChC,wCAAwC;EACxC,qBAAqB;EACrB,eAAe;;AAEjB;AACA;;EAEE,qBAAqB;;AAEvB;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":["\n.menu-logo-text {\n  font-size: 48px;\n}\n.menu-logo-text-loggedin {\n  font-size: 32px;\n}\n\n.search-text-input {\n\n  background-color: var(--navSearchBgColour);\n  color: var(--navSearchTextColour);\n  border: 0;\n\n}\n\n.search-submit {\n\n  background-color: var(--colour5);\n  border: 0px solid transparent !important;\n  color: var(--colour3);\n  cursor: pointer;\n\n}\n.search-text-input::placeholder {\n\n  color: var(--colour3);\n\n}\n\nnav {\n  background-color: var(--navbarBgColour) !important;\n}\n\nnav .nav-link, nav {\n  color: var(--navbarTextColour);\n}\n\nnav .nav-link:hover, .navbar-light .navbar-nav .show>.nav-link, nav {\n  color: var(--navbarTextHoverColour);\n}\n\nnav .navbar-brand, nav .navbar-brand a, .navbar-brand a:visited {\n  font-weight: bold;\n  color: var(--colour3) !important;\n  cursor: pointer;\n}\n\nnav .navbar-brand a {\n  color: inherit;\n  text-decoration: inherit;\n}\n\n.navbar-light .navbar-toggler {\n  color: var(--navbarTextHoverColour);\n}\n\n.search-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.search-box {\n  max-width: 752px;\n}\n\n:focus {\n  outline-width: 0 !important;\n}\n\n.dropdown-item {\n  padding-left: 1rem;\n  padding-right: 1rem;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
