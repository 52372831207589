import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AttribService} from '../../../../services';
import {StatusService} from '../../../../services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AdminUrlService} from "../../services";
import {HelpersService, LoggerService} from "../../../../global/services/index";
import {AttribDTO} from '../../../../global/interfaces';
import {AttribTypeEnum, StatusEnum} from "../../../../global/enums/index";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-main',
  templateUrl: './attrib.component.html',
  styleUrls: ['./attrib.component.scss', '../../../../../../../common/components/app-side-menu/app-side-menu.component.scss'],
  providers: []
})
export class AttribMaintComponent implements OnInit {

  @ViewChild('attribModal') attribModal: ModalDirective;

  public statusOptions: {}[];
  public attribTypeOptions: {}[];
  public statusName: string[] = [];
  public attribTypeName: string[] = [];
  public showDeleteConfirm: boolean;
  public attribIdToDelete: number;

  constructor(
    private attribService: AttribService,
    private statusService: StatusService,
    private formBuilder: UntypedFormBuilder,
    public adminUrl: AdminUrlService,
    private helpersService: HelpersService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  attribList: AttribDTO[] = [];
  attribTypeList: Array<any> = [
    "integer", "lookup", "string", "date"
  ];
  isLoading: boolean;
  submitted: boolean;
  isEdit = false;
  form: UntypedFormGroup;

  async ngOnInit() {

    await this.initForm();
    this.loadStatuses();
    this.loadAttribs();
    this.loadAttribTypes();
  }

  async initForm() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      attribType: new UntypedFormControl(),
      attribName: new UntypedFormControl(),
      attribCode: new UntypedFormControl(),
      statusId: new UntypedFormControl(''),
    });

  }

  async loadAttribs() {

    this.attribService.getAll().then(attribs => {
      this.attribList = attribs;
    });
  }

  addAttrib() {

    this.form.patchValue(({
      id: '',
      attribType: '',
      attribName: '',
      attribCode: '',
      statusId: StatusEnum.Active
    }));

    this.isEdit = false;

    // show modal that allows location name entry
    this.attribModal.show();
  }

  editAttrib($event: MouseEvent, attribDTO: AttribDTO) {

    this.form.patchValue(({
      ...attribDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.attribModal.show();
  }

  async saveAttrib() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const attribDTO = this.form.value;

    if (this.isEdit) {
      await this.attribService.patch(attribDTO);
    } else {
      delete attribDTO.id;
      await this.attribService.create(attribDTO);
    }

    this.attribModal.hide();
    await this.loadAttribs();
  }

  async loadStatuses() {

    const statuses = this.helpersService.getNamesAndValues(StatusEnum);

    this.statusOptions = statuses.map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadAttribTypes() {

    const attribTypes = this.helpersService.getNamesAndValues(AttribTypeEnum);

    this.attribTypeOptions = attribTypes.map(attribType => {
      this.attribTypeName[attribType.value] = attribType.name;
      return {label: attribType.name, value: attribType.value};
    });
  }

  deleteAttrib(event, attrib: AttribDTO) {

    event.stopPropagation();
    this.attribIdToDelete = attrib.id;
    this.showDeleteConfirm = true;
  }

  hideModal() {
    this.attribModal.hide();
  }

  async confirmDelete() {

    this.logger.debug("ATTRIB TO DELETE " + this.attribIdToDelete);
    await this.attribService.delete(this.attribIdToDelete);
    this.attribModal.hide();
    await this.loadAttribs();
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }

}

