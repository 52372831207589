export interface VehicleStatusCheckDTO {
  id?: string;
  vrm?: string;
  makeId?: number;
  makeName?: number;
  modelId: number;
  modelName: number;
  derivId: number;
  derivName: string;
  vehicleTypeId: number;
  vehicleTypeName: string;
  plateId: number;
  plateName: string;
  transmissionTypeId: number;
  transmissionTypeName: string;
  bodyTypeId: number;
  bodyTypeName: string;
  fuelTypeId: number;
  fuelTypeName: string;
  customerId: string;
  customerName: string;
  advertId: string;
  saleId: string;
  saleStatusName: string;
  addressId: string;
  addressName: string;
  platformId: number;
  platformName: string;
  saleTypeId: number;
  saleTypeName: string;
  sphLinkId: number;
  motId?: string;
}

