export enum ValuationTypeEnum {
  Estimate,
  Actual,
  Manual,
  External = 4 // 3rd party service
}

export const ValuationTypeName = [
  "Estimated",
  "Appraised",
  "Manual",
];
