import {DomainData} from './domain-data.interface';
import {Capacitor} from '@capacitor/core';
import {OdometerUnitEnum} from "../enums";
import {environment} from "../../remarq/src/environments/environment";
import {CRMTypeEnum} from "../enums/index";

export class GlobalConstants {

  public static FrontEndVersion = "1.0";
  public static IsMobile = window.innerWidth <= 768;
  public static IsDevice = Capacitor.isNativePlatform();
  public static CompanyProductCode = {
    Remarq: "remarq",
    UInspect: "uinspect",
    SiteScan: "sitescan",
    CRM: "crm",
    CarBuying: "carbuying",
    InspectCollect: "inspectCollect"
  };

  public static getPlatformDetails(product, hostName): DomainData {

    const domainKeys: { host: string; data: DomainData }[] = [
      {
        host: 'portal.tradesales.com',
        data: {
          theme: 'tradesales',
          companyName: 'TradeSales Ltd',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'www.tradesales.com',
          apiURL: 'https://api.tradesales.com',
          homepage: 'https://www.tradesales.com',
          domain: 'tradesales.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          salesPhone: '0800 058 4020',
          supportPhone: '0800 058 4020',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'dev-portal.tradesales.com',
        data: {
          theme: 'tradesales',
          homepage: 'https://dev-portal.tradesales.com',
          companyName: 'TradeSales Ltd',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'www.tradesales.com',
          apiURL: 'https://dev-api.tradesales.com',
          domain: 'tradesales.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          salesPhone: '0800 058 4020',
          supportPhone: '0800 058 4020',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'motiox.tradesales.com',
        data: {
          theme: 'motiox',
          homepage: 'https://motiox.tradesales.com',
          companyName: 'MotioX Ltd',
          platformId: 1,
          platformName: 'MotioX',
          platformDetails: 'MotioX',
          companyURL: 'www.motiox.com',
          apiURL: 'https://dev-api.tradesales.com',
          domain: 'motiox.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          salesPhone: '0800 058 4020',
          supportPhone: '0800 058 4020',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'uinspect.tradesales.com',
        data: {
          theme: 'tradesales',
          companyName: 'TradeSales Ltd',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'www.tradesales.com',
          apiURL: 'https://api.tradesales.com',
          domain: 'uinspect.tradesales.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          salesPhone: '01234 567890',
          supportPhone: '01234 567890',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'crm.tradesales.com',
        data: {
          theme: 'tradesales',
          companyName: 'TradeSales Ltd',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'www.tradesales.com',
          apiURL: 'https://api.tradesales.com',
          domain: 'uinspect.tradesales.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          salesPhone: '01234 567890',
          supportPhone: '01234 567890',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'portal.tradesales.com',
        data: {
          theme: 'tradesales',
          companyName: 'TradeSales Ltd',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'www.tradesales.com',
          apiURL: 'https://api.tradesales.com',
          domain: 'tradesales.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'app.tradesales.com',
        data: {
          theme: 'tradesales',
          companyName: 'Trade Sales Ltd',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'tradesales.com',
          apiURL: 'https://api.tradesales.com',
          domain: 'tradesales.com',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
        }
      },
      {
        host: 'remarq.dev',
        data: {
          theme: 'bigauction',
          companyName: 'BidUp',
          platformId: 1,
          platformName: 'BidUp',
          platformDetails: 'BidUp',
          companyURL: 'remarq.dev',
          apiURL: 'https://api.remarq.dev',
          domain: 'remarq.dev',
          currencyCode: 'GBP',
          currencySymbol: '£',
          locale: 'en-GB',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          salesPhone: '01234 567890',
          supportPhone: '01234 567890',
          useSiteScan: true,
          mapLocation: {lat: 51.68749628175348, lng: -0.04487011464722376},
          address: {line1: "John Wesley Rd", line2: "Werrington", line3: "Peterborough", postcode: "PE4 6ZL"},
          // hideDivIds: ['advert-view-tyres', 'advert-view-location']
        }
      },
      {
        host: 'tradecollect.tradesales.com',
        data: {
          theme: 'tradecollect',
          companyName: 'TradeSales',
          platformId: 1,
          platformName: 'TradeSales',
          platformDetails: 'TradeSales',
          companyURL: 'carsure.tradesales.com',
          apiURL: 'https://api.tradesales.com',
          domain: 'carsure.tradesales.com',
          currencyCode: 'USD',
          currencySymbol: '$',
          locale: 'en-US',
          writeOffPhrase: 'Totaled',
          odometerUnits: OdometerUnitEnum.Miles,
          defaultOdometerUnit: OdometerUnitEnum.Miles,
          salesPhone: '(555) 567890',
          supportPhone: '(555) 567890',
          mapLocation: {lat: 51.6033, lng: -0.6891},
          address: {line1: "Great Burgh", line2: "Epsom", line3: "Surrey", postcode: "KT18 5UX"},
          crmType: CRMTypeEnum.CarBuying
        }
      },
      {
        host: 'localhost',
        data: {
          copyOf: {
            remarq: 'tradesales',
            uinspect: 'toyotagb',
            crm: 'carsure',
            carbuying: 'carsure',
            inspectCollect: 'tradecollect',
          }
        }
      }
    ];

    // If we have a fixed hostname as we're on mobile
    if (this.IsDevice && hostName !== "localhost" && environment?.mobileHost !== "") {
      hostName = environment.mobileHost;
    } else {
      hostName = hostName.split(":")[0];
    }

    console.log("LOOKING FOR HOST ", hostName);

    let domain = domainKeys.find(x => x.host === hostName);

    // If we are imitating another host for dev purposes, use a copy of that
    if (hostName === "localhost") {
      domain = domainKeys.find(x => x.data.theme === domain.data.copyOf[product]);
      domain.data.apiURL = 'https://localhost:5001';
      domain.data.domain = 'https://localhost:44322';
      return domain.data;
    }

    if (domain) {
      return domain.data;
    }

    return null;
  }
}
