import {Component, OnInit} from '@angular/core';
import {URLService} from '../../../services';
import {} from "../../../global/interfaces";
import {UserService} from "../../../global/services";
import {} from "../../../global/enums";


@Component({
  selector: 'app-mobile-bar',
  templateUrl: './app-mobile-bar.component.html',
  styleUrls: ['./app-mobile-bar.scss']
})
export class AppMobileBarComponent implements OnInit {

  isLoggedIn = false;

  constructor(
    public url: URLService,
    public userService: UserService,
  ) { }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then((x) => {

      if (x != null) {
        this.isLoggedIn = true;
        return;
      }
    });
  }
}

