import {BaseSearchDTO} from "./baseSearchDTO.interface";

export interface AdminTaskSearchDTO extends BaseSearchDTO {
  filters?: {
    tasksForUserId?: string;
    customerId?: string;
    fromContactId?: string;
    forContactId?: string;
    overdue: boolean;
    statusId?: number;
  };
  sortBy?: string;
  sortDescending?: boolean;
}
