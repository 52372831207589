import {Injectable} from "@angular/core";
import {UInspectSectionSyncDTO, UInspectSyncDTO} from "../../interfaces/DTOs/UInspect/index";
import {UInspectSectionTypeEnum} from "../../enums/uinspect/index";

@Injectable()
export class UInspectCommonService {

  fetchOrderedInspectSections(uinspect: UInspectSyncDTO): UInspectSectionSyncDTO[] {

    const sectionData: { [key: number]: UInspectSectionSyncDTO } = {};
    const sections: UInspectSectionSyncDTO[] = [];

    uinspect.uInspectFormat.sections.forEach((x) => {
      sectionData[x.id] = x;
    });

    const response = this.walkTree(uinspect.uInspectFormat.startSectionId, sectionData, [], false);

    // Here if you need it, but you could just use sectionList[sectionlist.length - 1]
    const lastSectionId = response[0];
    const sectionList = response[1];

    sectionList.forEach(id => {
      sections.push(sectionData[id]);
    });

    return sections;
  }

  walkTree(
    sectionId: number,
    sectionData: { [key: number]: UInspectSectionSyncDTO },
    seenSections: number[],
    stopAtUnanswered: boolean): [number, number[]] {

    // NOTE: Before calling SectionData should be populated as follows sectionData[x.sectionId] = sectionSyncObject;

    const section = sectionData[sectionId];

    // LOOP PREVENTION
    if (seenSections.includes(sectionId)) {
      console.log("LOOP PREVENTION: " + sectionId);
      console.log("SEEN: " + seenSections);
      return [sectionId, seenSections];
    }

    seenSections.push(sectionId);

    if (section?.sectionComplete?.complete !== true) {

      // console.log("SECTION NOT COMPLETE " + sectionId);
      // console.log("RETURNING ", seenSections);

      return [sectionId, seenSections];
    }

    if (section?.sectionType === UInspectSectionTypeEnum.Media) {

      if (section.medias.length >= section.minMedia) {

        // console.log("Descending from " + sectionId + " Tree (media) to ", section.nextSectionId);

        return this.walkTree(section.nextSectionId, sectionData, seenSections, stopAtUnanswered);
      }
    }

    if (section?.sectionType === UInspectSectionTypeEnum.Question) {

      // console.log(" WE HAVE A QUESTIONS ");

      if (section?.questions?.length > 0) {

        // console.log(" WE HAVE ONE OR MORE QUESTION ");

        // We will return to the idea of a list of questions in sections later..
        // section.questions.forEach((question) => {

        const question = section.questions[0];

        if (question.answer || !stopAtUnanswered) {

          // console.log(" WE HAVE AN ANSWER ");

          let nextSectionId = section.nextSectionId;

          // If we have a list of options
          if (question?.options?.length > 0) {

            // console.log(" WE HAVE AN ANSWER OPTION ");

            if (question.answer != null) {

              // console.log(" WE HAVE AN ANSWER THAT IS NOT NULL ");

              const choice = question.options.find(x => x.id === question.answer.answerOptionId);

              if (choice != null && choice.nextSectionId > 0) {

                // console.log(" WE HAVE AN ANSWER OPTION THAT IS NOT NULL AND HAS A NEXT SECTION ID");

                nextSectionId = choice.nextSectionId;
              }
            }
          }

          // console.log("Descending from " + sectionId + " Tree (question) to ", nextSectionId);

          return this.walkTree(nextSectionId, sectionData, seenSections, stopAtUnanswered);

        } else {

          if (question.medias && question.medias.length >= question.minMedia) {

            // console.log("Descending from " + sectionId + " Tree (question media) to ", section.nextSectionId);

            return this.walkTree(section.nextSectionId, sectionData, seenSections, stopAtUnanswered);
          }
        }
      }
    }

    return [sectionId, seenSections];
  }

}
