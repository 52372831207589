import {BaseDTO} from "./_baseDTO.interface";
import {RoleDTO} from "./roleDTO.interface";
import {ContactDTO} from "./contactDTO.interface";

export interface ContactRoleDTO extends BaseDTO {
  contactId?: string;
  roleId?: string;
  role?: RoleDTO;
  contact?: ContactDTO;
}
