import {AdvertDTO} from "./advertDTO.interface";
import {BidDTO} from "./bidDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {CustomerOrderDTO} from './customer-orderDTO.interface';

export interface DealDTO extends BaseDTO {
  advertId: string;
  bidId: string;
  buyerContactId: string;
  sellerContactId: string;
  advert: AdvertDTO;
  bid: BidDTO;
  bidAmt?: number;
  buyerContact: ContactDTO;
  sellerContact: ContactDTO;
  customerOrders?: CustomerOrderDTO[];
  buyerAssignee: string;
  sellerAssignee: string;
  completed: boolean;
}
