import {MessageAreaEnum} from '../../../enums/message-area.enum';
import {MessageTypeEnum} from '../../../enums/message-type.enum';
import {MessageMethodEnum} from "../../../enums/message-method.enum";

export interface MessageDTO {
  messageMethod?: MessageMethodEnum;
  messageArea?: MessageAreaEnum;
  messageType?: MessageTypeEnum;
  messageData?: any; // stringified JSON object or plain text
}
