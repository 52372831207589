import {BaseDTO} from './_baseDTO.interface';
import {BodyPartGroupDTO} from './bodyPartGroupDTO.interface';
import {BaseIntDTO} from "./_baseIntDTO.interface";

export interface BodyPartDTO extends BaseIntDTO {
  bodyPartGroupId: number;
  bodyPartGroup: BodyPartGroupDTO;
  partName: string;
  // rgb string that corresponds to body part on the kipper, i.e. "255, 255, 254"
  kipperRGB: string;

  // true = inside of vehicle
  internal: boolean;
}
