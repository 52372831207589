import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  AdvertViewBidBoxDTO,
  AppraisalDTO,
  BidDTO,
  BidReplyDTO,
  BidSearchDTO,
  OfferSearchDTO,
  SearchResultDTO,
  ValidatedResultDTO
} from '../global/interfaces/index';
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";
import {LoggerService} from '../global/services/index';

@Injectable()
export class BidService {

  private serviceUrl = '/api/bid';

  constructor(private apiClient: ApiService,
              private data: DataService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  search(searchDTO: BidSearchDTO): Promise<SearchResultDTO> {
    let url = `${this.data.apiUrl}/api/bids/`;
    url += '?query=' + encodeURIComponent(JSON.stringify(searchDTO));
    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }

  acceptBid(bid: BidDTO): Promise<ValidatedResultDTO> {

    const body = {};

    const url = `${this.data.apiUrl}${this.serviceUrl}/${bid.bidGuid}/accept`;
    return this.apiClient.put({url, data: body}) as Promise<ValidatedResultDTO>;

  }

  rejectBid(bid: BidDTO): Promise<any> {

    const body = {};

    const url = `${this.data.apiUrl}${this.serviceUrl}/${bid.bidGuid}/reject`;
    return this.apiClient.put({url, data: body}) as Promise<any>;

  }



  searchBidHistory(advertId) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/bidHistory/${advertId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  bidsByAdvert(bidSearchDTO: BidSearchDTO): Promise<any> {
    const dto = JSON.stringify(bidSearchDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/byAdvert`;

    return this.apiClient.get({url, params: {query: dto}}) as Promise<any>;
  }

  searchContactCounterOffers(query?: OfferSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/contact/${query.filters.contactId}/counterOffers`;
    console.log("URL ", url);
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  searchBidderOffers(customerId: string, pendingOnly?: boolean): Promise<BidDTO[]> {

    const url = `${this.data.apiUrl}/api/customer/${customerId}/bidderOffers`;
    return this.apiClient.get({url}, { query: { pendingOnly }}) as Promise<BidDTO[]>;
  }

  bidderOffer(bid: BidDTO): Promise<BidDTO> {

    const url = `${this.data.apiUrl}/api/listing/${bid.advertId}/bid`;
    return this.apiClient.post({url, data: bid}) as Promise<BidDTO>;
  }

  // To be used for proxy bids where timing is not essential
  offlineBid(bid: BidDTO): Promise<BidDTO> {

    this.logger.info('SUBMITTING PROXY BID', bid);

    const url = `${this.data.apiUrl}${this.serviceUrl}/offlineBid`;
    return this.apiClient.post({url, data: bid}) as Promise<BidDTO>;
  }



  cancelBid(bidGuid: string, bid: BidDTO): Promise<BidDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${bidGuid}/cancel`;
    return this.apiClient.put({url, data: bid}) as Promise<BidDTO>;
  }

  getRequestOptions() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    };
    return {
      headers: new HttpHeaders(headers),
    };
  }

  public getBidBoxDataByAdvert(advertId: string): Promise<AdvertViewBidBoxDTO> {
    const url = `${this.data.apiUrl}/api/listing/${advertId}/bid-box-data`;
    return this.apiClient.get({url}) as Promise<AdvertViewBidBoxDTO>;
  }
}
