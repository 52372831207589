import {Component, OnInit} from '@angular/core';
import {SaleService, URLService } from '../../../../../services';
import {Router} from '@angular/router';
import {SaleDTO} from "../../../../../global/interfaces";
import {LoggerService, UserService} from "../../../../../global/services";
import {} from "../../../../../global/enums";

@Component({
  selector: 'app-seller-hub-sale-performance',
  templateUrl: './seller-hub-sale-performance.component.html',
  styleUrls: ['./seller-hub-sale-performance.component.scss']
})
export class SellerHubSalePerformanceComponent implements OnInit {

  constructor(
    private userService: UserService,
    private url: URLService,
    private saleService: SaleService,
    private router: Router,
    private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading: boolean;
  sales: SaleDTO[] = [];

  ngOnInit(): void {

    this.loadSales();
  }

  viewSale(saleId: string) {

    this.url.sale(saleId);
  }

  loadSales() {

    this.isLoading = true;

    this.saleService.getSales().then(result => {

      this.sales = result.sales.splice(0, 5);
      this.isLoading = false;

      this.logger.info("Loaded sales: ", this.sales);
    });
  }
}
