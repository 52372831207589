// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 768px) {
  tr td {
    vertical-align: middle !important;
  }
}
td {
  font-weight: 400;
}

.delete-button {
  font-size: 1.2rem;
}

.status {
  font-weight: 600;
}

.statuscolor-1 {
  color: green;
}

.statuscolor-3 {
  color: red;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/maintenance/attribval/attribval.component.scss"],"names":[],"mappings":"AAAA;EAEI;IACE,iCAAA;EAAJ;AACF;AAIA;EAAK,gBAAA;AADL;;AAGA;EAAiB,iBAAA;AACjB;;AACA;EAAU,gBAAA;AAGV;;AADA;EAAiB,YAAA;AAKjB;;AAJA;EAAiB,UAAA;AAQjB","sourcesContent":["@media all and (min-width: 768px) {\n  tr  {\n    td {\n      vertical-align: middle !important;\n    }\n  }\n}\n\ntd { font-weight: 400; }\n\n.delete-button { font-size: 1.2rem; }\n\n.status { font-weight: 600; }\n\n.statuscolor-1 { color: green; }\n.statuscolor-3 { color: red; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
