import {Injectable} from '@angular/core';
import {User} from '../../interfaces/index';
import {CookieService} from 'ngx-cookie-service';
import {Router} from "@angular/router";
import {LoggerService} from "../index";
import {ApiService} from "../api.service";
import {DataServiceInterface, URLServiceInterface} from "../interfaces/index";
import {AuthGuardServiceInterface} from "../interfaces/index";
import {fetchAuthSession, signOut} from 'aws-amplify/auth';
import {environment} from "../../../remarq/src/environments/environment";
import {GlobalConstants} from "../../shared";

@Injectable()

export class UserService {

  public CurrentUser: User;
  private serviceUrl = "/api/user";

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private data: DataServiceInterface,
    private apiClient: ApiService,
    private authService: AuthGuardServiceInterface,
    private urlService: URLServiceInterface,
    private logService: LoggerService) {
    this.CurrentUser = {
      isLoggedIn: false,
      statusId: 0,
      contactAddressId: null,
      impersonate: null,
      contactPostcode: null
    };
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  async logout() {

    if (this.CurrentUser?.impersonator != null) {
      this.logger.info("END IMPERSONATION");
      await this.apiClient.endImpersonation();
    }

    signOut({global: true})
      .then((data) => {
        console.log("REDIRECTING TO HOMEPAGE", this.urlService.homepage(true));

        if (GlobalConstants.IsDevice) {
          this.router.navigate(["/"]);
        } else {
          // ie. Wordpress page
          window.location.href = environment.homepage;
        }

      }).catch(err => this.logger.debug(err));
  }

  async loadCurrentUser(bypassCache = false): Promise<any> {

    // If you want to force a refresh of tokens set bypassCache = true
    // Only do this during signup etc. as it will slow down the app

    return fetchAuthSession({forceRefresh: bypassCache}).then(authUser => {

      // console.log("LOADED CURRENT USER (bypass: " + bypassCache + ")", authUser);

      this.CurrentUser = this.authService.userInfo(authUser);

      return authUser;

    }).catch((err) => {
      this.logger.debug('Authentication Error: ' + err);
    });
  }
}
