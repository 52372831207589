import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DatePipe} from "@angular/common";
import {StatusService, URLService} from '../../../../services';
import {LookupService} from "../../../../services";
import {CustomerAttribService} from "../../../../services";
import {AdminUrlService} from "../../services";
import {CustomerNotes2Component} from "../customer-notes2/customer-notes2.component";
import {NgbdSortableHeader} from "../../../../global/directives";
import {StatusEnum} from "../../../../global/enums";
import {CustomerService, HelpersService, LoggerService, UserService} from "../../../../global/services";
import {ActivatedRoute} from "@angular/router";
import {ModalDirective} from "ng-uikit-pro-standard";
import {CustomerDTO} from '../../../../global/interfaces';
import {CustomerSearchDTO} from 'projects/common/interfaces/DTOs/API/customerSearchDTO.interface';

@Component({
  selector: "app-customer-search",
  templateUrl: "./customer-search.component.html",
  styleUrls: ['./customer-search.component.scss'],
  providers: [DatePipe],
})
export class CustomerSearchComponent implements OnInit {

  @ViewChild("app-customer-notes2", {static: true}) notesComponent: CustomerNotes2Component;
  @ViewChild('notesModal') notesModal: ModalDirective;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  newCustomerForm: UntypedFormGroup;
  isLoading: boolean;
  statusEnum = StatusEnum;

  public notesCustomer: CustomerDTO;
  public newStatusId: number;
  public statusName: string[] = [];
  public statusAbbrev: string[] = [];
  public customers: CustomerDTO[];
  public temp: {} = false;
  public searchForm: UntypedFormGroup;
  public user;
  public dtOptions = {};

  public customerSearchDTO: CustomerSearchDTO = new CustomerSearchDTO();

  newNote: string;

  statusOptions: any;
  searchStatusOptions: any;

  constructor(
    private userService: UserService,
    private customerAttribService: CustomerAttribService,
    private datePipe: DatePipe,
    public urlService: URLService,
    public adminUrl: AdminUrlService,
    private statusService: StatusService,
    private fb: UntypedFormBuilder,
    private helperService: HelpersService,
    private lookupService: LookupService,
    private customerService: CustomerService,
    private helpersService: HelpersService,
    private logService: LoggerService,
    private activeRoute: ActivatedRoute
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  async ngOnInit() {
    this.searchStatusOptions = this.helperService.getLabelsAndValues(StatusEnum);

    this.searchStatusOptions = this.searchStatusOptions.map(x => ({
        value: x.value.toString(),
        label: x.label
      })
    );

    this.searchStatusOptions.forEach(x => {
      this.statusAbbrev[x.value] = x.label.substring(0, 1);
      this.statusName[x.value] = x.label;
    });

    const statusId = this.activeRoute.snapshot.params.statusId?.toString() || null;

    this.searchForm = this.fb.group({
      customerName: [''],
      contactName: [''],
      email: [''],
      statusId: [statusId],
    });

    this.dtOptions = {
      fixedColumns: {leftColumns: 1, rightColumns: 1},
      scrollX: true,
      scrollCollapse: true,
      paging: false
    };

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    if (!statusId || statusId == '') {
      this.loadCustomers();
    } else {
      this.customerSearch();
    }

    this.newCustomerForm = new UntypedFormGroup({
      newCustomerName: new UntypedFormControl('', Validators.required),
    });
  }

  get f() {
    return this.newCustomerForm.controls;
  }

  loadCustomers() {
    this.isLoading = true;

    this.customerService
      .search({component: "AdminCustomerList"})
      .then((result) => {
        this.logger.info("Loaded customers: ", result);

        this.customers = result.customers;
        this.temp = true;
        this.isLoading = false;
      });
  }

  customerSearch() {

    this.customerSearchDTO.filters = Object.assign(this.searchForm.value);
    this.customerSearchDTO.component = "AdminCustomerSearch";

    this.customerService.search(this.customerSearchDTO).then((result) => {
      this.customers = result.customers;
    });
  }

  showNotes(event, customer: CustomerDTO) {
    event.stopPropagation();

    this.notesCustomer = customer;
    this.notesModal.show();
  }

  patchCustomer(customerId, patch) {
    this.customerService.patchCustomer(this.notesCustomer.id, patch).then(() => {
    });
  }

  noteSaved() {
    this.notesModal.hide();
    this.loadCustomers();
  }
}
