import {Component, OnInit} from '@angular/core';
import {AdvertService, DashBoardService, URLService} from '../../../services';
import {Router} from '@angular/router';
import {AdviewService} from '../../../services';
import {AdvertDTO, AdvertOfInterestDTO, User, VehicleOfInterestDTO} from "../../../global/interfaces";
import {ApiService, ContactService, ImageService, LoggerService, UserService} from "../../../global/services";
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public user: User;
  firstName: string;
  stats: any[];

  public recentBidActivityAdverts: AdvertOfInterestDTO[];
  public recentViewActivityAdverts: AdvertDTO[];
  public advertsOfInterest: {}[];
  public saleCount = 1;
  imagesOfInterest: { big: string }[];
  logger = this.logService.taggedLogger(this.constructor?.name);
  newsItems: { title: string, link: string, image?: string }[] = [];
  loadingNews = true;

  constructor(
    private userService: UserService,
    private dashboardService: DashBoardService,
    private router: Router,
    public url: URLService,
    private contactService: ContactService,
    private logService: LoggerService,
    public imageService: ImageService,
    private http: HttpClient,
    public urlService: URLService) {

  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {

      this.user = this.userService.CurrentUser;

      if (this.user.name) {
        const sp = this.user.name.split(' ');
        this.firstName = sp[0];
      }
    });

    // get statistics for relevant data
    this.dashboardService.getContactStats().then((x) => {
      this.stats = x;
    });

    this.logger.info('Buyer stats loaded', this.stats);

    // load adverts (recent bids/purchases, recently viewed, you may be interested in)
    this.loadAdverts();

    this.loadNews().then();
  }

  loadNews() {

    this.loadingNews = true;


    return this.dashboardService.getNews().then((news) => {

      this.loadingNews = false;

      if (news.channel.item.length > 0) {
        this.newsItems = news.channel.item.map((item) => {
          const title = item['title']['#text'];
          const link = item['link']['#text'];
          const image = item["{https://search.yahoo.com/mrss/}thumbnail"]['@url'];

          return {title, link, image};
        })
          .filter(x => !/(dealer auction|dealer-auction|dealerway|auca|bca)/.test(x.title.toLowerCase()));

        this.newsItems.sort(() => Math.random() - 0.5);
        this.newsItems = this.newsItems.slice(0, 4);
      }
    });
  }

  loadAdverts() {

    this.contactService.getRecentBidActivity(this.user.contactId, 10).then(result => {
      this.logger.info('Loaded recent bid activity', result);

      this.recentBidActivityAdverts = result.map(x => ({
        id: x.id,
        vehicle: x.vehicle,
        headline: (x.vehicle?.model?.make?.makeName ?? "") + ' ' + (x.vehicle.model?.modelName ?? ""),
        description:
          (x.vehicle?.plate?.plateName ?? "") + ", " +
          (x.vehicle?.fuelType?.fuelTypeName ?? "") + ", " +
          (x.vehicle?.transmissionType?.transmissionTypeName ?? "") + ", " +
          Math.round(x.vehicle?.odometer / 1000) + "k, " +
          (x.vehicle?.bodyType?.bodyTypeName ?? ""),
        endDateTime: x.endDateTime,
        big: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 800),
        medium: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 400),
        small: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 200)
      }));
    });

    this.contactService.getAdvertsOfInterest(this.user.contactId).then(result => {

      this.advertsOfInterest = result.map(x => ({
        id: x.id,
        headline: x.headline,
        vehicle: x.vehicle,
        description: x.description,
        endDateTime: x.endDateTime,
        big: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 800),
        medium: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 400),
        small: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 200)
      }));

      this.logger.info('Loaded adverts of interest', this.advertsOfInterest);
    });

    this.contactService.recentlyViewed(this.user.contactId).then(result => {
      this.logger.info('Loaded recently views', result);

      this.recentViewActivityAdverts = result.map(x => ({
        id: x.id,
        vehicle: x.vehicle,
        headline: (x.vehicle?.model?.make?.makeName ?? "") + ' ' + (x.vehicle.model?.modelName ?? ""),
        description:
          (x.vehicle?.plate?.plateName ?? "") + ", " +
          (x.vehicle?.fuelType?.fuelTypeName ?? "") + ", " +
          (x.vehicle?.transmissionType?.transmissionTypeName ?? "") + ", " +
          Math.round(x.vehicle?.odometer / 1000) + "k, " +
          (x.vehicle?.bodyType?.bodyTypeName ?? ""),
        endDateTime: x.endDateTime,
        big: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 800),
        medium: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 400),
        small: this.imageService.sizedImageUrl(x.vehicle.primaryImageURL, null, 200)
      }));
    });
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  toAccountSettings() {
    this.router.navigate([this.url.settings(true)]);
  }

  toStatsRoute(statIndex: number) {
    switch (statIndex) {
      case 0:
        if (this.user.isSeller) {
          this.router.navigate([this.url.stock(1, true)]);
        } else {
          this.router.navigate([this.url.mySearches(true)]);
        }
        break;
      case 1:
        this.router.navigate([this.url.myWatchList(true)]);
        break;
    }
  }

  showBids(active = true) {
    this.router.navigate([this.url.bids(active ? 0 : 1, true)]);
  }

  showPurchases() {
    this.urlService.myPurchases();
  }

  showSales() {
    this.urlService.mySales();
  }

  saleCountUpdate($event: number) {
    this.saleCount = $event;
  }
}
