import {Injectable} from '@angular/core';
import {ApiService} from "../../api.service";
import {DataServiceInterface} from "../../interfaces/index";
import {CustomerDTO, CustomerInternalInfoDTO} from "../../../interfaces/DTOs/index";
import {compare} from "fast-json-patch";

@Injectable()
export class AdminCustomerInternalInfoService {

  constructor(
    private apiClient: ApiService,
    private data: DataServiceInterface
  ) {
  }

  private serviceUrl = '/api/customer-internal-info';

  setAssignedTo(customerId: string, data: { assignedTo: string, assignComment: string }): Promise<any> {

    const url = `${this.data.apiUrl}/api/customer/${customerId}/assignedTo`;

    return this.apiClient.put({url, data}) as Promise<any>;
  }

  removeIdPending(customerId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/removePending`;
    return this.apiClient.put({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  patchCustomer(customerId: string, dto: CustomerInternalInfoDTO, previousDTO: CustomerInternalInfoDTO = {}): Promise<any> {

    const patch = compare(previousDTO, dto);

    const url = `${this.data.apiUrl}/api/customer/${customerId}/internalInfo`;
    return this.apiClient.patch({url, data: patch});
  }
}
