import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {MovementAddressDTO, MovementAddressSearchDTO} from "../../interfaces/DTOs/Movements/movement-address-dto.interface";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces/data.service.interface";

@Injectable()
export class MovementAddressService {

  private serviceUrl = '/api/movement-address';

  constructor(private apiClient: ApiService, private data: DataServiceInterface) {
  }

  search(): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }

  get(id: number, query: MovementAddressSearchDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  create(movement: MovementAddressDTO): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: movement}) as Promise<ValidatedResultDTO>;
  }

  delete(id: number): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  patch(movementAddressDTO: MovementAddressDTO, previousDTO: MovementAddressDTO = {} ): Promise<ValidatedResultDTO> {

    const patch = compare(previousDTO, movementAddressDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${movementAddressDTO.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<ValidatedResultDTO>;
  }
}
