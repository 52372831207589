import {Injectable} from '@angular/core';
import {BidDTO, OfferDTO} from '../../../global/interfaces';
import {DataService} from "../../../services/index";
import {ApiService} from "../../../global/services";

@Injectable()
export class AdminBidService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  submitAdminBid(bid: BidDTO): Promise<BidDTO> {
    const url = `${this.data.apiUrl}/api/listing/${bid.advertId}/bid`;
    return this.apiClient.post({url, data: bid}) as Promise<BidDTO>;
  }
}
