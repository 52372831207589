import {Injectable} from "@angular/core";
import {compare} from "fast-json-patch";
import {BaseSearchDTO, CustomerAttribDTO} from "../global/interfaces/index";
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";

@Injectable()
export class CustomerAttribService {

  private serviceUrl = "/api/customerAttrib";

  constructor(private data: DataService, private apiClient: ApiService) {
  }

  search(searchDTO?: BaseSearchDTO): Promise<any> {

    let url = `${this.data.apiUrl}${this.serviceUrl}s`;

    if (searchDTO != null && searchDTO.ignoreCache) {
      url += "?uniq=" + Math.floor(Math.random() * 10000);
    }

    return this.apiClient.get({url}) as Promise<any>;
  }

  create(customerAttrib: CustomerAttribDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: customerAttrib}) as Promise<any>;
  }

  patch(dto: CustomerAttribDTO, previousDTO: CustomerAttribDTO = {}): Promise<any> {
    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  put(customerId: string, attribId: number, dto: CustomerAttribDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/customer/${customerId}/attrib/${attribId}`;
    return this.apiClient.put({url, data: dto}) as Promise<any>;
  }

  delete(customerAttribId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerAttribId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }


}
