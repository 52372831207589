import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EventService} from '../../../../services';
import {compare} from 'fast-json-patch';
import {VehicleDTO} from "../../../../global/interfaces";
import {} from "../../../../global/services";
import {AdvertEventEnum} from "../../../../global/enums";

@Component({
  selector: 'app-vehicle-tyres',
  templateUrl: './vehicle-tyres.component.html',
  styleUrls: ['./vehicle-tyres.component.scss'],
})
export class VehicleTyresComponent implements OnInit {

  @Input() readOnly: boolean;
  @Input() vehicle: VehicleDTO;

  @Output() dataChanged: EventEmitter<any> = new EventEmitter();

  tyreDepths: { label: string, value: number }[] = [];
  form: UntypedFormGroup;
  formState: any;
  showTyreDepths: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private eventService: EventService) {
  }

  async ngOnInit() {

    // create lookup data for allowable tyre depths
    this.tyreDepths = [];

    for (let i = 0; i <= 8; i++) {
      this.tyreDepths.unshift({label: i + "mm", value: i});
    }
    this.tyreDepths.push({label: "N/A", value: null});

    this.form = this.formBuilder.group({
      tyreDepth_NSF: new UntypedFormControl(this.vehicle?.tyreDepth_NSF),
      tyreDepth_NSR: new UntypedFormControl(this.vehicle?.tyreDepth_NSR),
      tyreDepth_OSR: new UntypedFormControl(this.vehicle?.tyreDepth_OSR),
      tyreDepth_OSF: new UntypedFormControl(this.vehicle?.tyreDepth_OSF),
      tyreDepth_Spare: new UntypedFormControl(this.vehicle?.tyreDepth_Spare)
    });

    this.showTyreDepths = this.vehicle?.tyreDepth_NSF !== null || this.vehicle?.tyreDepth_NSR !== null ||
      this.vehicle?.tyreDepth_OSR !== null || this.vehicle?.tyreDepth_OSF !== null ||
      this.vehicle?.tyreDepth_Spare !== null;

    this.formState = this.form.value;

    this.form.valueChanges.subscribe(x => {
      const nsf = this.form.value.tyreDepth_NSF;
      const nsr = this.form.value.tyreDepth_NSR;
      const osr = this.form.value.tyreDepth_OSR;
      const osf = this.form.value.tyreDepth_OSF;
      const spare = this.form.value.tyreDepth_Spare;

      // raise an event with component name and current vehicle patch values as the args
      const object = compare(this.formState, this.form.value);
      this.eventService.AdvertActionEvent.emit({eventType: AdvertEventEnum.VehicleDetailsChangedEvent, object});
      this.formState = this.form.value;
    });
  }

  haveTyreDepths() {

    return this.showTyreDepths

  }
}
