import {PlatformDTO} from '../platformDTO.interface';
import {SaleTypeDTO} from './saleTypeDTO.interface';
import {LocationDTO} from '../locationDTO.interface';
import {AdvertDTO} from "../advertDTO.interface";
import {BaseDTO} from "../_baseDTO.interface";
import {AddressDTO} from "../addressDTO.interface";
import {LiveBiddingStatusEnum} from "../../../enums/live-bidding-status.enum";
import {SaleProfileDTO} from './saleProfileDTO.interface';

export interface SaleDTO extends BaseDTO {

  /* From Model */
  saleName?: string;
  saleStart?: string;
  saleEnd?: string;
  addressId?: string;
  address?: AddressDTO;
  platformId?: number;
  platform?: PlatformDTO;
  saleTypeId?: number;
  saleType?: SaleTypeDTO;
  adverts?: AdvertDTO[];
  advertCount?: number;
  currentAdvertId?: string;
  liveBiddingStatus?: LiveBiddingStatusEnum;
  saleProfileId?: number;
  saleProfile?: SaleProfileDTO;

  /* Used by Front End Only */
  startTime?: string;
  startDate?: string;
  endDate?: string;
  endTime?: string;
  isLive?: boolean;
}
