import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {SaleService} from "../../../services";
import {ModalDirective} from "ng-uikit-pro-standard";
import {AdminBrokerageService} from "../services/admin-brokerage.service";
import {BrokerageDTO, BrokerageSearchDTO, ContactDTO, SaleDTO, User} from '../../../global/interfaces';
import {BrokerageStateEnum, SaleTypeEnum} from '../../../global/enums';
import {HelpersService, ImageService, LoggerService, UserService} from '../../../global/services';
import {AdminUrlService, AdminWhosWhoService} from "../services/index";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";

@Component({
  templateUrl: './manage-brokerages.component.html',
  styleUrls: ['./manage-brokerages.component.scss'],
})
export class ManageBrokeragesComponent implements OnInit {

  constructor(
    public imageService: ImageService,
    public adminUrl: AdminUrlService,
    private logService: LoggerService,
    private saleService: SaleService,
    private brokerageService: AdminBrokerageService,
    private userService: UserService,
    private whosWhoService: AdminWhosWhoService,
    private helperService: HelpersService,
    private location: Location,
    private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      this.searchText = params.searchText;
      this.searchSaleId = params.searchSaleId ?? 0;
      this.searchBrokerageStates = params.searchBrokerageStates?.length > 0 ? params.searchBrokerageStates.split(',').map(Number) : [1, 2];
      this.searchBrokerageContactId = params.searchBrokerageContactId;

      console.log("BS ", this.searchBrokerageStates);
    });
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @ViewChild('notesModal') notesModal: ModalDirective;

  toastOpts = {opacity: 0.98};
  allUsers = "00000000-0000-0000-0000-000000000000";

  currentUser: User;
  brokerages: BrokerageDTO[] = [];
  siteAdminOptions: { label: string; value: string, statusId: number }[] = [];
  searchSaleId = 0;
  saleOptions: SaleDTO[] = [];
  saleTypeEnum = SaleTypeEnum;
  brokerageStates: any[] = [];

  searchBrokerageContactId: string;
  searchText: string;
  searchBrokerageStates: number[]; //  = [1, 2];
  loadingBrokerages = true;

  async ngOnInit() {

    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;

      this.searchBrokerageContactId = this.searchBrokerageContactId ?? this.currentUser.contactId;

      this.search();
    });

    this.brokerageStates = this.helperService.getLabelsAndValues(BrokerageStateEnum, true);
    this.loadAssignees();
    this.loadSales();
  }

  private loadAssignees() {
    this.whosWhoService.getSiteAdmins({component: 'whos-who'}).then(result => {

      this.siteAdminOptions = this.sortedAdminResult(result.results);
    });
  }

  private sortedAdminResult(items: ContactDTO[]) {
    return items.map(x => {
      return {label: x.contactName, value: x.id, statusId: x.statusId};
    }).sort((a, b) => {
      // First, compare by statusId
      if (a.statusId < b.statusId) {
        return -1;
      }
      if (a.statusId > b.statusId) {
        return 1;
      }
      // If statusId is equal, compare by contactName
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      // If both statusId and contactName are equal, return 0
      return 0;
    });
  }

  search() {

    this.loadingBrokerages = true;

    const dto = {
      component: 'brokerage',
      filters: {
        textSearch: this.searchText,
        brokerageStates: (this.searchBrokerageStates.length > 0 && this.searchBrokerageStates[0] != 0) ? this.searchBrokerageStates : [1,2],
        contactId: (this.searchBrokerageContactId != this.allUsers) ? this.searchBrokerageContactId : null,
        saleId: (this.searchSaleId != 0) ? this.searchSaleId : null
      }
    } as BrokerageSearchDTO;

    let params: string[] = [];
    let q = "";

    if (this.searchText) {
      params.push("searchText=" + this.searchText);
    }

    if (this.searchSaleId) {
      params.push("searchSaleId=" + this.searchSaleId);
    }

    if (this.searchBrokerageContactId) {
      params.push("searchBrokerageContactId=" + this.searchBrokerageContactId);
    }

    if (this.searchBrokerageStates) {
      params.push("searchBrokerageStates=" + this.searchBrokerageStates.join(","));
    }

    if (params.length > 0) {
      q = "?" + params.join("&");
    }

    this.location.replaceState(this.adminUrl.adminBrokerage(true), q);

    this.brokerageService.search(dto).then(xyz => {
      this.loadingBrokerages = false;
      this.brokerages = xyz.results;
    });

  }

  getState(brokerage: BrokerageDTO) {
    return BrokerageStateEnum[brokerage.brokerageStateId].replace(/([A-Z])/g, ' $1').trim();
  }

  clearSearch() {

    this.searchBrokerageContactId = this.currentUser.contactId;
    this.searchText = "";
    this.searchBrokerageStates = [1, 2];
    this.searchSaleId = 0;
  }

  private loadSales() {
    this.saleService.getSales().then(result => {
      this.saleOptions = result.sales.map(x => {
        return {
          saleName: x.saleName,
          saleStart: moment(x.saleStart).format("DD/MM/YY"),
          id: x.id,
          saleTypeId: x.saleTypeId,
          statusId: x.statusId
        };
      }).sort((a, b) => a.statusId - b.statusId);
    });
  }

  toggleUser() {

    if (this.searchBrokerageContactId == this.allUsers) {
      this.searchBrokerageContactId = this.currentUser.contactId;
    } else {
      this.searchBrokerageContactId = this.allUsers;
    }
  }
}
