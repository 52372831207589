import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {EventService, RoleGuardService, URLService, VehicleService} from '../../../services';
import {MessageDTO, SaleDTO, SaleSearchResultDTO, User} from '../../../global/interfaces';
import {LoggerService, UserService} from '../../../global/services';
import {LiveBiddingStatusEnum, MessageTypeEnum, UserRolesEnum, VehicleEventEnum} from '../../../global/enums';

@Component({
  selector: 'app-undernavbar',
  templateUrl: './app-undernavbar.component.html',
  styleUrls: ['./app-undernavbar.component.scss']
})


export class AppUnderNavbarComponent implements OnInit, AfterViewInit, OnDestroy {

  public user: User;
  public isLoggedIn = false;
  // tslint:disable-next-line:variable-name
  _underbarLinks: any[];
  private inMailEventSubscription: Subscription;

  @Input("underbarLinks") set underbarLinks(value: any[]) {
    this._underbarLinks = value ? value : this.auctionLinks;
  }

  get underbarLinks() {
    return this._underbarLinks;
  }

  @Input() hideLiveSales = false;
  @Input() wrongVersion = false;
  impersonating: boolean;
  liveSales: SaleDTO[];
  private contactEventSubscription: Subscription;
  sideBar: string;
  auctionLinks = [];

  constructor(
    private userService: UserService,
    public url: URLService,
    public router: Router,
    private vehicleService: VehicleService,
    private eventService: EventService,
    private logService: LoggerService,
    private logger2: NGXLogger,
    private authService: RoleGuardService
  ) {

    this.router = router;

    // console.log("LOADING CURRENT USER", new Date());

    this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
      this.isLoggedIn = this.user.isLoggedIn;
      this.impersonating = (this.user.impersonator != null);

      // console.log("LOADED CURRENT USER", new Date());

      this.setMenuItemsForUser();
    });

    this.auctionLinks = [
      {itemRoute: url.admin(true), itemLabel: "Admin", roles: [UserRolesEnum.Admin]},
      {itemRoute: url.dashboard(true), itemLabel: "Dashboard"},
      {itemRoute: url.search(true), itemLabel: "Buy", roles: [UserRolesEnum.Admin, UserRolesEnum.Buyer]},
      {
        itemRoute: 'EnterVRMEvent',
        itemLabel: "Sell",
        isClickable: true,
        roles: [UserRolesEnum.Admin, UserRolesEnum.Seller]
      },
      {itemRoute: url.stock(1, true), itemLabel: "Stock", roles: [UserRolesEnum.Admin, UserRolesEnum.Seller]},
      {itemRoute: url.myBids("1", "0", true), itemLabel: "Bids"},
      {itemRoute: url.mySearches(true), itemLabel: "Searches", roles: [UserRolesEnum.Admin, UserRolesEnum.Buyer]},
      {
        itemRoute: url.myWatchList(true),
        itemLabel: "Watchlist",
        roles: [UserRolesEnum.Admin, UserRolesEnum.Buyer],
        class: "d-none d-md-inline d-lg-inline"
      },
    ];

    // Listen to the router to see if we're on a full width including side-bar page
    // This will inform any indent/padding for undernav items
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        this.sideBar = (event.url === "/main/search") ? "side-bar" : "";
      }
    });
  }

  logger = this.logService.taggedLogger(this.constructor?.name);
  alert = false;

  async ngOnInit() {

  }

  async ngAfterViewInit() {

    this.contactEventSubscription = this.eventService.ContactEvent.subscribe((msg: MessageDTO) => {
      this.handleContactEvent(msg);
    });

    this.inMailEventSubscription = this.eventService.InMailEvent.subscribe((msg: MessageDTO) => {
      this.handleInmailEvent(msg);
    });
  }

  ngOnDestroy() {

  }

  private setMenuItemsForUser() {

    // filter out menu items based on user roles
    const items = [];
    this.auctionLinks.flatMap(x => ({menu: x, roles: x.roles})).forEach(x => {
      if (!x.roles || this.authService.HasRole(this.user, x.roles)) {
        items.push(x.menu);
      }
    });

    this.underbarLinks = items;
  }


  linkClicked(link) {
    switch (link.itemRoute) {
      case "EnterVRMEvent":
        this.eventService.VehicleActionEvent.emit({eventType: VehicleEventEnum.EnterVRMEvent});
        break;
    }
  }

  private handleContactEvent(msg: MessageDTO) {

    if (msg.messageType === MessageTypeEnum.LiveSalesUpdate) {

      this.processLiveSalesUpdateEvent(msg.messageData);
    }
  }

  private processLiveSalesUpdateEvent(messageData: SaleSearchResultDTO) {

    this.liveSales = messageData.sales.filter(x => x.liveBiddingStatus === LiveBiddingStatusEnum.Live ||
      x.liveBiddingStatus === LiveBiddingStatusEnum.Paused);

  }

  private handleInmailEvent(msg: MessageDTO) {

    if (msg.messageType === MessageTypeEnum.OfferAlert) {

      this.processOfferAlertEvent(msg.messageData);
    }

  }

  private processOfferAlertEvent(messageData: any) {
    this.alert = true;
  }
}
