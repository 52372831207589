import {ServiceHistoryTypeEnum} from "../../../../enums";
import {FuelTypeDTO} from "../../fuelTypeDTO.interface";
import {TransmissionTypeDTO} from "../../transmissionTypeDTO.interface";
import {BodyTypeDTO} from "../../bodyTypeDTO.interface";
import {AttribvalDTO} from "../../attribvalDTO.interface";

export interface AdvertViewVehicleInfoDTO {
  vrm: string;
  odometer?: number;
  colour: string;
  doors?: number;
  owners?: number;
  logBook?: boolean;
  serviceHistory?: boolean;
  serviceHistoryType?: ServiceHistoryTypeEnum;
  motExpires?: Date;
  engineCc?: number;
  noOfKeys?: number;
  dateOfReg?: Date;
  co2?: number;
  fuelType: FuelTypeDTO;
  transmissionType: TransmissionTypeDTO;
  bodyType: BodyTypeDTO;
  address: AdvertViewVehicleInfoAddressDTO;
  vatStatus: AttribvalDTO;
  hasMOTHistory?: boolean;
  hasServiceHistory?: boolean;
}

export interface AdvertViewVehicleInfoAddressDTO {
  addressName: string;
}
