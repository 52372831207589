import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PlatformService, SiteService} from '../../../../services';
import {StatusService} from '../../../../services';
import {MdbTablePaginationComponent, ModalDirective} from "ng-uikit-pro-standard";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {SiteDTO} from "../../../../global/interfaces";
import {HelpersService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";

@Component({
  selector: 'app-main',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss', '../../../../../../../common/components/app-side-menu/app-side-menu.component.scss'],
  providers: []
})
export class SiteMaintComponent implements OnInit {

  mdbTablePagination: MdbTablePaginationComponent;

  @ViewChild('siteModal') siteModal: ModalDirective;

  public siteList: SiteDTO[];
  public statusOptions: {}[];
  public statusName: string[] = [];
  public platformName: string[] = [];
  public platformOptions: {}[];
  public showDeleteConfirm: boolean;
  public siteIdToDelete: number;

  constructor(
    private siteService: SiteService,
    private statusService: StatusService,
    private platformService: PlatformService,
    private formBuilder: UntypedFormBuilder,
    private helpersService: HelpersService
  ) {
  }

  isLoading: boolean;
  submitted: boolean;
  isEdit = false;
  form: UntypedFormGroup;

  async ngOnInit() {

    await this.initForm();
    this.loadStatuses();
    this.loadPlatforms();
    this.loadSites();
  }

  async initForm() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      siteName: new UntypedFormControl(),
      platformId: new UntypedFormControl(),
      statusId: new UntypedFormControl(''),
    });

  }

  async loadSites() {

    this.siteService.getAll().then(sites => {
      this.siteList = sites;
    });
  }

  addSite() {

    this.form.patchValue(({
      id: '',
      siteName: '',
      platformId: '',
      statusId: StatusEnum.Active
    }));

    this.isEdit = false;

    // show modal that allows location name entry
    this.siteModal.show();
  }

  editSite($event: MouseEvent, siteDTO: SiteDTO) {

    this.form.patchValue(({
      ...siteDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.siteModal.show();
  }

  async saveSite() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const siteDTO = this.form.value;

    if (this.isEdit) {
      await this.siteService.patch(siteDTO);
    } else {
      delete siteDTO.id;
      await this.siteService.create(siteDTO);
    }

    this.siteModal.hide();
    await this.loadSites();
  }

  async loadStatuses() {

    const statuses = this.helpersService.getNamesAndValues(StatusEnum);

    this.statusOptions = statuses.map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadPlatforms() {
    await this.platformService.search().then(result => {
      this.platformOptions = result.map(x => {
        this.platformName[x.id] = x.platformName;
        return {label: x.platformName, value: x.id};
      });
    });
  }

  deleteSite(event, site: SiteDTO) {

    event.stopPropagation();
    this.siteIdToDelete = site.id;
    this.showDeleteConfirm = true;
  }

  hideModal() {
    this.siteModal.hide();
  }

  async confirmDelete() {

    await this.siteService.delete(this.siteIdToDelete);
    this.siteModal.hide();
    await this.loadSites();
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }

}

