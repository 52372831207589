import {UInspectQuestionOptionDTO} from "./uinspect-question-option-dto.interface";
import {UInspectAnswerSyncDTO} from "./uinspect-answer.interface";
import {UInspectMediaSyncDTO} from "./uinspect-media-dto.interface";
import { BaseDTO } from "../_baseDTO.interface";
import { MediaTypeEnum } from "../../../enums/index";

export interface UInspectQuestionDTO extends BaseDTO {
  uInspectSectionId?: number;
  questionText?: string;
  questionType?: number;
  questionMask?: string;
  minMedia?: number;
  maxMedia?: number;
  mediaType?: MediaTypeEnum;
  options?: UInspectQuestionOptionDTO[];
}

export interface UInspectQuestionSyncDTO extends UInspectQuestionDTO {
  answer?: UInspectAnswerSyncDTO;
  medias?: UInspectMediaSyncDTO[];
  synced?: boolean;
}

