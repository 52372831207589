import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {AuthGuardServiceInterface} from "../services";

export const InterceptorSkipHeader = "X-Skip-Interceptor";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private authguardService: AuthGuardServiceInterface) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({headers}));
    }

    if (request.url.indexOf("/api") > 0) {

      // console.log("INTERCEPTOR");

      return this.authguardService.getTokenObservable().pipe(
        mergeMap((token) => {

          // console.log("INTERCEPTOR TOKEN ", token);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });

          return next.handle(request);
        })
      );
    }
  }

}
