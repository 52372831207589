import { Injectable } from '@angular/core';
import { ApiService, DataServiceInterface } from '../../../global/services';
import {ImportCreateAdvertDTO} from "../interfaces/import-create-advert.dto.interface";
import {SimpleImportResultDTO} from "../interfaces/simple-import-result.dto.interface";

@Injectable()
export class AdminSimpleImportService {
  private serviceUrl = '/api/simple-import';

  constructor(private apiClient: ApiService, private data: DataServiceInterface) {}

  processImport(file: File, vendor: string): Promise<ImportCreateAdvertDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vendor}/process`;

    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.apiClient.post({
      url,
      data: formData,
      headers: {accept: 'application/json'},
    }) as Promise<ImportCreateAdvertDTO[]>;
  }

  importAdvert(vendor: string, dto: ImportCreateAdvertDTO) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vendor}/import`;

    return this.apiClient.post({
      url,
      data: dto,
      headers: {accept: 'application/json'},
    }) as Promise<SimpleImportResultDTO>;
  }
}
