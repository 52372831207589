export enum SortingFilter {
  AnyTime,
  OneHour,
  TwoHours,
  FourHours,
  EightHours,
  TwelveHours,
  TwentyFourHours,
  TwoDays,
  SevenDays = 8
}
