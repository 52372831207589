import {RemarqCustomerDTO} from "./remarqCustomerDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {OdometerUnitEnum} from "../../../enums";
import {PlateDTO} from "../plateDTO.interface";
import {BodyTypeDTO} from "../bodyTypeDTO.interface";
import {DerivDTO} from "../derivDTO.interface";
import {MakeDTO} from "../makeDTO.interface";
import {ModelDTO} from "../modelDTO.interface";
import {FuelTypeDTO} from "../fuelTypeDTO.interface";
import {TransmissionTypeDTO} from "../transmissionTypeDTO.interface";


export interface ScanVehicleDTO extends BaseIntDTO {

  errors?: number;
  cO2: string;
  doors?: number;
  vrm: string;
  title: string;
  odometer?: number;
  odometerUnit?: OdometerUnitEnum;
  colour: string;
  capId: string;
  capCode: string;
  phone: string;
  email: string;
  fullDesc: string;
  modelyear?: number;
  imageCount?: number;
  engineCC?: number;
  plateId?: number;
  plate?: PlateDTO;
  bodyTypeId?: number;
  body?: BodyTypeDTO;
  customer?: RemarqCustomerDTO;
  customerId?: number;
  deriv?: DerivDTO;
  derivId?: number;
  make?: MakeDTO;
  MakeId?: number;
  Model?: ModelDTO;
  modelId?: number;
  fuelTypeId?: number;
  fuelType?: FuelTypeDTO;
  detailUrl: string;
  primaryImageURL: string;
  transmissionTypeId?: number;
  transmissionType?: TransmissionTypeDTO;
  price?: number;
}
