import {BaseDTO} from "../_baseDTO.interface";
import {VehicleFaultCheckItemDTO} from "./vehicle-fault-check-item.dto.interface";
import {VehicleDTO} from "../Vehicle";
import {FaultCheckTypeDTO} from "./fault-check-type.dto.interface";

export interface VehicleFaultCheckDTO extends BaseDTO {
  vehicleId?: string;
  vehicle?: VehicleDTO;
  inspectDate?: Date;
  odometer?: number;
  freeText?: string;
  faultCheckItems?: VehicleFaultCheckItemDTO[];
  faultCheckTypeId?: number;
  faultCheckType?: FaultCheckTypeDTO;
}
