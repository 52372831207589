import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminComponent} from '../components/admin/admin.component';
import {
  VehicleLookupImporterComponent
} from "../components/admin/vehicle/vehicle-lookup-importer/vehicle-lookup-importer.component";
import {AdminVehicleCheckerComponent} from "../components/admin/vehicle/vehicle-checker/vehicle-checker.component";
import {AdminVehicleViewComponent} from "../components/admin/vehicle/vehicle-view/vehicle-view.component";
import {AdminDashboardComponent} from "../components/admin/dashboard/admin-dashboard.component";
import {CustomerEditComponent} from "../components";
import {ContactSearchComponent} from "../components";
import {CustomerSearchComponent} from "../components/admin/customer/customer-search/customer-search.component";
import {MmdComponent} from "../components/admin/maintenance/alt/mmd/mmd.component";
import {AttribMaintComponent} from "../components/admin/maintenance/attrib/attrib.component";
import {AttribvalMaintComponent} from "../components/admin/maintenance/attribval/attribval.component";
import {CountryProductComponent} from "../components/admin/maintenance/country-product/country-product.component";
import {CountryComponent} from "../components/admin/maintenance/country/country.component";
import {ProductComponent} from "../components/admin/maintenance/product/product.component";
import {TaxComponent} from "../components/admin/maintenance/tax/tax.component";
import {SiteMaintComponent} from "../components/admin/maintenance/site/site.component";
import {PlatformComponent} from "../components/admin/maintenance/platform/platform.component";
import {AdminSaleSearchComponent} from "../components/admin/sale/sale-search/sale-search.component";
import {AdminSaleEditComponent} from "../components/admin/sale/sale-edit/sale-edit.component";
import {AdminSaleViewComponent} from "../components/admin/sale/sale-view/sale-view.component";
import {UnreconciledComponent} from '../components/admin/accounts/unreconciled/unreconciled.component';
import {VehicleHistoryComponent} from '../components/admin/vehicle/vehicle-history/vehicle-history.component';
import {ManageAuctionsComponent} from '../components/admin/sale/manage-auctions/manage-auctions.component';
import {AdminSaleAuctioneerComponent} from "../components/admin/sale/sale-auctioneer/sale-auctioneer.component";
import {ManageNegotiationsComponent} from '../components/admin/sale/manage-negotiations/manage-negotiations.component';
import {AdminAttendeesComponent} from '../components/admin/sale/attendees/admin-attendees.component';
import {InvoicesComponent} from '../components/admin/accounts/invoices/invoices.component';
import {AdminSaleProfilesComponent} from '../components/admin/sale/profiles/admin-sale-profiles.component';
import {RoleGuardService as AuthGuard} from 'projects/remarq/src/app/services/authguard.service';
import {OffersSearchComponent} from '../components/admin/offers-search/offers-search.component';
import {DealSearchComponent} from "../components/admin/deal/deal-search/deal-search.component";
import {AdvertAdminViewComponent} from "../components/admin/advert-admin-view/advert-admin-view.component";
import {FaultCheckMaintComponent} from "../components/admin/maintenance/fault-check/fault-check-maint.component";
import {WhosWhoComponent} from "../components/admin/whos-who/whos-who.component";
import {ManageBrokeragesComponent} from "../components/admin/manage-brokerages/manage-brokerages.component";
import {AdminTasksViewComponent} from "../components/admin/admin-tasks-view/admin-tasks-view.component";
import {AdminCallRecordsComponent} from "../components/admin/admin-call-records/admin-call-records.component";
import {AdminCustomerViewComponent} from "../components/admin/customer/customer-view/customer-view.component";
import {SiteAdminSearchComponent} from "../components/admin/site-admin/site-admin-search.component";
import {SimpleImporterComponent} from "../components/admin/import/simple-importer/simple-importer.component";
import {AdminAccountCreatorComponent} from "../components/admin/admin-account-creator/admin-account-creator.component";
import {
  AdminMessagesToSellersComponent
} from "../components/admin/admin-messages-to-sellers/admin-messages-to-sellers.component";
import {AdminExtLeadComponent} from "../components/admin/admin-ext-leads/admin-ext-lead.component";

const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  component: AdminComponent,
  data: {
    roles: ['ADMIN']
  },
  children: [
    {
      path: 'deals',
      children: [
        {path: ':daysAgo', component: DealSearchComponent},
        {path: '', component: DealSearchComponent}
      ],
    },
    {
      path: 'vehicles',
      children: [
        {path: 'import', component: VehicleLookupImporterComponent},
        {path: 'checker', component: AdminVehicleCheckerComponent},
        {path: 'history', component: VehicleHistoryComponent},
        {path: 'history/:vehicleId', component: VehicleHistoryComponent},
        {path: ':vehicleId', component: AdminVehicleViewComponent}
      ]
    },
    {
      path: 'accounts',
      children: [
        {path: 'unreconciled', component: UnreconciledComponent},
        {path: 'invoices', component: InvoicesComponent},
      ]
    },
    {path: '', component: AdminDashboardComponent},
    {path: 'account-creator', component: AdminAccountCreatorComponent},
    {
      path: 'customer',
      children: [
        {path: 'create', component: CustomerEditComponent},
        {
          path: ':customerId',
          children: [
            {path: '', component: AdminCustomerViewComponent},
            {path: 'contacts', component: ContactSearchComponent}
          ]
        }
      ],
    },
    {
      path: 'import-data',
      children: [
        {path: ':vendor', component: SimpleImporterComponent}
      ]
    },
    {
      path: 'ext-leads',
      component: AdminExtLeadComponent
    },
    {
      path: 'customers',
      children: [
        {path: '', component: CustomerSearchComponent},
        {
          path: ':statusId',
          children: [
            {path: '', component: CustomerSearchComponent}
          ]
        }
      ]
    },
    {
      path: 'maintenance',
      children: [
        {
          path: 'alt',
          children: [
            {path: "mmd", component: MmdComponent},
          ]
        },
        {path: 'attrib', component: AttribMaintComponent},
        {
          path: 'attribval', children: [
            {path: ":attribId", component: AttribvalMaintComponent},
          ]
        },
        {path: 'country-product', component: CountryProductComponent},
        {path: 'country', component: CountryComponent},
        {path: 'product', component: ProductComponent},
        {path: 'tax', component: TaxComponent},
        {path: 'site', component: SiteMaintComponent},
        {path: 'platform', component: PlatformComponent},
        {path: 'fault-check', component: FaultCheckMaintComponent},
      ]
    },
    {
      path: 'admin-sales',
      children: [
        {path: '', component: AdminSaleSearchComponent},
        {path: 'attendees', component: AdminAttendeesComponent},
        {path: 'profiles', component: AdminSaleProfilesComponent},
        {path: 'create', component: AdminSaleEditComponent},
        {
          path: ':saleId',
          children: [
            {path: '', component: AdminSaleViewComponent},
            {path: 'edit', component: AdminSaleEditComponent},
            {path: 'auctioneer', component: AdminSaleAuctioneerComponent},
          ]
        }
      ]
    },
    {
      path: 'manage-auctions',
      children: [
        {path: '', component: ManageAuctionsComponent},
      ]
    },
    {
      path: 'manage-negotiations',
      children: [
        {path: ':advertId', component: ManageNegotiationsComponent}
      ]
    },
    {
      path: 'comms',
      loadChildren: () => import('./comms.module').then(m => m.CommsModule)
    },
    {
      path: 'offers',
      children: [
        {path: '', component: OffersSearchComponent},
      ]
    },
    {
      path: 'admin-listings',
      children: [
        {path: '', component: AdvertAdminViewComponent},
      ]
    },
    {
      path: 'whos-who',
      children: [
        {path: ':setTab', component: WhosWhoComponent},
        {path: ':setTab/:page', component: WhosWhoComponent},
      ]
    },
    {
      path: 'brokerage',
      children: [
        {path: '', component: ManageBrokeragesComponent},
      ]
    },
    {
      path: 'messages-to-seller',
      children: [
        {path: '', component: AdminMessagesToSellersComponent},
      ]
    },
    {
      path: 'site-admin-search',
      children: [
        {path: '', component: SiteAdminSearchComponent},
      ]
    },
    {
      path: 'admin-tasks',
      children: [
        {path: '', component: AdminTasksViewComponent}
      ]
    },
    {
      path: 'call-records',
      children: [
        {path: '', component: AdminCallRecordsComponent}
      ]
    },
    {
      path: 'scan',
      loadChildren: () => import('./site-scan.module').then(m => m.SiteScanModule)
    },
  ]
}];

/*
 */

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class AdminRoutingModule {
}
