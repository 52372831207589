import {AdvertDTO} from "../advertDTO.interface";
import {BaseResultDTO} from "./baseResultDTO.interface";
import {AdvertViewVehicleInfoDTO} from "../Components";

export interface AdvertSearchResultDTO extends BaseResultDTO {
  advert?: AdvertDTO;
  adverts?: AdvertDTO[];
  advertViewVehicleInfo?: AdvertViewVehicleInfoDTO;
}

export interface SellerCountsDTO {

  counts: {};

}
