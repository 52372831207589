import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {LoggerService} from "../../services/index";
import AdvancedMarkerElement = google.maps.marker.AdvancedMarkerElement;

@Component({
  selector: 'app-google-maps-script',
  templateUrl: './google-maps-script.component.html',
})
export class GoogleMapsScriptComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line:variable-name
  _latitude: number | undefined;
  private map: any;

  @Input('latitude') set latitude(value: any) {

    this._latitude = value;
    // this.updateCentre();
  }

  get latitude() {
    return this._latitude;

  }

  // tslint:disable-next-line:variable-name
  _longitude: number | undefined;
  @Input('longitude') set longitude(value: any) {

    this._longitude = value;
    // this.updateCentre();
  }

  get longitude() {
    return this._longitude;
  }

  // tslint:disable-next-line:variable-name
  _zoom: number | undefined;
  @Input('zoom') set zoom(value: any) {

    this._zoom = value;
    this.updateZoom();
  }

  get zoom() {
    return this._zoom;

  }

  @Input() streetViewControl = true;
  @Input() mapTypeControl = true;
  @Input() fullscreenControl = true;

  // tslint:disable-next-line:variable-name
  _markers: AdvancedMarkerElement[] | undefined;
  @Input('markers') set markers(value: any) {

    this._markers = value;
    this.updateMarkers();
  }

  get markers() {
    return this._markers;
  }

  // tslint:disable-next-line:variable-name
  _placeIds: string[] = [];
  @Input('placeIds') set placeIds(value: string[]) {
    this._placeIds = value;

    this.redrawMap();
  }

  get placeIds() {
    return this._placeIds;
  }

  // tslint:disable-next-line:variable-name
  _featureType = "ADMINISTRATIVE_AREA_LEVEL_2";
  @Input('featureType') set featureType(value: string) {
    this._featureType = value;
  }

  get featureType() {
    return this._featureType;
  }

  constructor(private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  ngAfterViewInit() {

  }

  async ngOnInit() {


    // @ts-ignore
    const [{Map, Markers}] = await Promise.all([
      google.maps.importLibrary("maps"),
      google.maps.importLibrary("marker")
    ]);

    this.map = new Map(document.getElementById("map"), {
      mapId: "866f0268d4e1a46b",
      streetViewControl: this.streetViewControl,
      mapTypeControl: this.mapTypeControl,
      fullscreenControl: this.fullscreenControl
    });

    this.updateCentre();
    this.updateZoom();
    this.updateMarkers();

    const featureStyleOptions = {
      strokeColor: "#4E93D1",
      strokeOpacity: 1.0,
      strokeWeight: 3.0,
      fillColor: "#4E93D1",
      fillOpacity: 0.5,
    };

    const featureLayer = this.map.getFeatureLayer("ADMINISTRATIVE_AREA_LEVEL_2");

    featureLayer.style = (options: any) => {
      if (this.placeIds.includes(options.feature.placeId)) {
        return featureStyleOptions;
      }
    };
  }

  private updateCentre() {
    if (this.map != null) {
      this.map.setCenter({lat: this.latitude ?? 52.33, lng: this.longitude ?? -1.940936});
    }
  }

  private updateZoom() {
    if (this.map != null) {
      this.map.setZoom(this.zoom ?? 6);
    }
  }

  private redrawMap() {
    if (this.map != null) {
      this.map.resize();
    }
  }

  private updateMarkers() {

    if (this.map != null && this.markers?.length > 0) {

      this.markers
        .filter((x: any) => x.position != null && x.position.lat != null && x.position.lng != null)
        .forEach((latlng: any) => {
          const marker = new google.maps.marker.AdvancedMarkerElement(
            {map: this.map, position: latlng.position}
          );
        });
    }
  }
}
