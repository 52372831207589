import {CustomerGroupDTO} from "./customerGroupDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {AdminTaskDTO} from "./adminTaskDTO.interface";
import {CustomerDTO} from './customerDTO.interface';
import {CustomerNoteDTO} from './customerNotesDTO.interface';
import {ContactActionDTO} from './contactActionDTO.interface';
import {AddressDTO} from './addressDTO.interface';
import {CustomerInternalInfoDTO} from "./customerInternalInfoDTO.interface";
import {CustomerTypeEnum} from "../../enums";
import {CustomerMediaDTO} from "./CustomerDocuments";
import {ScanCustomerDTO} from "./site-scan/scanCustomerDTO.interface";

export interface CustomerAdminDTO extends BaseDTO {
  customerGroupId?: string;
  parentcustomerId?: string;
  billingcustomerId?: string;
  email?: string;
  websiteUrl?: string;
  phone1?: string;
  customerName?: string;
  isBuyer?: boolean;
  isSeller?: boolean;
  scanCustomerId?: number;
  billingCustomer?: CustomerDTO;
  parentCustomer?: CustomerDTO;
  customerGroup?: CustomerGroupDTO;
  scanCustomer?: ScanCustomerDTO;
  adminTasks?: AdminTaskDTO[];
  primaryContactId?: string;
  primaryContact?: ContactDTO;
  lastAdvert?: Date;
  lastLogin?: Date;
  totalAdverts?: number;
  liveAdverts?: number;
  vehiclesBought?: number;
  vehiclesSold?: number;
  vatNumber?: string;
  userCount?: number;
  customerNotes?: CustomerNoteDTO[];
  contactActions?: ContactActionDTO[];
  addresses?: AddressDTO[];
  vendorPrefix?: string;
  buyingLimit?: number;
  contacts?: ContactDTO[];
  customerInternalInfo?: CustomerInternalInfoDTO;
  customerType?: CustomerTypeEnum;
  customerMedias?: CustomerMediaDTO[];
}
