import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {compare} from "fast-json-patch";
import {DataServiceInterface} from "../interfaces";
import { UInspectSectionDTO } from '../../interfaces/DTOs/UInspect';
import {ApiService} from "../api.service";
import {UInspectSectionSearchDTO} from "../../interfaces/DTOs/UInspect/API";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";

@Injectable()
export class UInspectSectionService {

  private serviceUrl = '/api/uinspect/section';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
    private toast: ToastService,
  ) {
    // this.logging.componentName = "UInspectSectionService";
  }

  public search(query?: UInspectSectionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  public get(id: number, query: UInspectSectionSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  public post(dto: UInspectSectionDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: number, patchData: UInspectSectionDTO, previousValue: UInspectSectionDTO = {}):
    Promise<ValidatedResultDTO> {
    const data = compare(previousValue, patchData);

    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: number): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }
}
