import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {VehicleService} from "../../../../services";
import {AdvertSearchSummaryVehicleDTO, AdvertSearchSummaryProvenanceDTO} from "../../../../global/interfaces";

@Component({
  selector: 'app-advert-vehicle-hpi',
  templateUrl: './advert-vehicle-hpi.component.html',
  styleUrls: ['./advert-vehicle-hpi.component.scss']
})
export class AdvertVehicleHpiComponent implements OnInit, OnChanges {

  @Input() vehicle: AdvertSearchSummaryVehicleDTO;

  provenance: AdvertSearchSummaryProvenanceDTO;
  tooltip: string;


  constructor(private vehicleService: VehicleService) {
  }

  ngOnInit(): void {
  }


  async OnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {

    if (changes.vehicle) {

      this.provenance = this.vehicle.latestProvenance;

      this.tooltip = "";
      if (this.provenance?.finance) {
        this.tooltip += "Vehicle has outstanding finance<br/>";
      }
      if (this.provenance?.stolen) {
        this.tooltip += "Vehicle is reported stolen<br/>";
      }
      if (this.provenance?.scrapped) {
        this.tooltip += "Vehicle has been scrapped<br/>";
      }
      if (this.provenance?.vcaR_Damage) {
        this.tooltip += "Vehicle is an insurance write-off<br/>";
      }
    }
  }
}
