import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdminAdvertService} from "../../services";
import {
  AddressService,
  AdvertService, LocaleService,
  EventService,
  SaleService,
  URLService,
  VehicleService
} from '../../../../services';
import {AdvertLottedDTO} from "../../interfaces/advertLottedDTO.interface";
import {AdminSaleService} from "../../services";
import {AdminUrlService} from "../../services";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {compare} from "fast-json-patch";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {
  AddressSearchResultDTO,
  AdvertDTO,
  AdvertSearchResultDTO,
  CustomerSearchResultDTO, IOption,
  SaleDTO,
  SaleSearchResultDTO,
  VehicleDTO
} from "../../../../global/interfaces";
import {CustomerService, LoggerService} from "../../../../global/services";
import {AdvertStatusEnum, SoldStatusEnum} from "../../../../global/enums";


@Component({
  selector: 'app-sale-view',
  templateUrl: './sale-view.component.html',
  styleUrls: ['./sale-view.component.scss'],
})

export class AdminSaleViewComponent implements OnInit {
  private perPage = 10;
  private page = 1;
  private previousLotted: AdvertLottedDTO[];
  unlottedWait = false;
  loadingLots: boolean;
  beforeAdvert: AdvertDTO;
  beforeVehicle: VehicleDTO;
  today: string;
  soldStatusEnum = SoldStatusEnum;
  advertStatusEnum = AdvertStatusEnum;

  constructor(
    private addressService: AddressService,
    private saleService: SaleService,
    private advertService: AdvertService,
    private customerService: CustomerService,
    private vehicleService: VehicleService,
    private adminSaleService: AdminSaleService,
    private adminAdvertService: AdminAdvertService,
    private route: ActivatedRoute,
    public adminUrl: AdminUrlService,
    public url: URLService,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    public localeService: LocaleService,
    private logService: LoggerService
  ) {

    this.saleId = this.route.snapshot.params.saleId;

    const now = new Date();
    this.today = now.toISOString();
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading = false;
  saleId: string;
  sale: SaleDTO;
  mode = "showLots";
  adverts: AdvertDTO[];
  checkAll = false;
  advertsLottable: AdvertLottedDTO[] = [];
  public addresses: any;
  public vendors: IOption[];
  filterForm: UntypedFormGroup;

  ngOnInit() {
    this.initFilterForm();
    this.loadSale(this.saleId);
    this.loadSaleLots(this.saleId);
    this.loadVendors();
  }

  onDrop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.adverts, event.previousIndex, event.currentIndex);

    const updates = [];

    let lotSeq = 1;

    this.adverts.map(x => x.lotSeq = (lotSeq++));

    this.adverts.forEach((x) => {
      updates.push({advertId: x.id, lotSeq: x.lotSeq, lotNo: x.lotNo, customerId: x.customerId});
    });

    this.adminAdvertService.setLotNums(updates).then(() => {
    });

  }

  loadSale(saleId: string) {

    this.loadingLots = true;

    this.saleService.getSale(this.saleId, {component: "AdminViewSaleLots"}).then((data: SaleSearchResultDTO) => {

      if (data) {
        this.sale = data.sale;
        this.loadingLots = false;
      }
    });
  }

  loadVendors() {

    this.customerService.search({component: "AdminSaleViewAddLots"}).then((data: CustomerSearchResultDTO) => {
      this.vendors = data.customers
        .sort((x, y) => {
          return x.customerName.localeCompare(y.customerName);
        })
        .map((x) => {
          return {value: x.id, label: x.customerName};
        });

      this.vendors.unshift({value: "", label: "All Vendors"});
    });
  }

  loadSaleLots(saleId: string) {

    this.isLoading = true;

    this.advertService.search({
      filters: {saleId},
      component: "AdminViewSaleLots"
    }).then((data: AdvertSearchResultDTO) => {

      if (data) {
        this.adverts = data.adverts;

        this.logger.info("Loaded sale lots", this.adverts);
      }
      this.isLoading = false;
    });
  }

  get f() {
    return this.filterForm?.controls;
  }

  openAddLots(): Promise<any> {

    this.unlottedWait = true;
    this.checkAll = false;
    this.mode = "addLots";

    const offset = (this.perPage * (this.page - 1));

    return this.loadLottableAds(offset);
  }

  loadLottableAds(offset: number) {

    return this.advertService.search({
      component: "AdminViewSaleLottable",
      offset,
      limit: this.perPage,
      filters: {
        // TODO: Only the vendors we've chosen above
        customerId: this.f.customerId.value,
        addressId: this.f.addressId.value
      }
    }).then((result: AdvertSearchResultDTO) => {

      this.advertsLottable = result.adverts?.map(x => {
        return {advert: x, lotted: false};
      });

      this.previousLotted = JSON.parse(JSON.stringify(this.advertsLottable));
      this.unlottedWait = false;
    });
  }

  openShowLots() {

    this.mode = "showLots";
    this.loadSaleLots(this.saleId);
  }

  toggleCheck(event) {

    this.advertsLottable.forEach((x, index) => {

      const element = document.getElementById("checkbox-" + index);

      x.lotted = this.checkAll;

      if (x.lotted) {
        element.setAttribute("checked", "checked");
      } else {
        element.removeAttribute("checked");
      }

    });

  }

  updateSaleLots() {

    const updates = [];

    this.advertsLottable.forEach((x) => {
      const previous = this.previousLotted.filter(y => y.advert.id === x.advert.id)[0];
      if (previous.lotted !== x.lotted) {
        updates.push({advertId: x.advert.id, customerId: x.advert.customerId, saleId: (x.lotted) ? this.saleId : null});
      }
    });

    this.unlottedWait = true;

    this.adminSaleService.setSaleLots(updates).then(() => {

      this.openAddLots().then(() => {
      });

    });
  }

  editAdvert(id: string) {
    window.open(this.url.advertEdit(id, true), "_blank");
  }

  unlot(advert: AdvertDTO) {

    this.adverts = this.adverts.filter(x => x.id !== advert.id);

    const updates = [];
    updates.push({advertId: advert.id, customerId: advert.customerId, saleId: advert.saleId, unlot: true});

    this.adminSaleService.setSaleLots(updates).then(() => {

    });
  }

  autoLot() {


    const updates = [];
    let lotSeq = 0;

    // Group lots into customers
    const vendorList = this.adverts.map(x => x.customerId).filter((value, index, self) => self.indexOf(value) === index);

    vendorList.forEach((customerId) => {

      // Sequence within the customer
      let custLotSeq = 1;

      this.adverts.filter(x => x.customerId === customerId).forEach((x) => {

        const lotNo = x.vehicle.customer.vendorPrefix + (custLotSeq++).toString().padStart(3, "0");
        lotSeq++;

        updates.push({advertId: x.id, lotNo, lotSeq, customerId: x.customerId});

        x.lotNo = lotNo;
        x.lotSeq = lotSeq;
      });
    });

    this.adverts = this.adverts.sort((a, b) => {
      return a.lotSeq - b.lotSeq;
    });

    this.logger.debug("UPDATES ", updates);
    this.logger.debug("NEWLIST  ", this.adverts);


    this.adminAdvertService.setLotNums(updates).then(() => {
    });
  }

  logNumEntry() {

  }

  storeAdvertBeforeState(advert: any) {
    this.beforeAdvert = Object.assign({}, advert);
  }

  patchAdvertStateDifference(advert: AdvertDTO) {
    const patch = compare(this.beforeAdvert, advert);
    this.advertService.patchAdvert(advert.id, patch).then(() => {
    });
  }

  storeVehicleBeforeState(advert: AdvertDTO) {
    this.beforeVehicle = Object.assign({}, advert.vehicle);
  }

  patchVehicleStateDifference(advert: AdvertDTO) {
    const patch = compare(this.beforeVehicle, advert.vehicle);
    this.vehicleService.patchVehicle(advert.vehicleId, patch).then(() => {
    });
  }

  private initFilterForm() {

    this.filterForm = this.formBuilder.group({
      customerId: new UntypedFormControl(),
      addressId: new UntypedFormControl()
    });


  }

  vendorChange(event: any) {

    if (event.value !== "") {
      this.loadLocations(event.value);
    } else {
      this.addresses = [];
      this.filterForm.patchValue({addressId: null});
    }
  }

  private loadLocations(customerId?: string) {

    this.addressService
      .search({component: "AdminSaleViewAddLots", filters: {customerId}})
      .then((data: AddressSearchResultDTO) => {
        this.addresses = data.addresses
          .sort((x, y) => {
            return x.addressName.localeCompare(y.addressName);
          })
          .map((x) => {
            return {value: x.id, label: x.addressName};
          });
      });
  }

  goLive() {

  }

  openSuggestedLots() {
    // raise event to open the suggested vehicles dialog
    this.eventService.ShowSaleProfileSuggestedVehicles.emit(this.sale);
  }

  resetEndDates(saleId: string) {

    this.adminSaleService.resetLotEndDates(saleId).then((x) => {
      this.loadSaleLots(this.saleId);
    });
  }
}
