export * from "./advert-view-bid-box.dto.interface";
export * from "./advert-view-vehicle-info.dto.interface";
export * from "./advert-view-vehicle-media.dto.interface";
export * from "./advert-view-vehicle-tyres.dto.interface";
export * from "./advert-view-advert-location.dto.interface";
export * from "./advert-view-vehicle-valuation.dto.interface";
export * from "./advert-view-vehicle-provenance.dto.interface";
export * from "./advert-view-vehicle-service-history.dto.interface";
export * from "./advert-view-data.dto.interface";
export * from "./advert-view-contact-seller.dto.interface";
