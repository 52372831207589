import {Injectable} from '@angular/core';
import {
  BidDTO,
  ProspectDTO, ProspectNoteDTO,
  ProspectSearchDTO, SaveProspectDTO, SaveProspectNoteDTO,
  TypedSearchResultDTO,
  TypedValidatedResultDTO
} from '../../../global/interfaces';
import { ApiService } from '../../../global/services';
import {DataService} from "../../../services/index";

@Injectable()
export class AdminProspectService {

  private serviceUrl = '/api/prospect';


  constructor(private apiClient: ApiService, private data: DataService) {
  }

  getAdvertProspects(advertId: string, query: ProspectSearchDTO): Promise<TypedSearchResultDTO<ProspectDTO>> {
    const url = `${this.data.apiUrl}/api/listing/${advertId}/prospects`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<ProspectDTO>>;
  }

  suggestAdvertProspects(advertId: string, query: ProspectSearchDTO): Promise<TypedSearchResultDTO<ProspectDTO>> {
    const url = `${this.data.apiUrl}/api/listing/${advertId}/prospects/suggest`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<ProspectDTO>>;
  }

  saveProspectNote(dto: SaveProspectNoteDTO): Promise<TypedValidatedResultDTO<ProspectNoteDTO>> {
    const url = `${this.data.apiUrl}/api/brokerage/${dto?.brokerageId}/prospect-note`;
    return this.apiClient.post({url, data: dto}) as Promise<TypedValidatedResultDTO<ProspectNoteDTO>>;
  }

  submitProspectBid(dto: BidDTO): Promise<TypedValidatedResultDTO<ProspectDTO>> {
    const url = `${this.data.apiUrl}/api/listing/${dto.advertId}/prospect-bid`;
    return this.apiClient.post({url, data: dto}) as Promise<TypedValidatedResultDTO<ProspectDTO>>;
  }

  patchProspectNote(prospectId: string, prospectHistoryId: string, patchDTO): Promise<TypedValidatedResultDTO<ProspectNoteDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${prospectId}/note/${prospectHistoryId}`;
    return this.apiClient.patch({url, data: patchDTO}) as Promise<TypedValidatedResultDTO<ProspectNoteDTO>>;
  }

  patchProspect(prospectId: string, patchDTO): Promise<TypedValidatedResultDTO<ProspectDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${prospectId}`;
    return this.apiClient.patch({url, data: patchDTO}) as Promise<TypedValidatedResultDTO<ProspectDTO>>;
  }

  createProspect(dto: SaveProspectDTO): Promise<TypedValidatedResultDTO<ProspectDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<TypedValidatedResultDTO<ProspectDTO>>;
  }

  createProspectForAdvert(dto: SaveProspectDTO): Promise<TypedValidatedResultDTO<ProspectDTO>> {
    const url = `${this.data.apiUrl}/api/listing/${dto.advertId}/prospect`;
    return this.apiClient.post({url, data: dto}) as Promise<TypedValidatedResultDTO<ProspectDTO>>;
  }
}
