export enum SaleTypeEnum {
  BuyItNow = 1,
  ManagedSale = 2,
  TimedAuction = 3,
  Underwrite = 4
}

export const SaleTypeName = [
  "Unknown",
  "Buy It Now",
  "Live Sale",
  "Timed Auction",
  "Underwrite"
];
