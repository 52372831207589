import {OdometerUnitEnum} from "../../../enums/odometerUnit.enum";

export interface VehicleLookupInfoDTO {
  vrm: string;
  makeName: string;
  modelName: string;
  derivName: string;
  engineCC: string;
  transmissionTypeName: string;
  bodyTypeName: string;
  fuelTypeName: string;
  plateName: string;
  dateRegistered: string;
  doors: string;
  vehicleTypeName: string;
  colour: string;
  cO2: string;
  imported: string;
  weight: string;
  euroStatus: string;
  previousKeepers: string;
  bhp: string;
  odometer: number;
  odometerUnit: OdometerUnitEnum;
  primaryImageURL: string;
}

