import {Component, Input, OnInit} from '@angular/core';
import {EventService, VehicleMediaService} from '../../../services';
import * as moment from 'moment';
import {VehicleMediaDTO} from "../../../global/interfaces";
import {HelpersService} from "../../../global/services";
import {AdvertEventEnum, MediaTypeEnum, VehicleEventEnum} from "../../../global/enums";

@Component({
  selector: 'app-media-thumbnail-viewer',
  templateUrl: './media-thumbnail-viewer.component.html',
  styleUrls: ['./media-thumbnail-viewer.component.scss']
})
export class MediaThumbnailViewerComponent implements OnInit {

  constructor(
    private eventService: EventService,
    private vehicleMediaService: VehicleMediaService,
    private helperService: HelpersService) {
  }

  @Input() medias: VehicleMediaDTO[];
  @Input() readOnly: boolean;
  @Input() imageHeight: number;

  public MediaType = MediaTypeEnum;

  mediaToDelete: VehicleMediaDTO;

  ngOnInit(): void {
    if (this.medias) {
      this.medias = this.medias.sort((a, b) => moment(a.added).diff(moment(b.added)));
    }
  }

  viewImage(mediaURL: string) {
    // raise event to view image at full size
    // there should be an image-viewer component that can handle this
    // note: the reason for this is that using a modal to show full-size image, if this component is already in a modal, does not work
    this.eventService.AdvertActionEvent.emit({eventType: AdvertEventEnum.ViewImageFullSizeEvent, object: mediaURL});
  }

  deleteMedia(event, media: VehicleMediaDTO) {
    this.mediaToDelete = media;
    const msg = `Are you sure you want to remove this image?`;

    this.helperService.confirmationDialog("Confirm Delete", msg, 'Yes', 'No', true)
      .subscribe(result => {
        if (result) {
          this.vehicleMediaService.deleteVehicleMedia(this.mediaToDelete.id).then(() => {
            // raise event so parent component can remove media from list
            this.eventService.VehicleActionEvent.emit({eventType: VehicleEventEnum.DetailMediaDeletedEvent, object: this.mediaToDelete.id});
          });
        }
      });
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

}
