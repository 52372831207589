import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {BaseSearchDTO, ProductDTO} from '../global/interfaces';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class ProductService {

  private serviceUrl = '/api/product';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  search(searchDTO?: BaseSearchDTO): Promise<any> {

    let url = `${this.data.apiUrl}${this.serviceUrl}s`;

    if (searchDTO != null && searchDTO.ignoreCache) {
      url += "?uniq=" + Math.floor(Math.random() * 10000);
    }

    return this.apiClient.get({url}) as Promise<any>;
  }

  create(product: ProductDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: product}) as Promise<any>;
  }

  patch(dto: ProductDTO, previousDTO: ProductDTO = {}): Promise<any> {

    const patch = compare(previousDTO , dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.id}`;
    return this.apiClient.patch({ url, data: patch}) as Promise<any>;
  }

  delete(productId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${productId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
