import {
  BaseDTO,
  BodyTypeDTO, ContactDTO,
  DerivDTO,
  FuelTypeDTO,
  MakeDTO,
  ModelDTO,
  PlateDTO,
  TransmissionTypeDTO, VehicleFaultCheckDTO
} from "../index";
import {CustomerDTO} from "../customerDTO.interface";
import {AdvertDTO} from "../advertDTO.interface";
import {AttribvalDTO} from "../attribvalDTO.interface";
import {AppraisalDTO} from "../appraisalDTO.interface";
import {VehicleMediaDTO} from "./vehicleMediaDTO.interface";
import {VehicleAttribDTO} from "./vehicleAttribDTO";
import {AddressDTO} from "../addressDTO.interface";
import {MotHistoryDTO} from "../motHistoryDTO.interface";
import {ServiceHistoryTypeEnum} from "../../../enums";
import {ServiceHistoryDTO} from "../serviceHistoryDTO.interface";
import {VehicleActionHistoryDTO} from "./vehicleActionHistoryDTO.interface";
import {VehicleCheckDTO} from "./vehicleCheckDTO.interface";
import {VehicleColourDTO} from "./vehicleColourDTO";

export interface VehicleDTO extends BaseDTO {
  vrm?: string;
  colour?: string;
  customerId: string;
  customerRef?: string;
  motExpires?: Date;
  dateOfReg?: Date;
  doors?: number;
  odometer?: number;
  owners?: number;
  engineCc?: number;
  vin?: string;
  equipment?: string;
  co2?: number;
  primaryImageId: string;
  primaryImageURL?: string;
  makeId?: number;
  modelId?: number;
  derivId?: number;
  transmissionTypeId?: number;
  fuelTypeId?: number;
  plateId?: number;
  bodyTypeId?: number;
  vatStatusId?: number;
  v5StatusId: number;
  customer?: CustomerDTO;
  contact?: ContactDTO;
  advert?: AdvertDTO;
  make?: MakeDTO;
  model?: ModelDTO;
  deriv?: DerivDTO;
  transmissionType?: TransmissionTypeDTO;
  fuelType?: FuelTypeDTO;
  plate?: PlateDTO;
  bodyType?: BodyTypeDTO;
  vatStatus?: AttribvalDTO;
  appraisals: AppraisalDTO[];
  vehicleMedia: VehicleMediaDTO[];
  conditionReports: VehicleMediaDTO[];
  v5Media: VehicleMediaDTO[];
  walkaround?: VehicleMediaDTO;
  serviceBookMedia: VehicleMediaDTO[];
  vehicleAttribs: VehicleAttribDTO[];
  adverts?: AdvertDTO[];
  kerbweight?: number;
  runner?: number;
  grade?: number;
  logBook?: boolean;
  addressId?: string;
  address?: AddressDTO;
  serviceHistory?: boolean;
  lastService?: Date;
  tyreDepth_NSF: number;
  tyreDepth_NSR: number;
  tyreDepth_OSF: number;
  tyreDepth_OSR: number;
  tyreDepth_Spare: number;
  motHistory?: MotHistoryDTO[];
  standInValue: number;
  noOfKeys: number;
  bhp: number;
  imageCount?: number;
  vehicleTypeId: number;
  serviceHistoryType: ServiceHistoryTypeEnum;
  serviceHistories: ServiceHistoryDTO[];
  actionHistory: VehicleActionHistoryDTO[];
  latestAdvert: AdvertDTO;
  latestValuation: VehicleCheckDTO;
  latestProvenance: VehicleCheckDTO;
  vehicleColour: VehicleColourDTO;
  hasMOTIssues: boolean;
  latestVehicleFaultCheckId?: string;
  latestVehicleFaultCheck?: VehicleFaultCheckDTO;
}
