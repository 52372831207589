import {BaseDTO} from "../../_baseDTO.interface";

export interface EnquiryInmailDTO extends BaseDTO {
  advertId: string;
  toContactId: string;
  toContact: EnquiryMailContactDTO;
  fromContactId: string;
  fromContact: EnquiryMailContactDTO;
  subject: string;
  body: string;
}

export interface EnquiryMailContactDTO {
  contactName: string;
  email: string;
  phone1: string;
  phone2: string;
}
