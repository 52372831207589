import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {DataService} from "./data.service";
import { ApiService } from '../global/services/index';
import {BaseSearchDTO, CountryDTO} from '../global/interfaces';

@Injectable()
export class CountryService {

  private countryServiceUrl = '/api/Country';

  constructor(private apiClient: ApiService, private data: DataService) { }

  create(dto: CountryDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.countryServiceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<any>;
  }

  patch(countryDTO: CountryDTO, previousDTO: CountryDTO = {}): Promise<any> {

    const patch = compare(previousDTO, countryDTO);

    const url = `${this.data.apiUrl}${this.countryServiceUrl}/${countryDTO.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  search(searchDTO?: BaseSearchDTO): Promise<CountryDTO[]> {

    let url = `${this.data.apiUrl}/api/Countries`;

    if (searchDTO != null && searchDTO.ignoreCache) {
      url += "?uniq=" + Math.floor(Math.random() * 10000);
    }

    return this.apiClient.get({url}) as Promise<CountryDTO[]>;
  }

  delete(countryId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.countryServiceUrl}/${countryId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
