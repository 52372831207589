import {MediaDTO} from "../../mediaDTO.interface";
import {AttribvalDTO} from "../../attribvalDTO.interface";
import {ServiceHistoryTypeEnum} from "../../../../enums";

export interface AdvertViewDataDTO {
  id: string;
  statusId: number;
  customerId: string;
  advertStatus: number;
  saleTypeId: number;
  makeName: string;
  modelName: string;
  derivName: string;
  bodyTypeName: string;
  plateName: string;
  fuelTypeName: string;
  transmissionTypeName: string;
  vrm: string;
  ggg: number;
  odometer: number;
  currentPrice: number;
  topBidGuid?: string;
  latestVehicleFaultCheckId?: string;
  runner: boolean;
  description: string;
  engineCc: number;
  colour: string;
  doors?: number;
  owners?: number;
  logBook?: boolean;
  vatStatusName: string;
  serviceHistoryType?: ServiceHistoryTypeEnum;
  v5StatusName?: string;
}
