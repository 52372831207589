// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-print {
  font-size: 12px;
  color: #aaa;
  line-height: 26px;
}

.pair-label {
  font-size: 0.6rem;
  line-height: 1.5;
  font-weight: 500;
  white-space: nowrap;
}

.pair-value {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  background-color: var(--chipBackgroundColour);
  border: 1px solid var(--chipBorderColour);
  border-radius: 12px;
}

.valuation-item {
  max-width: 125px;
  flex-grow: 1;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-valuation/vehicle-valuation.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,6CAAA;EACA,yCAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,kBAAA;AACF","sourcesContent":[".small-print {\n  font-size: 12px;\n  color: #aaa;\n  line-height: 26px;\n}\n\n.pair-label {\n  font-size: 0.6rem;\n  line-height: 1.5;\n  font-weight: 500;\n  white-space: nowrap;\n}\n\n.pair-value {\n  font-size: 13px;\n  line-height: 24px;\n  font-weight: 500;\n  background-color: var(--chipBackgroundColour);\n  border: 1px solid var(--chipBorderColour);\n  border-radius: 12px;\n}\n\n.valuation-item {\n  max-width: 125px;\n  flex-grow: 1;\n  text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
