export interface BaseUser {
  email?: string;
  isLoggedIn?: boolean;
  userName?: string;
  name?: string;
  roles?: string[];
  statusId?: number;
  impersonate?: object;
  impersonator?: string;
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
}
