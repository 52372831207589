import {BaseDTO} from "../index";
import {ContactDTO} from "../contactDTO.interface";
import {LeadVehicleDTO} from "../../../../crm/src/app/interfaces/index";
import {AdvertDTO} from "../advertDTO.interface";
import {MovementAddressDTO} from "./movement-address-dto.interface";
import {MovementStatusEnum} from "../../../enums/movements/movement-status.enum";
import {MovementProviderEnum} from "../../../enums/movements/movement-provider.enum";
import { BaseSearchDTO } from "projects/common/interfaces/DTOs/API";

export interface MovementDTO extends BaseDTO {

  advert?: AdvertDTO;
  advertId?: number;
  leadVehicle?: LeadVehicleDTO;
  leadVehicleId?: string;
  provider?: MovementProviderEnum;
  movementStatus?: MovementStatusEnum;
  pickupAddressId?: number;
  pickupAddress?: MovementAddressDTO;
  dropoffAddressId?: number;
  dropoffAddress?: MovementAddressDTO;
  providerPrice?: number;
  platformPrice?: number;
  bookedByContact?: ContactDTO;
  pickupEarliest?: string;
  pickupLatest?: string;
  dropoffEarliest?: string;
  dropoffLatest?: string;
  driverLat?: number;
  driverLon?: number;
  driverName?: string;
  driverPhone1?: string;
  driverPhone2?: string;
}
