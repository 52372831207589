import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-side-menu',
  templateUrl: './app-side-menu.component.html',
  styleUrls: ['./app-side-menu.component.scss']
})
export class AppSideMenuComponent implements OnInit {
  @Input() menuItems: {
    subMenu: {
      itemRoute: string,
      itemLabel: string
    }[],
    itemRoute: string,
    line: number,
    itemLabel: string,
    class: string
    badge?: number
    badgeClass?: string
  } [];

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public router: Router
  ) {
  }

  ngOnInit(): void {
  }
}
