import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {DataService, URLService} from "../../../services";
import {ApiService} from "../../../global/services";
import {compare} from "fast-json-patch";
import {
  AdminTaskDTO,
  CustomerDTO,
  CustomerNoteDTO,
  CustomerNoteSearchDTO,
  NewCustomerNoteDTO
} from "../../../global/interfaces";

@Injectable()
export class AdminCustomerService {

  private serviceUrl = '/api/customer';

  constructor(
    public urlService: URLService,
    private apiClient: ApiService,
    private data: DataService,
  ) {
  }

  getAdminDetails(customerId: string): Promise<CustomerDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/adminDetails`;
    return this.apiClient.get({url}) as Promise<CustomerDTO>;
  }

  patch(customerId: string, dto: CustomerDTO, previousDTO: AdminTaskDTO = {}) {

    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}`;
    return this.apiClient.patch({url, data: patch});
  }

  searchCustomerNotes(dto: CustomerNoteSearchDTO): Promise<CustomerNoteDTO[]> {

    let url = `${this.data.apiUrl}${this.serviceUrl}/${dto.filters.customerId}/notes`;

    url += "?query=" + encodeURIComponent(JSON.stringify(dto));

    return this.apiClient.get({url}) as Promise<CustomerNoteDTO[]>;
  }

  saveCustomerNote(customerId: string, dto: NewCustomerNoteDTO): Promise<any> {

    if (typeof (dto.sleepUntil) == "string") {
      dto.sleepUntil = new Date(dto.sleepUntil) ?? null;
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/note`;
    return this.apiClient.post({url, data: dto});
  }
}
