import {SaleProfileDTO} from './saleProfileDTO.interface';
import {SavedSearchDTO} from '../saved-searchDTO.interface';
import {BaseDTO} from '../_baseDTO.interface';

export interface SaleSearchProfileDTO extends BaseDTO {
  savedSearchId: string;
  savedSearch?: SavedSearchDTO;

  saleProfileId: string;
  saleProfile?: SaleProfileDTO;
}
