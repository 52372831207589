import {BaseDTO} from "./_baseDTO.interface";
import {ContactDTO} from "./contactDTO.interface";

export interface InviteDTO extends BaseDTO {
  customerId: string;
  contactId: string;
  contact: ContactDTO;
  email: string;
  roles: string[];
  comment: string;
  invitedByEmail: string;
  invitedByName: string;
}
