import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {compare} from 'fast-json-patch';
import {DataService} from "./data.service";
import {ApiService, LoggerService} from "../global/services/index";
import {BaseSearchDTO, SaleAttendeeDTO, SaleDTO, SaleSearchDTO, SaleSearchResultDTO} from '../global/interfaces';


@Injectable()
export class SaleService {

  private serviceUrl = '/api/sale';

  constructor(private apiClient: ApiService,
              private data: DataService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  /*
  public getSales(date: Date): Observable<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.httpClient.get<any>(url);
  }
  */

  public getSales(saleSearchDTO?: BaseSearchDTO): Promise<SaleSearchResultDTO> {

    let queryString = 's';

    if (saleSearchDTO != null) {
      const queryParams = new HttpParams().set('query', JSON.stringify(saleSearchDTO));
      queryString += '?' + queryParams.toString();
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}${queryString}`;
    return this.apiClient.get({url}) as Promise<SaleSearchResultDTO>;
  }

  public getSale(id: string, saleSearchDTO?: SaleSearchDTO): Promise<SaleSearchResultDTO> {

    let url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;

    if (saleSearchDTO != null) {
      const queryParams = new HttpParams().set('query', JSON.stringify(saleSearchDTO));
      url += '?' + queryParams.toString();
    }

    return this.apiClient.get({url}) as Promise<SaleSearchResultDTO>;
  }

  public createSale(formData: any): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    this.logger.log('Posting to: ', url, ' - object: ', formData);
    return this.apiClient.post({url, data: formData}) as Promise<any>;
  }

  public patchSale(saleId, formData: any, previous?: any): Promise<any> {

    const patch = compare(previous != null ? previous : {}, formData);

    this.logger.log('PATCH ', patch);

    const url = `${this.data.apiUrl}${this.serviceUrl}/${saleId}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  public getSalesByVRM(vrm: string): Promise<SaleDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/salesByVRM`;

    const params = new HttpParams({
      fromObject: {vrm}
    });

    return this.apiClient.get({url, headers: {accept: 'application/json'}, data: {params}}) as Promise<any>;
  }

  public getSaleAttendees(saleId: string): Promise<SaleAttendeeDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${saleId}/saleAttendees`;
    return this.apiClient.get({url}) as Promise<SaleAttendeeDTO[]>;
  }

  public addSaleAttendee(dto: SaleAttendeeDTO): Promise<SaleAttendeeDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.saleId}/saleAttendee`;
    return this.apiClient.post({url, data: dto}) as Promise<SaleAttendeeDTO>;
  }

  public removeSaleAttendee(id: string) {
    const url = `${this.data.apiUrl}/api/saleAttendee/${id}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public getSaleLotCount(saleId: string): Promise<number> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${saleId}/lot-count`;
    return this.apiClient.get({url}) as Promise<number>;
  }
}
