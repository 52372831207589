import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AdvertService, EventService, URLService} from '../../../../../services';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import * as moment from 'moment';
import {AdvertDTO, ErrorDTO, User} from "../../../../../global/interfaces";
import {LoggerService, UserService, ImageService, HelpersService} from "../../../../../global/services";
import {SaleTypeEnum, SoldStatusEnum, VehicleEventEnum} from "../../../../../global/enums";

@Component({
  selector: 'app-seller-hub-vehicle-search',
  templateUrl: './seller-hub-vehicle-search.component.html',
  styleUrls: ['./seller-hub-vehicle-search.component.scss']
})
export class SellerHubVehicleSearchComponent implements OnInit, OnDestroy {

  @Input() soldStatus: SoldStatusEnum;
  @Output() advertCount = new EventEmitter();

  @ViewChild('endSaleModal') endSaleModal: ModalDirective;

  public fg: UntypedFormGroup;
  public filterForm: UntypedFormGroup;
  public endListingForm: UntypedFormGroup;
  relistingAdvertId: string;

  endListingReasons: any[];
  downloadingCSV = false;
  perPage = 10;
  page = 1;
  pages: object[];

  constructor(private userService: UserService,
              public redirect: URLService,
              private fb: UntypedFormBuilder,
              private advertService: AdvertService,
              private router: Router,
              private eventService: EventService,
              private toast: ToastService,
              public imageService: ImageService,
              private logService: LoggerService,
              private helperService: HelpersService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  public soldStatusEnum = SoldStatusEnum;

  public saleTypeEnum = SaleTypeEnum;
  isLoading = true;
  keywords: string;
  adverts: AdvertDTO[] = [];
  user: User;

  toastOpts = {opacity: 0.98};
  reloadStockSub: Subscription;

  endSaleAdvertId: string;

  showDeleteConfirm = false;
  deleteAdvertId: string;

  async ngOnInit() {

    this.fg = this.fb.group({
      keywords: ['']
    });

    this.filterForm = new UntypedFormGroup({
      keywords: new UntypedFormControl('', Validators.required),
    });

    this.endListingForm = this.fb.group({reasonId: [1, Validators.required]});

    await this.userService.loadCurrentUser().then(() => {

      this.user = this.userService.CurrentUser;

      this.searchAdverts();
    });

    const reasons = await this.advertService.getCancelListingReasons();

    this.endListingReasons = reasons.map(x => {
      return {label: x.reason, value: x.id};
    });

    this.reloadStockSub = this.eventService.ReloadStockTab.subscribe(soldStatus => {
      if (this.soldStatus == soldStatus) {
        this.searchAdverts();
      }
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.reloadStockSub) {
      this.reloadStockSub.unsubscribe();
    }
  }

  async ngOnView() {

  }

  async viewAdvert(advertId: string) {
    this.redirect.advertEdit(advertId);
  };

  createPages(results, perPage) {
    const pageCount = Math.floor(results / perPage) + (results % perPage > 0 ? 1 : 0);

    this.pages = [];

    for (let i = 1; i <= pageCount; i++) {
      this.pages.push({page: i});
    }
  }

  get f() {
    return this.fg.controls;
  }

  get ff() {
    return this.filterForm.controls;
  }

  searchAdverts() {

    // this.loadAdvertCounts();

    const keywords = this.ff.keywords.value;
    const customerId = this.user.customerId;

    this.advertService.getCustomerAdverts(customerId, {
      component: "SellerHub",
      offset: (this.perPage * (this.page - 1)),
      limit: this.perPage,
      filters: {
        keywords,
        soldStatus: this.soldStatus
      }
    }).then(result => {
      this.isLoading = false;
      this.adverts = result.adverts;
      this.createPages(result.count, this.perPage);
    });
  }

  addVehicle() {
    this.eventService.VehicleActionEvent.emit({eventType: VehicleEventEnum.EnterVRMEvent});
  }

  reserveMetIcon(advert: AdvertDTO) {


    if (advert?.reserveMet) {
      return "<i class='fa fa-check reserve-met text-success'></i>";
    }

    return "-";
  }

  createAdvert() {
    this.eventService.VehicleActionEvent.emit({eventType: VehicleEventEnum.EnterVRMEvent});
  }

  relistAdvert(event, advertId) {

    this.relistingAdvertId = advertId;

    event.stopPropagation();

    // confirm relisting will create a Draft advert copy
    const msg = `This process will create a copy of the Advert in 'Draft' state to be edited, ok to continue?`;

    this.helperService.confirmationDialog("Confirm Relist", msg, 'Yes', 'No')
      .subscribe(result => {
        if (result) {
          this.advertService.doRelistProcess(advertId);
        }
        else {
          this.relistingAdvertId = null;
        }
      });
  }

  deleteAdvert(event, advertId: string) {
    event.stopPropagation();

    this.deleteAdvertId = advertId;
    this.showDeleteConfirm = true;
  }

  confirmDeleteAdvert() {

    this.advertService.deleteAdvert(this.deleteAdvertId).then(result => {
      this.toast.info("Draft Advert Deleted", "Success", this.toastOpts);
      this.searchAdverts();
    });
  }

  endAdvert(event, advertId) {
    event.stopPropagation();

    this.endSaleAdvertId = advertId;
    this.endSaleModal.show();
  }

  confirmEndSale() {
    this.endSaleModal.hide();

    // attempt to end the lot (set status to Withdrawn)
    this.advertService.cancelListing(this.endSaleAdvertId, this.endListingForm.value.reasonId).then(() => {
      this.toast.info("Listing Ended", "Success", this.toastOpts);

      // reload this tab
      this.searchAdverts();

      // reload withdrawn tab
      this.eventService.ReloadStockTab.emit(SoldStatusEnum.Withdrawn);

    }).catch(err => {
      this.logger.error("Error updating advert", err);

      const dto = err.error as ErrorDTO;
      this.toast.error(`An error occurred attempting to end the sale, quote error code ${dto.Code}`, "Error", this.toastOpts);
    });
  }

  cancelEndSale() {
    this.endSaleModal.hide();
  }

  downloadCSV() {
    // export adverts to csv
    this.downloadingCSV = true;

    const date = moment().format("DDD_dd_MM_yyyy_hh_mm");
    const fileName = `${this.user.customerName}_${SoldStatusEnum[this.soldStatus]}Vehicles_${date}.csv`;

    this.advertService.exportAdvertData(this.soldStatus, fileName).then(() => {
      this.downloadingCSV = false;
    }).catch(error => {
      this.toast.error(`An error occurred attempting to export the data`, "Error", this.toastOpts);
      this.logger.error("CSV Export error: ", error);
      this.downloadingCSV = false;
    });
  }

  goToPage(num: number) {

    this.page = num;
    this.searchAdverts();

  }

  listingPending(advert: AdvertDTO) {

    return advert.availableDate && new Date(advert.availableDate) > new Date();

  }

  viewListing($event: MouseEvent, id: string) {
    this.redirect.advertView(id)
  }
}
