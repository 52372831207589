// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-items {
  font-weight: 400;
}

.contact-details {
  font-size: 0.875rem;
  padding-left: 20px;
}

.contact-header {
  font-size: 24px;
  font-weight: 700
}
`, "",{"version":3,"sources":["webpack://./projects/common/components/contact-us/contact-us.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf;AACF","sourcesContent":[".row-items {\n  font-weight: 400;\n}\n\n.contact-details {\n  font-size: 0.875rem;\n  padding-left: 20px;\n}\n\n.contact-header {\n  font-size: 24px;\n  font-weight: 700\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
