import {BaseSearchDTO} from "./API";
import {BaseDTO} from "./_baseDTO.interface";
import {CustomerDTO} from "./customerDTO.interface";

export interface AdminExtLeadDTO extends BaseDTO {
  source?: number;
  company: string;
  location: string;
  postcode: string;
  last30Days: number;
  contactEmail: string;
  contactPhone: string;
  contactName: string;
  customerId: string;
  customer: CustomerDTO;

  haveNote: boolean;
  notes: string;

  liveAds?: number;
  lastAd?: string;
  cars: string;
}

export interface AdminExtLeadSearchDTO extends BaseSearchDTO {
  filters?: {
    source?: number;
    company?: string;
    location?: string;
    postcode?: string;
    last30Days?: number;
    contactEmail?: string;
    contactPhone?: string;
    contactName?: string;
    customerId?: string;
    haveNote?: boolean;
    note?: string;
    liveAds?: number;
    lastAd?: string;
    cars?: string;
  };
}


export interface AdminExtLeadVehicleDTO extends BaseDTO {

  extLeadId: string;
  vehicle: string;
  mileage?: number;
  year?: number;

  retailPrice?: number;
  tradePrice?: number;
  margin?: number;
  saleType?: number;

}
