import {AdvertSearchCount} from '../advertSearchCount';
import {SphAdvertDTO, SphAdvertDTO_Public} from '../../sphAdvertDTO.interface';
import {AdvertSearchSummaryAdvertDTO} from "../../Components";

export class AdvertSearchCountList {
  [key: string]: AdvertSearchCount[];
}

export class AdvertSearchResultsDTO {
  listings: SphAdvertDTO_Public[];
  advertSearchSummaryAdverts: AdvertSearchSummaryAdvertDTO[];

  adminListings: SphAdvertDTO[];
  searchCounts: AdvertSearchCountList;
  totalResults: number;
}
