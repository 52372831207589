import {VehicleFaultCheckDTO} from "./vehicle-fault-check.dto.interface";
import {FaultItemDTO} from "./fault-item.dto.interface";
import {FaultStatusDTO} from "./fault-status.dto.interface";
import {BaseDTO} from "../_baseDTO.interface";

export interface VehicleFaultCheckItemDTO extends BaseDTO{
  vehicleFaultCheckId: string;
  vehicleFaultCheck?: VehicleFaultCheckDTO;
  faultItemId: number;
  faultItem?: FaultItemDTO;
  faultStatusId: number;
  faultStatus?: FaultStatusDTO;
  freeText: string;
}
