import {BaseIntDTO} from "../_baseIntDTO.interface";
import {FaultItemDTO} from "./fault-item.dto.interface";

export interface FaultCategoryDTO extends BaseIntDTO {
  name?: string;
  description?: string;
  allowClaims?: boolean;
  faultItems?: FaultItemDTO[];
  sequence?: number;
}
