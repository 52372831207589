import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {URLServiceInterface} from '../../../uinspect/src/app/global/services/interfaces';

@Injectable({providedIn: 'root'})
export class UInspectURLService implements URLServiceInterface {

  constructor(
    public router: Router
  ) {
  }

  placeHolderURL(placeHolderString: string, returnOnly: boolean = false) {
    return this.commonReturn("/assets/images/" + placeHolderString, returnOnly);
  }

  commonReturn(path, returnOnly) {
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
      return true;
    }
  }

  redirect(path, params = null, replaceUrl = false) {

    this.router.navigate([path], {replaceUrl, queryParams: params}).then(() => {
    });
  }

  login(returnOnly: boolean = false, toUrl: boolean = false) {
    return this.commonReturn("login", returnOnly);
  }

  dashboard(returnOnly: boolean = false) {
    return this.commonReturn("dashboard", returnOnly);
  }

  homepage(returnOnly: boolean = false) {
    return this.commonReturn("", returnOnly);
  }

  formatList(returnOnly: boolean = false) {
    return this.commonReturn("/main/formats", returnOnly);
  }

  formatView(id: number, returnOnly: boolean = false) {
    return this.commonReturn("/main/format/" + id, returnOnly);
  }

  contact(returnOnly: boolean = false) {
    return this.commonReturn("contact-us", returnOnly);
  }

  logout(returnOnly: boolean = false) {
    return this.commonReturn("logout", returnOnly);
  }

  startTestInspection(formatId: number, returnOnly: boolean = false) {
    return this.commonReturn("/main/format/" + formatId + "/test", returnOnly);
  }
}
