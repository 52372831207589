import {ValuationPillarDTO} from './valuation-pillar-dto.interface';
import {ValuationNodeDTO} from './valuation-node-dto.interface';
import {BaseDTO, BaseSearchDTO} from "../../../../uinspect/src/app/global/interfaces/index";


export interface ValuationProfileDTO extends BaseDTO {
  name?: string;
  valuationPillars?: ValuationPillarDTO[];
  valuationNodes?: ValuationNodeDTO[];
  isDefault?: boolean;
}
