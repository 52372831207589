import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DataService, URLService} from '../../../services';
import {} from "../../../global/interfaces";
import {ApiService} from "../../../global/services";
import {} from "../../../global/enums";

@Injectable()
export class AdminSaleService {

  constructor(
    private router: Router,
    private apiClient: ApiService,
    private data: DataService,
    public urlService: URLService
  ) {
  }

  private serviceUrl = '/api/sale';

  setSaleLots(update): Promise<any> {
    const url = this.serviceUrl + '/setLots';
    return this.apiClient.post({url: `${this.data.apiUrl}${url}`, data: update}) as Promise<any>;
  }

  setCurrentLot(saleId, advertId): Promise<any> {
    const url = this.serviceUrl + `/${saleId}/currentLot/${advertId}`;
    return this.apiClient.get({url: `${this.data.apiUrl}${url}`}) as Promise<any>;
  }

  startSale(saleId: string): Promise<any> {

    return this.apiClient.get({url: `${this.data.apiUrl}${(this.serviceUrl)}/${saleId}/start`}) as Promise<any>;
  }

  resetLotEndDates(saleId: string): Promise<any> {

    return this.apiClient.get({url: `${this.data.apiUrl}${(this.serviceUrl)}/${saleId}/resetLotEndDates`}) as Promise<any>;
  }

  pauseSale(saleId: string): Promise<any> {

    return this.apiClient.get({url: `${this.data.apiUrl}${(this.serviceUrl)}/${saleId}/pause`}) as Promise<any>;
  }

  skipLot(saleId: string, advertId: string): Promise<any> {

    return this.apiClient.get({url: `${this.data.apiUrl}${(this.serviceUrl)}/${saleId}/skipLot/${advertId}`}) as Promise<any>;
  }

  endSale(saleId: string): Promise<any> {

    return this.apiClient.get({url: `${this.data.apiUrl}${(this.serviceUrl)}/${saleId}/end`}) as Promise<any>;
  }
}
