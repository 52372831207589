import {ScanFieldDTO} from "./scanFieldDTO.interface";
import {ScanStyleDTO} from "./scanStyleDTO.interface";
import {ScanStageDTO} from "./scanStageDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanConfigDTO extends BaseIntDTO {

  scanCode?: string;

  scanSequence?: number;

  scanStage?: ScanStageDTO;

  scanStageId?: number;

  scanField?: ScanFieldDTO;

  scanFieldId?: number;

  scanStyle?: ScanStyleDTO;

  scanStyleId?: number;
}
