import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AdvertService, AdviewService, EventService, WatchlistService} from "../../../../services";
import {URLService} from "../../../../services";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {
  AdvertSummaryDTO,
  AdvertViewDataDTO,
  MessageDTO,
  User,
} from "../../../../global/interfaces";
import {LoggerService, UserService} from "../../../../global/services";
import {
  MessageTypeEnum,
  SaleTypeEnum,
  SaleTypeName, ServiceHistoryTypeEnum,
} from "../../../../global/enums";
import {AppComponent} from "../../../app/app.component";
import {GlobalConstants} from "../../../../global/shared";
import { DecodeHtmlEntitiesPipe } from 'projects/common/pipes';

@Component({
  selector: 'app-advert-view',
  templateUrl: './advert-view.component.html',
  styleUrls: ['./advert-view.component.scss'],
  providers: [DecodeHtmlEntitiesPipe]
})
export class AdvertViewComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private advertService: AdvertService,
    private watchlistService: WatchlistService,
    private route: ActivatedRoute,
    public url: URLService,
    private titleService: Title,
    private userService: UserService,
    private adviewService: AdviewService,
    private logService: LoggerService,
    private eventService: EventService
  ) {
    this.advertId = this.route.snapshot.params.id;
  }

  public user: User;
  public isWatched = false;
  public saleTypeEnum = SaleTypeEnum;
  public isDevice = GlobalConstants.IsDevice;

  advert: AdvertViewDataDTO;

  advertId = '';
  private advertEventSubscription: Subscription;
  saleTypeName = "";

  public serviceHistoryType = ServiceHistoryTypeEnum;

  logger = this.logService.taggedLogger(this.constructor?.name);

  ngOnDestroy(): void {
  }

  async ngOnInit() {

    const hideDivIds = AppComponent.globals.hideDivIds;

    if (hideDivIds) {
      hideDivIds.forEach(x => {
        const element = document.getElementById(x);
        if (element) {
          element.hidden = true;
        }
      });
    }

    this.getAdvert().then(() => {
      this.scrollToTop();
    });

    this.userService.loadCurrentUser()
      .then(() => {
        this.user = this.userService.CurrentUser;
      })
      .then(() => {
        this.watchlistService.loadWatchlistItems(this.user.contactId);
      })
      .then(() => {
        this.isWatched = this.watchlistService.advertIsWatched(this.advertId, this.user.contactId);
      });
  }

  async ngAfterViewInit() {

    this.scrollToTop();

    this.advertEventSubscription = this.eventService.AdvertEvent.subscribe((msg: MessageDTO) => {
      this.handleAdvertEvent(msg);
    });

    this.adviewService.recordView(this.advertId).then(() => {
    });
  }

  scrollToTop() {

    const top = document.getElementById('page-container');

    if (top !== null && !GlobalConstants.IsDevice) {
      top.scrollIntoView();
    }
  }

  handleAdvertEvent(msg: any) {

    if (msg.messageType == MessageTypeEnum.AdvertUpdate) {

      this.processAdvertUpdateEvent(msg.data);
    }
  }


  getAdvert() {

    this.logger.debug("Loading advert using id", this.advertId);

    return this.advertService
      .getAdvertViewData(this.advertId, {component: "AdvertView"})
      .then((result: any) => {

        this.advert = result.dto;
        this.logger.debug("Loaded advert", this.advert);

        this.saleTypeName = SaleTypeName[this.advert.saleTypeId];

        const title =
          this.advert.plateName + " " +
          this.advert.makeName + " " +
          this.advert.modelName + " " +
          this.advert.vrm
        ;

        this.titleService.setTitle(title);
      })
      .catch((err) => {
        this.logger.error("Error occurred loading advert", err);
      });
  }

  getOdometerUnits() {

    return "miles";
  }


  private processAdvertUpdateEvent(data: AdvertSummaryDTO) {

    if (data && data.id) {
      this.advert.advertStatus = data.advertStatus;
      this.advert.statusId = data.statusId;
    }
  }


}
