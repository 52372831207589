import {BidDTO} from './bidDTO.interface';

export interface VehicleBidDTO {
  advertId: string;
  vrm: string;
  primaryImageURL?: string;
  make: string;
  model: string;
  deriv: string;
  odometer?: number;
  bids?: BidDTO[];
}
