import {AdvertDTO} from '../advertDTO.interface';
import {SavedSearchDTO} from '../saved-searchDTO.interface';
import {CustomerDTO} from '../customerDTO.interface';

export interface ISuggestedVehicle {
  searchProfile: SavedSearchDTO;
  advertData: IAdvertData[];
  loading: boolean;
}

export interface IAdvertData {
  advert: AdvertDTO;
  customer: CustomerDTO;
}
