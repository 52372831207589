import {BaseSearchDTO} from "./baseSearchDTO.interface";

export class CustomerSearchDTO extends BaseSearchDTO {
  filters?: {
    customerName: string;
    contactName: string;
    email: string;
    statusId: number;
  };
}
