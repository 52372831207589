// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overdue {
  font-weight: bold;
  color: var(--errorColour);
}

.reminder {
  font-weight: bold;
}

.assigned-to {
  font-weight: 600;
  line-height: 0.875rem;
}

.sortable {
  cursor: pointer;
  position: relative;
  padding-right: 10px;
}
.sortable:after {
  content: "\\f0d8";
  position: absolute;
  right: 10px;
  font-family: var(--fontAwesome);
}

#reminder-time.ng-dirty {
  border: 1px solid var(--linkColour) !important;
}

.time-hover:hover {
  background-color: var(--secondaryButtonColour);
  color: #fff;
}

.bold {
  font-weight: 700;
  background-color: #f4f4f4;
}

.assigned-by {
  font-size: 11px;
  opacity: 0.8;
}

.task-priority {
  border-radius: 5px;
  padding: 0 5px;
  line-height: 18px;
  display: inline-block;
  font-size: 12px;
  width: 65px;
  text-align: center;
  font-weight: 600;
  color: #fff;
}
.task-priority.task-priority-3 {
  background-color: var(--dangerColour);
}
.task-priority.task-priority-2 {
  background-color: var(--warningColour);
}
.task-priority.task-priority-1 {
  background-color: var(--successColour);
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/admin-tasks-view/admin-tasks-view.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,+BAAA;AACJ;;AAGA;EACE,8CAAA;AAAF;;AAGA;EACE,8CAAA;EACA,WAAA;AAAF;;AAGA;EACE,gBAAA;EACA,yBAAA;AAAF;;AAGA;EACE,eAAA;EACA,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AAAF;AAEE;EACE,qCAAA;AAAJ;AAGE;EACE,sCAAA;AADJ;AAIE;EACE,sCAAA;AAFJ","sourcesContent":[".overdue {\n  font-weight: bold;\n  color: var(--errorColour);\n}\n\n.reminder {\n  font-weight: bold;\n}\n\n.assigned-to {\n  font-weight: 600;\n  line-height: 0.875rem;\n}\n\n.sortable {\n  cursor: pointer;\n  position: relative;\n  padding-right: 10px;\n\n  &:after {\n    content: '\\f0d8';\n    position: absolute;\n    right: 10px;\n    font-family: var(--fontAwesome);\n  }\n}\n\n#reminder-time.ng-dirty {\n  border: 1px solid var(--linkColour) !important;\n}\n\n.time-hover:hover {\n  background-color: var(--secondaryButtonColour);\n  color: #fff;\n}\n\n.bold {\n  font-weight: 700;\n  background-color: #f4f4f4;\n}\n\n.assigned-by {\n  font-size: 11px;\n  opacity: 0.8;\n}\n\n.task-priority {\n  border-radius: 5px;\n  padding: 0 5px;\n  line-height: 18px;\n  display: inline-block;\n  font-size: 12px;\n  width: 65px;\n  text-align: center;\n  font-weight: 600;\n  color: #fff;\n\n  &.task-priority-3 {\n    background-color: var(--dangerColour);\n  }\n\n  &.task-priority-2 {\n    background-color: var(--warningColour);\n  }\n\n  &.task-priority-1 {\n    background-color: var(--successColour);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
