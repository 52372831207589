import {Injectable} from '@angular/core';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class MapService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  public GeocodeLocation(location: string): Promise<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${location},UK&key=${this.data.googleMapsAPIKey}`;
    return this.apiClient.get({ url, headers: { "X-Skip-Interceptor": "true" }}) as Promise<any>;
  }

}
