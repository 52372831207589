import {Component, OnInit} from '@angular/core';
import {SaleService} from '../../../../services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import {FormsService} from '../../../../services';
import {formatDate} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {SaleDTO} from "../../../../global/interfaces";
import {ImageService, LoggerService} from "../../../../global/services";
import {SaleTypeEnum, StatusEnum} from "../../../../global/enums";

@Component({
  selector: 'app-manage-auctions',
  templateUrl: './manage-auctions.component.html',
  styleUrls: ['./manage-auctions.component.scss']
})
export class ManageAuctionsComponent implements OnInit {

  constructor(private saleService: SaleService,
              private fb: UntypedFormBuilder,
              private formService: FormsService,
              public imageService: ImageService,
              private router: Router,
              private route: ActivatedRoute,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  upcomingSales: SaleDTO[];
  endedSales: SaleDTO[];

  form: UntypedFormGroup;

  defaultStartDate: Date;
  defaultEndDate: Date;

  selectedSaleId: string;

  ngOnInit(): void {

    this.defaultStartDate = moment().subtract(1, 'month').toDate();
    this.defaultEndDate = moment().toDate();

    this.form = this.fb.group({
      startDate: new UntypedFormControl(formatDate(this.defaultStartDate, 'yyyy-MM-dd', 'en'), [this.formService.dateValidator()]),
      endDate: new UntypedFormControl(formatDate(this.defaultEndDate, 'yyyy-MM-dd', 'en'), [this.formService.dateValidator()])
    });

    // upcoming/active sales are active or pending status (ordered by endDate desc)
    this.saleService.getSales({
      component: 'AdminSaleSearch',
      filters: {
        saleTypeId: SaleTypeEnum.ManagedSale,
        includeStatusIds: [StatusEnum.Active, StatusEnum.Pending]
      }
    }).then(result => {
      this.upcomingSales = result.sales;
      this.logger.info("Upcoming", this.upcomingSales);
    });

    this.getEndedSales();

    this.selectSaleFromURL();
  }

  getEndedSales() {
    this.saleService.getSales({
      component: 'AdminSaleSearch',
      filters: {
        saleTypeId: SaleTypeEnum.ManagedSale,
        includeStatusIds: [StatusEnum.Ended],
        saleDateFrom: this.form.value.startDate,
        saleDateTo: this.form.value.endDate
      }
    }).then(result => {
      this.endedSales = result.sales;
      this.logger.info("Ended: ", this.endedSales);
    });
  }

  selectSaleFromURL() {
    this.route.queryParams.subscribe(params => {

      this.logger.debug("Auction route activated: ", params);
      this.selectedSaleId = params.saleId;
    });
  }

  saleSelected(sale: SaleDTO) {
    this.selectedSaleId = sale.id;

    // store selected sale in URL
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {saleId: sale.id},
      queryParamsHandling: 'merge'
    });
  }

}
