import {AppraisalMediaDTO} from './appraisal-mediaDTO.interface';
import {BaseDTO} from "./_baseDTO.interface";
import {BodyPartDTO} from './bodyPartDTO.interface';
import {DamageDTO} from './damageDTO.interface';
import {DamageSeverityDTO} from './damageSeverityDTO.interface';
import {DamageDetailDTO} from './damageDetailDTO.interface';

export interface AppraisalItemDTO extends BaseDTO
{
  repairCost?: number;
  itemDesc?: string;
  repairDesc?: string;
  score?: number;
  itemLocation?: string;
  appraisalId?: string;
  appraisalMedia?: AppraisalMediaDTO[];

  bodyPart?: BodyPartDTO;
  bodyPartId?: number;

  damageId?: number;
  damage?: DamageDTO;

  damageSeverityId?: number;
  damageSeverity?: DamageSeverityDTO;

  damageDetailId?: number;
  damageDetail?: DamageDetailDTO;

  splatPositionX?: number; // x-position in unit percentage (i.e. 0-1)
  splatPositionY?: number; // y-position in unit percentage (i.e. 0-1)
  splatDesc?: string; // one or two characters used to label the splat point on the kipper

  internal?: boolean;
}
