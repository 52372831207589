import {Injectable} from '@angular/core';
import { RateCardDTO } from '../global/interfaces';
import {ApiService} from "../global/services/index";
import { DataService } from './data.service';

@Injectable()
export class RateCardService {

  private serviceUrl = '/api/ratecard';

  constructor(private apiClient: ApiService, private data: DataService) {}

  public getRateCard(productCode: string): Promise<RateCardDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${productCode}/platform/1`;
    return this.apiClient.get({url}) as Promise<RateCardDTO[]>;
  }
}
