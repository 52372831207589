import {Injectable} from '@angular/core';
import {ManualQuoteRequestDTO} from '../../interfaces/DTOs/Valuations/manual-quote-request-dto.interface';
import {ApiService} from '../api.service';
import {ValuationQuoteSearchDTO, ValuationRequestDTO} from "../../interfaces/DTOs/Valuations/index";
import {DataServiceInterface} from "../interfaces/index";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API/index";
import {UInspectSearchDTO} from "../../interfaces/DTOs/UInspect";

@Injectable()
export class ValuationQuoteService {

  private serviceUrl = '/api/valuation-quote';
  private leadUrl = '/api/lead';

  constructor(private apiClient: ApiService, private data: DataServiceInterface) {
  }

  initializeQuote(request: ValuationRequestDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/initialize-quote`;
    return this.apiClient.post({url, headers: {accept: 'application/json'}, data: request}) as Promise<ValidatedResultDTO>;
  }

  getExternalAppraisal(request: UInspectSearchDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/external-valuation/` + request.filters.id;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: request}) as Promise<ValidatedResultDTO>;
  }

  searchExternalAppraisals(request: UInspectSearchDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/external-valuations`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: request}) as Promise<ValidatedResultDTO>;
  }

  createManualQuote(request: ManualQuoteRequestDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/manual-quote`;
    const data = JSON.stringify(request);

    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  search(searchDTO: ValuationQuoteSearchDTO): Promise<SearchResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: searchDTO}) as Promise<SearchResultDTO>;
  }

  requestCallback(leadId) {

    const url = `${this.data.apiUrl}${this.leadUrl}/${leadId}/request-callback`;
    const data = JSON.stringify({});

    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}}) as Promise<ValidatedResultDTO>;
  }
}
