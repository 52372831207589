export enum MessageMethodEnum {
  Message = 0,
  GroupSubscribe,
  GroupUnsubscribe
}

export const MessageMethodName = [
  "Message",
  "GroupSubscribe",
  "GroupUnsubscribe"
]
