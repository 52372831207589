import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import { ExternalSettings } from "./externalsettings";

@Injectable()
export class ExternalSettingsService {
  getSettings(): Promise<ExternalSettings> {
    let settings = new ExternalSettings();
    return of(settings).pipe(take(1)).toPromise();
  }
}
