import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AdvertService, EventService, LookupService, URLService, VehicleService} from '../../../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsetComponent, ToastService} from 'ng-uikit-pro-standard';
import {Observable, Subscription} from 'rxjs';
import {ECodes} from '../../../../classes/ecodes.class';
import * as jsonpatch from 'fast-json-patch';
import {compare, deepClone} from 'fast-json-patch';
import {ComponentCanDeactivate} from '../../../../services/navigation/pending-changes.guard';
import {
  AdvertEventEnum,
  AdvertStatusEnum,
  SaleTypeEnum,
  SoldStatusEnum,
  VehicleEventEnum
} from "../../../../global/enums";
import {AdvertDTO, AppraisalDTO, BaseSearchDTO, ErrorDTO, User, VehicleDTO} from "../../../../global/interfaces/index";
import {HelpersService, LoggerService, UserService} from "../../../../global/services";

@Component({
  selector: 'app-advert-edit',
  templateUrl: './advert-edit.component.html',
  styleUrls: ['./advert-edit.component.scss']
})
export class AdvertEditComponent implements OnInit, ComponentCanDeactivate, OnDestroy, AfterViewInit {

  currentInstructions: string[] = [];

  public AdvertStatus = AdvertStatusEnum;
  public SoldStatus = SoldStatusEnum;
  public SaleType = SaleTypeEnum;

  toastOpts = {opacity: 0.98};

  existingAdvertId?: string;
  ourAdvert = true;
  user: User;

  advert: AdvertDTO;
  advertPatched: AdvertDTO;

  vehicle: VehicleDTO;
  vehiclePatched: VehicleDTO;

  advertEventSub: Subscription;

  // v5/condition report changed (keep vehiclePatch synced with vehicle)
  // vehicleDetailMediaChangedSub: Subscription;
  // vehicleMediaUpdatedSub: Subscription;
  // vehicleMediaDeletedSub: Subscription;
  // vehicleAppraisalUpdatedSub: Subscription;
  vehicleEventSub: Subscription;

  // per-component errors (set in component changed event)
  componentErrors: Map<string, string[]> = new Map<string, string[]>();

  // all current errors preventing publishing the advert
  publishErrors: any = [];

  // if advert is published and has bids/offers set this to false
  canUpdate = true;

  Instructions = new Map<string, string[]>([
    ["Vehicle", [`Ensure all vehicle details are correct`, `Details can be altered in the relevant fields`]],
    ["Details", [`Add values for all required fields`, `If the vehicle is a runner, be sure to click the 'Does the vehicle run' field`]],
    ["Photos", [`Add at least one photo to the advert`, `Photos can have their order changed by dragging the images`]],
    ["Condition",
      [`Add a condition item by clicking the kipper in the approximate location of the issue`,
        `Remove condition items that do not apply or were entered mistakenly`]],
    ["Pricing", [`Add pricing details for the advert`]]
  ]);

  logger = this.logService.taggedLogger(this.constructor?.name);
  private returnTab: number;
  isPublishing: boolean;

  constructor(public lookupService: LookupService,
              private vehicleService: VehicleService,
              private helperService: HelpersService,
              private route: ActivatedRoute,
              private advertService: AdvertService,
              private userService: UserService,
              private toast: ToastService,
              private url: URLService,
              private eventService: EventService,
              private router: Router,
              private logService: LoggerService) {
    this.existingAdvertId = this.route.snapshot.params.id;
  }

  @ViewChild('tabs') uiTabs: TabsetComponent;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  disabledForm = false;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (!this.canUpdate) {
      return true; // always leave screen since we cannot update the advert
    }

    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    const vehiclePatch = compare(this.vehicle, this.vehiclePatched);
    const advertPatch = compare(this.advert, this.advertPatched);

    this.logger.info("** Vehicle Patch: ", vehiclePatch);
    this.logger.info("** Advert Patch: ", advertPatch);

    // can only navigate away without warning when no unsaved patch values are present
    return (vehiclePatch.length === 0 && advertPatch.length === 0);
  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {

      this.user = this.userService.CurrentUser;
    });

    this.returnTab = this.advertService.CurrentStockTab || 1;

    // load advert and vehicle separately (some components only edit vehicle data)
    const searchDTO = {
      component: "AdvertEdit"
    } as BaseSearchDTO;

    const response = await this.advertService.getAdvert(this.existingAdvertId, searchDTO);
    this.advert = response.advert;

    this.canUpdate =
      (this.advert.advertStatus === AdvertStatusEnum.Active &&
        (this.advert.bidCount === 0 &&
          (this.advert.currentPrice === 0 || this.advert.currentPrice == null)
        )
      ) ||
      this.advert.advertStatus === AdvertStatusEnum.Inactive
      || this.advert.soldStatus === SoldStatusEnum.Unsold
      || this.advert.soldStatus === SoldStatusEnum.Withdrawn;

    this.advertPatched = deepClone(this.advert);
    this.logger.debug("Advert loaded", this.advert);

    this.vehicle = await this.vehicleService.getVehicle(this.advert.vehicleId);
    this.vehiclePatched = deepClone(this.vehicle);
    this.logger.info("Vehicle loaded", this.vehicle);

    // load lookups
    this.lookupService.loadVehicleLookups(this.vehicle.makeId, this.vehicle.modelId,
      this.vehicle.vehicleTypeId, this.user.customerId).then(x => {
      this.logger.info("Vehicle lookups loaded");
    });

    this.advertEventSub = this.eventService.AdvertActionEvent.subscribe(action => {
      switch (action.eventType) {
        case AdvertEventEnum.ContinueClicked:
          this.continueClicked();
          break;

        case AdvertEventEnum.PublishErrorsSet:
          this.publishErrorsSet(action.object);
          break;

        case AdvertEventEnum.SaleTypeChanged:
          this.advert.saleTypeId = action.object;
          break;

        case AdvertEventEnum.VehicleDetailsChangedEvent:
          this.vehicleDetailsChanged(action.object);
          break;

        case AdvertEventEnum.DetailsChangedEvent:
          this.advertDetailsChanged(action.object);
          break;
      }
    });

    this.vehicleEventSub = this.eventService.VehicleActionEvent.subscribe(action => {
      switch (action.eventType) {
        case VehicleEventEnum.DetailMediaDeletedEvent:

          this.vehicle.v5Media.forEach(v5 => {
            if (v5.id == action.object) {
              this.vehicle.v5Media = this.vehicle.v5Media.filter(x => x.id != action.object);
              this.vehiclePatched.v5Media = this.vehicle.v5Media;
            }
          });

          this.vehicle.serviceBookMedia.forEach(sb => {
            if (sb.id == action.object) {
              this.vehicle.serviceBookMedia = this.vehicle.serviceBookMedia.filter(x => x.id != action.object);
              this.vehiclePatched.serviceBookMedia = this.vehicle.serviceBookMedia;
            }
          });

          break;
        case VehicleEventEnum.DetailsMediaChanged:
          // keep 'detail' media properties synced
          this.vehiclePatched.serviceBookMedia = action.object.serviceBookMedia;
          this.vehiclePatched.v5Media = action.object.v5Media;
          this.vehiclePatched.walkaround = action.object.walkaround;
          break;

        case VehicleEventEnum.MediaUpdatedEvent:
          this.vehicleMediaUpdated(action.object);
          break;

        case VehicleEventEnum.MediaDeletedEvent:
          this.vehicleMediaDeleted(action.object);
          break;

        case VehicleEventEnum.AppraisalUpdated:
          this.vehicleAppraisalUpdated(action.object);
          break;
      }
    });
  }

  continueClicked() {
    // don't update data if bids or offers on advert
    if (this.canUpdate) {
      // save the current vehicle data
      this.updateAdvert(true);
    }

    let active = this.uiTabs.getActive() + 1;
    if (active >= this.uiTabs.tabs.length) {
      active = 0;
    }

    this.uiTabs.tabs[active].active = true;
  }

  publishErrorsSet(data) {
    this.componentErrors.set(data.componentName, data.errors);

    this.publishErrors = [];
    this.componentErrors.forEach((errors, key) => {
      this.publishErrors.push(...errors);
    });
  }

  vehicleDetailsChanged(data) {
    this.logger.info("Vehicle details patch: ", data);
    this.vehiclePatched = jsonpatch.applyPatch(this.vehiclePatched, data).newDocument;

    this.logger.debug("Full vehicle patch: ", compare(this.vehicle, this.vehiclePatched));
  }

  advertDetailsChanged(data) {
    this.logger.debug("Advert update: ", data);
    this.logger.debug("Comparison ad: ", this.advertPatched);

    // store in the advert update patch object
    this.advertPatched = jsonpatch.applyPatch(this.advertPatched, data).newDocument;
    this.logger.info("Full advert patch: ", compare(this.advert, this.advertPatched));
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.advertEventSub) {
      this.advertEventSub.unsubscribe();
    }

    if (this.vehicleEventSub) {
      this.vehicleEventSub.unsubscribe();
    }
  }

  async ngAfterViewInit() {
    if (this.existingAdvertId) {
      this.uiTabs.setActiveTab(2);
    }
  }

  setTabs(status: boolean) {
    this.uiTabs.tabs.forEach((t, index) => {
    });
  }

  async vehicleMediaUpdated(vehicleInfo) {

    this.logger.info("Full vehicle patch: ", compare(this.vehicle, this.vehiclePatched));

    if (vehicleInfo.media) {
      this.vehicle.vehicleMedia = vehicleInfo.media;
      this.vehiclePatched.vehicleMedia = vehicleInfo.media;
    }

    this.vehiclePatched.primaryImageURL = this.vehicle.primaryImageURL;
    if (this.vehicle.primaryImageURL) {
      const obj = {componentName: "vehicle-media", errors: []};
      this.eventService.AdvertActionEvent.emit({eventType: AdvertEventEnum.PublishErrorsSet, object: obj});
    }

    this.logger.debug("Updated vehicle from media component", this.vehicle);
  }

  async vehicleAppraisalUpdated(appraisal: AppraisalDTO) {
    const vehApp = this.vehicle.appraisals.filter(x => x == appraisal);
    if (vehApp && vehApp.length > 0) {
      this.logger.info("Patching appraisal into vehicle: ", vehApp[0]);
      this.vehiclePatched.appraisals = this.vehicle.appraisals;
    }
  }

  async vehicleMediaDeleted(vehicleInfo) {
    this.vehicle.vehicleMedia = vehicleInfo.media;

    // check primaryImageId, if not set raise the event to disallow publishing
    if (!this.vehicle.primaryImageURL) {
      const obj = {componentName: "vehicle-media", errors: ['Add at least one image in the Photos tab']};
      this.eventService.AdvertActionEvent.emit({eventType: AdvertEventEnum.PublishErrorsSet, object: obj});
    }
  }

  async publishAdvert() {

    this.isPublishing = true;

    // update the advert first
    await this.updateAdvert(true);

    // if this is a managed sale and a sale is not set (i.e. it's for auto-lotting in sales admin) just return (advert is saved above)
    if (this.advert.saleTypeId == SaleTypeEnum.ManagedSale && !this.advert.saleId) {
      await this.router.navigate([this.url.stock(1, true)]);
      this.isPublishing = false;
      return;
    }

    this.logger.info("Publishing errors: ", this.publishErrors);

    if (this.publishErrors.length > 0) {
      this.toast.error("Fix listing issues before publishing", "Error", this.toastOpts);
      this.isPublishing = false;
      return;
    }

    await this.advertService.publishAdvert(this.advert.id).then(result => {
      this.toast.info("Advert Published", "Success", this.toastOpts);
      this.isPublishing = false;
      this.router.navigate([this.url.stock(this.returnTab, true)]);
    })
      .catch(error => {
        this.toast.error("An error occurred publishing the advert", "Error", this.toastOpts);
        this.logger.error("Error publishing advert", error);
        this.isPublishing = false;
      });
  }

  async updateAdvert(updateOnly = false) {

    // don't attempt to update the vehicle if there are errors in any of the forms


    this.logger.debug("Updating vehicle");

    // each component has a list of changes made to it that are properties of vehicle or advert
    // patch all the values using relevant end-points (patchAdvert, patchVehicle)
    const success = await this.updateVehicle();
    if (!success) {
      this.logger.debug("Updating vehicle returned false");
      return;
    }

    this.logger.info("Updating Advert");

    // patch advert changes
    const advertPatch = compare(this.advert, this.advertPatched);
    console.log("Advert: ", this.advert);
    console.log("Advert Patched: ", this.advertPatched);

    await this.advertService.patchAdvert(this.advert.id, advertPatch).then(result => {
      this.advertPatched = deepClone(this.advert);
      this.vehiclePatched = deepClone(this.vehicle);

      if (!updateOnly) {
        this.toast.info("Advert Updated", "Success", this.toastOpts);
        this.router.navigate([this.url.stock(this.returnTab, true)]);
      }
    })
      .catch(err => {
        this.logger.error("Error updating advert", err);

        const dto = err.error as ErrorDTO;
        if (dto.Code === ECodes.Advert.hasbids) {
          this.toast.error(`${dto.Code} - ${dto.Message}`, "Error", this.toastOpts);
        } else {
          this.toast.error(`An error occurred updating the advert, quote error code ${dto.Code}`, "Error", this.toastOpts);
        }
      });
  }

  async updateVehicle(): Promise<boolean> {

    const vehiclePatch = compare(this.vehicle, this.vehiclePatched);

    this.vehicleService.patchVehicle(this.vehicle.id, vehiclePatch).then(result => {
      this.vehiclePatched = deepClone(this.vehicle);
    })
      .catch(err => {
        this.logger.error("Error updating vehicle", err);
        this.toast.error(`An error occurred updating the vehicle`);

        return false;
      });

    return true;
  }

  tabChanged(event) {
    this.currentInstructions = this.Instructions.get(event.heading);
  }

  relistAdvert(event, advertId) {

    event.stopPropagation();

    // confirm relisting will create a Draft advert copy
    const msg = `This process will create a copy of the Advert in 'Draft' state to be edited, ok to continue?`;

    this.helperService.confirmationDialog("Confirm Relist", msg, 'Yes', 'No')
      .subscribe(result => {
        if (result) {
          this.advertService.doRelistProcess(advertId);
        }
      });

  }

  canUpdateAdvert() {
    return this.canUpdate && (!this.publishErrors || this.publishErrors.length == 0)
      && this.advert.advertStatus != this.AdvertStatus.Inactive;
  }

  canSaveDraftAdvert() {
    return this.canUpdate && this.advert.advertStatus == AdvertStatusEnum.Inactive;
  }

  canPublishAdvert() {
    return this.canUpdate && (!this.publishErrors || this.publishErrors.length == 0)
      && this.advert.advertStatus == AdvertStatusEnum.Inactive;
  }

  canContinue() {
    const active = this.uiTabs.getActive() + 1;
    return active < this.uiTabs.tabs.length;
  }
}
