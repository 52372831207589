import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import {AdminStatDataService, AdminUrlService, AdminWhosWhoService} from '../services';
import _ from 'lodash';
import {AdminDashboardStatsDTO} from '../../../interfaces/DTOs/Admin/admin-dashboard-statsDTO.interface';
import {LoggerService, UserService} from '../../../global/services';
import {SimpleChartDataDTO, User} from '../../../global/interfaces';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import { EMPTY_GUID } from 'projects/common/shared';

interface IChartData {
  datasets: any;
  labels: any;
  colors: any;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  constructor(private adminDataService: AdminStatDataService,
              private logService: LoggerService,
              private fb: FormBuilder,
              public adminUrl: AdminUrlService,
              private whosWhoService: AdminWhosWhoService,
              private userService: UserService) {

    this.filterForm = this.fb.group({assignedToId: new FormControl(EMPTY_GUID)});
  }

  logger = this.logService.taggedLogger(this.constructor?.name);
  setTab = 1;

  public chartColorsCustomers: Array<any> = [
    {
      backgroundColor: 'rgba(75, 190, 230, .7)',
      borderColor: 'rgba(51, 51, 51, 0.5)',
      borderWidth: 1
    }
  ];
  public chartColorsListings: Array<any> = [
    {
      backgroundColor: 'rgba(15, 193, 136, .7)',
      borderColor: 'rgba(51, 51, 51, 0.5)',
      borderWidth: 1
    }
  ];
  public colourListings: Array<any> = [];

  public activeCustomersData: IChartData;
  public activeCustomersDataWeekly: IChartData;
  public liveListingsData: IChartData;
  public liveListingsDataWeekly: IChartData;
  public salesByCustomerData: IChartData;
  public plugin: any;

  public adminStats: AdminDashboardStatsDTO;


  assignedOptions: {label: string, value: any, statusId: number}[];
  currentUser: User;
  filterForm: FormGroup;

  async ngOnInit() {

    this.loadAssignees().then(() => {

    });

    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
      this.filterToMe();
    });

    const colours = ['rgba(63, 109, 242, 0.7)', 'rgba(66, 163, 252, 0.7)', 'rgba(71, 195, 230, 0.7)', 'rgba(66, 252, 243, 0.7)', 'rgba(63, 242, 183, 0.7)'];

    for (let i = 0; i < colours.length - 1; i++) {
      // this.marqueColorsListings.push({ backgroundColor: this.randomColor(190), borderColor: 'rgba(51, 51, 51, 0.5)', borderWidth: 1 });
      this.colourListings.push({backgroundColor: colours[i], borderColor: 'rgba(51, 51, 51, 0.5)', borderWidth: 1});
    }

    this.plugin = ChartDataLabels;
  }

  async loadAssignees() {
    return this.whosWhoService.getSiteAdmins({component: 'whos-who'}, true).then(result => {
      this.assignedOptions = result.results.map(x => {
        return {label: x.contactName, value: x.id, statusId: x.statusId};
      });
    });
  }

  get f() {
    return this.filterForm.value;
  }

  loadData(contactId?: string) {

    if (contactId === EMPTY_GUID) {
      contactId = null;
    }

    console.log("Loading data for: ", contactId);

    this.adminDataService.getActiveCustomers(contactId, false).then(result => {
      this.activeCustomersData = this.getSingleChartDataset(this.chartColorsCustomers, 'Active Customers Monthly', result);
    });

    this.adminDataService.getActiveCustomers(contactId, true).then(result => {
      this.activeCustomersDataWeekly = this.getSingleChartDataset(this.chartColorsCustomers, 'Active Customers Weekly', result);
    });

    this.adminDataService.getLiveAdverts(contactId, false).then(result => {
      this.liveListingsData = this.getSingleChartDataset(this.chartColorsListings, 'Live Adverts', result);
    });

    this.adminDataService.getLiveAdverts(contactId, true).then(result => {
      this.liveListingsDataWeekly = this.getSingleChartDataset(this.chartColorsListings, 'Live Adverts', result);
    });

    this.adminDataService.getSalesByCustomer(contactId).then(result => {
      this.salesByCustomerData = this.getSoldByChart(this.colourListings, result);
      this.logger.info("Sales by customer: ", this.salesByCustomerData);
    });

    this.adminDataService.getAdminStats(contactId).then(result => {
      this.adminStats = result;
    });
  }

  getSingleChartDataset(cols, dataLabels, data: SimpleChartDataDTO[]): IChartData {
    // create an array of all months from beginning of year
    const labels: Array<any> = [];
    const datapoints = [];
    const datas: Array<any> = [];

    data.forEach(d => {
      if (d.date) {
        labels.push(moment(d.date).format("MMM YY"));
      } else {
        labels.push(d.label);
      }
      datapoints.push(d.total);
    });

    datas.push({data: datapoints, label: dataLabels, lineTension: 0.1});
    return {datasets: datas, labels, colors: cols};
  }

  getSoldByChart(cols, data: SimpleChartDataDTO[]): IChartData {

    const labels = _.uniq(data.map(x => x.date));
    const marques = _.uniq(data.map(x => x.extraData));
    const marqueData = new Map<string, number[]>();

    labels.forEach(dt => {
      marques.forEach(m => {
        if (!marqueData.has(m)) {
          marqueData.set(m, []);
        }

        const dat = data.filter(x => x.date === dt && x.extraData === m);
        if (dat && dat.length) {
          marqueData.get(m).push(dat[0].total);
        } else {
          marqueData.get(m).push(0);
        }
      });
    });

    const datas = Array.from(marqueData).map(([name, value]) => ({label: name, data: value}));

    return {datasets: datas, labels: labels.map(x => moment(x).format("DD MMM YYYY")), colors: cols};
  }

  filterToMe() {

    if (this.f.assignedToId !== this.currentUser.contactId) {
      this.filterForm.patchValue({assignedToId: this.currentUser.contactId});
    } else {
      this.filterForm.patchValue({assignedToId: EMPTY_GUID});
    }

    this.loadData(this.f.assignedToId);
  }

  protected readonly EMPTY_GUID = EMPTY_GUID;
}
