// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link .badger.bg-secondary {
  background-color: #000 !important;
}

.nav-item {
  width: 50% !important;
  display: inline-block !important;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/dashboard/seller-hub/seller-hub.component.scss"],"names":[],"mappings":"AAAA;EAAgC,iCAAA;AAEhC;;AAAA;EAAY,qBAAA;EAAuB,gCAAA;AAKnC","sourcesContent":[".nav-link .badger.bg-secondary{ background-color: #000 !important; }\n\n.nav-item { width: 50% !important; display: inline-block !important; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
