import {BaseDTO} from "./_baseDTO.interface";

export interface BaseCustomerDTO extends BaseDTO {
  customerGroupId?: string;
  parentcustomerId?: string;
  billingcustomerId?: string;
  email?: string;
  websiteUrl?: string;
  customerName?: string;
  phone1?: string;
  phone2?: string;
  isBuyer?: boolean;
  isSeller?: boolean;
  scanCustomerId?: number;
  primaryContactId?: string;
  lastAdvert?: Date;
  lastLogin?: Date;
  totalAdverts?: number;
  liveAdverts?: number;
  vatNumber?: string;
  vendorPrefix?: string;
  buyingLimit?: number;
}
