import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AdvertService, EventService} from "../../../../services";
import {SignalRService} from "../../../../services";
import {Subscription} from "rxjs";
import {AdvertDTO, MessageDTO} from "../../../../global/interfaces";
import {LoggerService} from "../../../../global/services";
import {} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'message-rostrum',
  templateUrl: './message-rostrum.component.html',
  styleUrls: ['./message-rostrum.component.scss']
})
export class MessageRostrumComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() advert: AdvertDTO;
  @ViewChild('messageRostrumModal') messageRostrumModal: ModalDirective;
  @ViewChild('myTextArea') textAreaRef: ElementRef;
  messageText: string;
  private contactEventSubscription: Subscription;

  constructor(private advertService: AdvertService,
              private eventService: EventService,
              private signalRService: SignalRService,
              private logService: LoggerService) {

  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.contactEventSubscription = this.eventService.ContactEvent.subscribe((msg: MessageDTO) => {
      this.logger.log("INBOUND CONTACT MESSAGE");
      this.handleContactEvent(msg);
    });

    this.signalRService.subscribeSaleGroup(this.advert.saleId).then(() => {
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.signalRService.unsubscribeSaleGroup(this.advert.sale.id).then(() => {
    });
  }

  messageRostrum() {

    this.messageRostrumModal.show();

    setTimeout(() => {
      this.textAreaRef.nativeElement.focus();
      this.textAreaRef.nativeElement.select();
    }, 500);
  }

  sendMessage() {

    this.advertService.sendMessageToRostrum(this.advert.id, this.advert.saleId, this.messageText).then(() => {
    });

  }

  private handleContactEvent(msg: MessageDTO) {

  }
}
