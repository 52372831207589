const parsedUrl = new URL(window.location.href);
const baseUrl = parsedUrl.origin;

const awsmobile = {
  "aws_project_region": "eu-west-2",
  "aws_cognito_identity_pool_id": "eu-west-2:e8a7df84-7cbc-497a-9d35-b3590db1398b",
  "aws_cognito_region": "eu-west-2",
  "aws_user_pools_id": "eu-west-2_iOvsA3vvC",
  "aws_user_pools_web_client_id": "3sd8t07jtvf6gdfe0imte85uc5",
  "oauth": {
    "domain": "dev-auth.tradesales.com", // Should we use cognito itself as a domain ?
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": baseUrl + "/login",
    "redirectSignOut": baseUrl,
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS"
}
export default awsmobile;
