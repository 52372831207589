import {Component, OnInit, ViewChild} from '@angular/core';
import {SaleProfileService} from '../../../../services';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../../services';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {EventService, SearchService, URLService} from '../../../../services';
import {SaleProfileDTO, SaleSearchProfileDTO, SavedSearchDTO} from "../../../../global/interfaces";
import {HelpersService, LoggerService} from "../../../../global/services";
import {} from "../../../../global/enums";

@Component({
  selector: 'app-profiles',
  templateUrl: './admin-sale-profiles.component.html',
  styleUrls: ['./admin-sale-profiles.component.scss']
})
export class AdminSaleProfilesComponent implements OnInit {

  constructor(private profileService: SaleProfileService,
              private formsService: FormsService,
              private toast: ToastService,
              private searchService: SearchService,
              private helperService: HelpersService,
              public url: URLService,
              private eventService: EventService,
              private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  profiles: SaleProfileDTO[];
  selectedProfile: SaleProfileDTO;

  searchProfiles: SavedSearchDTO[];
  selectedSearchProfile: SavedSearchDTO;

  toastOpts = {opacity: 0.98};

  form: UntypedFormGroup;
  submitted = false;
  @ViewChild('saleProfileModal') saleProfileModal: ModalDirective;

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      maxAdverts: new UntypedFormControl(null, [Validators.pattern(this.formsService.numberPattern), Validators.maxLength(3)]),
      profileName: new UntypedFormControl('', [Validators.required, Validators.maxLength(64)])
    });

    this.profileService.getSaleProfiles().then(result => {
      this.profiles = result;

      this.profiles.forEach(p => {
        if (p.saleSearchProfiles) {
          p.saleSearchProfiles.forEach(s => {
            s.savedSearch.parsedDesc = this.getParsedDesc(s.savedSearch.search.description);
          });
        }
      });

      this.logger.info("PROFILES Loaded", this.profiles);
    });

    this.searchService.getSavedProfileSearches().then(result => {
      this.searchProfiles = result;

      this.searchProfiles.forEach(p => {
        p.parsedDesc = this.getParsedDesc(p.search.description);
      });
    });
  }

  getParsedDesc(description: string) {
    let vals = [];
    let cVals = Object.values(JSON.parse(description));

    cVals.forEach((v: any, index) => vals.push(v.description + (index < cVals.length - 1 ? ", " : "")));
    return vals;
  }

  get f() {
    return this.form.controls;
  }

  createProfile() {
    this.logger.log("Create new sale profile");
    this.submitted = true;

    if (this.form.valid) {

      let dto = this.form.value as SaleProfileDTO;

      this.profileService.createSaleProfile(dto).then((result) => {

        this.saleProfileModal.hide();
        this.toast.info("Saved sale profile", "Saved", this.toastOpts);
        this.profiles.push(result);

      }).catch(err => {
        this.toast.error("Error saving Sale Profile", "Error", this.toastOpts);
        this.logger.error("Error saving sale profile: ", err);
      });
    }
  }

  deleteProfile(profile: SaleProfileDTO) {
    const msg = `Are you sure you want to delete sale profile ${profile.profileName}?`;

    this.helperService.confirmationDialog("Confirm Delete Sale Profile", msg, 'Yes', 'No')
      .subscribe(result => {
        if (result) {
          this.profileService.deleteSaleProfile(profile.id).then(() => {
            this.profiles = this.profiles.filter(x => x.id != profile.id);
          });
        }
      });
  }

  addProfile() {
    // show create profile dialog
    this.saleProfileModal.show();
  }


  addSearchToSaleProfile(search: SavedSearchDTO) {
    if (!this.selectedProfile) {
      this.toast.error("Select a Sale Profile to add this Search Profile to", "Select Sale Profile", this.toastOpts);
      return;
    }

    if (!this.selectedProfile.saleSearchProfiles) {
      this.selectedProfile.saleSearchProfiles = [];
    }

    if (!this.selectedProfile.saleSearchProfiles.map(s => s.savedSearchId).includes(search.id)) {
      // save the Sale Profile Link
      this.profileService.addSearchProfile({saleProfileId: this.selectedProfile.id, savedSearchId: search.id}).then(() => {
        this.selectedProfile.saleSearchProfiles.push({
          savedSearchId: search.id,
          savedSearch: search,
          saleProfileId: this.selectedProfile.id,
          saleProfile: this.selectedProfile
        });
      })
        .catch(err => {
          this.toast.error("Could not add search profile to sale profile", "Error", this.toastOpts);
          this.logger.error("Error adding sale search profile: ", err);
        });
    } else {
      this.toast.warning("This Search Profile already exists in the selected Sale Profile", "Profile Exists", this.toastOpts);
    }
  }

  onProfileSelected(profile: SaleProfileDTO) {
    this.selectedProfile = profile;
  }

  removeSearchProfile(saleProfile: SaleProfileDTO, saleSearchProfile: SaleSearchProfileDTO) {
    this.profileService.deleteSaleSearchProfile(saleSearchProfile.id).then(() => {
      saleProfile.saleSearchProfiles = saleProfile.saleSearchProfiles.filter(x => x.id != saleSearchProfile.id);
    }).catch(err => {
      this.toast.error("Could not remove search profile from sale profile", "Error", this.toastOpts);
      this.logger.error("Error removing sale search profile: ", err);
    });
  }

  viewVehicles(search: SavedSearchDTO) {
    let queryDTO = JSON.parse(search.search.searchJSONValue);
    this.url.search(false, queryDTO);
  }

  saveProfileMaxAdverts(profile: SaleProfileDTO) {
    // patch the sale profile

  }
}
