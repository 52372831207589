export interface ScanSampleStageDTO {
  stage_id: number;
  data: ScanSampleDataDTO[];
  errors: ScanSampleErrorsDTO[];
}

export interface ScanSampleDataDTO {
  scan_field_id: number;
  scan_value: string;
  scan_comment: string;
  errors: string[];
}

export interface ScanSampleErrorsDTO {
  scan_field_id: number;
  error: string[];
}
