export enum AdvertEventEnum {
  ContinueClicked,
  PublishErrorsSet,
  VehicleDetailsChangedEvent,
  DetailsChangedEvent,
  SaleTypeChanged,
  ViewImageFullSizeEvent,
  RefreshAdvertNotes,
  RefreshProspect,
  ProspectSelected,
  UpdateProspectList,
}
