import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../index';


@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {

  currentYear: number;
  globals = AppComponent.globals;

  ngOnInit(): void {

    this.currentYear = (new Date()).getFullYear();

  }
}
