import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";
import {BaseSearchDTO, CountryProductDTO} from '../global/interfaces/index';

@Injectable()
export class CountryProductService {

  private serviceUrl = '/api/countryProduct';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  search(searchDTO?: BaseSearchDTO): Promise<any> {

    let url = `${this.data.apiUrl}${this.serviceUrl}s`;

    if (searchDTO != null && searchDTO.ignoreCache) {
      url += "?uniq=" + Math.floor(Math.random() * 10000);
    }

    return this.apiClient.get({url}) as Promise<any>;
  }

  create(countryProduct: CountryProductDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: countryProduct}) as Promise<any>;
  }

  patch(dto: CountryProductDTO, previousDTO: CountryProductDTO = {}): Promise<any> {

    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.id}`;

    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  delete(countryProductId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${countryProductId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
