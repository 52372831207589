import {Component, OnInit} from '@angular/core';
import {EventService, SearchService, URLService} from '../../../services';
import {ToastService} from 'ng-uikit-pro-standard';
import {SavedSearchDTO, User} from "../../../global/interfaces";
import {LoggerService, UserService} from "../../../global/services";
import {UpdateFrequencyEnum} from "../../../global/enums";

@Component({
  selector: 'app-my-searches',
  templateUrl: './my-searches.component.html',
  styleUrls: ['./my-searches.component.scss']
})
export class MySearchesComponent implements OnInit {

  constructor(
    private userService: UserService,
    private url: URLService,
    private searchService: SearchService,
    private eventService: EventService,
    private toast: ToastService,
    private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading: boolean;
  searches: SavedSearchDTO[];
  currentSavedSearch: SavedSearchDTO;

  user: User;

  toastOpts: { opacity: 0.98 };

  //searchInfos: any[];

  async ngOnInit() {
    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.loadSearches();
  }

  loadSearches() {
    this.isLoading = true;

    const contactId = this.user.contactId;
    this.searchService.getSavedSearches(contactId).then(result => {

      this.searches = result;
      this.isLoading = false;
    });
  }

  getUpdateMethodName(search) {
    const methods = [];
    if (search.sendEmail) {
      methods.push("E-mail");
    }
    if (search.sendSMS) {
      methods.push("Text/SMS");
    }

    return methods.join(" and ");
  }

  getUpdateFrequencyName(frequency: number) {
    return UpdateFrequencyEnum[frequency];
  }

  viewSearch(search: SavedSearchDTO) {
    this.logger.info("Navigating to advert search: ", search);

    // save searchDTO.json into session storage
    //this.searchService.saveSearchToSession(search.search.searchJSONValue);

    // route to advert-search
    // the json value needs to be added to the url as a queryParam for the v2 search to work
    let queryDTO = JSON.parse(search.search.searchJSONValue);
    //this.router.navigate([this.url.search(true)], { queryParams: obj.filters });
    this.url.search(false, queryDTO);

    //this.router.navigate([this.url.search(false, queryDTO)]);
  }

  editSearch(search: SavedSearchDTO) {

    this.currentSavedSearch = search;
    this.eventService.SaveSearchEvent.emit({isProfile: false, savedSearchDTO: search, searchDTO: null});

  }

  deleteSearch(search: SavedSearchDTO) {
    this.searchService.deleteSearch(search.id).then(result => {

      if (!result) {
        this.toast.error("Could not delete search, ensure it is not being used in a Sale Profile", "Error", this.toastOpts);
        return;
      }

      this.logger.info("Deleted: ", result);
      this.loadSearches();
    });
  }

  savedSearchDialogClosed(hasChanges: boolean) {

    // refresh all searches
    if (hasChanges) {
      this.loadSearches();
    }
  }
}
