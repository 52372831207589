import {Injectable} from '@angular/core';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class MakeModelDerivService {

  private serviceUrl = '/api';

  constructor(private apiClient: ApiService, private data: DataService) {

  }

  changeRecord(altTable: string, id: number, newValue: string) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/alt/${altTable}/${id}`;

    const data = {newValue};

    return this.apiClient.post({url, headers: {accept: 'application/json'}, data}) as Promise<any>;
  }

  mergeRecord(altTable: string, vehicleTypeId: number, fromId: number, toId: number) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/alt/${altTable}/${fromId}/merge/${toId}`;

    return this.apiClient.get({url}) as Promise<any>;
  }

  approveReject(altTable: string, id: any, action: any) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/alt/${altTable}/${id}/pending/${action}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  autoFixParent() {
    const url = `${this.data.apiUrl}${this.serviceUrl}/alt/autofix`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  getNextPending(altTable: string, vehicleTypeId: number) {

    let url;

    if (altTable === "make") {
      url = `${this.data.apiUrl}${this.serviceUrl}/alt/nextPending/${altTable}/vehicleTypeId/${vehicleTypeId}`;
    } else {
      url = `${this.data.apiUrl}${this.serviceUrl}/alt/nextPending/${altTable}`;
    }

    return this.apiClient.get({url}) as Promise<any>;
  }

  getPendingCounts() {
    const url = `${this.data.apiUrl}/api/alt/pending/counts`;

    return this.apiClient.get({url}) as Promise<any>;
  }

  getAltsByRealId(showTable: string, altRealId: number) {

    const url = `${this.data.apiUrl}/api/alt/${showTable}/altRealId/${altRealId}`;

    return this.apiClient.get({url}) as Promise<any>;
  }

  getRecords(showTable: string, vehicleTypeId?: number, parentId?: number) {

    let url;

    if (vehicleTypeId != null) {
      url = `${this.data.apiUrl}/api/altParents/${showTable}/vehicleType/${vehicleTypeId}`;
    } else if (parentId != null) {
      url = `${this.data.apiUrl}/api/altParents/${showTable}/parent/${parentId}`;
    } else {
      url = `${this.data.apiUrl}/api/altParents/${showTable}`;
    }

    return this.apiClient.get({url}) as Promise<any>;
  }

  removeAlt(altId: number) {

    const url = `${this.data.apiUrl}/api/alt/${altId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
