import { Directive, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[appbackbutton]'
})
export class BackButtonDirective {

  constructor(private location: Location) {

  }

  ngOnInit() {

  }

  @HostListener('click') onClick() {
    this.location.back();
  }
}
