import {Injectable} from "@angular/core";
import {DataService} from "../../../services/data.service";
import {
  AdvertNoteDTO,
  AdvertNoteSearchDTO,
  SaveAdvertNoteDTO,
  TypedSearchResultDTO,
  TypedValidatedResultDTO
} from "../../../global/interfaces/index";
import {ApiService} from "../../../global/services/index";

@Injectable()
export class AdminAdvertNoteService {

  private serviceUrl = '/api/listing';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  search(advertId: string, query: AdvertNoteSearchDTO): Promise<TypedSearchResultDTO<AdvertNoteDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}/notes`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<AdvertNoteDTO>>;
  }

  create(advertId: string, dto: SaveAdvertNoteDTO): Promise<TypedValidatedResultDTO<AdvertNoteDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}/note`;
    return this.apiClient.post({url, data: dto}) as Promise<TypedValidatedResultDTO<AdvertNoteDTO>>;
  }

  patch(noteId: string, patchDTO): Promise<TypedValidatedResultDTO<AdvertNoteDTO>> {
    const url = `${this.data.apiUrl}/api/listing-note/${noteId}`;
    return this.apiClient.patch({url, data: patchDTO}) as Promise<TypedValidatedResultDTO<AdvertNoteDTO>>;
  }
}
