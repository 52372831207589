import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoggerService, ApiService} from '../global/services/index';
import {compare} from 'fast-json-patch';
import {DataService} from "./data.service";
import {VehicleTypeEnum} from '../global/enums/index';
import {
  AdvertViewVehicleTyresDTO,
  AppraisalDTO,
  AppraisalItemDTO,
  AppraisalMediaUploadDTO,
  BodyPartGroupDTO,
  DamageDTO
} from '../global/interfaces/index';
import {} from "../global/interfaces";
import {} from "../global/services";
import {} from "../global/enums";

@Injectable()
export class AppraisalService {
  private serviceUrl = '/api/appraisal';
  private itemServiceUrl = '/api/appraisalItem';
  private mediaServiceUrl = '/api/appraisalMedia';

  constructor(private apiClient: ApiService,
              private data: DataService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  // splat sources dictionary (vehicleType as key, object has internal and external splat sources)
  public splatImages = new Map<number, { internal: string; external: string }>()
    .set(VehicleTypeEnum.Car, {
      internal: '/assets/images/kippers/4-door-saloon-interior.png'
      , external: '/assets/images/kippers/4-door-saloon.png'
    });

  getRequestOptions() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    };
    return {
      headers: new HttpHeaders(headers),
    };
  }

  public uploadAppraisalMedia(uploadMediaDTO: AppraisalMediaUploadDTO, files: File[]): Promise<any> {
    const url = `${this.data.apiUrl}${this.mediaServiceUrl}`;
    const fd = new FormData();
    fd.append('appraisalMediaUploadDTO', JSON.stringify(uploadMediaDTO));

    files.forEach(file => {
      fd.append('image', file, file.name);
    });

    this.logger.info("UPLOADING APPRAISAL MEDIA");

    // return this.apiClient.post({url, data: fd, headers: {"Content-Type": "application/x-www-form-urlencoded"}}) as Promise<any>;
    return this.apiClient.post({url, data: fd}) as Promise<any>;
  }

  public deleteAppraisalMedia(appraisalMediaId): Promise<any> {

    const url = `${this.data.apiUrl}${this.mediaServiceUrl}/${appraisalMediaId}`;

    this.logger.info("UPLOADING APPRAISAL MEDIA");

    return this.apiClient.delete({url}) as Promise<any>;
  }

  public addAppraisal(appraisalDTO: AppraisalDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    // const data = JSON.stringify(appraisalDTO);
    // this.logger.info("ADD APPRAISAL", appraisalDTO);

    return this.apiClient.post({url, data: appraisalDTO}) as Promise<any>;
  }

  public updateAppraisal(appraisalDTO: AppraisalDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${appraisalDTO.id}`;
    const data = JSON.stringify(appraisalDTO);

    this.logger.info('Updating appraisal: ', appraisalDTO);

    return this.apiClient.put({url, data, headers: {"Content-Type": "application/json"}}) as Promise<any>;
  }

  public patchAppraisal(appraisalId: string, patchData): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${appraisalId}`;
    return this.apiClient.patch({url, data: patchData}) as Promise<any>;
  }

  public deleteAppraisal(appraisalId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${appraisalId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public addAppraisalItem(appraisalItemDTO: AppraisalItemDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.itemServiceUrl}`;
    const data = JSON.stringify(appraisalItemDTO);
    return this.apiClient.post({url, data, headers: {"Content-Type": "application/json"}}) as Promise<any>;
    // return this.apiClient.post({url, data }) as Promise<any>;
  }

  public patchAppraisalItem(appraisalItemDTO: AppraisalItemDTO, previousDTO: AppraisalItemDTO = {}): Promise<any> {
    const url = `${this.data.apiUrl}${this.itemServiceUrl}/${appraisalItemDTO.id}`;
    const patch = compare(previousDTO, appraisalItemDTO);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  public updateAppraisalItem(appraisalItemDTO: AppraisalItemDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.itemServiceUrl}/${appraisalItemDTO.id}`;
    const data = JSON.stringify(appraisalItemDTO);

    return this.apiClient.put({url, data, headers: {"Content-Type": "application/json"}}) as Promise<any>;
  }

  public deleteAppraisalItem(appraisalItemId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.itemServiceUrl}/${appraisalItemId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public getBodyPartGroups(isInternal: boolean): Promise<BodyPartGroupDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/bodyPartGroups?isInternal=${isInternal}`;
    return this.apiClient.get({url}) as Promise<BodyPartGroupDTO[]>;
  }

  public getDamages(): Promise<DamageDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/damages`;
    return this.apiClient.get({url}) as Promise<DamageDTO[]>;
  }

  public getLatestAppraisalByAdvert(advertId: string, cached?: boolean): Promise<AppraisalDTO> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/latest-appraisal`;
    if (cached) { url += '?cache=true'; }
    return this.apiClient.get({url}) as Promise<AppraisalDTO>;
  }
}
