export interface ValuationRequestDTO {
  vrm: string;
  mileage?: number;
  name?: string;
  email?: string;
  mobile?: string;
  baseCapPrice?: number;
  baseRetailPrice?: number;
  testValuation: boolean;
  capId?: string;
  createTestQuote?: boolean;
}
