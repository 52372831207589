import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {TaggedLogger} from './tagged-logger';

/**
 * Logger Service
 * Forwards to ngx-logger, middle-man service to ensure logger can be changed in future if required
 */
@Injectable({providedIn: 'root'})
export class LoggerService {
  tags: TaggedLogger[] = [];

  constructor(private logger: NGXLogger) {
  }

  trace(message: any, ...additional: any[]) {
    this.logger.trace(message, ...additional);
  }

  debug(message: any, ...additional: any[]) {
    this.logger.debug(message, ...additional);
  }

  info(message: any, ...additional: any[]) {
    this.logger.info(message, ...additional);
  }

  log(message: any, ...additional: any[]) {
    this.logger.log(message, ...additional);
  }

  warn(message: any, ...additional: any[]) {
    this.logger.warn(message, ...additional);
  }

  error(message: any, ...additional: any[]) {
    this.logger.error(message, ...additional);
  }

  fatal(message: any, ...additional: any[]) {
    this.logger.fatal(message, ...additional);
  }

  taggedLogger(tag: string): TaggedLogger {
    return TaggedLogger.create(tag, this.logger);
  }
}

