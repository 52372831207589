import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {CurrencyPipe, DatePipe, DecimalPipe, getNumberOfCurrencyDigits} from '@angular/common';
import {DataService} from "./data.service";
import {ApiService, HandlerService} from '../global/services/index';
import {
  AdvertSearchCount,
  AdvertSearchDTO,
  AdvertSearchFiltersDTO,
  AdvertSearchResultsDTO,
  AllLookupsDTO,
  RangeDTO, SearchResultDTO,
  SphAdvertDTO
} from '../global/interfaces';
import {AdvertSearchFieldEnum, SaleTypeFilter, SortingFilter, SortingType} from '../global/enums';
import {} from "../global/interfaces";
import {} from "../global/services";
import {} from "../global/enums";
import {LocaleService} from "./locale.service";
import {AppComponent} from "../components/index";

@Injectable()
export class AdvertSearchService {

  public static CurrentSearchQuery: string; // current encoded URL, stored for navigating back to search screen

  private serviceUrl = '/api/listingSearch';
  public Lookups: AllLookupsDTO;


  // todo: get locale from config
  public decimalPipe: DecimalPipe = new DecimalPipe(AppComponent.globals.locale);
  public currencyPipe: CurrencyPipe = new CurrencyPipe(AppComponent.globals.locale);
  public datePipe: DatePipe = new DatePipe(AppComponent.globals.locale);

  public saleFilters: {}[];
  public sortingTypes: any[];
  public sortingFilters: any[];
  public runnerOptions: any[];
  public catStatusOptions: any[];

  constructor(
    private apiClient: ApiService,
    private data: DataService
    ) {

    // this.logging.componentName = 'AdvertSearchService';

    this.saleFilters = [
      {label: 'All', value: SaleTypeFilter.All},
      {label: 'Buy it now', value: SaleTypeFilter.BuyItNow},
      {label: 'Auction', value: SaleTypeFilter.Auction},
//      {label: 'Underwrite', value: SaleTypeFilter.Underwrite},
    ];

    this.sortingTypes = [
      {label: 'Ending Soonest', value: SortingType.EndingSoonest}
      , {label: 'Newly Listed', value: SortingType.NewlyListed}
      , {label: 'Estimated Margin', value: SortingType.EstimatedMargin}
      , {label: 'Price (Lowest)', value: SortingType.PriceLowest}
      , {label: 'Price (highest)', value: SortingType.PriceHighest}
      , {label: 'Age (newest)', value: SortingType.AgeNewest}
      , {label: 'Age (oldest)', value: SortingType.AgeOldest}
    ];

    this.sortingFilters = [
      {label: 'Any time', value: SortingFilter.AnyTime},
      {label: '1 Hour', value: SortingFilter.OneHour},
      {label: '2 Hours', value: SortingFilter.TwoHours},
      {label: '4 Hours', value: SortingFilter.FourHours},
      {label: '8 Hours', value: SortingFilter.EightHours},
      {label: '12 Hours', value: SortingFilter.TwelveHours},
      {label: '24 Hours', value: SortingFilter.TwentyFourHours},
      {label: '2 Days', value: SortingFilter.TwoDays},
    ];

    this.runnerOptions = [{label: 'All', value: -1}, {label: 'Runner', value: 1}, {label: 'Non-Runner', value: 0}];

    this.catStatusOptions = [
      {label: 'Include Cat S/C/D/N', value: -1},
      {label: 'Exclude Cat S/C/D/N', value: 0},
      {label: 'Only Show Cat S/C/D/N', value: 1}
    ];
  }

  // public async loadRangeLookups() {
  //
  //   this.getPriceRangeLookup().then(result => {
  //     this.PriceRangeLookup = result;
  //   });
  //   this.getCapacityRangeLookup().then(result => {
  //     this.CapacityRangeLookup = result;
  //   });
  //   this.MileageRangeLookup = await this.getMileageRangeLookup();
  // }

  public async loadLookups() {

    const url = `${this.data.apiUrl}${this.serviceUrl}/options`;
    const result = await this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<AllLookupsDTO>;

    this.Lookups = await result;
  }

  private getPriceRangeLookup(): Promise<RangeDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/priceRanges`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<RangeDTO[]>;
  }

  private getCapacityRangeLookup(): Promise<RangeDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/capacityRanges`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<RangeDTO[]>;
  }

  private getMileageRangeLookup(): Promise<RangeDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/mileageRanges`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<RangeDTO[]>;
  }

  public getRangeValuesString(searchField: AdvertSearchFieldEnum, rangeMinId, rangeMaxId): string {
    let str = '';

    if (!this.Lookups) {
      return str;
    }

    let rangeLookup: any;
    switch (searchField) {
      case AdvertSearchFieldEnum.Price:
        rangeLookup = this.Lookups.priceRanges;
        break;
      case AdvertSearchFieldEnum.Capacity:
        rangeLookup = this.Lookups.capacityRanges;
        break;
      case AdvertSearchFieldEnum.Mileage:
        rangeLookup = this.Lookups.mileageRanges;
        break;
    }

    if (rangeMinId || rangeMaxId) {
      const min = rangeLookup ? rangeLookup.find(x => x.id === rangeMinId) : rangeMinId;
      const max = rangeLookup ? rangeLookup.find(x => x.id === rangeMaxId) : rangeMaxId;

      switch (searchField) {
        case AdvertSearchFieldEnum.Age:
          str = this.getFilterString(min, max, this.transformMonths);
          break;
        case AdvertSearchFieldEnum.Capacity:
          str = this.getFilterString(min?.min, max?.max, this.transformCapacity);
          break;
        case AdvertSearchFieldEnum.Mileage:
          str = this.getFilterString(min?.min, max?.max, this.transformMileage);
          break;
        case AdvertSearchFieldEnum.Price:
          str = this.getFilterString(min?.min, max?.max, this.transformPrice);
          break;
      }
    }

    return str;
  }

  getFilterString(minVal, maxVal, transform): string {

    let str;

    if (!minVal) {
      str = `Up to ${transform(maxVal)}`;
    } else if (!maxVal) {
      str = `At least ${transform(minVal)}`;
    } else {
      str = `Between ${transform(minVal)} and ${transform(maxVal)}`;
    }

    return str;
  }

  getRunnerFilterString(runner: number) {
    if (runner === 0) {
      return "Non-Runners only";
    } else if (runner === 1) {
      return "Runner only";
    }
  }

  getCatStatusFilterString(catStatus: number) {
    if (+catStatus === 0) {
      return "Exclude Cat S/C/D/N";
    } else if (+catStatus === 1) {
      return "Only Show Cat S/C/D/N";
    }
  }

  public transformCapacity(cc: number): string {
    return `${(cc / 1000.0).toFixed(1)}L`;
  }

  public transformMileage(mileage: number): string {
    const decimalPipe: DecimalPipe = new DecimalPipe('en-gb');
    return decimalPipe.transform(mileage);
  }

  public transformPrice(price: number): string {
    const currencyPipe: CurrencyPipe = new CurrencyPipe(AppComponent.globals.locale);
    return currencyPipe.transform(price, AppComponent.globals.currencyCode, 'symbol', '1.0-0');
  }

  public transformMonths(months: number): string {
    return months < 12 ? months.toString() + " month" + (months !== 1 ? "s" : "")
      : Math.floor(months / 12).toString() + " year" + (Math.floor(months / 12) !== 1 ? "s" : "");
  }

  public getSearchCounts(searchFiltersDTO: AdvertSearchFiltersDTO): Promise<AdvertSearchCount[]> {

    if (searchFiltersDTO != null && searchFiltersDTO.filters != null) {
      searchFiltersDTO.filters = this.removeEmptyKeys(searchFiltersDTO.filters);
    }

    const dto = JSON.stringify(searchFiltersDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/listingSearchCounts`;

    return this.apiClient.get({url, params: {query: dto}}) as Promise<AdvertSearchCount[]>;
  }

  public getResultsCount(searchFiltersDTO: AdvertSearchFiltersDTO): Promise<number> {

    if (searchFiltersDTO && searchFiltersDTO.filters != null) {
      searchFiltersDTO.filters = this.removeEmptyKeys(searchFiltersDTO.filters);
    }

    const dto = JSON.stringify(searchFiltersDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/searchResultsCount`;

    return this.apiClient.get({url, params: {query: dto}}) as Promise<number>;
  }

  private removeEmptyKeys(input) {

    Object.keys(input).forEach(key => input[key] === undefined ? delete input[key] : {});
    Object.keys(input).forEach(key => typeof input[key] === 'object' && input[key] && input[key].length === 0 ? delete input[key] : {});

    return input;
  }

  public getSearchResults(searchFiltersDTO: AdvertSearchFiltersDTO): Promise<SphAdvertDTO[]> {

    if (searchFiltersDTO != null && searchFiltersDTO.filters != null) {
      searchFiltersDTO.filters = this.removeEmptyKeys(searchFiltersDTO.filters);
    }

    const dto = JSON.stringify(searchFiltersDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/listing-search-results`;

    return this.apiClient.get({url, params: {query: dto}}) as Promise<SphAdvertDTO[]>;
  }

  public getSearchResults_v2(searchFiltersDTO: AdvertSearchDTO): Promise<AdvertSearchResultsDTO> {

    if (searchFiltersDTO != null && searchFiltersDTO.filters != null) {
      searchFiltersDTO.filters = this.removeEmptyKeys(searchFiltersDTO.filters);
    }

    const dto = JSON.stringify(searchFiltersDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/listing-search-results`;

    return this.apiClient.get({url, params: {query: dto}}) as Promise<AdvertSearchResultsDTO>;
  }

}
