import {ContactDTO} from "../../contactDTO.interface";
import {BaseDTO} from "../../_baseDTO.interface";

export interface ProspectNoteDTO extends BaseDTO {
  prospectId: string;
  prospectActionId: number;
  note: string;

  // the contact of the user who created the history record
  contactId: string;
  contact: ContactDTO;
  reminderDateTime?: Date;
}
