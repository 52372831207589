import {AdviewDTO} from "./adviewDTO.interface";

export interface CustomerLoginTotalsDTO {
  assignedTo: string;
  customerId: string;
  customerName: string;
  customerEmail: string;
  contactName: string;
  contactPhone1: string;
  contactEmail: string;
  loginCount: number;
  latestLoginDate?: Date;
  isOpen?: boolean; // used in UI
}
