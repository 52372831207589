import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Routes, RouterModule} from '@angular/router';
import {RoleGuardService as AuthGuard} from '../services/authguard.service';
import {HomepageComponent} from '../components';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: '',
    loadChildren: () => import('./homepages.module').then(m => m.HomepagesModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main.module').then(m => m.MainModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin.module').then(m => m.AdminModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 64]
})],
  exports: [RouterModule, FormsModule],
  providers: [AuthGuard]
})

export class IndexRoutingModule {
}
