import {Component, OnInit} from '@angular/core';
import {DataServiceInterface} from "../../services/interfaces/index";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private data: DataServiceInterface) {

  }

  globals = this.data.globals;

  ngOnInit(): void {

  }
}
