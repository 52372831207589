import {Injectable} from '@angular/core';
import {
  CustomerMediaSearchDTO,
  SearchResultDTO,
  ValidatedResultDTO
} from '../global/interfaces';
import {ApiService, ImageService} from '../global/services';
import {DataService} from "./data.service";
import {compare} from "fast-json-patch";
import {MediaTypeFromMime} from "../global/enums";
import {CustomerMediaUploadDTO} from "../global/interfaces";

@Injectable()
export class CustomerMediaservice {

  private serviceUrl = '/api/customer-media';

  constructor(
    private apiClient: ApiService,
    private data: DataService,
    private commonImageService: ImageService
  ) {
  }

  search(searchDTO?: CustomerMediaSearchDTO): Promise<SearchResultDTO> {

    const filters = searchDTO.filters;
    const url = `${this.data.apiUrl}/api/customer/${filters.customerId}/media`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: searchDTO}) as Promise<any>;
  }

  create(data: CustomerMediaUploadDTO): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    return this.apiClient.post({url, data, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  patch(id, dto: CustomerMediaUploadDTO, previousDTO = {}): Promise<any> {
    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  public delete(customerId: string, mediaId: string): Promise<any> {
    const url = `${this.data.apiUrl}/api/customer/${customerId}/media/${mediaId}`;

    return this.apiClient.delete({url}) as Promise<any>;
  }

  /*
  public uploadDocuments(uploadDocumentDTO: CustomerDocumentUploadDTO, files: File[]): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}/api/customer/${uploadDocumentDTO.customerId}/media`;
    const fd = new FormData();
    fd.append('customerMediaDTO', JSON.stringify(uploadDocumentDTO));

    files.forEach(file => {
      fd.append('image', file, file.name);
    });

    // return this.apiClient.post({url, data: fd, headers: {"Content-Type": "application/x-www-form-urlencoded"}}) as Promise<any>;
    return this.apiClient.post({url, data: fd}) as Promise<any>;
  }
   */

  public async uploadMedia(uploadMediaDTO: CustomerMediaUploadDTO, files: File[] = [], dataUrls: string[] = []): Promise<any> {

    const promises = [];
    const sendFiles: string[] = [];

    // If we had a list of FILES[] then convert them to dataURLS
    for (const file of files) {

      const mediaFileType = MediaTypeFromMime[file.type] ?? null;

      if (mediaFileType == null) {
        // How do we raise an error to the front end ??
      } else {

        uploadMediaDTO.mediaType.push(MediaTypeFromMime[file.type] ?? null);

        // Convert FILE into DataURL
        promises.push(this.commonImageService.convertFileToDataURL(file).then((fileContent) => {
          sendFiles.push(this.removeFileTypePrefix(fileContent));
        }));
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }

    // If we were given a set of dataURLS
    dataUrls.forEach((x) => {

      const fileType = x.substring(x.indexOf(':') + 1, x.indexOf(';'));
      const mediaFileType = MediaTypeFromMime[fileType];

      uploadMediaDTO.mediaType.push(mediaFileType);

      sendFiles.push(this.removeFileTypePrefix(x));
    });

    const data = {
      customerMediaUploadDTO: uploadMediaDTO,
      files: sendFiles
    };

    const url = `${this.data.apiUrl}/api/customer/${uploadMediaDTO.customerId}/media`;

    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}});
  }

  removeFileTypePrefix(dataURL: string) {
    return dataURL.substring(dataURL.indexOf(',') + 1);
  }
}

