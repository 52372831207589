// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-box {
  width: 100%;
  height: 55px;
  font-size: 0.875rem;
}
.message-box::placeholder {
  color: #bbb;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/sale/message-rostrum/message-rostrum.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;AACE;EAAiB,WAAA;AAEnB","sourcesContent":[".message-box {\n  width: 100%;\n  height: 55px;\n  font-size: 0.875rem;\n\n  &::placeholder { color: #bbb; }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
