import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CountryService, PlatformService, TaxService, CountryProductService, ProductService} from "../../../../services";
import {CountryProductDTO} from "../../../../global/interfaces";
import {HelpersService, LoggerService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-country-product',
  templateUrl: './country-product.component.html',
  styleUrls: ['./country-product.component.scss'],
providers:[]
})
export class CountryProductComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helpersService: HelpersService,
    private productService: ProductService,
    private countryService: CountryService,
    private countryProductService: CountryProductService,
    private platformService: PlatformService,
    private taxService: TaxService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @ViewChild('countryProductModal') countryProductModal: ModalDirective;

  public statusName: string[] = [];
  public taxName: string[] = [];
  public countryName: string[] = [];
  public productName: string[] = [];
  public platformName: string[] = [];

  public statusOptions: any[];
  public taxOptions: any[];
  public countryOptions: any[];
  public productOptions: any[];
  public platformOptions: any[];

  countryProducts: CountryProductDTO[];
  platforms: any;

  form: UntypedFormGroup;
  isLoading = false;
  isEdit = false;
  countryProductIdToDelete: string;
  submitted = false;

  showDeleteConfirm = false;

  async ngOnInit() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      productId: new UntypedFormControl(),
      platformId: new UntypedFormControl(),
      countryId: new UntypedFormControl(),
      taxId: new UntypedFormControl(),
      statusId: new UntypedFormControl(''),
    });


    this.loadStatuses();
    this.loadProducts();
    this.loadCountries();
    this.loadTaxes();
    this.loadPlatforms();
    this.loadCountryProducts();
  }

  async loadStatuses() {
    const statuses = this.helpersService.getNamesAndValues(StatusEnum);
    this.statusOptions = statuses.filter(x => x.value !== StatusEnum.Deleted).map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadTaxes() {
    await this.taxService.search().then(result => {
      this.taxOptions = result.map(x => {

        this.taxName[x.id] = x.taxCode;
        return {label: x.taxCode, value: x.id};
      });
    });
  }

  async loadPlatforms() {
    await this.platformService.search().then(result => {
      this.platformOptions = result.map(x => {
        this.platformName[x.id] = x.platformName;
        return {label: x.platformName, value: x.id};
      });
    });
  }

  async loadCountries() {
    await this.countryService.search().then(result => {
      this.countryOptions = result.map(x => {
        this.countryName[x.id] = x.countryName;
        return {label: x.countryName, value: x.id, icon: ''};
      });
    });
  }

  async loadProducts() {
    await this.productService.search().then(result => {
      this.productOptions = result.map(x => {
        this.productName[x.id] = x.productName;
        return {label: x.productName, value: x.id};
      });
    });
  }

  async loadCountryProducts() {

    this.isLoading = true;

    await this.countryProductService.search({ignoreCache: true}).then(result => {
      this.countryProducts = result;
      this.isLoading = false;
    });
  }

  editCountryProduct($event: MouseEvent, countryProductDTO: CountryProductDTO) {


    this.form.patchValue(({
      ...countryProductDTO
    }));
    this.isEdit = true;

    // show modal that allows location name entry
    this.countryProductModal.show();

  }

  addCountryProduct() {

    this.logger.debug("Product Options", this.productOptions);

    this.form.patchValue(({
      id: '',
      countryId: '',
      productId: '',
      platformId: '',
      taxId: '',
      statusId: StatusEnum.Active
    }));
    this.isEdit = false;

    // show modal that allows location name entry
    this.countryProductModal.show();
  }

  async saveCountryProduct() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const countryProductDTO = this.form.value;

    if (this.isEdit) {
      await this.countryProductService.patch(countryProductDTO);
    } else {
      delete countryProductDTO.id;
      await this.countryProductService.create(countryProductDTO);
    }

    this.countryProductModal.hide();
    await this.loadCountryProducts();
  }

  hideModal() {
    this.countryProductModal.hide();
  }

  deleteCountryProduct(event, countryProduct: CountryProductDTO) {
    event.stopPropagation();
    this.countryProductIdToDelete = countryProduct.id;
    this.showDeleteConfirm = true;
  }


  confirmDelete() {
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }
}
