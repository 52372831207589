import {Injectable} from '@angular/core';
import {SearchResultDTO} from '../global/interfaces/index';
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";

@Injectable()
export class DealService {

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  private serviceUrl = '/api/deal';

  public async search(query): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/deals`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  public async createTestInvoice(): Promise<any> {
    const url = `${this.data.apiUrl}/api/test/spoofInvoice`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public async getDealFromAdvert(advertId): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/fromAdvert/${advertId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }
}

