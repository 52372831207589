// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-cell {
  background-color: rgba(80, 172, 255, 0.07) !important;
}

.deals-done td {
  vertical-align: middle;
}

.price {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/deal/deal-search/deal-search.component.scss"],"names":[],"mappings":"AAAA;EACE,qDAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".contact-cell {\n  background-color: rgba(80, 172, 255, 0.07) !important;\n}\n\n.deals-done td {\n  vertical-align: middle\n}\n\n.price {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
