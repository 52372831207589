import {Component, OnInit, ViewChild} from '@angular/core';
import {BidDTO, DealDTO, DealSearchDTO, SearchResultDTO, User} from "../../../../global/interfaces";
import {ActivatedRoute} from "@angular/router";
import * as moment from 'moment';
import {BidService, DealService, URLService} from "../../../../services/index";
import {debounceTime, distinctUntilChanged, filter} from "rxjs/operators";
import {FormGroup, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {BidStatusEnum, BidStatusName} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";
import { UserService } from 'projects/common/services/remarq';
import {AdminUrlService, AdminWhosWhoService} from "../../services";
import { EMPTY_GUID } from 'projects/common/shared';

@Component({
  selector: 'app-deal-search',
  templateUrl: './deal-search.component.html',
  styleUrls: ['./deal-search.component.scss']
})
export class DealSearchComponent implements OnInit {
  readonly filter = filter;

  assignedOptions: { label: string; value: string }[];
  currentUser: User;

  deals: DealDTO[] = [];
  page = 1;
  count = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;
  daysAgo = 0;
  searchResult: SearchResultDTO;
  filterForm: UntypedFormGroup;
  dateRange: { value: string, label: string }[] = [
    {value: '7', label: 'Last 7 days'},
    {value: '30', label: 'Last 30 days'},
    {value: '90', label: 'Last 90 days'},
    {value: '0', label: 'All Dates'}
  ];

  statuses: { value: boolean, label: string }[] = [
    {value: null, label: 'All'},
    {value: true, label: 'Completed'},
    {value: false, label: 'Incomplete'},
  ];

  bids: BidDTO[];

  @ViewChild('bidHistoryModal') bidHistoryModal: ModalDirective;
  bidStatusName = BidStatusName;

  constructor(
    private dealService: DealService,
    public urlService: URLService,
    public adminUrlService: AdminUrlService,
    public bidService: BidService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private whosWhoService: AdminWhosWhoService
  ) {}

  ngOnInit(): void {
    this.initializeComponent().then(() => this.fetchDeals());
  }

  private async initializeComponent(): Promise<void> {
    const daysAgo = this.activeRoute.snapshot.params.daysAgo ?? '0';

    this.filterForm = new UntypedFormGroup({
      keywords: new UntypedFormControl(''),
      dateRangeId: new UntypedFormControl(daysAgo),
      assigneeId: new UntypedFormControl(EMPTY_GUID),
      completeState: new UntypedFormControl(false)
    });

    await Promise.all([
      this.loadAssignees(),
      this.loadCurrentUser()
    ]);

    this.setupFormValueChanges();
  }

  private async loadAssignees(): Promise<void> {
    try {
      const result = await this.whosWhoService.getSiteAdmins({ component: 'whos-who' }, true);
      this.assignedOptions = result.results.map(x => ({
        label: `${x.contactName || 'No Name'}`,
        value: x.id
      }));
    } catch (error) {
      console.error('Error loading assignees:', error);
      this.assignedOptions = [];
    }
  }

  private async loadCurrentUser(): Promise<void> {
    await this.userService.loadCurrentUser();
    this.currentUser = this.userService.CurrentUser;
    if (this.currentUser) {
      this.filterForm.patchValue({ assigneeId: this.currentUser.contactId }, { emitEvent: false });
    }
  }

  private setupFormValueChanges(): void {
    this.filterForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(() => {
      this.page = 1; // Reset to first page on filter change
      this.fetchDeals();
    });
  }

  get f() {
    return this.filterForm.value;
  }

  fetchDeals(): void {
    const { dateRangeId, keywords, assigneeId, completeState } = this.filterForm.value;

    const dto: DealSearchDTO = {
      component: 'AdminDealSearch',
      filters: {
        fromDateTime: dateRangeId == 0 ? null : moment().subtract(dateRangeId, 'days').format("YYYY-MM-DD 00:00:00"),
        toDateTime: dateRangeId == 0 ? null : moment().format("YYYY-MM-DD HH:mm"),
        assigneeId,
        completeState
      },
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize
    };

    this.dealService.search(dto).then(result => {
      console.log("Loaded Deals: ", result);
      this.deals = result.results;
      this.count = result.totalItems;
    });
  }

  onTableDataChange(page: number): void {
    this.page = page;
    this.fetchDeals();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchDeals();
  }

  showBidModal(advertId: string): void {
    this.bidHistoryModal.show();
    this.bidService.search({component: 'admin-deal-search', filters: {advertId}}).then(result => {
      this.bids = result.results;
    });
  }

  filterToMe(): void {
    const newAssigneeId = this.filterForm.value.assigneeId === this.currentUser.contactId
      ? EMPTY_GUID
      : this.currentUser.contactId;

    this.filterForm.patchValue({ assigneeId: newAssigneeId });
  }

  filterDealsByAssignee(assigneeId: string): void {
    this.filterForm.patchValue({ assigneeId });
  }

  getAssigneeName(assigneeId: string | undefined): string | undefined {
    if (!assigneeId || !this.assignedOptions) return undefined;
    const assignee = this.assignedOptions.find(option => option.value === assigneeId);
    return assignee?.label;
  }

  protected readonly EMPTY_GUID = EMPTY_GUID;
}
