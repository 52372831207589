export enum CustomerMediaCategoryEnum {
  SignUpDocs = 1,
  CompanyLogo = 2
}
export enum CustomerMediaSubCategoryEnum {
  TradePolicy = 100,
  DrivingLicence = 101,
  LetterHead = 102,
  CompanyLogo = 200
}
