import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import * as FileSaver from 'file-saver';
import {ApiService, HandlerService} from "../global/services/index";
import {DataService} from "./data.service";
import {LoggerService} from '../global/services/index';
import {
  AdvertDTO,
  AdvertSearchDTO,
  AdvertSearchFiltersDTO,
  AdvertSearchResultDTO, AdvertViewBidBoxDTO, AdvertViewDataDTO,
  BaseSearchDTO, BidDTO, BidsSummaryDTO, BidSummaryDTO,
  CreateAdvertDTO, InternalBidInfoDTO, SaleDTO, SearchResultDTO, SellerCountsDTO, User
} from '../global/interfaces';
import {AdvertStatusEnum, BidStatusEnum, SaleTypeEnum, SoldStatusEnum} from '../global/enums';
import {} from "../global/interfaces";
import {} from "../global/services";
import {} from "../global/enums";
import {URLService} from "./url.service";

@Injectable()
export class AdvertService {

  constructor(
    private apiClient: ApiService,
    private data: DataService,
    private logService: LoggerService,
    private urlService: URLService,
    private toast: ToastService,
    private handler: HandlerService) {
  }

  private serviceUrl = '/api/listing';

  public CurrentStockTab = 1;

  logger = this.logService.taggedLogger(this.constructor?.name);

  private static saveAsFile(buffer: any, fileName: string): void {
    FileSaver.saveAs(buffer, fileName);
  }

  createAdvert(customerId: string, contactId: string, vehicleId: string): Promise<AdvertDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    const createAdvertDTO: CreateAdvertDTO = {
      customerId,
      contactId,
      vehicleId,
    };

    const data = JSON.stringify(createAdvertDTO);
    return this.apiClient.post({url, data, headers: this.getRequestOptions()}) as Promise<AdvertDTO>;
  }

  doRelistProcess(advertId) {

    this.relistAdvert(advertId).then((newAdvertId) => {
      // navigate to advert edit
      this.CurrentStockTab = 7; // returning from advert will show draft stock tab

      window.open(this.urlService.advertEdit(newAdvertId, true), '_blank');

      // // raise event so Live stock tab is reloaded
      // this.eventService.ReloadStockTab.emit(SoldStatusEnum.Active);

    }).catch(err => {
      const obj = JSON.parse(err.error.Message);
      if (obj.Code == "AD13") {
        this.toast.error("Vehicle is part of existing advert", "Can't Relist", {opacity: 0.98});
      }
    });
  }

  getAdminAdverts(searchDTO: AdvertSearchDTO): Promise<AdvertSearchResultDTO> {
    let url = `${this.data.apiUrl}/api/listings/`;
    url += '?query=' + encodeURIComponent(JSON.stringify(searchDTO));
    return this.apiClient.get({url}) as Promise<AdvertSearchResultDTO>;
  }

  getCustomerAdverts(customerId: string, dto: AdvertSearchFiltersDTO) {

    let url = `${this.data.apiUrl}/api/customer/${customerId}/listings`;

    url += '?query=' + encodeURIComponent(JSON.stringify(dto));

    return this.apiClient.get({url}) as Promise<AdvertSearchResultDTO>;
  }

  getSellerCounts(customerId: string, dto?: SellerCountsDTO) {

    const url = `${this.data.apiUrl}/api/customer/${customerId}/sellerCounts`;

    return this.apiClient.get({url}, {query: dto}) as Promise<SellerCountsDTO>;
  }

  public patchAdvert(advertId, patchData): Promise<AdvertDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}`;
    return this.apiClient.patch({url, data: patchData}) as Promise<AdvertDTO>;
  }

  public search(dto: AdvertSearchDTO): Promise<AdvertSearchResultDTO> {

    let url = `${this.data.apiUrl}${this.serviceUrl}`;

    if (dto != null) {
      url += '?query=' + encodeURIComponent(JSON.stringify(dto));
    }
    return this.apiClient.get({url}) as Promise<AdvertSearchResultDTO>;
  }

  public advertViewVehicleInfo(dto: AdvertSearchDTO, useCache?: boolean): Promise<AdvertSearchResultDTO> {

    let url = `${this.data.apiUrl}${this.serviceUrl}/vehicle-info`;

    if (dto != null) {
      url += '?query=' + encodeURIComponent(JSON.stringify(dto));
      if (useCache) {
        url += '&cache=true';
      }
    }
    else if (useCache) {
      url += '?cache=true';
    }

    return this.apiClient.get({url}) as Promise<AdvertSearchResultDTO>;
  }

  publishAdvert(id: string): Promise<AdvertDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}/publish`;
    return this.apiClient.get({url}) as Promise<AdvertDTO>;
  }


  getAdvert(id: string, dto?: BaseSearchDTO): Promise<AdvertSearchResultDTO> {

    const url: string = `${this.data.apiUrl}${this.serviceUrl}/` + id;

    return this.apiClient.get({url}, {query: dto}) as Promise<AdvertSearchResultDTO>;

  }

  getAdvertViewData(id: string, dto?: BaseSearchDTO): Promise<AdvertSearchResultDTO> {
    const url: string = `${this.data.apiUrl}${this.serviceUrl}/${id}/view-data`;
    return this.apiClient.get({url}, {query: dto}) as Promise<AdvertSearchResultDTO>;
  }

  deleteAdvert(advertId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  relistAdvert(advertId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}/relist`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  relistAdvertByVehicle(vehicleId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/relistByVehicle/${vehicleId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  republishAdvertByVehicle(vehicleId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/republishByVehicle/${vehicleId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  cancelListing(advertId: string, reasonId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${advertId}/cancelListing/${reasonId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  getCancelListingReasons(): Promise<any[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/cancelListingReasons`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  exportAdvertData(soldStatus: SoldStatusEnum, fileName): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/soldStatus/${soldStatus}/exportToCSV`;

    const report = this.apiClient.get({
      url,
      headers: {accept: 'text/csv'},
      responseType: 'text/csv' as any
    }) as Promise<any>;
    report.then(result => {
      AdvertService.saveAsFile(result, fileName);
    });

    return report;
  }

  createTestNegotiations(advertId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/createTestNegotiations/${advertId}`;
    return this.apiClient.get({url}) as Promise<any>;
  }


  sendMessageToRostrum(advertId, saleId, messageText) {

    const url = `${this.data.apiUrl}/api/rostrumMessage`;

    this.logger.info('POSTING ROSTRUM MESSAGE TO ', url);

    return this.apiClient.post({
      url, data: {
        messageText,
        reAdvertId: advertId,
        saleId,
      }
    }) as Promise<any>;
  }


  canProxyBid(advert: AdvertDTO | AdvertViewDataDTO | AdvertViewBidBoxDTO) {

    return (advert != null && advert.advertStatus === AdvertStatusEnum.Active &&
      advert.saleTypeId === SaleTypeEnum.ManagedSale && advert.ggg < 3);
  }

  parseBidderUpdate(advert: AdvertDTO | AdvertViewBidBoxDTO, bidsSummary: BidsSummaryDTO, user: User) {

    const b: InternalBidInfoDTO = {fa: null, bs: null, lb: null, mb: null, tb: null};

    b.fa = null;

    // Have we bid on this sale
    const d = bidsSummary.bidders[user?.contactId];
    const bidsLeft = 3; // TODO: This should only be on Offer ads
    let maxBid: BidDTO = null;

    if (d != null) {

      let lastBid = null;
      b.fa = false;
      b.bl = bidsLeft;

      // Too low count
      if (advert && advert.saleTypeId === SaleTypeEnum.BuyItNow) {
        b.bl = bidsLeft - d.filter(x => x.bidStatus == BidStatusEnum.BID_TOOLOW)?.length;
      }

      d.filter(x => x.bidStatus !== BidStatusEnum.BID_CANCELLED).forEach((bid: BidDTO) => {

        if (bid.bidGuid === advert.topBidGuid) {
          b.fa = true;
          maxBid = bid;
        }

        if (advert.saleTypeId === SaleTypeEnum.BuyItNow) {

          if (!maxBid || bid.bidAmt > maxBid.bidAmt) {
            maxBid = bid;
          }
        } else {

          if ((bid.bidStatus === BidStatusEnum.BID_ACCEPTED) &&
            bid.bidAmt > advert.currentPrice &&
            (maxBid == null || bid.bidAmt > maxBid.bidAmt)
          ) {
            maxBid = bid;
          }
        }

        if (!lastBid || bid.bidAmt > lastBid.bidAmt) {
          lastBid = bid;
        }
      });

      if (maxBid != null) {
        b.mb = maxBid;
      }

      if (lastBid != null) {

        b.bs = lastBid.bidStatus;
        b.lb = lastBid;
      }
    }

    return b;
  }

  getRequestOptions() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    };
    return headers;
  }
}
