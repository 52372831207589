import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from 'ng-uikit-pro-standard';

@Injectable()
export class HandlerService {

  constructor(private toast: ToastService) {
  }

  public handleError(errorResponse: HttpErrorResponse | any) {
    let errorMsg: string;
    if (errorResponse instanceof HttpErrorResponse) {
      const error = errorResponse.error || JSON.stringify(errorResponse);
      errorMsg = `Error Status: ${errorResponse.status} - ${error}`;
    } else {
      errorMsg = errorResponse.message ? errorResponse.message : errorResponse.toString();
    }

    return Promise.reject(errorMsg);
  }
}
