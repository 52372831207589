import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceService} from '../../../../services';
import {AdminUrlService} from '../../services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BillDTO} from '../../../../global/interfaces/index';
import {LoggerService} from "../../../../global/services";
import {ModalDirective, ToastService} from "ng-uikit-pro-standard";
import {InvoiceDetailDTO} from "../../../../interfaces/DTOs/Payment/invoice-detail.dto.interface";
import {PaymentResultDTO} from "../../../../interfaces/DTOs/Payment/payment-result.dto.interface";

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  constructor(
    private invoiceService: InvoiceService,
    private urlService: AdminUrlService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private logService: LoggerService,
    private toast: ToastService
    ) {
  }

  @ViewChild("payModal") payModal: ModalDirective;

  logger = this.logService.taggedLogger(this.constructor?.name);

  invoices: BillDTO[];
  form: UntypedFormGroup;

  invoiceDetail: InvoiceDetailDTO;

  page = 1;
  count = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;

  processingInvoices = false;
  toastOpts: { opacity: 0.98 };

  loadingDetails = false;
  payingInvoice = false;

  paymentResult: PaymentResultDTO;

  async ngOnInit() {
    this.form = this.formBuilder.group({
      unpaidOnly: new UntypedFormControl(false, []),
      invoiceNo: new UntypedFormControl('', []),
      customer: new UntypedFormControl('', []),
      fromDate: new UntypedFormControl('', []),
      toDate: new UntypedFormControl('', []),
      fromDueDate: new UntypedFormControl('', []),
      toDueDate: new UntypedFormControl('', []),
    });

    this.fetchInvoices();
  }

  setFormValuesFromURL() {
    this.route.queryParams.subscribe(params => {
    });
  }

  viewCustomer(customerId) {
    this.urlService.adminCustomerEdit(customerId);
  }

  fetchInvoices() {
    // create dto using form values and pagination
    const dto = this.form.value;
    dto.offset = (this.page - 1) * this.pageSize;
    dto.limit = this.pageSize;

    this.invoiceService.getInvoices(dto).then(result => {
      this.invoices = result.results;
      this.count = result.totalItems;

      this.logger.info("Fetched invoices: ", result);
    });
  }

  onTableDataChange(page: number) {
    // fetch records using page as offset
    this.page = page;
    this.fetchInvoices();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchInvoices();
  }

  showPayInvoice(bill: BillDTO) {
    this.loadingDetails = true;
    this.payModal.show();

    this.invoiceService.getInvoiceDetails(bill.id).then(res => {
      this.invoiceDetail = res;
      console.log("Invoice details: ", res)
      this.loadingDetails = false;
    }).catch(err => {
      this.loadingDetails = false;
      this.toast.error(err.message, "Error", this.toastOpts);
    });
  }

  processInvoices() {
    this.processingInvoices = true;

    this.invoiceService.processInvoices().then(() => {
      this.page = 1;
      this.fetchInvoices();
      this.processingInvoices = false;
    }).catch(err => {
      this.toast.error(err.message, "Error", this.toastOpts);
      this.processingInvoices = false;
    })
  }

  payInvoice() {
    this.payingInvoice = true;
    this.paymentResult = null;

    this.invoiceService.makePayment(this.invoiceDetail.id).then(res => {
      if (!res.success) {
        this.toast.error(res.message, "Error", this.toastOpts);

        // show the relevant result data in the result section
        this.paymentResult = res;
      } else {
        this.invoiceDetail.paidDate = res.created;
      }

      this.payingInvoice = false;
    }).catch(err => {
      this.toast.error(err.message, "Error", this.toastOpts);
      this.payingInvoice = false;
    })
  }
}
