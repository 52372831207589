import {ScanConfigDTO} from "./scanConfigDTO.interface";
import {ScanFieldTypeEnum} from "../../../enums";
import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanFieldDTO extends BaseIntDTO {
  fieldName?: string;
  description?: string;
  sequence?: number;
  fieldType?: ScanFieldTypeEnum;
  mandatory?: boolean;
  isImage?: boolean;
  newScan?: boolean;
  isUrl?: boolean;
  isAttribPair?: boolean;
  scanConfigs?: ScanConfigDTO[];
  scanConfig?: ScanConfigDTO;
  preview?: string;
}
