import {CustomerDTO} from "./customerDTO.interface";
import {AdvertDTO} from "./advertDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {BaseIntDTO} from "./_baseIntDTO.interface";

export interface AdviewDTO extends BaseIntDTO {

  advertId: string;
  vehicleId: string;
  customerId: string;
  contactId: string;
  advert: AdvertDTO;
  contact: ContactDTO;
  customer: CustomerDTO;
}
