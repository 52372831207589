import {Injectable} from '@angular/core';
import {DataService, URLService} from "../../../services";
import {ApiService} from "../../../global/services";
import {compare} from "fast-json-patch";
import {
  AdminTaskDTO,
  WhosWhoAdminSearchDTO,
  ContactActionDTO,
  ContactActionSearchDTO,
  CustomerDTO, SearchResultDTO
} from "../../../global/interfaces";

@Injectable()
export class AdminContactActionService {

  private serviceUrl = '/api/contact-action';

  constructor(
    public urlService: URLService,
    private apiClient: ApiService,
    private data: DataService,
  ) {
  }

  search(query: ContactActionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/contact-actions`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  customerSearch(query: ContactActionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/customer/${query.filters.customerId}/contact-actions`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  contactSearch(query: ContactActionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/contact/${query.filters.contactId}/actions`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  getTopLogins(dto: WhosWhoAdminSearchDTO): Promise<SearchResultDTO> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/login-summary`;
    url += '?query=' + encodeURIComponent(JSON.stringify(dto));
    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }
}
