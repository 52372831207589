import {Injectable} from '@angular/core';
import xml2js from 'xml2js';
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";

@Injectable()

export class IndustryNewsService {

  industryNews;

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  private serviceUrl = '/api/news';

  public async getNews(howMany: number = 4): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    return new Promise(resolve => {

      const promise = this.apiClient.get({url, headers: {
          'Content-Type': 'application/rss+xml',
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Origin': '*'}
      }, { InterceptHeader: true }) as Promise<any>;
      promise.then((y) => {

        this.parseXML(y).then((subdata: []) => {

          const response = this.shuffle(subdata).splice(0, 4);

          return resolve(response);

        });
      });

      return promise;
    });
  }

  private shuffle(array) {

    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }


  parseXML(data) {

    return new Promise(resolve => {

      const arr = [];
      const parser = new xml2js.Parser(
        {
          trim: true,
          explicitArray: true
        });


      parser.parseString(data, (err, result) => {

        const items = result.rss.channel[0].item;

        items.forEach(item => {

          if (item["media:thumbnail"] !== undefined) {

            const title = item.title[0];

            arr.push({
              link: item.link[0],
              title,
              description: item,
              image: item["media:thumbnail"][0]['$']['url'],
            });
          }

        });
      });

      resolve(arr);
    });
  }
}

