import {InMailFolderEnum} from '../../enums/index';
import {InMailStatusEnum} from '../../enums/index';
import {BaseDTO} from './_baseDTO.interface';
import {ContactDTO} from './contactDTO.interface';

export interface InMailDTO extends BaseDTO {
  toContactId: string;
  toContact: ContactDTO;
  fromContactId: string;
  fromContact: ContactDTO;
  subject: string;
  body: string;
  toFolder: InMailFolderEnum;
  fromFolder: InMailFolderEnum;
  toStatus: InMailStatusEnum;
  fromStatus: InMailStatusEnum;
  inReplyToId?: string;
  inReplyTo: InMailDTO;
  isRead: boolean;
  advertId: string;
  isPrivate: boolean;
}
