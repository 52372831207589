import {ContactDTO} from "../../contactDTO.interface";
import {BaseDTO} from "../../_baseDTO.interface";
import {AdminTaskDTO} from "../../adminTaskDTO.interface";
import {AdvertDTO} from "../../advertDTO.interface";

export interface AdvertNoteDTO extends BaseDTO {
  advertId: string;
  note: string;

  // IMPORTANT: The contact of the user who created the history record
  contactId: string;
  contact: ContactDTO;

  added: Date;
  overdue: boolean;

  prospectId?: string;
  prospectActionId?: number;
  prospectCustomerName?: string;

  adminTask?: AdminTaskDTO;
  adminTaskId?: string;

  advert?: AdvertDTO;
}
