import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  AppComponent,
  HomepageComponent,
  AppFooterComponent,
  AppTopbarComponent,
  LoginComponent,
} from '../components';

import {
  DataService,
  EventService,
  LocalStorageService,
  SessionStorageService, WatchlistService,
} from '../services';
import {Router, RouterModule} from "@angular/router";
import {SortPipe} from "../pipes";
import {IconsModule, InputsModule, MDBBootstrapModulesPro} from "ng-uikit-pro-standard";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ImageCropperComponent} from "ngx-image-cropper";
import {DndModule} from "ngx-drag-drop";
import {NgsgModule} from "ng-sortgrid";
import {NgxPaginationModule} from "ngx-pagination";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AmplifyAuthenticatorModule} from "@aws-amplify/ui-angular";
import {AppMobileBarComponent} from "../components/common/app-mobile-bar/app-mobile-bar.component";
import {ApiService, CognitoService, ContactService, CustomerService, UserService} from "../global/services";
import {} from "../global/interfaces";
import {HeaderInterceptor} from "../global/interceptors";
import {} from "../global/enums";
import {GoogleMapsScriptComponent} from '../global/components';
import {ColorPickerModule} from "ngx-color-picker";
import {HttpModule} from "../global/modules";
import {AdminRoutingModule} from "./admin-routing.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
  CamelCasePipe,
  DatexPipe,
  DecodeHtmlEntitiesPipe,
  EngineCCPipe,
  MileagePipe,
  TimeLeftPipe
} from "../global/pipes";
import {FlagsEnumDisplayPipe} from "../global/pipes";
import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [
    AppTopbarComponent,
    AppFooterComponent,
    AppMobileBarComponent,
    AppComponent,
    HomepageComponent,
    GoogleMapsScriptComponent,
    LoginComponent,
    SortPipe,
    DatexPipe,
    EngineCCPipe,
    MileagePipe,
    TimeLeftPipe,
    CamelCasePipe,
    FlagsEnumDisplayPipe,
    DecodeHtmlEntitiesPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MDBBootstrapModulesPro,
    ImageCropperComponent,
    ColorPickerModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    DragDropModule,
    IconsModule,
    InputsModule,
    NgxGoogleAnalyticsModule.forRoot('G-MP25PKV1P0'),
    NgxGoogleAnalyticsRouterModule,
    DndModule, NgsgModule, NgxPaginationModule, AmplifyAuthenticatorModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    ApiService,
    UserService,
    WatchlistService,
    DataService,
    LocalStorageService,
    SessionStorageService,
    EventService,
    ContactService,
    CognitoService,
    CustomerService,
  ],
  exports: [
    AppTopbarComponent,
    AppFooterComponent,
    AppMobileBarComponent,
    GoogleMapsScriptComponent,
    HomepageComponent,
    LoginComponent,
    SortPipe,
    MileagePipe,
    EngineCCPipe,
    TimeLeftPipe,
    DatexPipe,
    CamelCasePipe,
    CommonModule,
    RouterModule,
    FlagsEnumDisplayPipe,
    DecodeHtmlEntitiesPipe
  ]
})
export class IndexModule {
}
