import {Injectable} from '@angular/core';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class LocationService {

  private serviceUrl = '/api/Location';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  getAll(): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  getLocationsByCustomer(): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/getLocationsByCustomer`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  add(dto): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<any>;
  }

  // todo: passing id so this can be turned into a generic crud service (there may be another way)
  update(dto, id: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.put({url, data: dto}) as Promise<any>;
  }

  delete(id: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
