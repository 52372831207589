import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {DataService, URLService} from "../../../services";
import {} from "../../../global/interfaces";
import {ApiService} from "../../../global/services";
import {} from "../../../global/enums";

@Injectable()
export class AdminAddressService {

  constructor(
    private router: Router,
    private apiClient: ApiService,
    private data: DataService,
    public urlService: URLService
  ) {
  }

}
