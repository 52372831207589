// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-print {
  font-size: 12px;
  color: #aaa;
  line-height: 26px;
}

.pair-label {
  font-size: 0.6rem;
  font-weight: 500;
  white-space: nowrap;
}

.pair-value {
  font-size: 0.875rem;
  font-weight: 500;
}

.valuation-item {
  max-width: 75px;
  flex-grow: 1;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-valuation/vehicle-valuation.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,gBAAA;AACF;;AAGA;EACE,eAAA;EACA,YAAA;EACA,kBAAA;AAAF","sourcesContent":[".small-print {\n  font-size: 12px;\n  color: #aaa;\n  line-height: 26px;\n}\n\n.pair-label {\n  font-size: 0.6rem;\n  font-weight: 500;\n  white-space: nowrap;\n}\n\n.pair-value {\n  font-size: 0.875rem;\n  font-weight: 500;\n}\n\n\n.valuation-item {\n  max-width: 75px;\n  flex-grow: 1;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
