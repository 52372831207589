import {BaseDTO} from "./_baseDTO.interface";
import {AttribDTO} from "./attribDTO.interface";

export interface CustomerAttribDTO extends BaseDTO {
  customerId?: string;
  attribId?: number;
  attribvalId?: number;
  attribInt?: number;
  attribDecimal?: number;
  attribBool?: boolean;
  attribChar?: string;
  attribDateTime?: string;
  attrib?: AttribDTO;
}
