import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CustomerNotes2Component} from "../customer-notes2/customer-notes2.component";
import {ToastService} from 'ng-uikit-pro-standard';
import {
  AdminContactService,
  AdminCustomerInternalInfoService,
  CustomerService,
  HelpersService,
  LoggerService
} from "../../../../global/services";
import {ContactActionDTO, CustomerAdminDTO, CustomerInternalInfoDTO, IOption} from "../../../../global/interfaces";
import {CustomerTypeEnum, StatusEnum} from "../../../../global/enums";
import {AdminCustomerService, AdminWhosWhoService} from "../../services/index";
import {AdminContactActionService} from "../../services/admin-contact-action.service";
import {WhosWhoStatusEnum} from "../../../../global/enums";
import {signInWithRedirect, signOut} from 'aws-amplify/auth';
import {AIService} from "../../../../services/ai.service";

@Component({
  selector: "app-admin-customer-view",
  templateUrl: "./customer-view.component.html",
  styleUrls: ['./customer-view.component.scss'],
  providers: []
})
export class AdminCustomerViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("customerNotesModal", {static: true}) notesComponent: CustomerNotes2Component;
  showEditVendorPrefix = false;
  showEditBuyingLimit = false;
  whosWhoStatusName: { [p: number]: { name: string; value: number } };
  whosWhoStatusTitle: {} = {};
  private fetchingDetails: boolean;
  customerTypeName: { [p: number]: { name: string; value: number } };
  customerTypeOptions: { label: string; value: number }[];

  constructor(
    private customerService: CustomerService,
    private adminCustomerService: AdminCustomerService,
    private adminContactActionService: AdminContactActionService,
    private adminUserService: AdminContactService,
    private whoswhoService: AdminWhosWhoService,
    private helperService: HelpersService,
    private activeRoute: ActivatedRoute,
    private toast: ToastService,
    private logService: LoggerService,
    private aiService: AIService,
    private customerInternalInfoService: AdminCustomerInternalInfoService) {

    this.customerTypeName = this.helperService.getNamesAndValuesAsObject(CustomerTypeEnum, true);

    // Convert the customerTypeName lookup into an array
    this.customerTypeOptions = this.helperService.getLabelsAndValues(CustomerTypeEnum, true);

    this.whosWhoStatusName = this.helperService.getNamesAndValuesAsObject(WhosWhoStatusEnum, true);

    // Create abbreviations of each whoswhostatus
    this.whosWhoStatusTitle = Object.keys(this.whosWhoStatusName).reduce((acc, x) => {
      acc[x] = {
        name: this.whosWhoStatusName[x].name,
        value: this.whosWhoStatusName[x].value,
      };
      return acc;
    }, {});

    const statuses = this.helperService.getNamesAndValues(StatusEnum);

    statuses.forEach((x) => {
      this.statusName[x.value] = x.name;
    });
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  customerId;
  customerData: CustomerAdminDTO;
  actions: ContactActionDTO[];
  statusName: {} = {};

  toastOpts = {opacity: 0.98};
  assignedName: {} = {};
  enteringCustomerType = false;
  showAssignDialog: boolean;
  editURL: boolean;
  editLimit = false;

  async ngAfterViewInit() {
  }

  async ngOnInit() {

    this.customerId = this.activeRoute.snapshot.params.customerId;

    console.log("FETCHING.. ");

    this.fetchAdminDetails().then();

    console.log("FETCHING 2.. ");

    /*
    this.adminContactActionService.getContactActions(this.customerId, {component: 'admin-customer-view'}).then(result => {
      this.actions = result.results?.sort((a, b) => {
        return a.added < b.added ? 1 : -1;
      });
    });

     */

    console.log("FETCHING 3.. ");

    this.whoswhoService.getSiteAdmins({component: 'admin-customer-view'}, true).then(result => {
      this.assignedName = result.results?.reduce((acc, x) => {
        acc[x.id] = x.contactName;
        return acc;
      }, {});

      console.log("AN ", this.assignedName);
    });

    this.logger.info("Customer data: ", this.customerData);
  }

  ngOnDestroy() {
  }

  toStatsRoute(statIndex: number) {
    switch (statIndex) {
      case 0:
        this.logger.info("Navigate to sold vehicles");
        break;
      case 1:
        this.logger.info("Navigate to bought vehicles");
        break;
      case 2:
        this.logger.info("Navigate to live adverts");
        break;
      case 3:
        this.logger.info("Navigate to all adverts");
        break;
      case 4:
        this.logger.info("Navigate to user (contact) list");
        break;
    }
  }

  async impersonateUser(email: string) {

    this.adminUserService.impersonateUser(email).then(() => {

      signOut().then(() => {
        signInWithRedirect({
          provider: "Google",
        });
      });
    });
  }

  editVendorPrefix(event: Event, b: boolean) {

    this.showEditVendorPrefix = b;
    event.stopPropagation();
  }

  saveVendorPrefix() {

    this.customerData.vendorPrefix = this.customerData.vendorPrefix.toLocaleUpperCase();

    this.customerService.patchCustomer(this.customerId, {vendorPrefix: this.customerData.vendorPrefix}).then(() => {
      this.showEditVendorPrefix = false;
      this.toast.info("Vendor prefix updated", "Success", this.toastOpts);
    }).catch(err => {
      this.toast.error("An error occurred updating vendor prefix", "Error", this.toastOpts);
    });
  }


  editBuyingLimit(event: Event, b: boolean) {

    this.showEditBuyingLimit = b;
    event.stopPropagation();
  }

  saveBuyingLimit() {

    const val = this.customerData.buyingLimit.toString();
    if (!this.isNumeric(val)) {
      this.toast.error("Buying limit must be numeric", "Error", this.toastOpts);
      return;
    }

    this.customerService.patchCustomer(this.customerId, {buyingLimit: this.customerData.buyingLimit}).then(() => {
      this.showEditBuyingLimit = false;
      this.toast.info("Buying limit updated", "Success", this.toastOpts);
    }).catch(err => {
      this.toast.error("An error occurred updating buying limit", "Error", this.toastOpts);
    });
  }

  isNumeric(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }

    if (str.trim() === '') {
      return false;
    }

    return !Number.isNaN(Number(str));
  }

  changeState(buyer: boolean) {
    const dto = buyer ? {isBuyer: !this.customerData.isBuyer} : {isSeller: !this.customerData.isSeller};

    this.customerService.patchCustomer(this.customerId, dto).then(() => {
      if (buyer) {
        this.customerData.isBuyer = !this.customerData.isBuyer;
      } else {
        this.customerData.isSeller = !this.customerData.isSeller;
      }

    }).catch(err => {
      this.toast.error(`An error occurred updating ${buyer ? 'buyer' : 'seller'} status`, "Error", this.toastOpts);
    });
  }

  adminContact(id: string | undefined) {

  }

  async fetchAdminDetails() {
    this.customerData = await this.adminCustomerService.getAdminDetails(this.customerId);
  }

  async statusChanged() {

    await this.fetchAdminDetails();
  }

  customerTypeChanged(customerType: number) {

    this.adminCustomerService.patch(this.customerId, {customerType: customerType as CustomerTypeEnum}).then(() => {

      this.enteringCustomerType = false;
    });
  }

  updateWebsiteURL() {

    this.editURL = false;
    this.adminCustomerService.patch(this.customerId, {websiteUrl: this.customerData.websiteUrl}).then();
  }

  suggestSearchProfile() {
    const question = `Can you provide a detailed summary of the average price, mileage,
    and makes of cars listed on ${this.customerData.websiteUrl}
    I need a comprehensive analysis, including the range of prices and mileages,
    as well as specific makes and models of cars available. Please include notable examples, general trends in pricing,
    and the typical mileage for different car types.`

    // use AI to return a suggested search profile for the customer based on their website
    this.aiService.askQuestion(
      question
    ).then(res => {
      console.log("AI Response is: ", res);
    })
  }

  updateDealLimit() {

    this.editLimit = false;

    this.customerInternalInfoService
      .patchCustomer(this.customerId, {dealLimit: this.customerData.customerInternalInfo.dealLimit})
      .then(() => {
        this.toast.info("Deal limit updated", "Success", this.toastOpts);
      });
  }

  customerUpdate() {

    this.fetchAdminDetails().then(() => {
    });
  }

  assignedTo(assignedTo: string) {
    return this.assignedName[assignedTo] || "Unassigned";
  }

  customerAssignedEvent(event: { customerId: number; assignedTo: number }) {

    console.log("XYZ");

    // If we didn't close the window then fetch the latest data
    if (event != null && event.customerId != null) {

      this.fetchAdminDetails().then();
    }

    this.showAssignDialog = false;
  }

  noURL(websiteUrl: any) {

    if (websiteUrl == null || websiteUrl == undefined) {
      return true;
    }
    return websiteUrl.trim().length == 0;
  }
}
