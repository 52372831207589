import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {ApiService, HandlerService} from "../global/services/index";
import {DataService} from "./data.service";
import {from, map, Observable} from "rxjs";
import {compare} from "fast-json-patch";
import {SafePaymentMethodDetailsInterface} from "../interfaces/stripe/safe-payment-method-details.interface";

@Injectable()
export class PaymentService {

  private serviceUrl = '/api/payment';

  constructor(
    private apiClient: ApiService,
    private data: DataService,
    private toast: ToastService,
    private handler: HandlerService) {
    // this.logging.componentName = 'AdvertSearchService';
  }

  public makePaymentIntent(invoiceId): Promise<any> {
    const url = `${this.data.apiUrl}/api/invoice/${invoiceId}/makePaymentIntent`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  public paymentMade(paymentIntentId: string): Promise<any> {
    const url = `${this.data.apiUrl}/api/paymentIntent/${paymentIntentId}/paymentMade`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  public createSetupIntent(customerId: string): Observable<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/create-setup-intent`;
    const data = { customerId };
    return from(
      this.apiClient.post({ url, data, headers: { accept: 'application/json' } })
    );
  }

  public customerHasPaymentMethod(customerId: string): Promise<boolean> {
    const url = `${this.data.apiUrl}/api/customer/${customerId}/has-payment-method`;
    return this.apiClient.get({url}) as Promise<boolean>;
  }

  public updateCustomerPaymentMethod(customerId: string, paymentMethodId: string): Promise<any> {
    const url = `${this.data.apiUrl}/api/customer/${customerId}/update-payment-method/${paymentMethodId}`;
    return this.apiClient.put({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  // customerId param is TradeSales customer
  public getPaymentCustomerId(customerId: string): Promise<string> {
    const url = `${this.data.apiUrl}/api/customer/${customerId}/payment-customer-id`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<string>;
  }

  public getPaymentMethodDetails(customerId: string): Promise<SafePaymentMethodDetailsInterface> {
    const url = `${this.data.apiUrl}/api/customer/${customerId}/payment-method-details`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<SafePaymentMethodDetailsInterface>;
  }
}
