import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {URLService} from "../../../../services";
import {User} from "../../../../global/interfaces";
import {ContactService, LoggerService, UserService} from "../../../../global/services";
import {} from "../../../../global/enums";

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.css'],
})
export class ContactViewComponent implements OnInit, OnDestroy {

  submitted = false;
  form: UntypedFormGroup;
  createMode = false;
  user: User;
  public contactId;

  constructor(
    private contactService: ContactService,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private url: URLService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private logService: LoggerService
  ) {

    this.contactId = this.currentRoute.snapshot.params.contactId;

    if (this.contactId === undefined) {
      this.createMode = true;
    }

    this.logger.debug("CREATE MODE " + this.createMode + " ID " + this.contactId);

  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  async ngOnInit() {
    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    this.form = this.fb.group({
      contactName: ['', Validators.required],
      email: ['', Validators.email],
      phone1: [''],
      phone2: [''],
      roles: [''],
    }, {validator: this.formValidator()});
  }


  formValidator(): ValidatorFn {

    return (control: UntypedFormGroup): ValidationErrors | null => {

      const errors: ValidationErrors = {};

      if (control.get('contactName').invalid) {
        errors.noContactName = true;
      }

      if (control.get('email').invalid) {
        errors.invalidEmail = true;
      }

      if (control.get('phone1').invalid) {
        errors.invalidPhone1 = true;
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  get f() {
    return this.form.controls;
  }

  get err() {
    return this.form.errors;
  }

  onSubmit() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const customerId = "";

    if (this.createMode) {

      var contactDTO = this.form.value;

      this.contactService.createContact(this.form.value).then(
        response => {
          this.router.navigate([this.url.customer(customerId, true)]);
        },
        err => this.logger.error(err)
      );

    } else {

      this.contactService.patch(this.contactId, this.form.value).then(
        response => {
          this.router.navigate([this.url.dashboard(true)]);
        },
        err => this.logger.error(err)
      );
    }

  }

  ngOnDestroy() {
  }
}
