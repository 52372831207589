import {BaseDTO} from "./_baseDTO.interface";
import {SaleDTO} from "./Sale/saleDTO.interface";
import {AdvertDTO} from "./advertDTO.interface";
import {ContactDTO} from "./contactDTO.interface";

export interface RostrumMessageDTO extends BaseDTO {
  saleId?: string;
  sale?: SaleDTO;
  reAdvertId: string;
  reAdvert: AdvertDTO;
  contactId?: string;
  contact: ContactDTO;
  contactName: string;
  customerName: string;
  contactNumber: string;
  replied?: boolean;
  timeReplied?: Date;
  toRostrum?: boolean;
  messageText?: string;
}
