import {BaseSearchDTO} from "./API";
import {BidDTO} from "./bidDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {OfferStatusEnum, StatusEnum} from "../../enums/index";

export interface OfferDTO extends BaseDTO {
  bidGuid?: string;
  contactId?: string;
  customerId?: string;
  offerAmt?: number;
  expires?: string;
  bid?: BidDTO;
  contact?: ContactDTO;
}

export interface OfferSearchDTO extends BaseSearchDTO {
  filters: {
    contactId?: string;
    countOnly?: boolean;
    pendingOnly?: boolean; // Bid Status
    offerStatus?: OfferStatusEnum; // Offer Status
  };
}

export interface OfferResponseDTO {
  offerId?: string;
  accept: boolean;
  comment?: string;
  contactId?: string;
  customerId?: string;
}
