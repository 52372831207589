import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Location} from "@angular/common";
import {AdminUrlService} from "../services/index";
import {ContactActionTypeEnum, CustomerTypeEnum, StatusEnum} from "../../../global/enums";
import {IOption} from "../../../global/interfaces";
import {ActivatedRoute} from "@angular/router";
import {TabsetComponent} from "ng-uikit-pro-standard";

@Component({
  templateUrl: './whos-who.component.html',
  styleUrls: ['./whos-who.component.scss'],
})
export class WhosWhoComponent implements OnInit, AfterViewInit {

  @ViewChild('tabs') uiTabs: TabsetComponent;

  selectedTab = null;

  public ContactActionType = ContactActionTypeEnum;
  page = 1;
  queryString = "";

  constructor(private location: Location, private adminURLService: AdminUrlService, private route: ActivatedRoute) {
    this.selectedTab = this.route.snapshot.params.setTab;
    this.page = this.route.snapshot.params.page;
    this.queryString = this.route.snapshot.queryParamMap.get('query');
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.uiTabs.setActiveTab(this.selectedTab);
  }

  updateUrl($event: any) {

    this.selectedTab = this.uiTabs.getActiveElement().activeTabIndex + 1;
    this.location.replaceState(this.adminURLService.adminWhosWho(this.selectedTab, 1, true));
  }
}
