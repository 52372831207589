import {BaseSearchDTO} from './baseSearchDTO.interface';

export interface DealSearchDTO extends BaseSearchDTO {
  filters?: {
    buyerCustomerId?: string;
    sellerCustomerId?: string;
    fromDateTime?: string;
    toDateTime?: string;
    advertIds?: string[];
    assigneeId?: string;
    completeState?: boolean;
  };
}
