export enum LeadEventTypeEnum {
  ShowLeadModal,
  ShowCustomerModal,
  ShowLeadContactModal,
  ShowLeadNoteModal,
  ShowReassignLeadModal,
  ShowLeadCustomerAddressModal,
  ShowLeadVehicleFinanceModal,
  LeadCustomerAdded,
  LeadContactAdded, // Also used for Updated
  LeadAdded,
  LeadUpdated,
  LeadNoteAdded,
  DialogClosed,
  ShowBookMovementModal,
  LeadProductsRequest,
  LeadProductsResponse,
  LeadContactLinkAdded = 14, // Also used for Updated
  LeadVehicleFinanceAdded = 15, // Also used for Updated
}
