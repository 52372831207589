import {BaseSearchDTO} from "../API";

export interface MovementSearchDTO extends BaseSearchDTO {
  filters?: {
    id: string;
    customerId?: string;
    advertId?: boolean;
    assignedTo?: string;
    fromDate: string;
    toDate: string;
  };
}
