import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {ApiService, DataServiceInterface} from '../../../global/services';
import {MovementDTO, MovementSearchDTO, SearchResultDTO, ValidatedResultDTO} from '../../../global/interfaces';

@Injectable()
export class AdminMovementService {

  private serviceUrl = '/api/movement';

  constructor(private apiClient: ApiService, private data: DataServiceInterface) {
  }

  search(query: MovementSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }

  get(id: number, query: MovementSearchDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  create(movement: MovementDTO): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: movement}) as Promise<ValidatedResultDTO>;
  }

  delete(id: number): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  patch(movementDTO: MovementDTO, previousDTO: MovementDTO = {}): Promise<ValidatedResultDTO> {

    const patch = compare(previousDTO, movementDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${movementDTO.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<ValidatedResultDTO>;
  }
}
