import {ValuationPillarDTO} from './valuation-pillar-dto.interface';
import {BaseSearchDTO, SelectItemDTO} from "../../../../uinspect/src/app/global/interfaces/index";
import { ValuationLookupTypeEnum } from '../../../../uinspect/src/app/global/enums/index';


export interface ValuationAttributeDTO {
  name: string;
  lookupType: ValuationLookupTypeEnum;
  isPillar: boolean;
  lookupItems: SelectItemDTO[];

  // for mdb-select in UI
  filteredItems: SelectItemDTO[];
  selectedItem;

  // for model and deriv
  parentPillar: ValuationAttributeDTO;
  noParentMessage: string;

  order?: number;

  // used to display count of valuationNodes with values set
  valueCount?: number;
}
