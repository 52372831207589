// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 768px) {
  tr td {
    vertical-align: middle !important;
  }
}
td {
  font-weight: 400;
}

.delete-button {
  font-size: 1.2rem;
}

.status {
  color: #fff;
}
.status.status-1 {
  background-color: var(--successColour);
}
.status.status-2, .status.status-4 {
  background-color: var(--warningColour);
}
.status.status-3, .status.status-5 {
  background-color: var(--dangerColour);
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/maintenance/attribval/attribval.component.scss"],"names":[],"mappings":"AAAA;EAEI;IACE,iCAAA;EAAJ;AACF;AAIA;EAAK,gBAAA;AADL;;AAGA;EAAiB,iBAAA;AACjB;;AACA;EACE,WAAA;AAEF;AAAE;EACE,sCAAA;AAEJ;AACE;EACE,sCAAA;AACJ;AAEE;EACE,qCAAA;AAAJ","sourcesContent":["@media all and (min-width: 768px) {\n  tr  {\n    td {\n      vertical-align: middle !important;\n    }\n  }\n}\n\ntd { font-weight: 400; }\n\n.delete-button { font-size: 1.2rem; }\n\n.status {\n  color: #fff;\n\n  &.status-1 {\n    background-color: var(--successColour);\n  }\n\n  &.status-2, &.status-4 {\n    background-color: var(--warningColour);\n  }\n\n  &.status-3, &.status-5 {\n    background-color: var(--dangerColour);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
