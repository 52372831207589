export enum BidStatusEnum {
  BID_UNPROCESSED,
  BID_ACCEPTED,
  BID_TOOSOON,
  BID_TOOLOW,
  BID_TOOLATE,
  BID_NOPERMISSION,
  BID_NOBUYITNOW,
  BID_DELETED,
  BID_CANCELLED,
  BID_WITHVENDOR,
  BID_ONHOLD,
  BID_OUTBID,
  BID_EXPIRED,
  BID_LIMIT_REACHED
}

export const BidStatusName = [
  "Pending",
  "Processed",  // is 'accepted' in value terms, but looks misleading in text, hence the change to 'Processed'
  "Too Soon",
  "Too Low",
  "Too Late",
  "Permission",
  "No Buy It Now",
  "Deleted",
  "Cancelled",
  "With Vendor",
  "On Hold",
  "Outbid",
  "Expired"
];
