import {Inject, Injectable} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {EventService} from './event.service';
import {from} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {DataService} from "./data.service";
import {RoleGuardService} from './authguard.service';
import {LoggerService} from '../global/services';
import {MessageAreaEnum, MessageGroupEnum, MessageGroupName} from '../global/enums';
import {MessageDTO} from '../global/interfaces';

@Injectable()
export class SignalRService {

  private hubConnected: Promise<void>;
  private subscriptions: object = {};

  constructor(private eventService: EventService,
              @Inject(DOCUMENT) private document,
              private data: DataService,
              private authGuardService: RoleGuardService,
              private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  public hubConnection: signalR.HubConnection;

  public async subscribeAuctioneerGroup(saleId: string) {
    await this.subscribeGroup(this.groupName(MessageGroupEnum.Auctioneer, saleId));
  }

  public async subscribeSaleGroup(id: string) {
    await this.subscribeGroup(this.groupName(MessageGroupEnum.Sales, id));
  }

  public async subscribeContactGroup(id: string) {
    await this.subscribeGroup(this.groupName(MessageGroupEnum.Contacts, id));
  }

  public async subscribeAdvertGroup(id: string) {
    await this.subscribeGroup(this.groupName(MessageGroupEnum.Adverts, id));
  }

  public async subscribeGlobalGroup(id: number) {
    await this.subscribeGroup(this.groupName(MessageGroupEnum.Global, id.toString()));
  }

  public async unsubscribeSaleGroup(id: string) {
    await this.unsubscribeGroup(this.groupName(MessageGroupEnum.Sales, id));
  }

  public async unsubscribeGlobalGroup(id: number) {
    await this.unsubscribeGroup(this.groupName(MessageGroupEnum.Global, id.toString()));
  }

  public async unsubscribeContactGroup(id: string) {
    await this.unsubscribeGroup(this.groupName(MessageGroupEnum.Contacts, id));
  }

  public async unsubscribeAdvertGroup(id: string) {
    await this.unsubscribeGroup(this.groupName(MessageGroupEnum.Adverts, id));
  }

  public async unsubscribeAuctioneerGroup(saleId: string) {
    await this.unsubscribeGroup(this.groupName(MessageGroupEnum.Auctioneer, saleId));
  }

  public async subscribeGroup(name: string, reconnection: boolean = false) {

    this.hubConnected.then(() => {

      if (this.subscriptions[name] === undefined) {
        this.subscriptions[name] = 0;
      }

      if (this.subscriptions[name] === 0 || reconnection) {

        this.hubConnection.invoke("GroupSubscribe", name)
          .then(() => {
            this.logger.info("Successfully subscribed to SignalR Group ", name);
            this.subscriptions[name]++;
            this.logger.info(this.subscriptions[name] + " components subscribed to Group " + name);
          })
          .catch((err) =>
            this.logger.error("Error sending message to hub (when subscribing to : " + name + ")", err)
          );
      } else {
        this.subscriptions[name]++;
        this.logger.info(this.subscriptions[name] + " components subscribed to Group " + name);
      }
    });
  }

  public async unsubscribeGroup(name: string) {

    this.hubConnected.then(() => {

      if (this.subscriptions[name] !== undefined) {
        if (this.subscriptions[name] === 1) {

          this.hubConnection.invoke("GroupUnsubscribe", name)
            .then(() => {
              this.logger.info("Unsubscribed from", name);
            })
            .catch(
              (err) =>
                this.logger.error("Error sending message to hub (when unsubscribing from : " + name, err)
            );
        }
      }
      if (this.subscriptions[name] >= 1) {
        this.subscriptions[name]--;
      }
      this.logger.info(this.subscriptions[name] + " components subscribed to Group " + name);
    });
  }

  public groupName(name: MessageGroupEnum, id: string) {
    const groupName = MessageGroupName[name];
    return groupName + "=" + id;
  }

  public getConnection() {

    this.logger.info("Initiating SignalR connection with ", this.data.messageHubUrl);

    return new signalR.HubConnectionBuilder()
      .withUrl(this.data.messageHubUrl, {
        transport: signalR.HttpTransportType.WebSockets, // Essential for cross-domain CORS
        skipNegotiation: true, // Essential for cross-domain CORS
        accessTokenFactory: () => {
          return this.authGuardService.getTokenPromise();
        }
      })
      .withAutomaticReconnect()
      .build();
  }


  public startConnection = () => {

    this.hubConnection = this.getConnection();

    // Disconnect + reconnect every 5 minutes
    this.hubConnection.serverTimeoutInMilliseconds = 300000;

    this.hubConnection.onreconnected(() => {

      for (const subscription of Object.keys(this.subscriptions)) {

        this.subscribeGroup(subscription, true);
        this.logger.info("Resubscribing to " + subscription);
      }
    });

    this.hubConnected = this.hubConnection
      .start()
      .then(() => {

          this.logger.info("Connection started");

          this.addSignalRListener();
          this.eventService.SignalRConnected.emit();
        }
      )
      .catch(err => this.logger.error('Error while starting connection: ' + err));
  }

  public sendMessageToHub(dto: MessageDTO) {

    const promise = this.hubConnected.then(() => {
      this.hubConnection.invoke("message", dto)
        .then(() => {
          this.logger.info("Message sent successfully to hub");
        })
        .catch((err) =>
          this.logger.info("Error sending message to hub when using SendMessage", err)
        );

    });

    return from(promise);
  }

  public addSignalRListener = () => {

    this.hubConnection.on('message', (data) => {

      // this.logger.info("Received data", data);

      data.messageData = JSON.parse(data.messageData);

      // create an event so any registered components can see the bid has been placed
      switch (data.messageArea) {

        case MessageAreaEnum.Bids: // any bid related event (outbid, highest bidder etc.)
          this.eventService.BidEvent.emit(data);
          break;

        case MessageAreaEnum.InMails:
          // this.logger.info("Emitting InMail message event from API", data);
          this.eventService.InMailEvent.emit(data);
          break;

        case MessageAreaEnum.Adverts:
          this.logger.info("Emit Advert message event from API", data);
          this.eventService.AdvertEvent.emit(data);
          break;

        case MessageAreaEnum.Sales:
          this.logger.info("Emit sale message event from API", data);
          this.eventService.SaleEvent.emit(data);
          break;

        case MessageAreaEnum.Contacts:
          this.logger.info("Emit contact message event from API", data);
          this.eventService.ContactEvent.emit(data);
          break;

        case MessageAreaEnum.Auctioneer:
          this.logger.info("Emit auctioneer message event from API", data);
          this.eventService.AuctioneerEvent.emit(data);
          break;

        case MessageAreaEnum.Global:
          this.logger.info("Emit global message event from API", data);
          this.eventService.GlobalEvent.emit(data);
          break;
      }
    });
  }
}
