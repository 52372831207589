import {Component, Inject, OnInit, ViewEncapsulation, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import {DomainData, GlobalConstants} from '../../global/shared';
import {} from '../../global/interfaces';
import {LoggerService} from '../../global/services';
import {} from '../../global/enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {

  public static globals: DomainData;
  public isMobile = GlobalConstants.IsMobile;
  public isDevice = GlobalConstants.IsDevice;

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private titleService: Title,
              private metaService: Meta,
              private zone: NgZone,
              private logService: LoggerService
  ) {

    AppComponent.globals = GlobalConstants.getPlatformDetails(GlobalConstants.CompanyProductCode.Remarq, window.location.host);

    if (AppComponent.globals == null) {
      console.log("NAVIGATING TO NOT CONFIGURED " + window.location.host);
      this.router.navigate(['/assets/html/notConfigured.html']);
    }

    this.theme = AppComponent.globals.theme;
    this.platformName = AppComponent.globals.platformName;
    this.loadCSSForTheme();
    this.setTitle();
    this.setMeta();
    this.setFavicon();

    if (Capacitor.isNativePlatform) {
      // console.log('ADDING MOBILE LISTENER');
      this.addMobileListeners();
    }

    // Load the YouTube IFrame API
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(script);
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  public showChatWidget = false;
  public theme = '';
  platformName: string;

  async ngOnInit() {

  }

  loadCSSForTheme() {

    const head = this.document.getElementsByTagName('head')[0];

    this.appendStyle(head, 'global-style', 'global-styles');
    this.appendStyle(head, 'global-mobile', 'global-mobile');
    this.appendStyle(head, 'global-variables', 'global-variables');
    this.appendStyle(head, 'global-app-style', 'app.component');
    this.appendStyle(head, 'client-theme', AppComponent.globals.theme + '-theme');
    this.appendStyle(head, 'client-variables', AppComponent.globals.theme + '-variables');
  }

  appendStyle(head, id, cssPath) {

    const themeLink = this.document.getElementById(id) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = cssPath;
    } else {
      const style = this.document.createElement('link');
      style.id = id;
      style.rel = 'stylesheet';
      style.href = `${cssPath}.css`;

      head.appendChild(style);
    }

  }

  setTitle() {
    this.titleService.setTitle(this.platformName + ' - Trading Platform');
  }

  setMeta() {
    this.metaService.addTags([
      {name: 'keywords', content: this.platformName + ',Trading,Vehicle Remarketing,Car Auction'},
      {name: 'description', content: this.platformName + ' Online Vehicle Remarketing - Trading Platform'}
    ]);
  }

  setFavicon() {
    const faviconId = this.document.getElementById('favicon') as HTMLLinkElement;
    if (faviconId != null) {
      faviconId.href = '/assets/sites/' + this.theme + '/images/favicon.ico';
    }

    const appleTouchIcon = this.document.getElementById('apple-touch-icon') as HTMLLinkElement;
    if (appleTouchIcon != null) {
      appleTouchIcon.href = '/assets/sites/' + this.theme + '/images/apple-touch-icon.png';
    }

    const icon16 = this.document.getElementById('png16') as HTMLLinkElement;
    if (icon16 != null) {
      icon16.href = '/assets/sites/' + this.theme + '/images/favicon-16x16.png';
    }

    const icon32 = this.document.getElementById('png32') as HTMLLinkElement;
    if (icon32 != null) {
      icon32.href = '/assets/sites/' + this.theme + '/images/favicon-32x32.png';
    }
  }

  loadCSS(id, theme, cssType) {

    const themeLink = this.document.getElementById(id) as HTMLLinkElement;
    themeLink.href = (id === 'main-variables' ? `../../assets/css/${cssType}.css` : `../../assets/sites/${theme}/css/${cssType}.css`);
  }

  addMobileListeners() {

    // console.log('Adding Mobile Listener');

    App.addListener('appStateChange', ({isActive}) => {

      // console.log('App State Changed');

      this.logger.warn('App state changed. Is active?', isActive);
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {

      // console.log('App Url Open');

      this.zone.run(() => {

        this.logger.debug('App opened with URL:', event.url);

        // Remove https://domainname from https://domainname/abc/def
        const appPath = event.url.replace(/^.*\/\/[^\/]+/, '');

        this.logger.debug('Revised app path ', appPath);

        /* DB REDIRECT AFTER LOGIN ON MOBILE --- FIX
        (Auth as any)._handleAuthResponse(appPath).then(() => {
          this.logger.debug('Navigating to ', appPath);
          this.router.navigateByUrl(appPath);
        });
        */
      });
    });

    App.addListener('appRestoredResult', data => {
      this.logger.debug('Restored state:', JSON.stringify(data));
    });
  }
}
