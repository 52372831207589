// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-items { font-weight: 400; }
.contact-details { font-size: 0.75rem; }
`, "",{"version":3,"sources":["webpack://./projects/common/components/contact-us/contact-us.component.css"],"names":[],"mappings":"AAAA,aAAa,gBAAgB,EAAE;AAC/B,mBAAmB,kBAAkB,EAAE","sourcesContent":[".row-items { font-weight: 400; }\n.contact-details { font-size: 0.75rem; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
