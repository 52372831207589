// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: var(--footerBackgroundColour);
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
}

.footer-item {
  padding: 6px 15px;
  vertical-align: middle;
  height: 30px;
}
.footer-item a, .footer-item a:visited {
  color: rgba(255, 255, 255, 0.6) !important;
}
.footer-item:not(:last-child) {
  border-right: 1px solid #444;
}
.footer-item:last-child {
  padding-right: 0px;
}

.top-section a, .top-section a:visited {
  font-weight: 500 !important;
  color: var(--colour19) !important;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/common/app-footer/app-footer.component.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,+BAAA;EACA,eAAA;AACF;;AAGA;EACE,iBAAA;EACA,sBAAA;EACA,YAAA;AAAF;AAEE;EAAe,0CAAA;AACjB;AACE;EACE,4BAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;;AAME;EACE,2BAAA;EACA,iCAAA;AAHJ","sourcesContent":["footer {\n  background-color: var(--footerBackgroundColour);\n  color: rgba(255,255,255, 0.6);\n  font-size: 12px;\n\n}\n\n.footer-item {\n  padding: 6px 15px;\n  vertical-align: middle;\n  height: 30px;\n\n  a, a:visited { color: rgba(255,255,255, 0.6)  !important; }\n\n  &:not(:last-child) {\n    border-right: 1px solid #444;\n  }\n\n  &:last-child {\n    padding-right: 0px;\n  }\n}\n\n.top-section {\n\n  a, a:visited {\n    font-weight: 500 !important;\n    color: var(--colour19) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
