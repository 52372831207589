import {DatePipe, formatDate} from '@angular/common';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AdminTaskTypeEnum, StatusEnum} from '../../../global/enums';
import {AdminTaskDTO, AdminTaskSearchDTO, AdvertNoteDTO, ContactDTO, User} from "../../../global/interfaces";
import {AdminUrlService, AdminWhosWhoService} from "../services";
import {AdminTaskService} from "../services/admin-task.service";
import {IOption, ModalDirective} from "ng-uikit-pro-standard";
import {EventService} from "../../../services";
import {HelpersService, UserService} from '../../../global/services';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {EMPTY_GUID} from 'projects/common/shared';

@Component({
  templateUrl: './admin-tasks-view.component.html',
  styleUrls: ['./admin-tasks-view.component.scss'],
  providers: [DatePipe]
})
export class AdminTasksViewComponent implements OnInit {

  page = 1;
  count = 0;
  overdueCount = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;
  tasks: AdminTaskDTO[];
  isBusy = false;
  sortBy = "sleepUntil";
  protected readonly StatusEnum = StatusEnum;

  @ViewChild('reschedule') mdbModal: ModalDirective;

  selectedTask: AdminTaskDTO;
  editingTask: AdminTaskDTO = null;
  priorities = [];
  statusEnum = StatusEnum;

  public TaskType = AdminTaskTypeEnum;
  statusName: { [p: number]: { name: string; value: number } };
  filterForm: UntypedFormGroup;
  currentUser: User;
  assignedOptions: { label: string; value: string }[];
  rescheduleForm: UntypedFormGroup;
  savingReschedule = false;
  now: moment.Moment;
  sortDescending = true;

  constructor(private adminTaskService: AdminTaskService,
              public adminUrl: AdminUrlService,
              private datePipe: DatePipe,
              private eventService: EventService,
              private whosWhoService: AdminWhosWhoService,
              private helpersService: HelpersService,
              private fb: UntypedFormBuilder,
              private userService: UserService) {

    this.statusName = this.helpersService.getValuesByIndex(StatusEnum);

    this.filterForm = this.fb.group({
      assignedTo: new UntypedFormControl(EMPTY_GUID),
      statusId: new UntypedFormControl(StatusEnum.Active)
    });

    this.rescheduleForm = this.fb.group({
      id: new UntypedFormControl(),
      reminderDate: new UntypedFormControl(),
      reminderTime: new UntypedFormControl()
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.fetchTasks().then(() => {
      });
      this.getOverdueCount().then(() => {
      });
    });

    this.getOverdueCount().then(() => {
    });
    this.fetchTasks().then(() => {
    });

    this.loadAssignees().then(() => {
    });

    this.now = moment();

    // To prevent calling moment all the time in comparisons
    const intervalId = window.setInterval(() => {
      this.now = moment();
    }, 5000);
  }


  async ngOnInit() {
    this.priorities = [
      {value: 1, label: 'Low'},
      {value: 2, label: 'Medium'},
      {value: 3, label: 'High'}
    ];


    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
    });
  }

  async getOverdueCount() {
    const dto = {
      component: 'admin-tasks',
      filters: {
        overdue: true,
        tasksForUserId: this.filterForm.value.assignedTo,
        countOnly: true,
      },
    } as AdminTaskSearchDTO;

    return this.adminTaskService.search(dto).then(res => {
      this.overdueCount = res.totalItems;
    });
  }


  async fetchTasks() {

    this.isBusy = true;

    const dto = {
      component: 'admin-tasks',
      filters: {
        tasksForUserId: this.filterForm.value.assignedTo,
        statusId: this.filterForm.value.statusId
      },
      sortBy: this.sortBy,
      sortDescending: this.sortDescending,
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize
    } as AdminTaskSearchDTO;

    return this.adminTaskService.search(dto).then(res => {

      this.tasks = res.results;
      this.count = res.totalItems;

      this.isBusy = false;
    }).catch(err => {
      this.isBusy = false;
    });
  }

  get f() {
    return this.filterForm.value;
  }

  get rf() {
    return this.rescheduleForm.value;
  }

  onTableDataChange(page: number) {
    // fetch records using page as offset
    this.page = page;
    this.fetchTasks();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchTasks();
  }

  clearTask(event: MouseEvent, adminTask: AdminTaskDTO) {
    event.stopPropagation();
    event.preventDefault();

    this.selectedTask = adminTask;

    this.adminTaskService.clearTask(adminTask.id).then(() => {
      this.selectedTask.sleepUntil = null;
      this.selectedTask.statusId = StatusEnum.Deleted;
      this.getOverdueCount().then(() => {
      });
    });
  }

  rescheduleReminder(event: MouseEvent, adminTask: AdminTaskDTO) {

    event.stopPropagation();
    event.preventDefault();

    this.selectedTask = adminTask;

    this.rescheduleForm.patchValue({
      id: adminTask.id,
      reminderDate: moment(adminTask.sleepUntil).format("YYYY-MM-DD"),
      reminderTime: moment(adminTask.sleepUntil).format("HH:mm")
    });

    this.mdbModal.show();
  }


  navigateTask(task: AdminTaskDTO) {
    if (task.advertNote) {
      window.open(this.adminUrl.adminManageNegotiations(task.advertNote.advertId, true), "_blank");
    }
    if (task.customerNoteId) {
      window.open(this.adminUrl.adminCustomerView(task.customerId, true), "_blank");
    }
  }

  reassignTask(event: MouseEvent, task: AdminTaskDTO) {
    event.stopPropagation();
    event.preventDefault();

    this.selectedTask = task;
    this.eventService.ShowAssigneeDialogEvent.emit();
  }

  onAssigneeSelected(assignee: ContactDTO) {
    this.adminTaskService.patch(this.selectedTask.id,
      {taskForContactId: assignee.id, taskFromContactId: this.userService.CurrentUser.contactId} as AdminTaskDTO, {})
      .then(() => {
        // because admin
        this.fetchTasks();
      });
  }

  onPriorityEdited() {
    if (this.editingTask) {
      this.adminTaskService.patch(this.editingTask.id, {priority: this.editingTask.priority}, {}).then(() => {
      });
      this.editingTask = null;
    }
  }

  onEditPriority(event: MouseEvent, task: AdminTaskDTO) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.editingTask) {
      this.editingTask = task;
    }
  }

  getPriorityString(priority: number): string {
    return this.priorities.find(x => x.value === priority)?.label;
  }

  preventDefault(event: MouseEvent) {
    event.stopPropagation();
  }

  whatIsItAllAbout(task: AdminTaskDTO) {

    if (task.advertNoteId) {
      return (task.advertNote?.advert?.vehicle?.make?.makeName ?? "") + " " +
        (task.advertNote?.advert?.vehicle?.model?.modelName ?? "");
    }

    return "";
  }

  filterToMe() {
    if (this.filterForm.value.assignedTo !== this.currentUser.contactId) {
      this.filterForm.patchValue({assignedTo: this.currentUser.contactId});
    } else {
      this.filterForm.patchValue({assignedTo: EMPTY_GUID});
    }
  }

  async loadAssignees() {
    return this.whosWhoService.getSiteAdmins({component: 'whos-who'}, true).then(result => {
      this.assignedOptions = result.results.map(x => {
        return {label: x.contactName, value: x.id};
      });
    });
  }

  showingAllUsers() {
    return this.filterForm.value.assignedTo === EMPTY_GUID;
  }

  showingAllStatus() {
    return this.filterForm.value.statusId === null;
  }

  addTime(s: string) {

    const numberVal = s.slice(0, -1);
    const unit = s.slice(-1);
    const unitName = {
      h: "hours",
      m: "minutes"
    };

    this.rescheduleForm.patchValue({reminderTime: moment().add(numberVal, unitName[unit]).format("HH:mm")});
    this.rescheduleForm.patchValue({reminderDate: moment().format("YYYY-MM-DD")});
    this.rescheduleForm.controls.reminderTime.markAsDirty();
  }

  clearTime() {
    this.rescheduleForm.patchValue({reminderTime: null});
    this.rescheduleForm.patchValue({reminderDate: null});
  }

  submitReschedule() {

    const newDateTime = formatDate(new Date(this.rescheduleForm.value.reminderDate), 'yyyy-MM-dd', 'en') + " "
      + this.rescheduleForm.value.reminderTime;

    if (!this.selectedTask.taskType) {
      this.selectedTask.taskType = AdminTaskTypeEnum.Call;
    }

    this.adminTaskService.patch(this.rescheduleForm.value.id, {
      sleepUntil: new Date(newDateTime),
      statusId: StatusEnum.Active,
      taskType: this.selectedTask.taskType
    }).then(() => {
      this.selectedTask.sleepUntil = new Date(newDateTime);
    });
    this.mdbModal.hide();
  }

  overDueTask(task: AdminTaskDTO) {
    return moment(task.sleepUntil) < this.now;
  }

  toggleStatus() {
  }

  lastUpdateBy(task: AdminTaskDTO) {
    return this.assignedOptions?.find(x => x.value == task.lastUpdateByContactId)?.label;
  }

  setSortBy(sortBy: string) {

    if (sortBy === this.sortBy) {
      this.sortDescending = !this.sortDescending;
    } else {
      this.sortBy = sortBy;
    }
    this.fetchTasks().then(() => {
    });
  }

  taskStatusName(statusId: number) {

    if (statusId === StatusEnum.Deleted) {
      return "Closed";
    } else {
      return this.statusName[statusId]?.name;
    }
  }

  protected readonly EMPTY_GUID = EMPTY_GUID;
}
