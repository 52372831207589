export interface BookMovementDTO {
  registrationNumber: string;
  kerbWeight: number;
  pickupAddress: BookMovementAddress;
  dropoffAddress: BookMovementAddress;
  pickupContact: BookMovementContact;
  dropoffContact: BookMovementContact;
  bookingForm: {
    email: string;
    phone1: string;
    name: string;
    customerOrderNumber: string;
    purchaseOrderNumber: string;
    bookingContact: BookMovementContact;
  };
}

export interface BookMovementAddress {
  businessName: string,
  addressLine1: string,
  addressLine2: string,
  town?: string,
  county?: string,
  postcode: string,
}

export interface BookMovementContact {
  name: string,
  email: string,
  phone1: string,
}
