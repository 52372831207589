import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {DataService, SignalRService, WatchlistService} from '../../../../services';
import {URLService} from "../../../../services";
import {interval, Subscription} from "rxjs";
import {
  AdvertStatusEnum,
  SaleTypeEnum,
  SaleTypeName,
  ServiceHistoryTypeEnum,
  SoldStatusEnum,
  TimeLeftEnum
} from "../../../../global/enums/index";
import {HelpersService, TimerService, UserService} from "../../../../global/services";
import {AdvertDTO, AdvertSearchSummaryAdvertDTO, User} from "../../../../global/interfaces";

@Component({
  selector: 'app-advert-search-summary',
  templateUrl: './advert-search-summary.component.html',
  styleUrls: ['./advert-search-summary.component.scss'],
})

export class AdvertSearchSummaryComponent implements OnInit {

  public timerLeftWords: any;
  private timerSubscription: Subscription;
  private timeTest: Date;
  private intervalId;
  serviceLookup: { [p: number]: { name: string; value: number } };

  constructor(
    private watchlistService: WatchlistService,
    private helpersService: HelpersService,
    public url: URLService,
    private timerService: TimerService,
  ) {
    // logger.componentName = "Advert Search Summary";
  }

  public saleTypeEnum = SaleTypeEnum;
  public saleTypeName = SaleTypeName;
  public advertStatusEnum = AdvertStatusEnum;
  public soldStatusEnum = SoldStatusEnum;

  @Input() advert: AdvertSearchSummaryAdvertDTO;
  @Input() user: User;


  contactId: string;
  isWatched: boolean;
  timeleft: any;
  yourAdvert = false;


  ngOnInit(): void {

    this.contactId = this.user.contactId;
    this.isWatched = this.watchlistService.advertIsWatched(this.advert.id, this.contactId);

    this.yourAdvert = (this.advert.vehicle.customerId == this.user.customerId);

    this.timerSubscription = interval(100).subscribe(x => {
      this.showCountdown(this.advert.endDateTime, 2);
    });
    this.showCountdown(this.advert.endDateTime, 2);

    this.serviceLookup = this.helpersService.getNamesAndValuesAsObject(ServiceHistoryTypeEnum, true);
  }

  watchListToggle() {

    this.isWatched = !this.isWatched;
    this.watchlistService.updateWatchlist(this.advert.id, this.user.contactId, this.isWatched);
  }

  getImageStyle() {
    return {
      'background-image': 'url(' + this.advert?.vehicle?.primaryImageURL + "?tr=h-200" + ')',
      'border-radius': '8px'
    };
  }

  showCountdown(time: Date, significant) {
    this.timerLeftWords = this.timerLeft(time, significant);
  }

  serviceHistoryTypeName(serviceHistoryType: ServiceHistoryTypeEnum): string {

    return (this.serviceLookup[serviceHistoryType]?.name ?? "Unknown") + " Service History";
  }

  timerLeft(endDateTime: Date, significant: number) {

    const unitArray = this.timerService.timeLeft(endDateTime, 'ASS - Timerleft');
    this.timeleft = unitArray[TimeLeftEnum.TotalSeconds];

    return this.timerService.timerWords(unitArray, "Ended", significant);
  }

  public viewListing(advert) {
    if (advert.soldStatus != SoldStatusEnum.Withdrawn) {
      this.url.advertView(advert.id, true);
    }
  }
}
