export enum ValuationLookupTypeEnum {
  Make,
  Model,
  Age,
  Deriv,

  // the following are node lookups
  Fuel,
  Body,
  Transmission,
  Doors,
  Colour,
  VehicleGrade,
  CatStatus,
  ServiceHistory,
  VATStatus,
  Keys,
  PreviousOwners,
  All,
  Margin
}
