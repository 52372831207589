import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IMyOptions} from 'ng-uikit-pro-standard';
import {AddressService, PlatformService, SaleService, SaleTypeService} from "../../../../services";
import {FormsService} from "../../../../services";
import {AdminUrlService} from "../../services";
import {SaleProfileService} from '../../../../services';
import {SaleDTO, SaleProfileDTO, SaleSearchResultDTO, User} from "../../../../global/interfaces";
import {HelpersService, LoggerService, UserService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";

@Component({
  selector: 'app-admin-sale-edit',
  templateUrl: './sale-edit.component.html',
  styleUrls: ['./sale-edit.component.scss'],
  providers: []
})
export class AdminSaleEditComponent implements OnInit, OnDestroy {

  @ViewChild('saleStart', {static: true}) saleStart: ElementRef;
  @ViewChild('saleEnd', {static: true}) saleEnd: ElementRef;

  public addresses: {}[] = [];
  public saleTypes: {}[] = [];
  public platforms: {}[] = [];
  public saleProfiles;

  submitted = false;
  editForm: UntypedFormGroup;
  createMode = false;
  user: User;
  saleId: string;
  datePipe = new DatePipe('en-gb'); // Use your own locale

  public statuses;


  public myDatePickerOptions: IMyOptions = {
    dateFormat: 'dd/mm/yyyy'
  };
  logger = this.logService.taggedLogger(this.constructor?.name);
  private previousVersion?: SaleDTO;

  constructor(
    private saleService: SaleService,
    private userService: UserService,
    private formsService: FormsService,
    private saleTypeService: SaleTypeService,
    private addressService: AddressService,
    private platformService: PlatformService,
    private fb: UntypedFormBuilder,
    private currentRoute: ActivatedRoute,
    private helpersService: HelpersService,
    private adminUrl: AdminUrlService,
    private router: Router,
    private logService: LoggerService,
    private saleProfileService: SaleProfileService
  ) {

    this.statuses = this.helpersService.getLabelsAndValues(StatusEnum);

    this.logService.debug("Statuses set", this.statuses);
  }

  get f() {
    return this.editForm.controls;
  }

  get err() {
    return this.editForm.errors;
  }

  async ngOnInit() {

    this.saleId = this.currentRoute.snapshot.params.saleId;

    if (this.saleId === undefined) {
      this.createMode = true;
    }

    this.editForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      saleName: new UntypedFormControl('', Validators.required),
      saleTypeId: new UntypedFormControl(''),
      addressId: new UntypedFormControl(''),
      saleStart: new UntypedFormControl(''),
      saleEnd: new UntypedFormControl(''),
      platformId: new UntypedFormControl(''),
      statusId: new UntypedFormControl(''),
      saleStartDate: new UntypedFormControl(''),
      saleStartTime: new UntypedFormControl(''),
      saleEndDate: new UntypedFormControl(''),
      saleEndTime: new UntypedFormControl(''),
      saleProfileId: new UntypedFormControl('')
    });

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });

    await this.getAddresses();
    await this.getPlatforms();
    await this.getSaleTypes();
    await this.getSaleProfiles();

    if (!this.createMode && this.saleId) {
      this.fetchSale(this.saleId);
    }
  }

  getSaleTypes() {

    const st = this.saleTypeService.getAll();
    this.saleTypes = [];
    st.forEach((x) => {
      this.saleTypes.push({value: x.id, label: x.saleTypeName});
    });

    this.logger.debug("Sale Types", this.saleTypes);
  }

  getAddresses() {
    this.addressService.getCustomerAddresses(this.user.customerId).then((data: any) => {
      if (data) {
        this.addresses = [];
        data.forEach((x) => {
          this.addresses.push({value: x.id, label: x.addressName});
        });
      }
    });
  }

  getPlatforms() {

    this.platformService.search().then((data: any) => {
      if (data) {
        this.platforms = [];
        data.forEach((x) => {
          this.platforms.push({value: x.id, label: x.platformName});
        });
      }
    });
  }

  getSaleProfiles() {

    this.saleProfileService.getSaleProfiles().then((data: SaleProfileDTO[]) => {
      if (data) {
        this.saleProfiles = [];
        data.forEach((x) => {
          this.saleProfiles.push({value: x.id, label: x.profileName});
        });
      }
    });
  }

  fetchSale(saleId: string) {

    this.saleService.getSale(this.saleId, {component: "AdminSaleEdit"}).then((data: SaleSearchResultDTO) => {

      if (data.sale) {

        let sale = data.sale;

        if (sale.saleStart != null) {
          const startDate = sale.saleStart.split("T")[0];
          const startTime = sale.saleStart.split("T")[1];
          this.editForm.patchValue({saleStartDate: startDate, saleStartTime: startTime});
        }

        if (sale.saleEnd != null) {
          const endDate = sale.saleEnd.split("T")[0];
          const endTime = sale.saleEnd.split("T")[1];
          this.editForm.patchValue({saleEndDate: endDate, saleEndTime: endTime});
        }

        this.editForm.patchValue({...sale});
        this.editForm.patchValue({id: sale.id});


        this.previousVersion = this.editForm.value;
      }
    });
  }

  formValidator(): ValidatorFn {

    return (control: UntypedFormGroup): ValidationErrors | null => {

      const errors: ValidationErrors = {};

      if (control.get('saleName').invalid) {
        errors.noSaleName = true;
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  dateChosen(event) {
  }

  async onSubmit() {

    this.submitted = true;

    if (this.editForm.invalid) {
      return;
    }

    const saleInfo: SaleDTO = this.editForm.value;

    if (this.f.saleStartDate.value != null) {
      saleInfo.saleStart = this.f.saleStartDate.value + " " + this.f.saleStartTime.value;
    }

    if (this.f.saleEndDate != null) {
      saleInfo.saleEnd = this.f.saleEndDate.value + " " + this.f.saleEndTime.value;
    }

    // TODO: Ensure we save as BST (or we display as UTC)
    // TODO: Provide optional DISPLAY formatting for date/time input

    if (this.createMode) {

      const x = this.saleService.createSale(saleInfo).then(() => {
        this.router.navigate([this.adminUrl.adminSales(true)]);
      });


    } else {

      this.saleService.patchSale(this.saleId, saleInfo, this.previousVersion).then(() => {
        this.router.navigate([this.adminUrl.adminSales(true)]);
      });
    }

  }

  cancel() {
    this.router.navigate([this.adminUrl.adminSales(true)]);
  }

  ngOnDestroy() {
  }
}
