import {BidStatusEnum} from "../../../enums/bid-status.enum";
import {VehicleDTO} from '../Vehicle/vehicleDTO.interface';
import {BaseIntDTO} from '../_baseIntDTO.interface';
import {BaseDTO} from '../_baseDTO.interface';

export interface AdvertSummaryDTO extends BaseDTO {
  currentPrice?: number;
  bidIncrement?: number;
  soldStatus?: number;
  advertStatus?: number;
  startPrice?: number;
  bidCount?: number;
  statusId?: number;
  topBidGuid?: string;
  ggg?: number;
  reserveMet?: boolean;
  t?: number;  // Contact ID of top bidder
  bidsSummary?: BidsSummaryDTO;
  endDateTime?: Date;
  //vehicle?: VehicleDTO;
}

export interface BidsSummaryDTO {
  bidders?: BidderListDTO;
}

export interface BidderListDTO {
  [key: number]: ContactBidsDTO[];
}

export interface ContactBidsDTO {
  id?: string;
  bidAmt?: number;
  bidStatus?: BidStatusEnum;
}

