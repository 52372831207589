import {FaultCategoryDTO} from "./fault-category.dto.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {FaultStatusDTO} from "./fault-status.dto.interface";

export interface FaultItemDTO extends BaseIntDTO {
  faultCategoryId?: number;
  faultCategory?: FaultCategoryDTO;
  name?: string;
  info?: string;
  sequence?: number;
  faultStatusId?: number;
  faultStatus?: FaultStatusDTO;
  freeText?: string;
}
