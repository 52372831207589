import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DataService, EventService, SignalRService, URLService} from '../../services';
import {LoggerService, TimerService, UserService} from '../../global/services/index';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from 'rxjs';
import {MessageDTO, User} from "../../global/interfaces";
import {} from "../../global/services";
import {MessageTypeEnum} from "../../global/enums";
import {GlobalConstants} from "../../global/shared";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {

  private loadAPI: Promise<any>;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private url: URLService,
    private eventService: EventService,
    private signalRService: SignalRService,
    private dataService: DataService,
    private timerService: TimerService,
    private logService: LoggerService,
    private renderer: Renderer2
  ) {

    this.signalRService.startConnection();
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  public wrongVersion = false;
  public user: User;
  connectedSub: Subscription;
  contactEventSubscription: Subscription;
  globalEventSubscription: Subscription;

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;

      this.loadAPI = new Promise((resolve) => {
        console.log('resolving promise...');
        this.loadChat();
      });
    });

  }

  async ngAfterViewInit() {

    this.connectedSub = this.eventService.SignalRConnected.subscribe(() => {
      this.logger.info("Connected to SignalR");
      this.signalRService.subscribeContactGroup(this.user.contactId).then(() => {
      });
      this.signalRService.subscribeGlobalGroup(1).then(() => {
      });
    });

    this.contactEventSubscription = this.eventService.ContactEvent.subscribe((msg: MessageDTO) => {
      this.handleContactEvent(msg);
    });


  }

  public loadChat() {
    console.log('preparing to load...');
    let node = document.createElement('script');
    node.src = "assets/thirdparty/salesiq/widget.js";
    node.type = 'text/javascript';
    node.dataset.param1 = (this.user?.name ?? '') + " - " + (this.user?.customerName ?? '');
    node.dataset.param2 = (this.user?.email ?? '');
    node.dataset.param3 = (this.user?.contactPhone ?? '');
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }


  handleContactEvent(msg) {

    if (msg.messageType == MessageTypeEnum.FrontEndVersion) {
      if (msg.messageData != GlobalConstants.FrontEndVersion) {
        this.logger.error("Incorrect Version. We are: ", GlobalConstants.FrontEndVersion, " Received ", msg.messageData);
        this.wrongVersion = true;
      }
    }

    if (msg.messageType == MessageTypeEnum.ServerTime) {
      this.timerService.updateServerTime(msg.messageData);
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.connectedSub) {
      this.connectedSub.unsubscribe();
    }

    if (this.contactEventSubscription) {
      this.contactEventSubscription.unsubscribe();
    }

    if (this.globalEventSubscription) {
      this.globalEventSubscription.unsubscribe();
    }
  }
}
