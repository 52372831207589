import {EventEmitter} from '@angular/core';

export enum VehicleEventEnum {
  MediaDeletedEvent,
  MediaUpdatedEvent,
  AppraisalUpdated,
  DetailsMediaChanged,
  DetailMediaDeletedEvent,
  EnterVRMEvent
}
