import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeHtmlEntities'
})
export class DecodeHtmlEntitiesPipe implements PipeTransform {
  private element: HTMLTextAreaElement;

  constructor() {
    // Create a temporary textarea element specifically
    this.element = document.createElement('textarea') as HTMLTextAreaElement;
  }

  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Use the textarea element to decode all HTML entities
    this.element.innerHTML = value;
    return this.element.value;
  }
}
