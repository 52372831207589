import {ScanImageDTO} from "./scanImageDTO.interface";

export interface ScanVehicleSearchResultWrapperDTO {
  totalResults: number;
  results: ScanVehicleSearchResultDTO[];
}

export interface ScanVehicleSearchResultDTO {
  id: number;
  vrm: string;
  uniqueId: string;
  scanCustomerId: number;
  scanVehicleGuid: string;
  title: string;
  primaryImageUrl: string;
  makeName: string;
  modelName: string;
  derivName: string;
  fuelTypeName: string;
  transmissionTypeName: string;
  bodyTypeName: string;
  plateName: string;
  co2: string;
  doors?: number;
  odometer?: number;
  imageCount: string;
  engineCC: string;
  batteryKwH: string;
  price?: number;
  colour: string;
  dateRegistered: string;
  scanImages: ScanImageDTO[];
  customerName: string;
}
