import {BaseDTO} from "../_baseDTO.interface";
import {UInspectQuestionDTO} from "./uinspect-question-dto.interface";

export interface UInspectQuestionOptionDTO extends BaseDTO {
  uInspectQuestionId?: string;
  uiInspectQuestion?: UInspectQuestionDTO;
  optionValue?: string;
  optionLabel?: string;
  nextSectionId?: number;
  sequence?: number;
  mappedToValue?: string;
}
