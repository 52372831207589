export interface WhosWhoEnquiryInMailDTO {
  date: Date;

  advertEndDateTime?: Date;
  saleTypeId: number;
  saleType: string;
  message: string;

  enquirer: WhosWhoEnquiryContactDTO;

  enquiryTarget: WhosWhoEnquiryContactDTO;

  buyerAssignee?: string;
  sellerAssignee?: string;

  advertId: string;
  primaryImageURL: string;

  vrm: string;
  vehicleColour: string;
  vehicleMake: string;
  vehicleModel: string;
  vehiclePlate: string;
}

export interface WhosWhoEnquiryContactDTO {
  name: string;
  email: string;
  phone: string;
  customerName: string;
  assignedTo: string;
}
