// NOTE: Think of "MessageGroups" as audiences (with the exception of Bid)

// NOTE: InMail updates arrive via the Contacts channel
export enum MessageGroupEnum {
  Bids = 0,
  Adverts,
  Sales,
  User,
  Contacts,
  Global,
  Auctioneer,
}

// NOTE: InMail updates arrive via the Contacts channel
export const MessageGroupName = [
  "Bids",
  "Adverts",
  "Sales",
  "User",
  "Contacts",
  "Global",
  "Auctioneer"
];
