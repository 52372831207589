import { BaseSearchDTO } from "../baseSearchDTO.interface";

export interface AdvertSearchDTO extends BaseSearchDTO {
  filters?: {
    vrm?: string;
    customerId?: string;
    addressId?: string;
    customerIds?: string[];
    saleName?: string;
    addressIds?: string[];
    saleId?: string;
    saleIds?: string[];
    keywords?: string;
    vehicleTypeIds?: number[];
    makeIds?: number[];
    modelIds?: number[];
    derivativeIds?: number[];
    fuelTypeIds?: number[];
    transmissionTypeIds?: number[];
    bodyTypeIds?: number[];
    mileageIds?: number[];
    ageRange?: number[];
    capacityIds?: number[];
    doors?: number[];
    plateIds?: number[];
    priceIds?: number[];
    vatStatusIds?: number[];
    colourIds?: number[];
    saleTypeId?: number;
    searchSortingEnum?: number;
    sortingFilterEnum?: number;
    soldStatuses?: number[];
    advertStatuses?: number[];
    statusId?: number;
    excludeBidSummary?: boolean;
    runner?: number;
    catStatus?: number;
    page?: number;
    id?: string;
  }
}
