import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from "./environments/environment";

import AWSConfig from "../../common/shared/aws/aws-exports";
import {Amplify} from 'aws-amplify';

import * as Sentry from '@sentry/angular';

Amplify.configure(AWSConfig);

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://09b2dd5e16e04b969a422024c6f4ff10@o4507901162422272.ingest.de.sentry.io/4507901166223440",
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/tradesales\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});


platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
