import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsetComponent} from "ng-uikit-pro-standard";
import {MessageDTO, User} from "../../../../global/interfaces";
import {LoggerService, UserService} from "../../../../global/services";
import {MessageAreaEnum, MessageTypeEnum, SoldStatusEnum} from "../../../../global/enums";
import {Location} from "@angular/common";
import {AdvertService, EventService, URLService} from "../../../../services";
import {Subscription} from "rxjs";

@Component({
  selector: "app-seller-hub",
  templateUrl: "./seller-hub.component.html",
  styleUrls: ["./seller-hub.component.scss"],
})
export class SellerHubComponent implements OnInit, OnDestroy, AfterViewInit {

  public user: User;
  public advertCount: number[] = [null, null, null, null, null, null];
  public offerCount: number;
  public soldStatusEnum = SoldStatusEnum;
  reloadTabEventSub: Subscription;

  @ViewChild('tabs') uiTabs: TabsetComponent;
  selectedTab: number;
  contactEventSubscription: Subscription;

  constructor(private userService: UserService,
              private route: ActivatedRoute,
              public urlService: URLService,
              private location: Location,
              private advertService: AdvertService,
              private router: Router,
              private eventService: EventService,
              private logger: LoggerService) {

    this.selectedTab = this.route.snapshot.params.setTab;

  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;

      this.getAdvertCounts();
    });

    this.reloadTabEventSub = this.eventService.ReloadStockTab.subscribe(() => {
      this.getAdvertCounts();
    });
  }

  async ngAfterViewInit() {

    this.uiTabs.setActiveTab(this.selectedTab);

    this.contactEventSubscription = this.eventService.ContactEvent.subscribe((msg: MessageDTO) => {
      this.logger.info("Contact event from API Server", msg);
      this.handleContactEvent(msg);
    });

  }

  ngOnDestroy() {
    if (this.reloadTabEventSub) {
      this.reloadTabEventSub.unsubscribe();
    }
  }

  getAdvertCounts() {
    this.advertService.getSellerCounts(this.user.customerId).then((x) => {

      Object.keys(x.counts).forEach((index) => {
        this.advertCount[index] = x.counts[index];
      });
    });
  }

  async setOfferCount(event) {

    this.offerCount = event;
    this.advertCount[99] = event;
  }

  async setAdvertCount(item, event) {

    this.advertCount[item] = event;
  }

  updateUrl() {
    this.selectedTab = this.uiTabs.getActiveElement().activeTabIndex + 1;
    this.location.replaceState(this.urlService.stock(this.selectedTab, true));
    this.advertService.CurrentStockTab = this.selectedTab;
  }

  private handleContactEvent(msg: MessageDTO) {

    if (msg.messageArea == MessageAreaEnum.Contacts) {

      switch (msg.messageType) {

        case MessageTypeEnum.OfferAlert:
          this.setAdvertCount(99, msg.messageData.offerCount);
          break;

      }
    }
  }
}
