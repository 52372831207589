// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner {
  max-height: 400px;
  overflow-y: auto;
}

.table-narrow td {
  font-size: 0.8rem;
}

.make-model {
  font-weight: 600;
}

.deriv, .summary1, .summary2 {
  font-size: 0.8rem;
}

.vehicle-status, .advert-status, .sold-status, .added {
  font-size: 0.8rem;
}

.highlight td {
  background-color: #dff0d8 !important;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/vehicle/vehicle-history/vehicle-history.component.scss"],"names":[],"mappings":"AAAA;EAAS,iBAAA;EAAmB,gBAAA;AAG5B;;AAFA;EAAmB,iBAAA;AAMnB;;AAJA;EAAc,gBAAA;AAQd;;AAPA;EAAgC,iBAAA;AAWhC;;AATA;EAAwD,iBAAA;AAaxD;;AAXA;EAAgB,oCAAA;AAehB","sourcesContent":[".inner { max-height: 400px; overflow-y: auto; }\n.table-narrow td { font-size: 0.8rem; }\n\n.make-model { font-weight: 600; }\n.deriv, .summary1, .summary2  { font-size: 0.8rem; }\n\n.vehicle-status, .advert-status, .sold-status, .added { font-size: 0.8rem; }\n\n.highlight td { background-color: #dff0d8 !important; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
