import {Injectable} from '@angular/core';
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";
import {SaleProfileDTO, SaleSearchProfileDTO} from '../global/interfaces';

@Injectable({
  providedIn: 'root'
})
export class SaleProfileService {

  private serviceUrl = '/api/sale-profile';
  private searchProfileUrl = '/api/saleSearchProfile';

  constructor(private apiClient: ApiService, private data: DataService) {}

  public getSaleProfiles(): Promise<SaleProfileDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url}) as Promise<SaleProfileDTO[]>;
  }

  public createSaleProfile(profileDTO: SaleProfileDTO): Promise<SaleProfileDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: profileDTO}) as Promise<SaleProfileDTO>;
  }

  public deleteSaleProfile(profileId) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${profileId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public addSearchProfile(dto: SaleSearchProfileDTO) {
    const url = `${this.data.apiUrl}${this.searchProfileUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<any>;
  }

  public deleteSaleSearchProfile(saleSearchProfileId: string) {
    const url = `${this.data.apiUrl}${this.searchProfileUrl}/${saleSearchProfileId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public createSaleFromProfile(saleProfileId: string, advertIds: string[]) {
    const url = `${this.data.apiUrl}${this.searchProfileUrl}/createSale`;
    return this.apiClient.post({url, data: { saleProfileId, advertIds }}) as Promise<any>;
  }
}
