import {Injectable} from "@angular/core";
import {DataService} from "../../../services";
import { ApiService } from "../../../global/services";
import {InvoiceDetailDTO} from "../../../interfaces/DTOs/Payment/invoice-detail.dto.interface";

@Injectable()
export class AdminAccountsService {

  private serviceUrl = '/api/accounts';

  constructor(
    private apiClient: ApiService,
    private data: DataService) {
  }

  getTenants(): Promise<any>
  {
    const url = `${this.data.apiUrl}${this.serviceUrl}/tenants`;
    return this.apiClient.get({url}) as Promise<InvoiceDetailDTO>;
  }

}
