import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {compare} from "fast-json-patch";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces";
import {ImageService} from "../image.service";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {UInspectMediaSearchDTO} from "../../interfaces/DTOs/UInspect/API";
import {UInspectMediaDTO, UInspectMediaSyncDTO} from "../../interfaces/DTOs/UInspect";
import {MediaTypeFromMime} from "../../enums";
import {LeadDocumentDTO} from "../../../crm/src/app/interfaces/lead-document.interface";
import {CreateAppraisalDocDTO} from "../../interfaces/DTOs/UInspect/create-appraisal-doc.dto.interface";

@Injectable()
export class UInspectMediaService {

  private serviceUrl = '/api/uinspect/media';
  private vehicleMediaUrl = '/api/vehicleMedia';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
    private toast: ToastService,
    private commonImageService: ImageService,
  ) {
    // this.logging.componentName = "UInspectMediaService";
  }

  public search(query?: UInspectMediaSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  public get(id: string, query: UInspectMediaSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  public add(dto: UInspectMediaDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: string, patchData: UInspectMediaDTO, previousValue: UInspectMediaDTO = {}):
    Promise<ValidatedResultDTO> {
    const data = compare(previousValue, patchData);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: string): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }

  public getAppraisalMedia(uInspectId: string): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/uinspect/${uInspectId}/media`;
    return this.apiClient.get({url}) as Promise<SearchResultDTO>;
  }

  public createLeadDocument(data: CreateAppraisalDocDTO) {
    // call the LeadCRM api
    const url = `${this.data.apiUrl}/api/lead-document/create-from-appraisal-media`;
    return this.apiClient.post({url, data, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  create(data: LeadDocumentDTO): Promise<ValidatedResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    return this.apiClient.post({url, data, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  public async uploadMedia(uploadMediaDTO: UInspectMediaSyncDTO, files: File[] = [], dataUrls: string[] = []): Promise<any> {

    const promises = [];
    const sendFiles: string[] = [];

    if (uploadMediaDTO.mediaType == null) {
      uploadMediaDTO.mediaType = [];
    }

    // If we had a list of FILES[] then convert them to dataURLS
    for (const file of files) {

      const mediaFileType = MediaTypeFromMime[file.type] ?? null;

      if (mediaFileType == null) {
        // How do we raise an error to the front end ??
      } else {

        uploadMediaDTO.mediaType.push(MediaTypeFromMime[file.type] ?? null);

        // Convert FILE into DataURL
        promises.push(this.commonImageService.convertFileToDataURL(file).then((fileContent) => {
          sendFiles.push(this.commonImageService.removeFileTypePrefix(fileContent));
        }));
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }

    // If we were given a set of dataURLS
    dataUrls.forEach((x) => {

      const fileType = x.substring(x.indexOf(':') + 1, x.indexOf(';'));

      const mediaFileType = MediaTypeFromMime[fileType];

      uploadMediaDTO.mediaType.push(mediaFileType);
      sendFiles.push(this.commonImageService.removeFileTypePrefix(x));
    });

    const data = {
      mediaUpload: uploadMediaDTO,
      files: sendFiles
    };

    const url = `${this.data.apiUrl}/api/uinspect/${uploadMediaDTO.uInspectId}/media`;
    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}});
  }
}
