import {Injectable} from "@angular/core";
import {ApiService, LoggerService} from "../../global/services";
import {DataService} from "../data.service";
import {
  FaultCategoryDTO, FaultCheckTypeDTO, FaultItemDTO,
  FaultStatusDTO, TypedSearchResultDTO, TypedValidatedResultDTO, VehicleFaultCategorySearchDTO,
  VehicleFaultCheckDTO,
  VehicleFaultCheckItemDTO,
  VehicleFaultCheckSearchDTO, VehicleFaultCheckTypeSearchDTO, VehicleFaultItemSearchDTO, VehicleFaultStatusSearchDTO
} from "../../global/interfaces";
import {compare} from "fast-json-patch";

@Injectable()
export class MechanicalFaultsService {

  private serviceUrl = '/api/vehicle-fault';

  logger = this.logService.taggedLogger(this.constructor?.name);

  constructor(private apiClient: ApiService,
              private data: DataService,
              private logService: LoggerService) {
  }

  public searchCategories(search: VehicleFaultCategorySearchDTO = null):
    Promise<TypedSearchResultDTO<FaultCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/categories`;

    return this.apiClient.get({
      url,
      headers: {accept: 'application/json'}
    }, {query: search}) as Promise<TypedSearchResultDTO<FaultCategoryDTO>>;
  }

  public searchCheckTypes(search: VehicleFaultCheckTypeSearchDTO = null):
    Promise<TypedSearchResultDTO<FaultCheckTypeDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-types`;

    return this.apiClient.get({
      url,
      headers: {accept: 'application/json'}
    }, {query: search}) as Promise<TypedSearchResultDTO<FaultCheckTypeDTO>>;
  }

  public getCheckType(id: number, search: VehicleFaultCheckTypeSearchDTO = null):
    Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type/${id}`;

    return this.apiClient.get({
      url,
      headers: {accept: 'application/json'}
    }, {query: search}) as Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>>;
  }

  public getVehicleFaultCheck(id: string, search: VehicleFaultCheckSearchDTO = null):
    Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check/${id}`;
    return this.apiClient.get({
      url,
      headers: {accept: 'application/json'}
    }, {query: search}) as Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>>;
  }

  public searchFaultStatuses(query: VehicleFaultStatusSearchDTO): Promise<TypedSearchResultDTO<FaultStatusDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/statuses`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<TypedSearchResultDTO<FaultStatusDTO>>;
  }

  public searchFaultItems(query: VehicleFaultItemSearchDTO): Promise<TypedSearchResultDTO<FaultItemDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/items`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<TypedSearchResultDTO<FaultItemDTO>>;
  }

  public getLatestVehicleFaultCheck(vehicleId: string, search: VehicleFaultCheckSearchDTO = null):
    Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>> {

    const url = `${this.data.apiUrl}/api/vehicle/${vehicleId}/fault-check/latest`;

    return this.apiClient.get({
      url,
      headers: {accept: 'application/json'}
    }, {query: search}) as Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>>;
  }

  public createVehicleFaultCheck(vehicleId: string, data: VehicleFaultCheckDTO = null):
    Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>> {

    if (data == null) {
      data = {vehicleId, faultCheckItems: []};
    }

    const url = `${this.data.apiUrl}/api/vehicle/${vehicleId}/fault-check`;
    return this.apiClient.post({url, data, headers: {accept: 'application/json'}});
  }

  public updateVehicleFaultCheckItem(vehicleFaultCheckId: string, faultItemId: number, dto: VehicleFaultCheckItemDTO) {
    const url = `${this.data.apiUrl}/api/vehicle-fault/` + vehicleFaultCheckId + "/fault-item/" + faultItemId;
    return this.apiClient.put({url, data: dto}) as Promise<TypedValidatedResultDTO<VehicleFaultCheckItemDTO>>;
  }

  public updateVehicleFaultCheck(id: string, dto: VehicleFaultCheckDTO, previous = {}) {
    const patch = compare(previous, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/check/${id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<TypedValidatedResultDTO<VehicleFaultCheckDTO>>;
  }

  public mapCategoryItems(faultCategories: FaultCategoryDTO[], vehicleFaultCheck: VehicleFaultCheckDTO) {

    faultCategories = faultCategories.map(category => {
        category.faultItems.forEach(item => {

          const vc = vehicleFaultCheck?.faultCheckItems
            .find(x => x.faultItemId === item.id);

          if (vc) {
            item.faultStatus = vc.faultStatus;
            item.faultStatusId = vc.faultStatusId;
            item.freeText = vc.freeText;
          } else {
            item.faultStatusId = 1; // not tested
          }
        });

        return category;
      }
    );
  }
}
