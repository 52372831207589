export enum ContactActionTypeEnum {
  Registration = 0,
  LoginAttempt = 1,
  LoginSuccess = 2,
  PasswordRequest = 3,
  Search = 4,
  ReadExternalMail = 5,
  ReadInternalMail = 6,
  Logout = 7,

  AddToWatchlist = 8,
  RemoveFromWatchlist = 9,
  BidOnAdvert = 10,
  VehiclePurchase = 11,
  PaidInvoice = 12,
  SavedSearch = 13,
  DeletedSearch = 14,
  ViewAdvert = 15
}
