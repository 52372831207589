export * from "./scanBatchDTO.interface";
export * from "./scanErrorDTO.interface";
export * from "./scanFieldDTO.interface";
export * from "./scanImageDTO.interface";
export * from "./scanQueueDTO.interface";
export * from "./scanStageDTO.interface";
export * from "./scanStatsDTO.interface";
export * from "./scanStyleDTO.interface";
export * from "./scanConfigDTO.interface";
export * from "./scanSampleDTO.interface";
export * from "./scanPreviewDTO.interface";
export * from "./scanServiceDTO.interface";
export * from "./scanVehicleDTO.interface";
export * from "./scanCustomerDTO.interface";
export * from "./remarqCustomerDTO.interface";
export * from "./scanErrorDetailDTO.interface";
export * from "./scanErrorSummaryDTO.interface";
export * from "./scanVehicleSearchDTO.interface";
export * from "./scanSampleResponseDTO.interface";
