import {Injectable} from '@angular/core';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class RoleService {

  private serviceUrl = '/api/roles';

  constructor(private apiClient: ApiService, private data: DataService) { }

}
