import {Component, Input, OnInit} from '@angular/core';
import {AdvertViewVehicleProvenanceDTO, VehicleDTO} from "../../../../global/interfaces";
import {LocaleService, VehicleService} from "../../../../services";

@Component({
  selector: 'app-provenance-summary-widget',
  templateUrl: './provenance-summary-widget.component.html',
  styleUrls: ['./provenance-summary-widget.component.scss'],
})
export class ProvenanceSummaryWidgetComponent implements OnInit {

  constructor(private vehicleService: VehicleService) {
  }

  // tslint:disable-next-line:variable-name
  private _advertId: string;
  @Input('advertId') set advertId(value: string) {
    this._advertId = value;
    if (value) {
      this.loadInfo();
    }
  }

  get advertId(): string {
    return this._advertId;
  }

  info: AdvertViewVehicleProvenanceDTO;

  async ngOnInit() {

  }

  loadInfo() {
    this.vehicleService.getProvenanceDataByAdvert(this.advertId, true).then(res => {
      this.info = res;
    });
  }
}

