import {VehicleDTO} from './Vehicle/vehicleDTO.interface';
import {DealDTO} from "./dealDTO.interface";
import {CustomerDTO} from "./customerDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {SaleDTO} from './Sale/saleDTO.interface';
import {BidderListDTO, BidsSummaryDTO} from "./API/advertSummaryDTO";
import {SoldStatusEnum} from "../../enums/sold-status.enum";
import {AdvertStatusEnum} from "../../enums/advert-enums/advert-status.enum";
import {AddressDTO} from "./addressDTO.interface";

export interface AltDTO extends BaseDTO {
  altTable: string;
  altParentId?: number;
  altRealId?: number;
  altVal: string;
  vehicleTypeId?: number;
}
