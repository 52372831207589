import {Observable, timer} from "rxjs";
import {Pipe, PipeTransform} from "@angular/core";
import {map} from "rxjs/operators";
import {HelpersService} from "../services/index";
import {TimerService} from "../services/timer.service";

@Pipe({
  name: 'timeLeftPipe',
  pure: true
})

export class TimeLeftPipe implements PipeTransform {

  constructor(private timerService: TimerService) {
  }
  public transform(futureDate: string, expiredString: string, significantWords: number = 2): Observable<string> {

    if (expiredString === "") {
      expiredString = "Ended";
    }

    return timer(0, 100).pipe(
      map(() => {
        return this.timerService.convertToWords(futureDate, expiredString, significantWords);
      })
    );
  }
}
