import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AdvertService, LocaleService, EventService, SaleService, URLService} from "../../../../services";
import {SignalRService} from "../../../../services";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {ContactsViewingSaleDTO} from "../../interfaces/contactsViewingSaleDTO.interface";
import {AdminAdvertService} from "../../services";
import {AdminSaleService} from "../../services";
import {
  AdvertDTO,
  AdvertSummaryDTO,
  AuctioneerAdvertPricingDTO, BidDTO,
  IncomingGGGDTO, MessageDTO, SaleDTO,
  User,
  VehicleMediaDTO
} from "../../../../global/interfaces";
import {LoggerService, UserService} from "../../../../global/services";
import {
  BidStatusEnum,
  BidTypeEnum,
  GGGEnum,
  LiveBiddingStatusEnum,
  MessageAreaEnum,
  MessageTypeEnum
} from "../../../../global/enums";

@Component({
  selector: 'app-admin-sale-auctioneer',
  templateUrl: './sale-auctioneer.component.html',
  styleUrls: ['./sale-auctioneer.component.scss'],
  providers: []
})
export class AdminSaleAuctioneerComponent implements OnInit, OnDestroy, AfterViewInit {
  upcomingLots: AdvertDTO[];
  previousLots: AdvertDTO[];
  gggEnum = GGGEnum;
  lotBidders: any;
  biddersOnline: ContactsViewingSaleDTO[];
  saleId: string;
  sale: SaleDTO;
  private advertEventSubscription: Subscription;
  private saleEventSubscription: Subscription;
  private auctioneerEventSubscription: Subscription;
  private contactEventSubscription: Subscription;
  private user: User;
  private saleSubscription: any;
  private auctioneerSubscription: any;
  activeLot: AdvertDTO;
  advert: AdvertDTO;
  galleryImages: any[];
  public liveBiddingStatus = LiveBiddingStatusEnum;
  private subscribedToAdvert?: string = null;
  bidHistory: BidDTO[];
  logger = this.logService.taggedLogger(this.constructor?.name);

  private static sortImages(imgArray: VehicleMediaDTO[]) {
    return imgArray.sort((a, b) => a.sequence > b.sequence ? 1 : a.sequence === b.sequence ? 0 : -1);
  }

  constructor(
    private eventService: EventService,
    private advertService: AdvertService,
    private adminAdvertService: AdminAdvertService,
    private adminSaleService: AdminSaleService,
    private userService: UserService,
    private saleService: SaleService,
    private route: ActivatedRoute,
    public localeService: LocaleService,
    private signalRService: SignalRService,
    public url: URLService,
    private logService: LoggerService
  ) {
    this.saleId = this.route.snapshot.params.saleId;


    this.galleryImages = [];

    const bullets = (this.galleryImages.length < 15);
  }


  async ngOnInit() {

    this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
    });
  }

  async ngAfterViewInit() {

    this.loadSaleInfo();

    this.subscribeToInternalEvents();

    this.subscribeToSignalREvents().then(() => {
    });

  }

  loadSaleInfo() {

    this.saleService.getSale(this.saleId, {component: "AdminSaleAuctioneer"}).then((result) => {

      this.sale = result.sale;

      if (this.sale.currentAdvertId != null) {

        this.loadAdvertInfo(this.sale.currentAdvertId);
      }
    });
  }

  setCurrentLot(newAdvertId: string) {

    // Update the sale to say this is the current lot, then fetch it from the back end, and set it to active lot
    this.adminSaleService.setCurrentLot(this.sale.id, newAdvertId).then(() => {
      this.loadAdvertInfo(newAdvertId);
      this.activeLot = this.advert;
    });
  }

  loadAdvertInfo(advertId: string) {

    this.advertService.getAdvert(advertId, {component: "AuctioneerLiveSaleViewAdvert"}).then((advertResponse) => {
      this.advert = advertResponse.advert;
      this.refreshGallery(this.advert);
      this.switchAdvertSubscription(advertId, this.subscribedToAdvert);
      this.parseLotBidHistory(advertResponse.advert);
    });
  }

  refreshGallery(advert: AdvertDTO) {

    this.galleryImages = [];

    AdminSaleAuctioneerComponent.sortImages(advert.vehicle.vehicleMedia).forEach(x => {
      this.galleryImages.push({
        small: x.mediaURL + "?tr=h-100",
        medium: x.mediaURL + "?tr=h-400",
        big: x.mediaURL
      });
    });

  }

  async subscribeToSignalREvents() {

    this.auctioneerSubscription = this.signalRService.subscribeAuctioneerGroup(this.saleId).then(() => {

      this.logger.info("SUBSCRIBED TO AUCTIONEER SIGNALR ");

      this.refreshUpcomingLots();
      this.refreshPreviousLots();
    });


    this.saleSubscription = this.signalRService.subscribeSaleGroup(this.saleId).then(() => {

      this.logger.info("SUBSCRIBED TO SALE SIGNALR ");
    });
  }

  subscribeToInternalEvents() {

    this.contactEventSubscription = this.eventService.ContactEvent.subscribe((msg: MessageDTO) => {
      this.logger.debug("INBOUND CONTACT MESSAGE");
      this.handleContactEvent(msg);
    });

    this.advertEventSubscription = this.eventService.AdvertEvent.subscribe((msg: MessageDTO) => {
      this.logger.debug("INBOUND ADVERT MESSAGE");
      this.handleAdvertEvent(msg);
    });

    this.saleEventSubscription = this.eventService.SaleEvent.subscribe((msg: MessageDTO) => {
      this.logger.debug("INBOUND SALE MESSAGE");
      this.handleSaleEvent(msg);
    });

    this.auctioneerEventSubscription = this.eventService.AuctioneerEvent.subscribe((msg: MessageDTO) => {
      this.logger.debug("INBOUND AUCTIONEER MESSAGE", msg);
      this.handleAuctioneerEvent(msg);
    });
  }

  switchAdvertSubscription(newAdvertId: string, previousAdvertId?: string) {

    if (previousAdvertId != null) {
      this.signalRService.unsubscribeAdvertGroup(previousAdvertId).then(() => {
      });
    }

    this.signalRService.subscribeAdvertGroup(newAdvertId).then(() => {
    });
    this.subscribedToAdvert = newAdvertId;
  }

  refreshUpcomingLots() {

    // Fetch upcoming lots
    this.signalRService.sendMessageToHub({
      messageArea: MessageAreaEnum.Auctioneer,
      messageType: MessageTypeEnum.UpcomingLots,
      messageData: JSON.stringify({saleId: this.saleId})
    } as MessageDTO);
  }

  refreshPreviousLots() {

    // Fetch Previous lots
    this.signalRService.sendMessageToHub({
      messageArea: MessageAreaEnum.Auctioneer,
      messageType: MessageTypeEnum.PreviousLots,
      messageData: JSON.stringify({saleId: this.saleId})
    } as MessageDTO);
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {

    if (this.contactEventSubscription) {
      this.contactEventSubscription.unsubscribe();
    }
    if (this.advertEventSubscription) {
      this.advertEventSubscription.unsubscribe();
    }
    if (this.saleEventSubscription) {
      this.saleEventSubscription.unsubscribe();
    }
    if (this.auctioneerEventSubscription) {
      this.auctioneerEventSubscription.unsubscribe();
    }

    this.signalRService.unsubscribeAdvertGroup(this.advert.id).then(() => {
    });

    this.signalRService.unsubscribeSaleGroup(this.saleId).then(() => {
    });

    this.signalRService.unsubscribeAuctioneerGroup(this.saleId).then(() => {
    });
  }

  private handleContactEvent(msg: MessageDTO) {
    this.logger.debug("CONTACT EVENT", msg);
  }

  private handleAdvertEvent(msg: MessageDTO) {

    if (msg.messageArea == MessageAreaEnum.Adverts) {

      switch (msg.messageType) {

        case MessageTypeEnum.AdvertUpdate:
          this.parseAdvertUpdate(msg.messageData as AdvertSummaryDTO);
          break;

        case MessageTypeEnum.CurrentLotUpdate:
          this.parseAdvertUpdate(msg.messageData as AdvertSummaryDTO);
          break;

        case MessageTypeEnum.LotBidHistory:
          this.parseLotBidHistory(msg.messageData as AdvertDTO);
          break;

        case MessageTypeEnum.SetGGG:
          this.parseIncomingGGG(msg.messageData as IncomingGGGDTO);
          break;

      }
    }
  }

  private handleSaleEvent(msg: MessageDTO) {

    if (msg.messageArea == MessageAreaEnum.Sales) {

      switch (msg.messageType) {

        case MessageTypeEnum.SaleUpdate:
          this.logger.debug("SALE UPDATE ", msg.messageData);
          this.sale = msg.messageData;
          break;

      }
    }
  }

  private handleAuctioneerEvent(msg: MessageDTO) {

    if (msg.messageArea == MessageAreaEnum.Auctioneer) {
      console.log("*_* HERE: ", msg)

      switch (msg.messageType) {

        case MessageTypeEnum.UpcomingLots: {
          this.parseUpcomingLotsUpdate(msg.messageData as AdvertDTO[]);
          break;
        }

        case MessageTypeEnum.PreviousLots: {
          this.parsePreviousLotsUpdate(msg.messageData as AdvertDTO[]);
          break;
        }

        case MessageTypeEnum.ContactsViewingSale: {
          this.parseContactsViewingSale(msg.messageData as ContactsViewingSaleDTO[]);
          break;
        }
      }
    }
  }

  private parseAdvertUpdate(advertSummary: AdvertSummaryDTO) {

    if (advertSummary.id == this.advert.id) {

      this.updatePricing(advertSummary);
    }
  }

  private parseLotBidHistory(advert: AdvertDTO) {

    this.bidHistory = advert.bids.filter(x => x.bidStatus == BidStatusEnum.BID_ACCEPTED);

    this.lotBidders = [...new Map(advert.bids.map(item =>
      [item[item.contactId], item])).values()];

    this.logger.info("LOT BIDDERS", this.lotBidders);

    this.updatePricing(advert);
  }

  private updatePricing(pricing: AuctioneerAdvertPricingDTO) {

    this.advert.bidIncrement = pricing.bidIncrement;
    this.advert.currentPrice = pricing.currentPrice;
    this.advert.startPrice = pricing.startPrice;
    this.advert.bidCount = pricing.bidCount;
    this.advert.reserveMet = pricing.reserveMet;
    this.advert.ggg = pricing.ggg;
  }

  private parseUpcomingLotsUpdate(lots: AdvertDTO[]) {

    this.upcomingLots = lots.sort((x, y) => {
      return x.lotSeq - y.lotSeq;
    });
  }

  private parseContactsViewingSale(contacts: ContactsViewingSaleDTO[]) {

    // Sort bidders by date

    contacts.sort((a, b) => {
      return a.added.localeCompare(b.added);
    });

    // Get a unique list of bidders
    // TODO: Consider making this a method, seems like we may want to do this in various places
    this.biddersOnline = [...new Map(contacts.map(item => [item.contactId, item])).values()];
  }


  changeBidIncrement(incdec: boolean) {

    return this.adminAdvertService.changeBidIncrement(this.advert.id, this.advert.customerId, this.advert.bidIncrement, incdec);
  }

  startSale() {

    return this.adminSaleService.startSale(this.sale.id);
  }

  pauseSale() {

    return this.adminSaleService.pauseSale(this.sale.id);
  }

  skipLot() {

    this.adminSaleService.skipLot(this.sale.id, this.sale.currentAdvertId).then(() => {
      this.loadAdvertInfo(this.sale.currentAdvertId);
    });

  }

  nextLot() {

    const nextLotId = this.upcomingLots[1].id;

    this.adminAdvertService.endListing(this.advert.id).then(() => {

      this.adminSaleService.setCurrentLot(this.sale.id, nextLotId).then(() => {
        this.loadAdvertInfo(nextLotId);
      });
    });
  }

  endSale() {

    return this.adminSaleService.endSale(this.sale.id);
  }


  setGGG(advertId, ggg) {

    ggg = ggg || GGGEnum.Ready;

    if (ggg <= GGGEnum.Gone) {

      const msg = {
        messageArea: MessageAreaEnum.Adverts,
        messageType: MessageTypeEnum.SetGGG,
        messageData: JSON.stringify({advertId, ggg})
      } as MessageDTO;

      this.signalRService.sendMessageToHub(msg);
    }
  }

  private parseIncomingGGG(messageData: IncomingGGGDTO) {

    if (this.sale.currentAdvertId == messageData.advertId) {
      this.advert.ggg = messageData.ggg;
    }
  }

  private parsePreviousLotsUpdate(messageData: AdvertDTO[]) {
    this.previousLots = messageData;
  }

  setStartPrice(startPrice: number | undefined) {

    this.adminAdvertService.setStartPrice(this.advert.id, this.advert.customerId, startPrice).then(() => {
    });
  }

  silentBidAmt() {

    if (this.advert.bidCount == 0) {
      return this.advert.startPrice;
    } else {
      return this.advert.currentPrice + this.advert.bidIncrement;
    }
  }

  hallBid() {
    this.adminAdvertService.silentBid(this.advert.id, BidTypeEnum.Hall, this.silentBidAmt()
    ).then(() => {
    });
  }

  takeOnBid() {
    this.adminAdvertService.silentBid(this.advert.id, BidTypeEnum.TakeOn, this.silentBidAmt()).then(() => {
    });
  }
}
