import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanErrorDetailDTO extends BaseIntDTO {
  scanStyleId: number;
  scanFieldId: number;
  scanStageId: number;
  scanCustomerId: number;
  companyName: number;
  errorCount: number;
  reason: string;
  lastError: string;
  url: string;
}
