export enum MediaTypeEnum {
  "Image" = 1,
  "Document" = 2,
  "Audio" = 3,
  "Video" = 4,
  "Image/Document"  = 5,
}

export const MediaTypeFromMime = {
  "image/png": MediaTypeEnum.Image,
  "image/bmp": MediaTypeEnum.Image,
  "image/gif": MediaTypeEnum.Image,
  "image/tiff": MediaTypeEnum.Image,
  "image/png8": MediaTypeEnum.Image,
  "image/png24": MediaTypeEnum.Image,
  "image/jpg": MediaTypeEnum.Image,
  "image/jpeg": MediaTypeEnum.Image,
  "image/webp": MediaTypeEnum.Image,
  "video/mp4": MediaTypeEnum.Video,
  "video/mpg": MediaTypeEnum.Video,
  "video/mpeg": MediaTypeEnum.Video,
  "video/ogg": MediaTypeEnum.Video,
  "video/webm": MediaTypeEnum.Video,
  "application/pdf": MediaTypeEnum.Document,
  "application/msword": MediaTypeEnum.Document,
};
