import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {URLService} from "../../../services/index";
import {AppComponent} from '../../index';
import {UserService} from '../../../global/services/index';
import {signInWithRedirect} from "aws-amplify/auth";


@Component({
  selector: 'app-home',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {


  constructor(
    private router: Router,
    private userService: UserService,
    public redirect: URLService,
  ) {
  }

  globals = AppComponent.globals;

  // @ViewChild('app-top-bar', {static: true}) public topbar: any;

  async ngOnInit() {

    await this.userService.loadCurrentUser().then((loggedIn) => {

      if (loggedIn != null) {
        this.redirect.login();
      }
    });
  }

  public login() {

    console.log("REQUESTING FEDERATED SIGNING");

    signInWithRedirect({
      provider: "Google",
    })
      .then(() => {
      });
  }
}


