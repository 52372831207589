import {VehicleCheckDTO, VehicleDTO} from './Vehicle';
import {DealDTO} from "./dealDTO.interface";
import {CustomerDTO} from "./customerDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {SaleDTO} from './Sale/index';
import {BidderListDTO, BidsSummaryDTO} from "./API";
import {SoldStatusEnum} from "../../enums";
import {AddressDTO} from "./addressDTO.interface";
import {BidDTO} from './bidDTO.interface';
import {NegotiationDTO} from './Negotiation/index';
import {ContactDTO} from './contactDTO.interface';
import {TaxStatusDTO} from "./taxStatusDTO";
import {AdvertStatusEnum} from "../../enums/advert-enums/index";
import {CustomerInternalInfoDTO} from "./customerInternalInfoDTO.interface";

export interface AdvertDTO extends BaseDTO {
  customerId?: string;
  availableDate?: Date;
  endDateTime?: Date;
  contactId?: string;
  contact?: ContactDTO;
  saleId?: string;
  sale?: SaleDTO;
  saleTypeId: number;
  advertType?: string;
  salePrice?: number;
  reservePrice?: number;
  currentPrice?: number;
  bidIncrement?: number;
  startPrice?: number;
  buyItNowPrice?: number;
  lotNo?: string;
  lotSeq?: number;
  vehicleId: string;
  activeDealId: string;
  activeDeal?: DealDTO;
  acceptBids: boolean;
  autoAcceptBid?: number;
  autoRejectBid?: number;
  description: string;
  headline: string;
  bidCount: number;
  customer?: CustomerDTO;
  vehicle?: VehicleDTO;
  deals?: DealDTO[];
  topBidGuid?: string;
  topBid?: BidDTO;
  bidsSummary?: BidsSummaryDTO;
  reserveMet: boolean;
  noReserve: boolean;
  advertStatus: AdvertStatusEnum;
  soldStatus: SoldStatusEnum;
  address: AddressDTO;
  isSold: boolean;
  soldToId: string;
  endPrice: number;
  imageCount: number;
  geoLocation: string;
  addressId: string;
  latestValuation: VehicleCheckDTO;
  negotiations?: NegotiationDTO[];
  ggg: number;
  bids: BidDTO[];
  taxStatusId: number;
  taxStatus: TaxStatusDTO;
  underwriteReservePrice: number;
  selected?: boolean;
  termsText?: string;
  countyPlaceId?: string;
  countyLat?: number;
  countyLng?: number;
}
