import {BaseDTO} from "../_baseDTO.interface";
import {DealerTypeEnum} from '../../../enums/dealer-type.enum';
import {ContactDTO} from "../contactDTO.interface";

export interface VehicleActionHistoryDTO {
  actionDate: string;
  actionDesc: string;
  advertId: string;
  contact: ContactDTO;
}
