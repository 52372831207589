import {ContactDTO} from "./contactDTO.interface";
import {BaseIntDTO} from "./_baseIntDTO.interface";
import {ContactActionTypeEnum} from '../../enums';
import {CustomerDTO} from "./customerDTO.interface";

export interface ContactActionDTO extends BaseIntDTO {
  contactId: string;
  contactActionType: ContactActionTypeEnum;
  externalId: string;
  contact: ContactDTO;
  actionName: string;
  ipAddress: string;
  customerId?: string;
  customer?: CustomerDTO;
  ipLocation?: string; // for UI use
}
