import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {IOption, ModalDirective} from "ng-uikit-pro-standard";
import {DatePipe} from "@angular/common";
import * as moment from 'moment';
import {
  AdvertSummaryDTO,
  AdvertViewBidBoxDTO,
  BidDTO,
  BidsSummaryDTO,
  IncomingGGGDTO,
  InternalBidInfoDTO,
  MessageDTO,
  RateCardDTO,
  User
} from "../../../../global/interfaces";
import {LoggerService, TimerService} from "../../../../global/services";
import {
  AdvertStatusEnum,
  BidStatusEnum,
  GGGEnum,
  MessageAreaEnum,
  MessageTypeEnum,
  SaleTypeEnum,
  SoldStatusEnum,
  StatusEnum,
  TimeLeftEnum
} from "../../../../global/enums";
import {
  AdvertService,
  BidService,
  EventService,
  LocaleService,
  RateCardService,
  SignalRService,
  URLService,
  WatchlistService
} from "../../../../services/index";
import {CustomCurrencyPipe} from "../../../../pipes/index";
import {AppComponent} from "../../../app/app.component";

@Component({
  selector: 'app-advert-bid-box',
  templateUrl: './advert-bid-box.component.html',
  styleUrls: ['./advert-bid-box.component.scss'],
  providers: [DatePipe, CustomCurrencyPipe]
})

export class AdvertBidBoxComponent implements OnInit, AfterViewInit, OnDestroy {

  checkPayment = false;

  constructor(
    @Inject(LOCALE_ID)
    private locale: string,
    private eventService: EventService,
    private signalRService: SignalRService,
    private bidService: BidService,
    private advertService: AdvertService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private rateCardService: RateCardService,
    public url: URLService,
    public datepipe: DatePipe,
    public timerService: TimerService,
    public watchlistService: WatchlistService,
    public localeService: LocaleService,
    private logService: LoggerService) {

    this.timedBidForm = this.fb.group({
      bidAmt: new UntypedFormControl('', [Validators.pattern("^(\\s*|\\d+)$")]),
    });

    this.makeOfferForm = this.fb.group({
      bidAmt: new UntypedFormControl('', [Validators.pattern("^(\\s*|\\d+)$")]),
      bidExpires: new UntypedFormControl("5", []),
    });

    this.b = {
      mb: null,
      tb: null,
      lb: null,
      fa: null,
      bs: null,
    };
  }


  protected readonly SaleTypeEnum = SaleTypeEnum;
  public saleTypeEnum = SaleTypeEnum;
  public gggEnum = GGGEnum;
  buyerRateCards: RateCardDTO[] = null;
  public soldStatusEnum = SoldStatusEnum;
  public awaitingBidConfirmation = false;
  loadingAdvert: boolean;
  isWatched: boolean;

  public yourAdvert = false;
  public timedBidForm: UntypedFormGroup;
  public makeOfferForm: UntypedFormGroup;
  public BidStatusEnum = BidStatusEnum;
  public timerLeftWords: string;
  public secondsLeft: number;
  public awaitingSubmit = true;
  public canMakeOffer = false;
  public bidderOffer: BidDTO;
  public offersRemaining: number;
  public expiresOptions: IOption[];

  private timerSubscription: Subscription;

  private advertEventSubscription: Subscription;
  private saleEventSubscription: Subscription;
  private contactEventSub: Subscription;

  public dDay: Date;
  public subscribedToAdvert = null;
  public b: InternalBidInfoDTO;
  public showDealLimitReached = false;

  // tslint:disable-next-line:variable-name
  // public _advert: AdvertDTO;
  // tslint:disable-next-line:variable-name
  public _user: User;
  showBuyersFees: boolean;
  subscribedToSale: boolean;

  timerInterval = 100;
  localSecondsLeft: number;

  logger = this.logService.taggedLogger(this.constructor?.name);

  globals = AppComponent.globals;

  @ViewChild('confirmBuyNowModal') confirmModal: ModalDirective;
  @ViewChild('offerModal') offerModal: ModalDirective;

  @Input('user') set user(value: User) {
    if (value) {
      this._user = value;
      this.isYourAdvert();
    }
  }

  get user(): User {
    return this._user;
  }

  @ViewChild('rateCardModal') rateCardModal: ModalDirective;

  // tslint:disable-next-line:variable-name
  private _advertId: string;
  @Input('advertId') set advertId(value: string) {
    this._advertId = value;
    if (value) {
      this.loadInfo();
    }
  }

  get advertId(): string {
    return this._advertId;
  }

  info: AdvertViewBidBoxDTO;

  loadInfo() {

    this.loadingAdvert = true;

    this.bidService.getBidBoxDataByAdvert(this.advertId).then(res => {

      this.info = res;

      if (this.info.bidsSummary) {
        this.parseBiddersUpdate(this.info.bidsSummary);
      }

      this.initialiseTimers();

      this.loadingAdvert = false;

      this.advertEventSubscription = this.eventService.AdvertEvent.subscribe((msg: MessageDTO) => {
        this.handleAdvertEvent(msg);
      });

      this.saleEventSubscription = this.eventService.SaleEvent.subscribe((msg: MessageDTO) => {
        this.handleSaleEvent(msg);
      });

      this.contactEventSub = this.eventService.ContactEvent.subscribe(event => {
        console.log("CONTACT EVEB|T: ", event)
        this.handleContactEvent(event);
      });

      this.signalRService.subscribeSaleGroup(this.info.saleId).then(() => {
        this.subscribedToSale = true;
      });

      this.advertChanged().then();

    });
  }

  isYourAdvert() {
    this.yourAdvert = (this.info?.customerId == this.user?.customerId);
  }


  async advertChanged() {

    if (this.subscribedToAdvert != null) {
      await this.signalRService.unsubscribeAdvertGroup(this.subscribedToAdvert).then(() => {
      });
    }

    this.subscribedToAdvert = this.advertId;

    this.signalRService.subscribeAdvertGroup(this.advertId).then(() => {
      this.requestBidSummary(this.advertId).then(() => {
      });
    });
  }


  async ngOnInit() {

  }

  private initialiseTimers() {
    this.logger.info("Subscribing to Advert Group", this.advertId);
    this.dDay = new Date(this.info.endDateTime);

    // First run before the second has elapsed
    this.showCountdown(2);

    // Run it every second
    this.timerSubscription = interval(this.timerInterval).subscribe(() => {
      this.showCountdown(2);
    });


    const dt = new Date();

    this.expiresOptions = [];

    this.expiresOptions.push({
      icon: "",
      value: "5",
      label: "In 1 week (" + this.datepipe.transform(dt.setHours(dt.getHours() + 168), "h:mm a d/L/y") + ")"
    });
    this.expiresOptions.push({
      icon: "",
      value: "4",
      label: "In 3 days (" + this.datepipe.transform(dt.setHours(dt.getHours() + 72), "h:mm a EEE") + ")"
    });
    this.expiresOptions.push({
      icon: "",
      value: "3",
      label: "In 2 days (" + this.datepipe.transform(dt.setHours(dt.getHours() + 48), "h:mm a EEE") + ")"
    });
    this.expiresOptions.push({
      icon: "",
      value: "2",
      label: "In 24 hours (" + this.datepipe.transform(dt.setHours(dt.getHours() + 24), "h:mm a EEE") + ")"
    });
    this.expiresOptions.push({
      icon: "",
      value: "1",
      label: "In 6 hours (" + this.datepipe.transform(dt.setHours(dt.getHours() + 6), "h:mm a EEE") + ")"
    });
    this.expiresOptions.push({
      icon: "",
      value: "0",
      label: "In 3 hours (" + this.datepipe.transform(dt.setHours(dt.getHours() + 3), "h:mm a") + ")"
    });
  }

  ngAfterViewInit() {
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.advertEventSubscription.unsubscribe();
    this.saleEventSubscription.unsubscribe();
    this.timerSubscription.unsubscribe();

    this.signalRService.unsubscribeAdvertGroup(this.advertId).then(() => {
    });
    this.signalRService.unsubscribeSaleGroup(this.info.saleId).then(() => {
    });
  }


  async requestBidSummary(advertId: string) {

    this.signalRService.sendMessageToHub({
      messageArea: MessageAreaEnum.Bids,
      messageType: MessageTypeEnum.BidsSummary,
      messageData: JSON.stringify({advertId})
    } as MessageDTO);
  }

  showCountdown(significantWords) {

    const unitArray = this.timerService.timeLeft(this.info.endDateTime, 'ShowCountDown');

    this.secondsLeft = unitArray[TimeLeftEnum.TotalSeconds];

    this.timerLeftWords = this.timerService.timerWords(unitArray, "0s", significantWords);

    // use local 'stopwatch' timer once we reach 5s left in auction
    if (this.secondsLeft <= 5) {
      this.localSecondsLeft -= (this.timerInterval / 1000.0);
    } else {
      this.localSecondsLeft = this.secondsLeft;
    }

    this.secondsLeft = this.localSecondsLeft;
  }


  submitOffer() {

    this.awaitingBidConfirmation = true;

    const expiresChosen = this.o.bidExpires.value;
    let expires = moment();

    if (expiresChosen === 1) {
      expires.add(3, 'hours');
    }
    if (expiresChosen === 2) {
      expires.add(6, 'hours');
    }
    if (expiresChosen === 3) {
      expires.add(24, 'hours');
    }
    if (expiresChosen === 4) {
      expires.add(72, 'hours');
    }
    if (expiresChosen === 5) {
      expires.add(168, 'hours');
    }

    this.bidService.bidderOffer({
      advertId: this.advertId,
      bidAmt: this.o.bidAmt.value,
      expires: expires.format("YYYY-MM-DD HH:mm:ss"),
    }).then((x) => {

      this.bidderOffer = x;
      this.awaitingBidConfirmation = false;

    });
  }

  handleAdvertEvent(msg: MessageDTO) {

    if (msg.messageArea == MessageAreaEnum.Adverts) {

      switch (msg.messageType) {

        case MessageTypeEnum.AdvertUpdate: {
          this.logger.log("Received Advert Update Event: ", msg);
          this.parseAdvertUpdate(msg.messageData as AdvertSummaryDTO);
          break;
        }

        case MessageTypeEnum.BidsSummary: {
          this.logger.log("MESSAGE DATA ", msg.messageData);
          this.parseBiddersUpdate(msg.messageData as BidsSummaryDTO);
          break;
        }

        case MessageTypeEnum.SetGGG: {
          this.parseGGGUpdate(msg.messageData as IncomingGGGDTO);
          break;
        }
      }
    }
  }

  handleSaleEvent(msg: any) {

    this.logger.info("Sale event message", msg);
  }

  parseGGGUpdate(gggdto: IncomingGGGDTO) {

    if (this.info != null) {
      this.info.ggg = gggdto.ggg;
    }
  }

  parseBiddersUpdate(bidsSummary: BidsSummaryDTO) {

    this.b = this.advertService.parseBidderUpdate(this.info, bidsSummary, this.user);

    this.canMakeOffer = this.info.acceptBids;
    this.offersRemaining = this.b.bl;

    if (this.wonAuction()) {
      this.checkPayment = true;
    }
  }

  parseAdvertUpdate(messageData: AdvertSummaryDTO) {

    if (messageData != null) {

      this.awaitingSubmit = true;

      this.info.currentPrice = messageData.currentPrice;
      this.info.bidIncrement = messageData.bidIncrement;
      this.info.reserveMet = messageData.reserveMet;
      this.info.bidCount = messageData.bidCount;
      this.info.topBidGuid = messageData.topBidGuid;
      this.info.soldStatus = messageData.soldStatus;
      this.info.startPrice = messageData.startPrice;
      this.info.advertStatus = messageData.advertStatus;
      this.info.endDateTime = messageData.endDateTime;

      // console.log("** END DAtetime MSG; ", messageData);

      this.info.ggg = messageData.ggg;

      if (messageData.bidsSummary) {
        this.parseBiddersUpdate(messageData.bidsSummary);
        this.awaitingBidConfirmation = false;
      }
    }
  }

  async confirmPurchase() {

    this.confirmModal.hide();
    this.awaitingBidConfirmation = true;

    const bid = {
      advertId: this.advertId,
      contactId: this.user.contactId,
      bidAmt: this.info.buyItNowPrice,
      buyItNow: true,
      bidPlaced: new Date()
    } as BidDTO;

    await this.submitBid(bid);

  }

  async managedBidSubmit(bidAmt: number) {
    // don't submit the bid if the auction has ended
    if (this.info.advertStatus != AdvertStatusEnum.Active) {
      return;
    }

    await this.bid(bidAmt);
  }

  async timedBidSubmit() {
    // don't submit the bid if the auction has ended
    if (this.info.advertStatus != AdvertStatusEnum.Active) {
      return;
    }

    this.b.bs = null;

    if (this.f.bidAmt.value < this.minBid()) {

      this.b.bs = BidStatusEnum.BID_TOOLOW;

      return false;
    }

    if (this.timedBidForm.valid) {
      await this.bid(this.f.bidAmt.value);
    } else {
    }
  }

  get f() {
    if (this.timedBidForm) {
      return this.timedBidForm?.controls;
    }
    return null;
  }

  get o() {
    if (this.makeOfferForm) {
      return this.makeOfferForm?.controls;
    }
    return null;
  }

  async bid(bidAmt: number) {

    const bid = {
      advertId: this.advertId,
      bidAmt,
      bidPlaced: new Date()
    } as BidDTO;

    this.timedBidForm.patchValue({bidAmt: ""});
    this.awaitingSubmit = false;

    await this.submitBid(bid);
  }

  async submitBid(bid: BidDTO) {

    const msg = {
      messageArea: MessageAreaEnum.Bids,
      messageType: MessageTypeEnum.BidCreate,
      messageData: JSON.stringify(bid)
    } as MessageDTO;

    this.signalRService.sendMessageToHub(msg);
  }

  openRateCard() {

    if (!this.buyerRateCards) {
      this.rateCardService.getRateCard("VS02").then(result => {
        this.buyerRateCards = result;
      });
    }

    this.rateCardModal.show();
  }


  countdownExpired(): boolean {

    const timeLeft = this.timerService.timeLeft(this.info.endDateTime, 'ABB-Is AdvertEnded');
    const secondsLeft = timeLeft[TimeLeftEnum.TotalSeconds];

    return secondsLeft <= 0 && this.localSecondsLeft <= 0;
  }

  minBid() {

    let minBid = 0;

    if (this.info.saleTypeId === SaleTypeEnum.BuyItNow) {

      minBid = this.info.buyItNowPrice;

    } else {

      if (this.info.currentPrice === 0 || this.info.currentPrice == null) {
        minBid = (this.info.startPrice > 0) ? this.info.startPrice : this.info.bidIncrement;
      } else {
        minBid = (this.info.currentPrice || 0) + (this.info.bidIncrement || 0);
      }
    }

    return minBid;
  }

  canBid() {
    if (this.localSecondsLeft > 0) {
      return true;
    }

    if (this.info.saleTypeId === SaleTypeEnum.ManagedSale && this.advertId !== this.info.currentAdvertId) {
      return false;
    }

    if (this.info.statusId !== StatusEnum.Active) {
      return false;
    }

    if (this.info.advertStatus !== AdvertStatusEnum.Active) {
      return false;
    }

    return true;
  }

  cancelOffer() {

    this.bidService.cancelBid(this.b.mb.bidGuid, {contactId: this.user.contactId}).then((x) => {

      this.offerModal.hide();

    });
  }

  showOfferModal() {

    this.makeOfferForm.patchValue({bidAmt: ''});
    this.offerModal.show();
  }

  toggleWatchList() {
    this.isWatched = !this.isWatched;
    this.watchlistService.updateWatchlist(this.advertId, this.user.contactId, this.isWatched);
  }

  get canProxyBid() {
    return this.advertService.canProxyBid(this.info);
  }

  wonAuction() {
    return this.info?.soldStatus == SoldStatusEnum.Sold && this.b.fa == true;
  }

  private handleContactEvent(event: MessageDTO) {
    if (event.messageType == MessageTypeEnum.DealLimitReached) {
      this.showDealLimitReached = true;
    }
  }
}
