import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enginecc',
})
export class EngineCCPipe implements PipeTransform {

  transform(
    value?: number,
  ): string | null {

    if (! value || value === 0) {
      return '';
    }

    return (Math.round(value / 100) / 10).toFixed(1).toString();
  }
}
