import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class URLHelperService {

  constructor(private router: Router) {
  }

  urlFrom(path, returnOnly) {
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
      return true;
    }
  }

  redirect(path, params = null, replaceUrl = false) {

    this.router.navigate([path], {replaceUrl, queryParams: params}).then(() => {
    });
  }
}
