import {MediaDTO} from "../mediaDTO.interface";
import {BaseDTO} from "../_baseDTO.interface";

export interface VehicleMediaDTO extends BaseDTO {
  mediaId?: string;
  mediaTypeId?: number;
  mediaCategoryId?: number;
  media?: MediaDTO;
  mediaURL?: string;
  sequence?: number;
  externalId?: string;
  isEnhanced?: boolean;
}
