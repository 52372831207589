import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoggerService} from "../../../../../global/services";
import {FaultCategoryDTO, FaultItemDTO, FaultStatusDTO} from "../../../../../global/interfaces";
import {MechanicalFaultsService} from "../../../../../services";

@Component({
  selector: 'app-vehicle-fault-category',
  templateUrl: './vehicle-fault-category.component.html',
  styleUrls: ['./vehicle-fault-category.component.scss']
})
export class VehicleFaultCategoryComponent implements OnInit {

  updating: number;

  constructor(private logService: LoggerService,
              private mechanicalFaultService: MechanicalFaultsService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @Input() category: FaultCategoryDTO;
  @Input() faultStatuses: FaultStatusDTO[];

  @Output() faultItemStatusChanged = new EventEmitter<FaultItemDTO>();

  async ngOnInit() {
  }

  faultStatusChanged(item: FaultItemDTO) {

    if (item.faultStatusId === undefined) {
      return;
    }

    this.updating = item.id;
    setTimeout(() => {
      this.updating = null;
    }, 3000);
    this.faultItemStatusChanged.emit(item);
  }

  sortedFaultItems(faultItems: FaultItemDTO[]) {

    if (!faultItems) {
      return [];
    }

    return faultItems.sort((a, b) => {
      if (a.sequence < b.sequence) {
        return -1;
      }
      if (a.sequence > b.sequence) {
        return 1;
      }
      return 0;
    });
  }
}
