import {RemarqCustomerDTO} from "./remarqCustomerDTO.interface";
import {ScanStyleDTO} from "./scanStyleDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";

export interface ScanCustomerDTO extends BaseIntDTO {
  customerId?: string;
  customer?: RemarqCustomerDTO;
  scanStyleId?: number;
  scanStyle: ScanStyleDTO;
  entryPoint?: number;
  maxPages?: number;
  scanUrl?: string;
  domainName?: string;
  homePage?: string;
  companyName?: string;
  scanEnabled?: boolean;
  lastScan?: Date;
  indexRequested?: Date;
  vehiclesIndexed?: number;
  previewQueueId?: number;
  customHeaders?: string;
}
