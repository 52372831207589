import {Injectable} from '@angular/core';
import {DataService} from "../../../services";
import {ApiService} from "../../../global/services";
import {
  CallRecordDTO, CallStatsDTO,
  SearchCallRecordsDTO,
  TypedSearchResultDTO
} from "../../../global/interfaces";

@Injectable()
export class AdminVoipService {

  private serviceUrl = '/api/voip';

  constructor(
    private apiClient: ApiService,
    private data: DataService) {
  }

  search(query: SearchCallRecordsDTO): Promise<TypedSearchResultDTO<CallRecordDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<CallRecordDTO>>;
  }

  getStats(): Promise<CallStatsDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stats`;
    return this.apiClient.get({url}) as Promise<CallStatsDTO[]>;
  }
}
