import {ScanFieldDTO} from "./scanFieldDTO.interface";
import {ScanServiceDTO} from "./scanServiceDTO.interface";
import {BaseIntDTO} from "../_baseIntDTO.interface";
import {ScanContentTypeEnum} from "../../../enums";

export interface ScanStyleDTO extends BaseIntDTO {

  styleName?: string;
  perPage?: number;
  imagePrefix?: string;
  vehicleTypes?: string;
  searchParams?: string;
  contentType?: ScanContentTypeEnum;
  entryPoint?: number;
  detailEntryPoint?: number;
  scanServiceId?: number;
  isDefault?: boolean;
  scanService?: ScanServiceDTO;
  scanFields?: ScanFieldDTO[];
  method: string;
  postTemplate: string;
  requestVarName1: string;
  requestVarName2: string;
  requestVarName3: string;
  customHeaders: string;
}
