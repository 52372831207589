import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {UInspectAnswerService} from "./uinspect-answer-service";
import {UInspectFormatService} from "./uinspect-format-service";
import {UInspectMediaService} from "./uinspect-media-service";
import {UInspectQuestionService} from "./uinspect-question.service";
import {UInspectQuestionOptionService} from "./uinspect-question-option-service";
import {UInspectSectionService} from "./uinspect-section-service";
import {UInspectMediaDTO, UInspectSearchDTO, UInspectSectionDTO, UInspectDTO} from "../../interfaces/DTOs/UInspect";
import {DataServiceInterface} from "../interfaces/index";
import {ApiService} from "../api.service";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {
  UInspectStatRequest, UInspectStatData
} from "../../interfaces/DTOs/UInspect/API/Stats";
import {UInspectSectionSearchDTO} from "../../interfaces/DTOs/UInspect/API";

@Injectable()
export class UInspectService {

  private serviceUrl = '/api/uinspect';

  constructor(
    private apiClient: ApiService,
    private externalAppraisalAnswerService: UInspectAnswerService,
    private externalAppraisalFormatService: UInspectFormatService,
    private externalAppraisalMediaService: UInspectMediaService,
    private externalAppraisalQuestionService: UInspectQuestionService,
    private externalAppraisalQuestionOptionService: UInspectQuestionOptionService,
    private externalAppraisalSectionService: UInspectSectionService,
    private data: DataServiceInterface,
    //   private logService: LoggerService
  ) {
  }

//  logger = this.logService.taggedLogger(this.constructor?.name);

  public getSections(dto: UInspectSectionSearchDTO): Promise<SearchResultDTO> {
    return this.externalAppraisalSectionService.search(dto);
  }

  public uploadAppraisalMedia(uploadMediaDTO: UInspectMediaDTO, dataUrls: string[] = []): Promise<ValidatedResultDTO[]> {

//    this.logger.warn("UPLOADING", uploadMediaDTO, dataUrls);
    return this.externalAppraisalMediaService.uploadMedia(uploadMediaDTO, [], dataUrls);
  }

  public search(query?: UInspectSectionSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  public get(appraisalId: string, query: UInspectSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${appraisalId}`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<ValidatedResultDTO>;
  }

  public create(dto: UInspectDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: number, patchData: UInspectSectionDTO, previousValue: UInspectSectionDTO = {}): Promise<ValidatedResultDTO> {
    const data = compare(previousValue, patchData);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: number): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }

  public getStats(query: UInspectStatRequest): Promise<UInspectStatData[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/stats`;

    return this.apiClient.get({url}, {query}) as Promise<UInspectStatData[]>;
  }

  public getFunnel(query: UInspectStatRequest): Promise<UInspectStatData[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/funnel`;

    return this.apiClient.get({url}, {query}) as Promise<UInspectStatData[]>;
  }
}
