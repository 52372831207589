import {Component, OnInit} from "@angular/core";
import {AdminWhosWhoService} from "../services";
import {ContactAdminDTO, ContactDTO, User} from "../../../global/interfaces";
import {HelpersService, UserService} from "../../../global/services";
import {StatusEnum} from "../../../global/enums";

@Component({
  selector: 'app-site-admin-search',
  templateUrl: './site-admin-search.component.html',
  styleUrls: ['./site-admin-search.component.scss']
})
export class SiteAdminSearchComponent implements OnInit {

  siteAdmins: ContactAdminDTO[] = [];
  statusName: {} = {};
  editingContact: ContactAdminDTO;
  showContactModalTime: any;
  private user: User;
  roleChoices: any;

  constructor(
    private userService: UserService,
    private whoswhoService: AdminWhosWhoService,
    private helpersService: HelpersService) {

    this.statusName = this.helpersService.getValuesByIndex(StatusEnum);
  }

  async ngOnInit() {
    this.fetchAdmins().then(() => {
    });
  }

  editContact(contact: ContactAdminDTO) {

    this.editingContact = contact;
    this.showContactModalTime = new Date();
  }

  async fetchAdmins() {

    return this.whoswhoService.getSiteAdmins({component: 'site-admin-search'}).then((res) => {
      this.siteAdmins = res.results;
    });

  }

  reloadContacts() {

    this.fetchAdmins().then(() => {
    });

  }
}


