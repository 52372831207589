// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-bg {
  background-color: var(--navbarBgColour) !important;
}

.category-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.category-cell {
  padding: 5px 10px;
}
.category-cell > div {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-mechanical-faults/vehicle-fault-report/vehicle-fault-report.component.scss"],"names":[],"mappings":"AAAA;EACE,kDAAA;AACF;;AACA;EACE,qCAAA;AAEF;;AACA;EACE,iBAAA;AAEF;AAAE;EACE,qBAAA;AAEJ","sourcesContent":[".logo-bg {\n  background-color: var(--navbarBgColour) !important;\n}\n.category-item:nth-child(odd) {\n  background-color: rgba(0, 0, 0, 0.05)\n}\n\n.category-cell {\n  padding: 5px 10px;\n\n  & > div {\n    display: inline-block;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
