// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#no-watchlist-widget {
  font-weight: 400;
  font-size: 0.875rem;
}

h2 {
  font-size: 30px;
  letter-spacing: -1px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/my-watchlist/my-watchlist.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EAAK,eAAA;EAAiB,oBAAA;AAGtB","sourcesContent":["#no-watchlist-widget {\n  font-weight: 400;\n  font-size: 0.875rem;\n}\n\nh2 { font-size: 30px; letter-spacing: -1px; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
