import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InMailService, URLService} from '../../../services';
import {EventService} from "../../../services";
import {Subscription} from "rxjs";
import {MessageDTO} from "../../../global/interfaces";
import {InMailFolderEnum, MessageTypeEnum} from "../../../global/enums";
import {TabsetComponent} from "ng-uikit-pro-standard";
import {Location} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inmail-manager',
  templateUrl: './inmail-manager.component.html',
  styleUrls: ['./inmail-manager.component.scss']
})
export class InmailManagerComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('tabs') uiTabs: TabsetComponent;

  public InMailFolderEnum = InMailFolderEnum;
  messageEventSub: Subscription;

  folderCounts: any;
  currentFolder: InMailFolderEnum = InMailFolderEnum.Inbox;
  markingAsRead = false;

  constructor(
    private inMailService: InMailService,
    private urlService: URLService,
    private eventService: EventService,
    private location: Location,
    private route: ActivatedRoute,
  ) {

    this.currentFolder = this.route.snapshot.params.folder as InMailFolderEnum;
  }


  async ngOnInit() {


    await this.getFolderCounts();

    this.messageEventSub = this.eventService.InMailEvent.subscribe((data: MessageDTO) => {

      // this.logger.info("Received InMail event", data);

      if (data.messageType == MessageTypeEnum.InMailGetCounts) {
        const messageCounts = data.messageData as any;

        Object.keys(messageCounts.folderCounts).forEach((key) => {
          this.folderCounts[key] = messageCounts.folderCounts[key];
        });

        // We're only getting single folder updates, so we can't overwrite the entire object
        // this.folderCounts = messageCounts.folderCounts;

        // this.logger.info("Message counts received", this.folderCounts);
      }
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.messageEventSub != null) {
      this.messageEventSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    const folder = (this.currentFolder as number * 1) + 1;
    // console.log("CURRENT FOLDER", folder);
    this.uiTabs.setActiveTab(folder);
  }

  async getFolderCounts() {
    const data = await this.inMailService.getFolderCounts();
    this.folderCounts = data.folderCounts;
  }

  tabChanged(tab: number) {

    // this.currentFolder = tab;

  }

  updateUrl() {
    this.currentFolder = (this.uiTabs.getActiveElement().activeTabIndex) as InMailFolderEnum;
    this.location.replaceState(this.urlService.mail(this.currentFolder as number, true));
  }

  async markAsRead() {
    this.markingAsRead = true;

    // mark all inbox mails as read
    await this.inMailService.markAllAsRead();
    await this.getFolderCounts();

    this.markingAsRead = false;
  }
}
