import {BaseDTO} from './_baseDTO.interface';
import {BodyPartGroupDTO} from './bodyPartGroupDTO.interface';
import {DamageDetailDTO} from './damageDetailDTO.interface';
import {DamageSeverityDTO} from './damageSeverityDTO.interface';
import {BaseIntDTO} from "./_baseIntDTO.interface";

export interface DamageDTO extends BaseIntDTO {
  bodyPartGroupId: number;
  bodyPartGroup: BodyPartGroupDTO;
  damageDescription: string;
  damageDetails: DamageDetailDTO;
  damageSeverities: DamageSeverityDTO[];
}
