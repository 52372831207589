import {StatusDTO} from '../statusDTO.interface';
import {AdvertDTO} from "../advertDTO.interface";
import {ContactDTO} from "../contactDTO.interface";
import {BaseDTO} from "../_baseDTO.interface";
import {BidStatusEnum} from '../../../enums/bid-status.enum';
import {CustomerDTO} from "../customerDTO.interface";
import {SaleDTO} from "../Sale/saleDTO.interface";
import {OfferDTO} from "../offerDTO.interface";
import {BidReplyActionEnum} from "../../../enums/bid-reply-action.enum";

export interface BidReplyDTO {

  advertId?: string;
  bidId?: string;
  bidGuid?: string;
  replyAction?: BidReplyActionEnum,
  offerAmt?: number;
  expires?: string;
  comment?: string;
}
