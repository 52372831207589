import {ContactDTO} from "./contactDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";

export interface ContactCommDTO extends BaseDTO {

  contactId: string;
  sent: number;
  read: number;
  contact: ContactDTO;
}
