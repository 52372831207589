import {CustomerDTO} from './customerDTO.interface';
import {BaseIntDTO} from "./_baseIntDTO.interface";

export interface TaxDTO extends BaseIntDTO {
  taxCode?: string;
  taxName?: string;
  platformId?: number;
  countryId?: number;
  taxRate?: string;
}
