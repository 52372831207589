import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TaxService} from "../../../../services";
import {CountryService} from "../../../../services";
import {TaxDTO} from "../../../../global/interfaces";
import {HelpersService} from "../../../../global/services";
import {StatusEnum} from "../../../../global/enums";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-taxes',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
providers:[]
})
export class TaxComponent implements OnInit {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private countryService: CountryService,
    private helpersService: HelpersService,
    private taxService: TaxService) {
  }

  @ViewChild('taxModal') taxModal: ModalDirective;

  taxes: TaxDTO[];

  public statusOptions: {}[];
  public countryOptions: {}[];

  public statusName: string[] = [];
  public countryName: string[] = [];
  public statusEnum = StatusEnum;

  form: UntypedFormGroup;
  isLoading = false;
  isEdit = false;
  taxIdToDelete: number;
  submitted = false;

  showDeleteConfirm: boolean = false;

  async ngOnInit() {

    this.form = this.formBuilder.group({
      id: new UntypedFormControl(''),
      countryId: new UntypedFormControl(''),
      statusId: new UntypedFormControl(''),
      taxCode: new UntypedFormControl('', Validators.required),
      taxName: new UntypedFormControl('', Validators.required),
      taxRate: new UntypedFormControl('', Validators.required),
    });

    this.loadStatuses();

    this.loadTaxes();

    this.loadCountries();
  }

  async loadStatuses() {
    // create status options from enum
    const statuses = this.helpersService.getNamesAndValues(StatusEnum);

    this.statusOptions = statuses.map(status => {
      this.statusName[status.value] = status.name;
      return {label: status.name, value: status.value};
    });
  }

  async loadTaxes() {
    this.taxService.search().then(result => {
      this.taxes = result;
    });
  }

  async loadCountries() {
    this.countryService.search().then(result => {
      this.countryOptions = result.map(x => {
        this.countryName[x.id] = x.countryName;
        return {label: x.countryName, value: x.id};
      });
    });
  }

  editTax($event: MouseEvent, taxDTO: TaxDTO) {
    this.form.patchValue(({
      ...taxDTO
    }));
    this.isEdit = true;

    this.taxModal.show();

  }

  addTax() {
    this.form.patchValue(({
      taxId: '',
      taxCode: '',
      taxName: '',
      taxRate: '',
      statusId: StatusEnum.Active,
      countryId: '',
    }));
    this.isEdit = false;

    // show modal that allows location name entry
    this.taxModal.show();
  }

  async saveTax() {

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const taxDTO = this.form.value;
    taxDTO.taxCode = taxDTO.taxCode.replace(/^\w/, c => c.toUpperCase());

    if (this.isEdit == false) {
      await this.taxService.create(taxDTO);
    } else {
      await this.taxService.patch(taxDTO);
    }

    this.taxModal.hide();
    await this.loadTaxes();
  }

  hideModal() {
    this.taxModal.hide();
  }

  async deleteTax(event, tax: TaxDTO) {
    event.stopPropagation();
    this.taxIdToDelete = tax.id;
    this.showDeleteConfirm = true;
    await this.loadTaxes();
  }

  async confirmDelete() {
    await this.taxService.delete(this.taxIdToDelete);
    this.showDeleteConfirm = false;
    await this.loadTaxes();
  }

  cancelDelete() {
    this.showDeleteConfirm = false;
  }
}
