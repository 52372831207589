import {BaseIntDTO} from "../_baseIntDTO.interface";
import {DocumentServiceEnum} from "../../../enums/documents/index";
import {BaseSearchDTO} from "../API";

export interface DocumentTemplateDTO extends BaseIntDTO {

  documentService: DocumentServiceEnum;
  externalTemplateId?: string;
  formCompletionRequired?: boolean;
  daysToComplete?: number;
}

export interface DocumentTemplateSearchDTO extends BaseSearchDTO {

}
