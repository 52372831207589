import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../../app/app.component";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {
  CustomerMediaCategoryEnum,
  CustomerMediaSubCategoryEnum,
  MediaTypeEnum,
  StatusEnum
} from "../../../global/enums";
import {
  CustomerMediaUploadItemDTO,
  CustomerMediaUploadDTO,
  User,
  CustomerMediaUploadResponseDTO,
  CustomerMediaSearchDTO
} from "../../../global/interfaces";
import {CustomerMediaservice} from "../../../services/customer-mediaservice";
import {CustomerService, UserService} from "../../../global/services";
import {devOnlyGuardedExpression} from "@angular/compiler";
import {ToastService} from "ng-uikit-pro-standard";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-pending',
  templateUrl: './user-pending.component.html',
  styleUrls: ['./user-pending.component.scss']
})
export class UserPendingComponent implements OnInit {

  readonly devOnlyGuardedExpression = devOnlyGuardedExpression;
  readonly environment = environment;
  docTypeForm: UntypedFormGroup;
  uploadedDocs: CustomerMediaUploadResponseDTO[] = [];
  customerId: string;
  globals = AppComponent.globals;
  filesUploading: any;
  mediaSubCategory = CustomerMediaSubCategoryEnum;
  private user: User;
  minCategories = 2;
  isLoggedIn = null;
  pleaseWait = true;

  acceptableMediaTypes = [
    "image/png",
    "image/x-png",
    "image/jpeg",
    "image/jpg",
    "image/gif"
  ];

  acceptableMediaList = this.acceptableMediaTypes.join(',');

  subDocTypes = [
    {value: CustomerMediaSubCategoryEnum.DrivingLicence, name: 'Driving Licence'},
    {value: CustomerMediaSubCategoryEnum.TradePolicy, name: 'Motor Trade Policy'},
    {value: CustomerMediaSubCategoryEnum.LetterHead, name: 'Company Letterhead'},
  ];

  documentCount = null;
  categorisedDocs: { [key: string]: CustomerMediaUploadResponseDTO[] } = {};
  uploadedCategories: CustomerMediaSubCategoryEnum[];
  photosDone = false;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private customerMediaService: CustomerMediaservice,
    private customerService: CustomerService,
    private userService: UserService) {

    this.docTypeForm = this.fb.group({subCategory: ['']});
  }

  photoCounter() {
    return this.uploadedDocs.length;
  }

  subDocTypeName(subDocType: CustomerMediaSubCategoryEnum) {
    return this.subDocTypes.find(x => x.value === subDocType)?.name;
  }


  get dtf() {
    return this.docTypeForm.value;
  }

  async ngOnInit() {

    await this.userService.loadCurrentUser(true).then((x) => {

      if (x == null) {
        this.isLoggedIn = false;
        return;
      }

      this.isLoggedIn = true;
      this.user = this.userService.CurrentUser;
      this.customerId = this.user.customerId;

      this.fetchUploadedDocs({
        component: 'user-pending',
        filters: {
          statusId: StatusEnum.Active,
          customerId: this.customerId,
          mediaCategory: CustomerMediaCategoryEnum.SignUpDocs
        }
      }).then(() => {

        this.photosDone = (this.uploadedCategories.length >= this.minCategories);
      });
    });
  }

  onFileDropped(fileList: FileList) {

    this.filesUploading = true;
    const files = Array.from(fileList);

    Array.from(fileList).forEach(file => {
      if (!this.acceptableMediaList.includes(file.type)) {
        this.filesUploading = false;
        this.toastService.error("Upload photos/scans only", "Error", {opacity: 0.98});
        return;
      }
    });

    if (this.filesUploading) {
      this.uploadFiles(files);
    }
  }

  uploadFiles(files: File[] = [], dataUrls: string[] = []) {

    // split the files by type
    this.doUpload({
      customerId: this.customerId,
      mediaCategory: CustomerMediaCategoryEnum.SignUpDocs,
      mediaSubCategory: this.dtf.subCategory,
      mediaType: MediaTypeEnum.Image,
      files
    });
  }

  uploadDisabled() {
    return !this.dtf.subCategory;
  }

  async fetchUploadedDocs(search: CustomerMediaSearchDTO) {

    return this.customerMediaService.search(search).then(res => {

      this.pleaseWait = false;

      res.results.forEach(x => {
        x.mediaURL = x.mediaURL + "?tr=h-100";
      });

      this.uploadedDocs = res.results;

      this.groupMedia();

    }).catch(err => {

      this.filesUploading = false;

    });
  }

  groupMedia() {

    // Get a unique list of uploaded categories
    this.uploadedCategories = Array.from(new Set(this.uploadedDocs.map(x => x.mediaSubCategory)));

    this.uploadedCategories.forEach((x) => {
      this.categorisedDocs[x] = this.uploadedDocs.filter(y => y.mediaSubCategory === x);
    });
  }

  doUpload(dto: FileUploadDTO) {

    if (!dto.files || dto.files.length === 0) {
      return;
    }

    this.filesUploading = true;

    const uploadDTO: CustomerMediaUploadDTO = {
      customerId: this.customerId,
      mediaType: [dto.mediaType],
      mediaCategory: dto.mediaCategory,
      mediaSubCategory: dto.mediaSubCategory,
      mediaUpload: {
        customerId: dto.customerId,
        mediaType: dto.mediaType,
      }
    };

    this.customerMediaService.uploadMedia(uploadDTO, dto.files).then(res => {
      if (res.isValid) {
        this.uploadedDocs.push(...res.dto);
        this.filesUploading = false;
        this.docTypeForm.patchValue({subCategory: ''});
        this.customerService.setPending(this.customerId).then((res2) => {
          this.groupMedia();
        });
      }
    }).catch(err => {
      console.log("ERROR: ", err);
      this.filesUploading = false;
    });
  }

  onSingleFileDropped($event: any) {

    this.filesUploading = true;

  }

  dragDropClosed() {

  }

  uploadedSubDocType(subDocType: CustomerMediaSubCategoryEnum) {
    return this.uploadedDocs.filter(x => x.mediaSubCategory === subDocType).length > 0;
  }

  notUploadedDocs() {

    const uploadedDocTypes = this.uploadedDocs.map(x => x.mediaSubCategory);

    return this.subDocTypes.filter(x => !uploadedDocTypes.includes(x.value));
  }


  deleteImage(id: string) {

    this.customerMediaService.delete(this.customerId, id).then(res => {

      this.uploadedDocs = this.uploadedDocs.filter(x => x.id !== id);

      this.groupMedia();

    });
  }

}

export interface FileUploadDTO {
  customerId: string;
  mediaCategory: CustomerMediaCategoryEnum;
  mediaSubCategory: CustomerMediaSubCategoryEnum;
  mediaType: MediaTypeEnum;
  files: File[];
}
