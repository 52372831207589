export enum OfferStatusEnum {
  OFFER_UNPROCESSED,
  OFFER_WITH_BIDDER,
  OFFER_ACCEPTED,
  OFFER_TOO_HIGH,
  OFFER_DELETED,
  OFFER_EXPIRED,
}

export const OfferStatusName = [
  "Unprocessed",
  "With Bidder",  // is 'accepted' in value terms, but looks misleading in text, hence the change to 'Processed'
  "Accepted",
  "Too High",
  "Deleted",
  "Expired",
];
