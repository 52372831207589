// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-top-line tr:first-of-type td {
  border-top: 0 red !important;
}

.search-info {
  font-size: 14px;
  font-weight: 600;
}

.isLive {
  font-weight: 600;
  color: var(--errorColour);
  font-size: 0.7rem;
}

.ball-cell {
  width: 25px;
}

.ball {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #555555;
  line-height: 16px;
}
.ball.isLive {
  background-color: var(--errorColour);
  animation: pulse-red 2s infinite;
}

mdb-card-header {
  font-weight: 500;
}

.sale-list {
  font-size: 0.8rem;
}

.lot-count {
  font-weight: 500;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/dashboard/widgets/widget-upcoming-sales/widget-upcoming-sales.component.scss"],"names":[],"mappings":"AAKE;EAAsB,4BAAA;AAHxB;;AAMA;EACE,eAAA;EACA,gBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,yBAAA;EACA,iBAAA;AAHF;;AAMA;EACE,WAAA;AAHF;;AAMA;EACE,qBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,iBAAA;AAHF;AAKE;EACE,oCAAA;EACA,gCAAA;AAHJ;;AAQA;EACE,gBAAA;AALF;;AAQA;EACE,iBAAA;AALF;;AAQA;EAAa,gBAAA;AAJb;;AAMA;EACE;IACE,qBAAA;IACA,0CAAA;EAHF;EAMA;IACE,mBAAA;IACA,2CAAA;EAJF;EAOA;IACE,qBAAA;IACA,wCAAA;EALF;AACF","sourcesContent":[".search-buttons button {\n\n}\n\n.no-top-line {\n  tr:first-of-type td { border-top: 0 red !important }\n}\n\n.search-info {\n  font-size: 14px;\n  font-weight: 600;\n}\n\n.isLive {\n  font-weight: 600;\n  color: var(--errorColour);\n  font-size: 0.7rem;\n}\n\n.ball-cell {\n  width: 25px;\n}\n\n.ball {\n  display: inline-block;\n  vertical-align: middle;\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: #555555;\n  line-height: 16px;\n\n  &.isLive {\n    background-color: var(--errorColour);\n    animation: pulse-red 2s infinite;\n  }\n\n}\n\nmdb-card-header {\n  font-weight: 500;\n}\n\n.sale-list {\n  font-size: 0.8rem;\n}\n\n.lot-count { font-weight: 500; }\n\n@keyframes pulse-red {\n  0% {\n    transform: scale(0.9);\n    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);\n  }\n\n  70% {\n    transform: scale(1);\n    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);\n  }\n\n  100% {\n    transform: scale(0.9);\n    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
