export interface SearchResultDTO {
  results: any[];
  totalItems: number;
}

// TYPED RESULTS DTO
export interface TypedSearchResultDTO<T> {
  results: T[];
  totalItems: number;
}
