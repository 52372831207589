// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.narrow-container {
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  font-size: 25px;
  letter-spacing: -1px;
}

.choose-account {
  border-radius: 10px;
}

#choose-title h2, #choose-title h3 {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.choose-account tr:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.choose-account tr:hover {
  background-color: #e8f0fe;
  cursor: pointer;
}
.choose-account tr td {
  line-height: 50px;
  padding-left: 10px;
  padding-right: 15px;
  font-weight: 400;
}
.choose-account tr td.user-icon {
  font-size: 30px;
  color: #ccc;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/home/login/login.component.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,eAAA;EACA,oBAAA;AAAF;;AAGA;EAEE,mBAAA;AADF;;AAOE;EACE,6BAAA;EACA,8BAAA;AAJJ;;AAWE;EACE,6BAAA;AARJ;AAWE;EAEE,yBAAA;EACA,eAAA;AAVJ;AAcE;EAEE,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AAbJ;AAeI;EACE,eAAA;EACA,WAAA;AAbN","sourcesContent":["\n.narrow-container {\n  max-width: 600px;\n  margin: 0 auto;\n}\n\nh1 {\n  font-size: 25px;\n  letter-spacing: -1px;\n}\n\n.choose-account {\n\n  border-radius: 10px;\n\n}\n\n#choose-title {\n\n  h2, h3 {\n    margin-bottom: 0px !important;\n    padding-bottom: 0px !important;\n  }\n\n}\n\n.choose-account tr {\n\n  &:not(:last-child) {\n    border-bottom: 1px solid #ccc;\n  }\n\n  &:hover {\n\n    background-color: #e8f0fe;\n    cursor: pointer;\n\n  }\n\n  td {\n\n    line-height: 50px;\n    padding-left: 10px;\n    padding-right: 15px;\n    font-weight: 400;\n\n    &.user-icon {\n      font-size: 30px;\n      color: #ccc;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
