import { NgModule, ModuleWithProviders } from '@angular/core';
import { EnvironmentConfig, ENV_CONFIG } from "../interfaces/index";
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [
  ],
  declarations: [
  ]
})
export class HttpModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<HttpModule> {
    return {
      ngModule: HttpModule,
      providers: [
        {
          provide: ENV_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
