import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";
import {MotHistoryDTO, VehicleDTO} from "./../../../../global/interfaces";
import {ContactService, LoggerService} from "../../../../global/services";
import {} from "../../../../global/enums";
import {VehicleService} from "../../../../services";

@Component({
  selector: 'app-vehicle-mot-history',
  templateUrl: './vehicle-mot-history.component.html',
  styleUrls: ['./vehicle-mot-history.component.scss']
})
export class VehicleMotHistoryComponent implements OnInit {

  constructor(private vehicleService: VehicleService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  private _advertId: string;
  @Input('advertId') set advertId(value: string) {
    this._advertId = value;
    if (value) {
      this.loadInfo();
    }
  }
  get advertId(): string {
    return this._advertId;
  }

  sortedHistory: MotHistoryDTO[];

  ngOnInit(): void {

  }

  loadInfo() {
    this.vehicleService.getMOTDataByAdvert(this.advertId).then(res => {
      this.sortedHistory = res.sort(
        (a, b) => moment(b.completedDate).diff(moment(a.completedDate)) || (a.passed ? -1 : 1));

      for(let i = 0; i < this.sortedHistory?.length; i++) {
        if (i > 0) {
          let sh = this.sortedHistory[i];
          let shp = this.sortedHistory[i - 1];

          //this.logger.warn("mot history: ", sh, "  - INDEX: ", shp);

          if (sh.odometerValue > shp.odometerValue) {
            sh.issue = true;
            shp.issue = true;
          }
        }
      }
    });
  }
}
