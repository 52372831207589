import {BaseDTO} from "../_baseDTO.interface";
import {DocumentServiceEnum} from "../../../enums/documents/index";
import {DocumentTemplateDTO} from "./documentTemplateDTO";
import {BaseSearchDTO} from "../API";

export interface DocumentDTO extends BaseDTO {

  inbound?: boolean;
  signed?: boolean;
  dateSent?: Date;
  dateReceived?: Date;
  dueDate?: Date;
  documentTemplate: DocumentTemplateDTO;
  documentService: DocumentServiceEnum;
  externalDocumentId?: string;
}

export interface DocumentSearchDTO extends BaseSearchDTO {

}
