import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {
  AddressDTO,
  AddressSearchDTO,
  AddressSearchResultDTO, AdvertViewAdvertLocationDTO,
  AdvertViewVehicleMediaDTO
} from '../global/interfaces/index';
import {DataService} from "./data.service";
import {ApiService} from "../global/services";
import {} from "../global/interfaces";
import {} from "../global/services";
import {} from "../global/enums";

@Injectable()
export class AddressService {

  private customerServiceUrl = '/api/Customer';
  private addressServiceUrl = '/api/Address';
  private contactServiceUrl = '/api/Contact';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  search(addressSearchDTO: AddressSearchDTO): Promise<AddressSearchResultDTO> {
    const url = `${this.data.apiUrl}/api/addresses?query=` + encodeURIComponent(JSON.stringify(addressSearchDTO));
    return this.apiClient.get({url}) as Promise<AddressSearchResultDTO>;
  }

  getCustomerAddresses(customerId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.customerServiceUrl}/${customerId}/addresses`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  createAddress(dto: AddressDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.addressServiceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<any>;
  }

  patchAddress(addressId: string, patch): Promise<any> {
    const url = `${this.data.apiUrl}${this.addressServiceUrl}/${addressId}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  deleteAddress(addressId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.addressServiceUrl}/${addressId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  async makePrimaryAddress(addressId: string, contactId: string): Promise<any> {

    const patchAddress = compare({}, {id: addressId, isPrimary: true});
    await this.patchAddress(addressId, patchAddress);
  }

  public getAdvertLocation(advertId: string, useCache?: boolean): Promise<AdvertViewAdvertLocationDTO> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/location`;
    if (useCache) {
      url += '?cache=true';
    }
    return this.apiClient.get({url}) as Promise<AdvertViewAdvertLocationDTO>;
  }

  public getMovementPrice(advertId: string, destinationPostcode: string): Promise<any> {

    const url = "https://hvpv4efhbgb4aqpgzdogvt4d2e0bsaqt.lambda-url.eu-west-2.on.aws";

    const data = {
      advertId: advertId,
      apiKey: "DEMOAPIKEY-1012",
      postcode: destinationPostcode,
      taskTypeId: 2,
      remarqDev: true,
    };

    return this.apiClient.post({ url, data, headers: {'Content-Type': 'application/json', "X-Skip-Interceptor": "true"}}) as Promise<any>;
  }
}
