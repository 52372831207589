import {BaseSearchDTO} from "./baseSearchDTO.interface";

export interface SaleSearchDTO extends BaseSearchDTO
{
  filters?: {
    saleName?: string;
    addressId?: string;
    statusId?: number;
    saleId?: string;
    saleTypeId?: number;
    saleDateFrom?: Date;
    saleDateTo?: Date;
    includeSaleTypeIds?: number[];
    includeStatusIds?: number[];
  }
}
