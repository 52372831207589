import {Component, Input, OnInit} from '@angular/core';
import {LoggerService} from "../../../../../global/services";
import {MechanicalFaultsService, URLService} from "../../../../../services";
import {
  VehicleFaultCheckDTO,
} from "../../../../../global/interfaces";

@Component({
  selector: 'app-vehicle-fault-report',
  templateUrl: './vehicle-fault-report.component.html',
  styleUrls: ['./vehicle-fault-report.component.scss']
})
export class VehicleFaultReportComponent implements OnInit {

  constructor(private logService: LoggerService,
              private mechanicalFaultService: MechanicalFaultsService,
              private url: URLService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  @Input() vehicleFaultCheckId: string;

  vehicleFaultCheck: VehicleFaultCheckDTO;

  async ngOnInit() {
  }

  viewReport() {
    this.url.faultReportView(this.vehicleFaultCheckId, false, true);
  }
}
