// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stack-list {
  display: flex;
  flex-wrap: wrap;
}

.list-container {
  flex: 1 1 200px;
  flex-direction: column;
}

.selected-item {
  cursor: pointer !important;
  line-height: 20px;
}
.selected-item:hover {
  background-color: rgba(50, 50, 50, 0.1);
}
.selected-item .fa-times-circle:before {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: inset 0 0 0 2px #c00;
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  font-size: 12px;
  line-height: 12px;
  color: #c00 !important;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/profiles/admin-sale-profiles.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,sBAAA;AACF;;AAEA;EAEE,0BAAA;EACA,iBAAA;AAAF;AAEE;EACE,uCAAA;AAAJ;AAGE;EACE,sBAAA;EACA,kBAAA;EACA,gCAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADJ","sourcesContent":[".stack-list {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.list-container {\n  flex: 1 1 200px;\n  flex-direction: column;\n}\n\n.selected-item {\n\n  cursor: pointer !important;\n  line-height: 20px;\n\n  &:hover {\n    background-color: rgba(50, 50, 50, 0.1);\n  }\n\n  .fa-times-circle:before {\n    background-color: #fff;\n    border-radius: 6px;\n    box-shadow: inset 0 0 0 2px #c00;\n    box-sizing: border-box;\n    height: 12px;\n    width: 12px;\n    font-size: 12px;\n    line-height: 12px;\n    color: #c00 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
