import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  AddressDTO, AttribDTO,
  AttribvalDTO, BaseSearchDTO,
  BodyTypeDTO,
  DerivDTO,
  FuelTypeDTO,
  MakeDTO,
  ModelDTO,
  TransmissionTypeDTO,
  VehicleDTO
} from '../global/interfaces';
import {ApiService, LoggerService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class LookupService {

  constructor(private apiClient: ApiService,
              private data: DataService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  vehicleId: string;
  vehicle: VehicleDTO;
  attribvals: AttribvalDTO[];
  attribvalList: {}[];
  bodyTypes: BodyTypeDTO[];
  fuelTypes: FuelTypeDTO[];
  transmissionTypes: TransmissionTypeDTO[];

  bodyTypesList: {}[];
  fuelTypesList: {}[];
  transmissionTypesList: {}[];

  makes: MakeDTO[];
  models: ModelDTO[];
  derivs: DerivDTO[];
  filteredModels: ModelDTO[];
  filteredDerivs: DerivDTO[];

  makesList: {}[];
  modelsList: {}[];
  derivsList: {}[];

  addresses: AddressDTO[];
  addressList: {}[];

  private serviceUrl = '/api';

  public async loadVehicleLookups(makeId: number, modelId: number, vehicleTypeId: number, customerId: string): Promise<any> {

    this.logger.log("Loading vehicle lookups..");

    // load basic lookups
    this.subResourceSearch("vehicleType", vehicleTypeId, "bodyTypes").then((result: BodyTypeDTO[]) => {
      this.bodyTypes = result;
      this.createBodyTypesList();
    });

    this.subResourceSearch("vehicleType", vehicleTypeId, "fuelTypes").then((result: FuelTypeDTO[]) => {
      this.fuelTypes = result;
      this.createFuelTypesList();
    });

    this.subResourceSearch("vehicleType", vehicleTypeId, "transmissionTypes").then((result: TransmissionTypeDTO[]) => {
      this.transmissionTypes = result;
      this.createTransmissionTypesList();
    });

    this.subResourceSearch("customer", customerId, "addresses").then((result: AddressDTO[]) => {
      this.addresses = result;
      this.createAddressList();
    });


    // load makes, models and derivs then split them into individual arrays
    this.subResourceSearch("vehicleType", vehicleTypeId, "makes").then((result: MakeDTO[]) => {
      this.makes = result;
      this.createMakesList();
    });

    this.subResourceSearch("make", makeId, "models").then((result: ModelDTO[]) => {
      this.models = result;
      this.filterModels(makeId);
    });

    return await this.subResourceSearch("model", modelId, "derivs").then((result: DerivDTO[]) => {
      this.derivs = result;
      this.filterDerivs(modelId);
    });
  }

  createBodyTypesList() {
    this.bodyTypesList = this.bodyTypes.map(x => {
      return {label: x.bodyTypeName, value: x.id};
    }).sort();
  }

  createFuelTypesList() {
    this.fuelTypesList = this.fuelTypes.map(x => {
      return {label: x.fuelTypeName, value: x.id};
    }).sort();
  }

  createTransmissionTypesList() {
    this.transmissionTypesList = this.transmissionTypes.map(x => {
      return {label: x.transmissionTypeName, value: x.id};
    }).sort();
  }

  createAddressList() {
    this.addressList = this.addresses.map(x => {
      return {label: x.addressName, value: x.id};
    }).sort();
  }

  createMakesList() {
    this.makesList = this.makes.map(x => {
      return {label: x.makeName, value: x.id};
    }).sort();
  }

  createModelsList() {
    this.modelsList = this.filteredModels.map(x => {
      return {label: x.modelName, value: x.id};
    }).sort();
  }

  createDerivsList() {
    this.derivsList = this.filteredDerivs.map(x => {
      return {label: x.derivName, value: x.id};
    }).sort();
  }

  createAttribvalList(result: AttribvalDTO[]) {
    this.attribvalList = result.map(x => {
      return {label: x.attribvalName, value: x.id};
    }).sort();
  }

  onMakeChanged(makeId: number) {
    // reset filtered models and derivs
    this.filterModels(makeId);
  }

  onModelChanged(modelId: number) {
    this.filterDerivs(modelId);
  }

  filterModels(makeId: number) {
    this.filteredModels = this.models.filter(x => x.makeId === makeId);
    this.createModelsList();
    this.logger.log("Filtered models: ", this.filteredModels);
  }

  filterDerivs(modelId: number) {
    this.filteredDerivs = this.derivs.filter(x => x.modelId === modelId);
    this.createDerivsList();
    this.logger.log("Filtered derivs: ", this.filteredDerivs);
  }

  subResourceSearch(resource: string, id: any, subResource: string, dto?: BaseSearchDTO): Promise<any> {

    let queryString = "";

    if (dto != null && dto.ignoreCache) {
      dto.uniqueId = Math.round(Date.now() / 1000);
    }

    if (dto != null) {
      const queryParams = new HttpParams().set("query", JSON.stringify(dto));
      queryString += "?" + queryParams.toString();
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}/${resource}/${id}/${subResource}${queryString}`;

    return this.apiClient.get({url}) as Promise<any>;
  }

  search(lookupName: string, dto?: BaseSearchDTO): Promise<any> {

    let queryString = "";

    if (dto != null) {

      const queryParams = new HttpParams().set("query", JSON.stringify(dto));

      queryString += "?" + queryParams.toString();
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}/${lookupName}${queryString}`;

    return this.apiClient.get({url}) as Promise<any>;
  }

  getAll(lookupName: string, dto: BaseSearchDTO = null): Promise<any> {

    let url = `${this.data.apiUrl}${this.serviceUrl}/${lookupName}s`;

    if (dto != null) {
      let queryParams = new HttpParams().set("query", JSON.stringify(dto));
      url += "?" + queryParams.toString();
    }

    return this.apiClient.get({url}) as Promise<any>;
  }

  get(lookupName: string, id: string): Promise<any> {
    let url = `${this.data.apiUrl}${this.serviceUrl}/${lookupName}/${id}`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  getAttribvalsByAttribId(attribId: number, dto?: BaseSearchDTO): Promise<AttribvalDTO[]> {

    return this.subResourceSearch("attrib", attribId, 'attribvals', dto);
  }

  async getAttribsById(attribId: number): Promise<AttribDTO> {
    let result = await this.getAll("Attrib", {includes: ["Attribvals"]});
    return new Promise(resolve => {
      let attr = result.filter(x => x.id == attribId) as AttribDTO[];
      resolve(attr[0]);
    });
  }

}
