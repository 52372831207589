import {OdometerUnitEnum} from "../enums";
import {CRMTypeEnum} from "../enums/crm-type.enum";

export interface DomainData {
  theme?: string;
  companyName?: string;
  companyURL?: string;
  apiURL?: string;
  domain?: string;
  homepage?: string;
  platformId?: number;
  platformName?: string;
  platformDetails?: string;
  currencyCode?: string;
  currencySymbol?: string;
  locale?: string;
  writeOffPhrase?: string;
  odometerUnits?: OdometerUnitEnum;
  defaultOdometerUnit?: OdometerUnitEnum;
  salesPhone?: string;
  supportPhone?: string;
  copyOf?: { remarq?: string, crm?: string, sitescan?: string, uinspect?: string , carbuying?: string, inspectCollect?: string };
  useSiteScan?: boolean;
  mapLocation?: { lat?: number, lng?: number, title?: string };
  address?: { line1?: string, line2?: string, line3?: string, line4?: string, postcode?: string };
  hideDivIds?: string[];
  crmType?: CRMTypeEnum;
}
