import {UInspectStatRangeEnum} from '../../../../../../uinspect/src/app/enums/uinspect-stat-range.enum';
import {UInspectStatGroupingEnum} from '../../../../../../uinspect/src/app/enums/uinspect-stat-grouping-enum';
import {UInspectChartTypeEnum} from '../../../../../../uinspect/src/app/enums/uinspect-chart-type.enum';

export interface UInspectStatRequest {
  statRange: UInspectStatRangeEnum;
  statGrouping: UInspectStatGroupingEnum;
  chartType: UInspectChartTypeEnum;

  // only used when statRange is custom, otherwise date range is calculated in the service method
  fromDate?: Date;
  toDate?: Date;
}
