import {SearchDTO} from './searchDTO.interface';
import {StatusDTO} from './statusDTO.interface';
import {UpdateFrequencyEnum} from '../../enums/update-frequency.enum';
import {UpdateMethodEnum} from '../../enums/update-method.enum';
import {BaseDTO} from "./_baseDTO.interface";
import {ContactDTO} from "./contactDTO.interface";

export interface SavedSearchDTO extends BaseDTO {
  contactId?: string;
  sendUpdates?: boolean;
  sendEmail?: boolean;
  sendSMS?: boolean;
  updateFrequency?: UpdateFrequencyEnum;
  pauseUntil?: Date;
  searchName?: string;
  contact?: ContactDTO; // todo: implement this DTO
  search?: SearchDTO;

  isProfile?: boolean;
  maxVehicles?: number;
  parsedDesc?: any; // UI only shortcut property (json parsed version of search.description)
}
