import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MediaTypeFromMime} from '../global/enums/index';
import {
  AdvertViewContactSellerDTO,
  AdvertViewVehicleMediaDTO,
  AdvertViewVehicleProvenanceDTO,
  AdvertViewVehicleServiceHistoryDTO,
  AdvertViewVehicleTyresDTO,
  AdvertViewVehicleValuationDTO,
  BaseSearchDTO,
  DVLAVehicleDataDTO,
  MotHistoryDTO,
  ServiceHistoryDTO, VehicleAttribDTO,
  VehicleBasicDTO,
  VehicleCheckDTO,
  VehicleDTO,
  VehicleMediaDTO,
  VehicleMediaUploadDTO, VehicleProvenanceUpdateDTO,
  VehicleSearchDTO,
  VehicleStatusCheckDTO,
  VehicleStatusSearchDTO
} from '../global/interfaces/index';
import {DataService} from "./data.service";
import {ApiService, ImageService, LoggerService} from "../global/services";

@Injectable()

export class VehicleService {
  private serviceUrl = '/api/vehicle';
  private vehicleMediaUrl = '/api/vehicleMedia';
  private customerUrl = '/api/customer';

  constructor(
    private apiClient: ApiService,
    private data: DataService,
    private commonImageService: ImageService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  // This should be /customer/X/vehicles
  loadImportedVehicles(customerId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.customerUrl}/${customerId}/vehicles`;

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  addVehicle(vehicle: VehicleDTO): Promise<VehicleDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    const data = JSON.stringify(vehicle);

    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}}) as Promise<VehicleDTO>;
  }

  vehicleStatusCheck(searchDTO: VehicleStatusSearchDTO): Promise<VehicleStatusCheckDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/vehicleStatusCheck`;

    const queryParams = new HttpParams()
      .set('query', JSON.stringify(searchDTO));

    const queryString = '?' + queryParams.toString();

    return this.apiClient.get({url: `${url}${queryString}`}) as Promise<VehicleStatusCheckDTO[]>;
  }

  getMatchingVehicles_Basic(searchDTO: VehicleSearchDTO) {

    let url = `${this.data.apiUrl}${this.serviceUrl}/lookup`;

    const queryParams = new HttpParams()
      .set('query', JSON.stringify(searchDTO));

    const queryString = '?' + queryParams.toString();

    url = url + queryString;

    return this.apiClient.get({url}) as Promise<VehicleBasicDTO[]>;
  }

  createVehicleFromLookup(vehicleLookupData: any): Promise<VehicleDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/createVehicleFromLookup`;
    const data = JSON.stringify(vehicleLookupData);

    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}}) as Promise<VehicleDTO>;
  }

  updateVehicle(vehicle: VehicleDTO) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicle.id}`;

    const data = JSON.stringify(vehicle);
    return this.apiClient.put({url, data, headers: {'Content-Type': 'application/json'}}) as Promise<any>;
  }

  getRequestOptions() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    };
    return {
      headers: new HttpHeaders(headers),
    };
  }

  /* Commented out as it seems unused.. reinstate when required DB 26/02/22
  public getLatestAppraisal(vehicleId): Promise<AppraisalDTO> {
    const url: string = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/latestAppraisal`;
    return this.httpClient.get<AppraisalDTO>(url).toPromise();
  }
   */

  search(vehicleSearchDTO?: BaseSearchDTO): Promise<VehicleDTO[]> {

    let queryString = '';

    if (vehicleSearchDTO != null) {

      const queryParams = new HttpParams()
        .set('query', JSON.stringify(vehicleSearchDTO));

      queryString += '?' + queryParams.toString();
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}${queryString}`;

    return this.apiClient.get({url}) as Promise<VehicleDTO[]>;
  }

  getVehicle(vehicleId: string): Promise<VehicleDTO> {

    const url: string = `${this.data.apiUrl}${this.serviceUrl}/` + vehicleId;
    this.logger.log('Fetching vehicle data, URL: ', url);

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<VehicleDTO>;
  }

  // will delete the vehicle with the specified vrm (only when active=1) from the internal api
  // todo: internal api should reject deletion request if
  // vehicle is not active or has any inspection records
  deleteVehicle(vehicleId: string) {
    this.logger.log('About to Delete VEHICLE ', vehicleId);

    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  updateMOT(vehicleId: string) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/update-mot`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  updateProvenanceData(vehicleId: string) {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/update-provenance`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public async uploadVehicleMedia(uploadMediaDTO: VehicleMediaUploadDTO, files: File[] = [], dataUrls: string[] = []): Promise<any> {

    if (!uploadMediaDTO.mediaType) {
      uploadMediaDTO.mediaType = [];
    }

    const promises = [];
    const sendFiles: string[] = [];

    // If we had a list of FILES[] then convert them to dataURLS
    for (const file of files) {

      const mediaFileType = MediaTypeFromMime[file.type] ?? null;

      if (mediaFileType == null) {
        // How do we raise an error to the front end ??
      } else {

        uploadMediaDTO.mediaType.push(MediaTypeFromMime[file.type] ?? null);

        // Convert FILE into DataURL
        promises.push(this.commonImageService.convertFileToDataURL(file).then((fileContent) => {
          sendFiles.push(this.removeFileTypePrefix(fileContent));
        }));
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }

    // If we were given a set of dataURLS
    dataUrls.forEach((x) => {

      const fileType = x.substring(x.indexOf(':') + 1, x.indexOf(';'));
      const mediaFileType = MediaTypeFromMime[fileType];

      uploadMediaDTO.mediaType.push(mediaFileType);

      sendFiles.push(this.removeFileTypePrefix(x));
    });

    const data = {
      vehicleMediaUpload: uploadMediaDTO,
      files: sendFiles
    };

    const url = `${this.data.apiUrl}${this.vehicleMediaUrl}/new`;

    return this.apiClient.post({url, data, headers: {'Content-Type': 'application/json'}});
  }

  removeFileTypePrefix(dataURL: string) {

    return dataURL.substring(dataURL.indexOf(',') + 1);
  }

  public patchVehicle(vehicleId, patchData): Promise<VehicleDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}`;
    return this.apiClient.patch({url, data: patchData}) as Promise<VehicleDTO>;
  }

  public updateAllDVLAData() {
    const url = `${this.data.apiUrl}${this.serviceUrl}/refreshDVLAData`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  public getDVLAData(vrm: string): Promise<DVLAVehicleDataDTO> {
    const url = `${this.data.apiUrl}/api/dvla/${vrm}`;
    return this.apiClient.get({url}) as Promise<DVLAVehicleDataDTO>;
  }

  public getProvenanceData(vehicleId: string): Promise<VehicleCheckDTO> {
    const url = `${this.data.apiUrl}/api/vehicle/${vehicleId}/provenance`;
    return this.apiClient.get({url}) as Promise<VehicleCheckDTO>;
  }

  public getValuationData(vehicleId: string): Promise<VehicleCheckDTO> {
    const url = `${this.data.apiUrl}/api/vehicle/${vehicleId}/valuation`;
    return this.apiClient.get({url}) as Promise<VehicleCheckDTO>;
  }

  public addServiceHistory(vehicleId: string, data): Promise<ServiceHistoryDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/serviceHistory`;
    return this.apiClient.post({
      url,
      data,
      headers: {'Content-Type': 'application/json'}
    }) as Promise<ServiceHistoryDTO>;
  }

  public updateServiceHistory(vehicleId: string, serviceHistoryId: string, patch): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/serviceHistory/${serviceHistoryId}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  public deleteServiceHistory(vehicleId: string, serviceHistoryId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/serviceHistory/${serviceHistoryId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  public importImages(vehicleId: string) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}/makeImagesLocal`;
    this.apiClient.get({url});
  }

  public getProvenanceDataByAdvert(advertId: string, useCache?: boolean): Promise<AdvertViewVehicleProvenanceDTO> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-provenance`;
    if (useCache) { url += '?cache=true'; }
    return this.apiClient.get({url}) as Promise<AdvertViewVehicleProvenanceDTO>;
  }

  public getValuationDataByAdvert(advertId: string, useCache?: boolean): Promise<AdvertViewVehicleValuationDTO> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-valuation`;
    if (useCache) { url += '?cache=true'; }
    return this.apiClient.get({url}) as Promise<AdvertViewVehicleValuationDTO>;
  }

  public getMOTDataByAdvert(advertId: string): Promise<MotHistoryDTO[]> {
    const url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-mot-history`;
    return this.apiClient.get({url}) as Promise<MotHistoryDTO[]>;
  }

  public getVehicleMediaByAdvert(advertId: string, useCache?: boolean): Promise<AdvertViewVehicleMediaDTO[]> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-media`;
    if (useCache) { url += '?cache=true'; }
    return this.apiClient.get({url}) as Promise<AdvertViewVehicleMediaDTO[]>;
  }

  public getVehicleServiceHistoryByAdvert(advertId: string): Promise<AdvertViewVehicleServiceHistoryDTO> {
    const url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-service-history`;
    return this.apiClient.get({url}) as Promise<AdvertViewVehicleServiceHistoryDTO>;
  }

  public getVehicleTyreInfoByAdvert(advertId: string, useCache?: boolean): Promise<AdvertViewVehicleTyresDTO> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-tyre-info`;

    if (useCache) {
      url += '?cache=true';
    }

    return this.apiClient.get({url}) as Promise<AdvertViewVehicleTyresDTO>;
  }

  public getVehicleOptionsByAdvert(advertId: string, useCache?: boolean): Promise<VehicleAttribDTO[]> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-options`;
    if (useCache) { url += '?cache=true'; }
    return this.apiClient.get({url}) as Promise<VehicleAttribDTO[]>;
  }

  public getContactSellerDataByAdvert(advertId: string, cache?: boolean): Promise<AdvertViewContactSellerDTO> {
    let url = `${this.data.apiUrl}/api/listing/${advertId}/contact-seller-data`;
    if (cache) { url += '?cache=true'; }
    return this.apiClient.get({url}) as Promise<AdvertViewContactSellerDTO>;
  }

  public upsertProvenance(id: string, data: VehicleProvenanceUpdateDTO) {
    const url = `${this.data.apiUrl}/api/vehicle/${id}/upsert-provenance`;
    return this.apiClient.post({
      url,
      data,
      headers: {'Content-Type': 'application/json'}
    }) as Promise<any>;
  }
}
