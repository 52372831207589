import {Injectable} from '@angular/core';
import { SaleTypeName } from '../global/enums';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class SaleTypeService {

  private serviceUrl = '/api/SaleType';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  getAll() {
    return SaleTypeName.map((x, y) => {
      return {saleTypeName: x, id: y + 1};
    });
  }
}
