import {StatusDTO} from './statusDTO.interface';
import {AddressDTO} from "./addressDTO.interface";
import {CustomerDTO} from "./customerDTO.interface";
import {SiteDTO} from "./siteDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {InviteDTO} from "./inviteDTO.interface";
import {ContactRoleDTO} from "./contactRoleDTO.interface";
import {RoleDTO} from "./roleDTO.interface";
import {SavedSearchDTO} from "./saved-searchDTO.interface";

export interface ContactDTO extends BaseDTO {
  customerId?: string;
  contactName?: string;
  email?: string;
  phone1?: string;
  phone2?: string;
  siteId?: string;
  primaryAddressId?: string;
  isPrimary?: boolean;
  tsandcs?: boolean;
  lastLogin?: string;
  primaryAddress?: AddressDTO;
  site?: SiteDTO;
  customer?: CustomerDTO;
  invite?: InviteDTO;
  roles?: RoleDTO[];
  savedSearches?: SavedSearchDTO[];
}
