// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-info {
  font-size: 14px;
  font-weight: 600;
}

mdb-card-header {
  font-weight: 500;
}

.bids-received {
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/dashboard/seller-hub/seller-hub-bids-received/seller-hub-bids-received.component.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,gBAAA;AAFF;;AAKA;EAAkB,gBAAA;AADlB;;AAGA;EAAiB,iBAAA;AACjB","sourcesContent":[".search-buttons button {\n\n}\n.search-info {\n  font-size: 14px;\n  font-weight: 600;\n}\n\nmdb-card-header { font-weight: 500; }\n\n.bids-received { font-size: 0.8rem; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
