import {BaseSearchDTO} from "./baseSearchDTO.interface";
import {UserRolesEnum} from "../../../enums/index";

export class ContactSearchDTO extends BaseSearchDTO {
  filters?: {
    customerId?: string;
    statusId?: number;
    hasRole?: UserRolesEnum;
    cache?: boolean;
  };
}

export class ContactsWithRoleSearchDTO extends BaseSearchDTO {
  filters?: {
    contactCustomerId?: string;
    contactStatusId?: number;
    hasRole?: UserRolesEnum;
    nameSearch?: string;
  };
}
