import {Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
import {AppComponent} from "../components/index";

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(
    value: number,
    currencyCode: string = 'USD',
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '1.0-0',
    locale: string = 'en-GB',
  ): string | null {

    currencyCode = AppComponent.globals.currencyCode;
    locale = AppComponent.globals.locale;

    return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}
