import {InjectionToken} from "@angular/core";

export interface EnvironmentConfig {
  environment: {
    serviceUrl: string;
    messageHubUrl: string;
  };
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>(
  "EnvironmentConfig"
);
