import {CountryDTO} from "./countryDTO.interface";
import {ProductDTO} from "./productDTO.interface";
import {TaxDTO} from "./taxDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";
import {PlatformDTO} from "./platformDTO.interface";

export interface CountryProductDTO extends BaseDTO {
  countryId?: number;
  Country?: CountryDTO;
  productId?: number;
  Product?: ProductDTO;
  platformId?: number;
  Platform?: PlatformDTO;
  taxId?: number;
  Tax?: TaxDTO;
}
