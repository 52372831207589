import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {SaleService} from '../../../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../../services';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {ContactDTO, SaleAttendeeDTO, SaleDTO} from "../../../../global/interfaces";
import {ContactService, HelpersService} from "../../../../global/services";
import {SaleTypeEnum, StatusEnum} from "../../../../global/enums";

@Component({
  selector: 'app-attendees',
  templateUrl: './admin-attendees.component.html',
  styleUrls: ['./admin-attendees.component.scss']
})
export class AdminAttendeesComponent implements OnInit {

  constructor(
    private saleService: SaleService,
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private formsService: FormsService,
    private toast: ToastService,
    private helperService: HelpersService) {
  }

  @ViewChild('paddleNumberModal') paddleNumberModal: ModalDirective;

  upcomingSales: SaleDTO[];
  selectedSaleId: string;

  attendees: SaleAttendeeDTO[];
  selectedAttendee: SaleAttendeeDTO;
  selectedContact: ContactDTO;

  allContacts: ContactDTO[];
  contacts: ContactDTO[];
  contactSearchText: string;

  form: UntypedFormGroup;
  submitted = false;

  toastOpts = {opacity: 0.98};

  @ViewChild('paddleInput') focusInput: ElementRef;

  @HostListener('focusout', ['$event'])
  public onListenerTriggered(event: any): void {
    this.setFocusToInput();
  }

  setFocusToInput() {
    this.focusInput.nativeElement.focus();
  }

  async ngOnInit() {
    this.form = new UntypedFormGroup({
      paddleNumber: new UntypedFormControl('',
        [Validators.pattern(this.formsService.decimalNumberPattern), Validators.required, Validators.minLength(1)])
    });

    // upcoming/active sales are active or pending status (ordered by endDate desc)
    this.saleService.getSales({
      component: 'AdminSaleSearch',
      filters: {
        saleTypeId: SaleTypeEnum.ManagedSale,
        includeStatusIds: [StatusEnum.Active, StatusEnum.Pending]
      }
    }).then(result => {
      this.upcomingSales = result.sales;
    });

    this.selectSaleFromURL();
  }

  filterContactList() {

    this.contacts = this.allContacts;

    if (this.contacts != null && this.contacts.length > 0) {

      // remove contacts in the attendees list
      if (this.attendees && this.attendees.length > 0 && this.contacts && this.contacts.length > 0) {
        this.contacts = this.contacts.filter(x => !this.attendees.map(y => y.contactId).includes(x.id));
      }

      // filter again by search field (if it's min 3 chars)
      if (this.contactSearchText && this.contactSearchText.length >= 3) {
        this.contacts = this.contacts.filter(x => x.contactName.includes(this.contactSearchText)
          || x.email.includes(this.contactSearchText));
      }
    }
  }

  selectSaleFromURL() {
    this.route.queryParams.subscribe(params => {
      this.selectedSaleId = params.saleId;
      this.getAttendees(this.selectedSaleId);
    });
  }

  saleSelected(sale: SaleDTO) {
    // store selected sale in URL
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {saleId: sale.id},
      queryParamsHandling: 'merge'
    }).then(() => {
      this.getAttendees(sale.id);
    });
  }

  getAttendees(saleId: string) {
    if (!saleId) {
      return;
    }

    this.saleService.getSaleAttendees(saleId).then(result => {
      this.attendees = result;
      this.filterContactList();
    });
  }

  addAttendee(contact: ContactDTO) {
    if (!this.selectedSaleId) {
      this.toast.error("Select a sale to add attendees", "Error", this.toastOpts);
      return;
    }

    this.selectedContact = contact;
    this.paddleNumberModal.show();
  }

  assignPaddleNumber() {

    if (!this.selectedSaleId) {
      this.toast.error("Select a sale to add attendees", "Error", this.toastOpts);
      return;
    }

    this.submitted = true;

    if (!this.form.valid) {
      return;
    }

    const paddleNumber = this.form.value.paddleNumber;

    // ensure paddle number isn't already used
    if (this.attendees && this.attendees.filter(x => x.paddleNumber === paddleNumber).length > 0) {
      this.toast.error("This paddle number is already used", "Error", this.toastOpts);
      return;
    }

    this.paddleNumberModal.hide();

    const dto = {
      saleId: this.selectedSaleId,
      contactId: this.selectedContact.id,
      paddleNumber
    } as SaleAttendeeDTO;

    this.saleService.addSaleAttendee(dto).then(attendee => {
      this.attendees.push(attendee);
      this.filterContactList();
    }).catch(err => {
      this.toast.error("Could not add the attendee", "Error", this.toastOpts);
    });
  }

  onContactSearch() {
    // filter contact list auto-includes search string
    this.filterContactList();
  }

  get f() {
    return this.form.controls;
  }

  confirmRemoveAttendee(attendee: SaleAttendeeDTO) {
    const msg = `Are you sure you want to remove ${attendee.contact.contactName || attendee.contact.email} from the attendees list?`;

    this.helperService.confirmationDialog("Confirm Remove Attendee", msg, 'Yes', 'No')
      .subscribe(result => {
        if (result) {
          this.removeAttendee(attendee);
        }
      });
  }

  removeAttendee(attendee: SaleAttendeeDTO) {
    this.saleService.removeSaleAttendee(attendee.id).then(() => {
      this.attendees = this.attendees.filter(x => x.id !== attendee.id);
      this.filterContactList();
    }).catch(err => {
      this.toast.error("Could not remove the attendee", "Error", this.toastOpts);
    });
  }
}
