import {BaseDTO} from '../_baseDTO.interface';

export interface VehicleBasicDTO extends BaseDTO {
  vrm: string;
  primaryImageURL: string;
  makeName: string;
  modelName: string;
  derivName: string;
  customerName: string;
}
