import {BaseDTO} from "../../_baseDTO.interface";
import {BodyTypeDTO} from "../../bodyTypeDTO.interface";
import {PlateDTO} from "../../plateDTO.interface";
import {AdvertStatusEnum, ServiceHistoryTypeEnum, SoldStatusEnum} from "../../../../enums";
import {MakeDTO} from "../../makeDTO.interface";
import {ModelDTO} from "../../modelDTO.interface";
import {DerivDTO} from "../../derivDTO.interface";
import {FuelTypeDTO} from "../../fuelTypeDTO.interface";
import {TransmissionTypeDTO} from "../../transmissionTypeDTO.interface";
import {AttribvalDTO} from "../../attribvalDTO.interface";

export interface AdvertSearchSummaryAdvertDTO extends BaseDTO {
  endDateTime?: Date;
  buyItNowPrice?: number;
  currentPrice?: number;
  soldStatus: SoldStatusEnum;
  advertStatus?: AdvertStatusEnum;
  vehicle: AdvertSearchSummaryVehicleDTO;
  sale: AdvertSearchSummarySaleDTO;
  reserveMet: boolean;
  startPrice?: number;
  ggg?: number;
}

export interface AdvertSearchSummaryVehicleDTO extends BaseDTO {
  imageCount?: number;
  primaryImageURL: string;
  customerId: string;
  runner: boolean;
  vrm: string;
  colour?: string;
  motExpires?: Date;
  dateOfReg?: Date;
  odometer?: number;
  logBook?: boolean;
  engineCc?: number;
  co2?: number;
  doors?: number;
  make?: MakeDTO;
  model?: ModelDTO;
  deriv?: DerivDTO;
  fuelType?: FuelTypeDTO;
  transmissionType?: TransmissionTypeDTO;
  plate?: PlateDTO;
  bodyType?: BodyTypeDTO;
  vatStatus?: AttribvalDTO;
  address: AdvertSearchSummaryVehicleAddressDTO;
  latestValuation: AdvertSearchSummaryVehicleCheckDTO;
  latestProvenance: AdvertSearchSummaryProvenanceDTO;

  // Adding the following
  owners?: number;
  noOfKeys?: number;
  serviceHistoryType?: ServiceHistoryTypeEnum;
}

export interface AdvertSearchSummarySaleDTO extends BaseDTO {
  saleTypeId?: number;
  saleName: string;
  saleStart?: Date;
}

export interface AdvertSearchSummaryVehicleAddressDTO {
  addressName: string;
}

export interface AdvertSearchSummaryVehicleCheckDTO {
  priceClean?: number;
}

export interface AdvertSearchSummaryProvenanceDTO {
  finance?: boolean;
  stolen?: boolean;
  scrapped?: boolean;
  vcaR_Damage?: boolean;
}
