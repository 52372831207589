import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flagsEnumDisplay'
})
export class FlagsEnumDisplayPipe implements PipeTransform {
  transform(value: number, enumType: object): string {
    // Ensure 'value' refers to the numeric enum value to be displayed
    // and use different variable names within the filter function to avoid confusion.
    const flags = Object.entries(enumType)
      .filter(([enumKey, enumValue]) => !isNaN(Number(enumKey)) && ((value & +enumValue) === +enumValue))
      .map(([enumKey]) => enumType[enumKey]);

    return flags.join(', ');
  }
}
