// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ended-table td {
  vertical-align: middle;
}

.sale-type {
  display: inline-block;
  border-radius: 10px;
  line-height: 20px;
  padding: 0 7px;
  color: #fff;
}
.sale-type.sale-type-1 {
  background-color: var(--buyNowColour);
}
.sale-type.sale-type-2 {
  background-color: var(--managedSaleColour);
}
.sale-type.sale-type-3 {
  background-color: var(--timedSaleColour);
}
.sale-type.sale-type-4 {
  background-color: var(--underwriteColour);
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/advert-admin-view/advert-admin-view.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,WAAA;AACF;AACE;EACE,qCAAA;AACJ;AAEE;EACE,0CAAA;AAAJ;AAGE;EACE,wCAAA;AADJ;AAIE;EACE,yCAAA;AAFJ","sourcesContent":[".ended-table td {\n  vertical-align: middle;\n}\n\n.sale-type {\n  display: inline-block;\n  border-radius: 10px;\n  line-height: 20px;\n  padding: 0 7px;\n  color: #fff;\n\n  &.sale-type-1 {\n    background-color: var(--buyNowColour);\n  }\n\n  &.sale-type-2 {\n    background-color: var(--managedSaleColour);\n  }\n\n  &.sale-type-3 {\n    background-color: var(--timedSaleColour);\n  }\n\n  &.sale-type-4 {\n    background-color: var(--underwriteColour);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
