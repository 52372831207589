import {ContactDTO} from "./contactDTO.interface";
import {BaseIntDTO} from "./_baseIntDTO.interface";
import {CustomerDTO} from "./customerDTO.interface";
import {AdminTaskDTO} from "./adminTaskDTO.interface";

export interface CustomerNoteDTO extends BaseIntDTO {
  customerId?: string;
  customer?: CustomerDTO;
  contactId?: string;
  contact?: ContactDTO;
  noteByContactId?: string;
  noteByContact?: ContactDTO;
  note?: string;
  sleepUntil?: Date | string;
  reminderSet?: boolean;
  adminTask?: AdminTaskDTO;
}

export interface NewCustomerNoteDTO extends CustomerNoteDTO {
  setReminder?: boolean;
}
