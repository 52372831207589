import {BaseDTO} from "./_baseDTO.interface";
import {DealerTypeEnum} from '../../enums/dealer-type.enum';

export interface ServiceHistoryDTO extends BaseDTO {
  vehicleId: string;
  serviceDate: Date;
  odometer: number;
  dealerType: DealerTypeEnum;
  dealerName: string;
}
