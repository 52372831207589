import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BidService, URLService} from "../../../../services";
import {BidDTO} from "../../../../global/interfaces";
import {LoggerService} from "../../../../global/services";

@Component({
  selector: 'app-bids-received',
  templateUrl: './bids-received.component.html',
  styleUrls: ['./bids-received.component.css'],
})
export class BidsReceivedComponent implements OnInit, OnDestroy {

  @Input() advertId: string;

  bids: BidDTO[];

  constructor(
    private bidService: BidService,
    public url: URLService,
    private router: Router,
    private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  isLoading: boolean;

  ngOnInit(): void {

    this.isLoading = true;
    this.bidService
      .searchBidHistory(this.advertId)
      .then((result) => {
        this.bids = result.bids;
        this.isLoading = false;

        this.logger.info("Loaded bids: ", this.bids);
      });
  }

  onSubmit() {
  }

  ngOnDestroy() {
  }
}
