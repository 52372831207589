// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-info {
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
}

mdb-card-header {
  font-weight: 500;
}

.change {
  font-size: 0.875rem;
}

#no-watchlist-widget {
  font-weight: 400;
  font-size: 0.875rem;
}

h2 {
  font-size: 28px;
  letter-spacing: -1px;
}

.text-muted {
  color: rgba(0, 0, 0, 0.6) !important;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/my-searches/my-searches.component.scss"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,gBAAA;AAHF;;AAMA;EACE,mBAAA;AAHF;;AAMA;EACE,gBAAA;EACA,mBAAA;AAHF;;AAMA;EACE,eAAA;EACA,oBAAA;AAHF;;AAMA;EACE,oCAAA;AAHF","sourcesContent":[".search-buttons button {\n\n}\n\n.search-info {\n  font-size: 14px;\n  font-weight: 600;\n  font-style: italic;\n}\n\nmdb-card-header {\n  font-weight: 500;\n}\n\n.change {\n  font-size: 0.875rem;\n}\n\n#no-watchlist-widget {\n  font-weight: 400;\n  font-size: 0.875rem;\n}\n\nh2 {\n  font-size: 28px;\n  letter-spacing: -1px;\n}\n\n.text-muted {\n  color: rgba(0,0,0,0.6) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
