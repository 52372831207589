import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {URLService} from "../../../services";
import {TabsetComponent} from "ng-uikit-pro-standard";

@Component({
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  selector: 'app-settings'
})
export class SettingsComponent implements OnInit, AfterViewInit {

  advertId = 0;
  selectedTab = 1;

  @ViewChild('tabs') uiTabs: TabsetComponent;

  constructor(
    private route: ActivatedRoute,
    public urlService: URLService
  ) {
  }

  updateUrl($event: any) {

    if ($event && $event.activeTabIndex !== undefined) {
      this.selectedTab = $event.activeTabIndex + 1;
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    this.route.paramMap.subscribe(params => {
      if (params['params'] !== undefined && params['params']['setTab'] !== undefined) {
        this.selectedTab = params['params']['setTab'];
        this.uiTabs.setActiveTab(this.selectedTab);
      }
    });
  }
}
