export enum SortingType {
  EndingSoonest,
  NewlyListed,
  EstimatedMargin,
  AverageDaysToSell,
  PriceLowest,
  PriceHighest,
  Distance,
  AgeNewest,
  AgeOldest,
}
