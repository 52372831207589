import {BaseIntDTO} from "../_baseIntDTO.interface";
import {CustomerDTO} from "../customerDTO.interface";
import {CommsEventDTO} from "./commsEventDTO";
import {BaseSearchDTO} from "../API";

export interface CommsTemplateDTO extends BaseIntDTO {
  commsEvent: CommsEventDTO;
  customer: CustomerDTO;

  commsEventId: number;
  customerId: string;
  commsType: number;
  headerTemplate: string;
  bodyTemplate: string;
  subjectTemplate: string;
  senderAddress: string;
  senderName: string;
  bccAddress: string;
  replyToAddress: string;
  replyToName: string;
  replyToNumber: string;
  recipientAddress: string;
  recipientName: string;
  templateName: string;
  added: Date;
  updated: Date;
  active: boolean;
  isWrapper: boolean;
  wrapperId: number;
  allowDuplicate: boolean;
  uniqueIdTemplate: string;
  imageUrlTemplate: string;
}

export interface CommsTemplateSearchDTO extends BaseSearchDTO {
  filters?: {
    commsEvent?: CommsEventDTO;
    customer?: CustomerDTO;
    commsType?: number;
    templateName?: string;
    id?: number;
  };
}
