import {Component, OnInit} from '@angular/core';
import {AdminUrlService, AdminVoipService, AdminWhosWhoService} from "../services";
import {SearchCallRecordsDTO, User} from "../../../global/interfaces";
import {IOption} from "ng-uikit-pro-standard";
import {UserService} from '../../../global/services';
import {CallRecordDTO} from '../../../global/interfaces';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart, registerables, TimeScale} from "chart.js";
Chart.register(...registerables, TimeScale, ChartDataLabels);

@Component({
  selector: 'app-admin-call-records',
  templateUrl: './admin-call-records.component.html',
  styleUrls: ['./admin-call-records.component.scss']
})
export class AdminCallRecordsComponent implements OnInit {

  isBusy = false;
  currentUser: User;

  constructor(private voipService: AdminVoipService,
              public adminUrl: AdminUrlService,
              private userService: UserService,
              private whosWhoService: AdminWhosWhoService) {
  }

  callRecords: CallRecordDTO[] = [];

  page = 1;
  count = 0;
  pageSizes = [10, 20, 50, 100];
  pageSize = 10;

  voipUserOptions: Array<IOption>;

  daysAgoOptions = [
    {label: "All Dates", value: 0},
    {label: "Today", value: 1},
    {label: "Yesterday", value: 2},
    {label: "Last 7 days", value: 7},
    {label: "Last 30 days", value: 30},
    {label: "Last 90 days", value: 90},
  ];

  callFilter: { contactId?: string } = {};

  ngOnInit(): void {
    this.fetchUsers();
    this.fetchRecords();
    this.userService.loadCurrentUser().then(() => {
      this.currentUser = this.userService.CurrentUser;
    });
  }

  fetchUsers() {
    this.whosWhoService.getSiteAdmins({component: 'whos-who'}, true).then((res) => {
      this.voipUserOptions = this.whosWhoService.getAssignedOptions(res.results);
    });
  }

  fetchRecords() {
    const dto = {
      component: 'call-records',
      filters: {
        contactId: this.callFilter.contactId
      },
      offset: (this.page - 1) * this.pageSize,
      limit: this.pageSize
    } as SearchCallRecordsDTO;

    this.isBusy = true;

    this.voipService.search(dto).then(res => {
      this.count = res.totalItems;
      this.callRecords = res.results;
      this.isBusy = false;

      console.log("Call records: ", this.callRecords);
    });
  }

  onTableDataChange(page: number) {
    // fetch records using page as offset
    this.page = page;
    this.fetchRecords();
  }

  handlePageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.fetchRecords();
  }

  onFilterChanged(data: { contactId: string }) {
    this.callFilter.contactId = data.contactId;
    this.fetchRecords();
  }
}
