import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {URLService} from '../../../../services';
import {TabsetComponent} from 'ng-uikit-pro-standard';
import {CustomerOrderDTO, User} from "../../../../global/interfaces";
import {CustomerService, LoggerService, UserService} from "../../../../global/services";
import {CustomerOrderTypeEnum} from "../../../../global/enums";

@Component({
  selector: 'app-customer-billing',
  templateUrl: './customer-billing.component.html',
  styleUrls: ['./customer-billing.component.scss']
})
export class CustomerBillingComponent implements OnInit, AfterViewInit {

  advertId = 0;
  orders: CustomerOrderDTO[];
  customerId: string;

  setTab = 0;
  @ViewChild('tabs') uiTabs: TabsetComponent;

  public OrderTypeEnum = CustomerOrderTypeEnum;
  user: User;

  constructor(
    private route: ActivatedRoute,
    public urlService: URLService,
    private location: Location,
    private customerService: CustomerService,
    private userService: UserService,
    private logService: LoggerService
  ) {
    this.customerId = userService.CurrentUser.customerId;
    this.user = this.userService.CurrentUser;
  }

  logger = this.logService.taggedLogger(this.constructor?.name);


  async ngOnInit() {
    // load all customer orders

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;

      this.customerService.getCustomerOrders(this.user.customerId).then(result => {

        this.logger.info("Customer Orders: ", result);
        this.orders = result;
      });
    });
  }

  ngAfterViewInit() {
    this.route.paramMap.subscribe(params => {

      if (params['params'] !== undefined && params['params']['setTab'] !== undefined) {
        let newTab = params['params']['setTab'];
        this.uiTabs.setActiveTab(newTab);
      }
    });
  }

  updateUrl($event: any) {

    if ($event && $event.activeTabIndex !== undefined) {
      this.location.replaceState(this.urlService.invoiceTab($event.activeTabIndex + 1, true));
    }
  }
}
