import {Injectable} from '@angular/core';
import {compare} from "fast-json-patch";
import {DataService} from "./data.service";
import {ApiService} from "../global/services/index";
import {BaseSearchDTO, SiteDTO} from '../global/interfaces';

@Injectable()
export class SiteService {

  private serviceUrl = '/api/site';

  constructor(private apiClient: ApiService, private data: DataService) { }

  getAll(search?: BaseSearchDTO): Promise<SiteDTO[]> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url}) as Promise<SiteDTO[]>;
  }

  get(siteId?: number): Promise<SiteDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${siteId}`;
    return this.apiClient.get({url}) as Promise<SiteDTO>;
  }

  create(site: SiteDTO): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: site}) as Promise<any>;
  }

  patch(dto: SiteDTO, previousDTO: SiteDTO = {}): Promise<any> {

    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${dto.id}`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }

  delete(attribId: number): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${attribId}`;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
