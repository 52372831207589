import {UInspectFormatDTO, UInspectFormatSyncDTO} from "./uinspect-format-dto.interface";
import {BaseDTO} from "../index";

export interface UInspectDTO extends BaseDTO {
  externalRef?: string;
  uInspectFormat?: UInspectFormatDTO;
  uInspectFormatId?: number;

  // NEED TO ADD THESE TO MODEL
  vrm?: string;
  customerName?: string;
  mobileNumber?: string;
  email?: string;
  vehicleDesc?: string;
  opened?: string;
  completed?: string;
  ipAddress?: string;
  longitude?: number;
  latitude?: number;
}

export interface UInspectSyncDTO extends UInspectDTO {
  uInspectFormat?: UInspectFormatSyncDTO;
  synced?: boolean;
}
