import {AttribDTO} from "./attribDTO.interface";
import {AppraisalItemDTO} from "./appraisal-itemDTO.interface";
import {ContactActionDTO} from "./contactActionDTO.interface";
import {VehicleMediaDTO} from "./Vehicle/vehicleMediaDTO.interface";
import {VehicleTypeDTO} from "./vehicleTypeDTO";
import {VehicleAttribDTO} from "./Vehicle/vehicleAttribDTO";
import {BaseIntDTO} from "./_baseIntDTO.interface";

export interface AttribvalDTO extends BaseIntDTO {
  attribId: number;
  vehicleTypeId: number;
  attribvalName: string;
  attribvalCode: string;
  sortOrder: number;
  attrib: AttribDTO;
  vehicleType: VehicleTypeDTO;
  appraisalItems: AppraisalItemDTO[];
  contactActions: ContactActionDTO[];
  vehicleAttribs: VehicleAttribDTO[];
  vehicleMedia: VehicleMediaDTO[];
}
