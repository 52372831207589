import {AdvertDTO} from "./advertDTO.interface";
import {CustomerDTO} from './customerDTO.interface';

export interface SphAdvertDTO_Public {
  advert: AdvertDTO;
}

export interface SphAdvertDTO extends SphAdvertDTO_Public {
  customer: CustomerDTO;
}
