import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiService, LoggerService} from "../global/services/index";
import {DataService} from "./data.service";
import {MediaCategoryEnum} from '../global/enums/index';
import {VehicleMediaDTO} from '../global/interfaces/index';

@Injectable()
export class VehicleMediaService {

  private serviceUrl = '/api/vehicleMedia';
  private apiLoaded = false;

  constructor(private apiClient: ApiService,
              private data: DataService,
              private httpClient: HttpClient,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  initYouTubeAPI() {

    if (!this.apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  getRequestOptions() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    };
    return {
      headers: new HttpHeaders(headers),
    };
  }

  public deleteVehicleMedia(id: string): Promise<any> {

    const url = this.data.apiUrl + this.serviceUrl + "/" + id;

    return this.apiClient.delete({url, headers: {"Content-Type": "application/json"}}) as Promise<any>;
  }

  public updateSequences(vehicleId: string, imageIdes: VehicleMediaDTO[]): Promise<any> {

    const url = this.data.apiUrl + this.serviceUrl + "/" + vehicleId + "/updateSequences";

    const data = JSON.stringify(imageIdes);

    this.logger.log("Updating photo sequences");

    return this.apiClient.put({url, data, headers: {"Content-Type": "application/json"}}) as Promise<any>;
  }

  public getVehicleMedia(vehicleId: string, mediaCategory?: MediaCategoryEnum): Promise<VehicleMediaDTO[]> {

    let url = `${this.data.apiUrl}${this.serviceUrl}/${vehicleId}`;

    if (mediaCategory) {
      url += `/category/${mediaCategory}`;
    }

    return this.apiClient.get({url}) as Promise<VehicleMediaDTO[]>;
  }

  public getVehicleMediaByAdvert(advertId: string, mediaCategory?: MediaCategoryEnum): Promise<VehicleMediaDTO[]> {

    let url = `${this.data.apiUrl}/api/listing/${advertId}/vehicle-media`;

    if (mediaCategory) {
      url += `/category/${mediaCategory}`;
    }

    return this.apiClient.get({url}) as Promise<VehicleMediaDTO[]>;
  }
}
