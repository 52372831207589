import {Component, OnInit} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {AdvertService, FileReaderService, URLService, VehicleService} from "../../../../services";
import {User} from "../../../../global/interfaces";
import {LoggerService, UserService, VehicleLookupService} from "../../../../global/services";
import {} from "../../../../global/enums";

@Component({
  selector: 'app-vehicle-lookup-importer',
  templateUrl: './vehicle-lookup-importer.component.html',
  styleUrls: ['./vehicle-lookup-importer.component.scss']
})
export class VehicleLookupImporterComponent implements OnInit {

  constructor(
    private fileReader: FileReaderService,
    private vehicleService: VehicleService,
    private advertService: AdvertService,
    private vehicleLookupService: VehicleLookupService,
    private userService: UserService,
    private toast: ToastService,
    public url: URLService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  // all vehicles in the db
  vehicles: any[];

  vehicleDatas: {
    vrm: string;
    export: boolean;
    isExporting?: boolean;
    dataNotFound: boolean;
  }[] = [];

  currentUser: User;

  public loadingVehicles: boolean;

  async ngOnInit() {
    await this.userService.loadCurrentUser();
    this.currentUser = this.userService.CurrentUser;

    this.loadImportedVehicles();
  }

  changeAll() {

    if (this.vehicles.every(val => val.checked == true)) {
      this.vehicles.forEach(val => {
        val.checked = false;
      });
    } else {

      this.vehicles.forEach(val => {
        val.checked = true;
      });
    }
  }

  loadImportedVehicles() {
    this.loadingVehicles = true;

    this.vehicleService.loadImportedVehicles("").then((result) => {
        this.vehicles = result;

        this.logger.info('Imported vehicles: ', this.vehicles);
        this.loadVehiclesToImport().then(() => {
          this.loadingVehicles = false;
        });
      }
    );
  }

  async loadVehiclesToImport() {
    this.loadingVehicles = true;

    // read in the current vehicle set from the csv
    try {
      this.logger.info('Loading vehicles to import');

      const result = await this.fileReader.getExternalDataFile('a-vrms-data.dat');
      const vrms = result.split('\r\n').filter(x => x != '');

      this.vehicleDatas = [];

      // set exists flag on import vrm where in vehicles data array
      vrms.forEach((x) => {
        const test = this.vehicles.findIndex(y => y.vrm === x);

        if (test === -1) {
          this.vehicleDatas.push({
            vrm: x,
            export: false,
            dataNotFound: false
          });
        }
      });
    } catch (error) {
      this.logger.error('Error occurred reading vehicles file: ', error);
      this.loadingVehicles = false;
    }
  }

  async importSelected() {
    for (const x of this.vehicleDatas) {
      if (x.export) {
        this.logger.info('Importing vehicle: ', x.vrm);

        // todo: eventually a customer will be selectable via dropdown
        try {
          x.isExporting = true;

          let result = await this.vehicleLookupService.getVehicleInfo(x.vrm);
          this.logger.info('Resulting vrm lookup data: ', result);

          if (!result.makeName) {
            const options = {opacity: 0.95};
            this.toast.error(`Vehicle '${x.vrm}' could not be found`, 'Error', options);
            x.isExporting = false;
            x.dataNotFound = true;
            x.export = false;
          }

          if (result.makeName) {
            const vehicleData = {
              customerId: this.currentUser.customerId,
              contactId: this.currentUser.contactId,
              addressId: "",
              vehicleLookupInfoDTO: {...result}
            };

            const vehicle = await this.vehicleService.createVehicleFromLookup(vehicleData);
            this.advertService.createAdvert(this.currentUser.customerId
              , this.currentUser.contactId, vehicle.id).then((result) => {
              this.logger.debug('Created advert: ', result);
            });
            x.isExporting = false;
            this.logger.info('Import succeeded: ', vehicle);
          }
        } catch (error) {
          // todo: add dialog to show error
          this.logger.error('Error importing data: ', error);
        }
      }
    }

    // refresh model data
    this.loadImportedVehicles();
  }

  deleteVRM(vehicle: any) {
    // this should only be done now from the VehicleChecker when user has highest role permissions
    // this.vehicleService.deleteVehicle(vehicle.vrm).then(result => {
    //   this.loadImportedVehicles();
    // }).catch(error => {
    // });
  }

  SelectAllToImport() {
    this.vehicleDatas.forEach(x => {
      x.export = true;
    });
  }

  SelectNoneToImport() {
    this.vehicleDatas.forEach(x => {
      x.export = false;
    });
  }
}
