import {RangeDTO} from "./rangeDTO.interface";
import {LookupIntStringDTO} from "./lookupIntStringDTO.interface";

export interface AllLookupsDTO {
  priceRanges: RangeDTO[];
  mileageRanges: RangeDTO[];
  capacityRanges: RangeDTO[];
  makes: LookupIntStringDTO[];
  fuelTypes: LookupIntStringDTO[];
  transmissionTypes: LookupIntStringDTO[];
  vehicleColours: LookupIntStringDTO[];
  bodyTypes: LookupIntStringDTO[];
  colours: LookupIntStringDTO[];
}
