import {CustomerMediaCategoryEnum, CustomerMediaSubCategoryEnum, MediaTypeEnum, StatusEnum} from "../../../enums/index";
import {BaseSearchDTO} from "../API";
import {BaseDTO} from "../_baseDTO.interface";

export interface CustomerMediaDTO extends BaseDTO {

  customerId: string;
  mediaSubCategory?: CustomerMediaSubCategoryEnum;
  mediaCategory?: CustomerMediaCategoryEnum;
  mediaType: MediaTypeEnum;
  mediaTypeId: number;
  mediaURL: string;
}

export interface CustomerMediaSearchDTO extends BaseSearchDTO {
  filters: {
    customerId?: string;
    statusId?: StatusEnum;
    mediaType?: MediaTypeEnum;
    mediaSubCategory?: CustomerMediaSubCategoryEnum;
    mediaCategory?: CustomerMediaCategoryEnum;
  };
}
