import {Component} from '@angular/core';
import {DataServiceInterface} from "../../services/interfaces/index";
import {DomainData} from "../../shared/index";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})

export class ContactUsComponent {

  globals: DomainData;

  map: { lat?: number, lng?: number, label?: string };

  constructor(private data: DataServiceInterface) {

    this.globals = data.globals;

    this.map = data.globals?.mapLocation;
  }

  joinedAddress(address: { line1?: string; line2?: string; line3?: string; line4?: string; postcode?: string }) {

    if (address != null) {
      const lines = Object.keys(address).filter(key => address[key].trim().length > 0).map(x => address[x]);
      return lines.join(", ");
    }
    return [];
  }
}
