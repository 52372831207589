import {AttribvalDTO} from '../attribvalDTO.interface';
import {BaseDTO} from "../_baseDTO.interface";

export interface VehicleCheckDTO extends BaseDTO {
  vehicleId: string;
  vrm: string;
  vin: string;
  odometer: number;
  vehicleCheckType?: number;
  vehicleCheckServiceId?: number;
  priceClean?: number;
  priceAvg?: number;
  priceBelow?: number;
  priceRetail?: number;
  provenanceStatus?: number;

  capId?: number;
  capCode: string;
  scrapped?: boolean;
  security?: boolean;
  stolen?: boolean;
  finance?: boolean;
  vcaR_Theft?: boolean;
  vcaR_Damage?: boolean;
  vehicleCheckAttribs?: AttribvalDTO[];
  vehicleCheckServiceName?: string;
  vehicleCheckTypeName?: string;
}
