import {NgxLoggerLevel} from "ngx-logger";

export const environment = {
  production: false,
  serviceUrl: "https://dev-api.tradesales.com",
  homepage: "https://dev-portal.tradesales.com",
  messageHubUrl: "https://dev-wss.tradesales.com/messagehub",
  mobileHost: "dev-app.tradesales.com",
  googleMapsAPIKey: "AIzaSyDRREM8vnh8GSDRXLsVt_Mi1tnhJCBYb6I",
  stripePublicKey: "pk_test_51Q6sIHCLElIQ4Dc0mCNEG1kItFEYRbze0vM2PhE1bZYRNRePrxYD8aROD37IDKiJEeU8gmHN2A882PKEpyL6OjxU00CJJq5Tdp", // sandbox
  logging: {
    level: NgxLoggerLevel.OFF,
    disableConsoleLogging: false
  }
};

// stripe prod version in test mode
//   stripePublicKey: "pk_test_51Q6rvoFkvrxV59xaGtXoWJ4RVQ0ioCzvqcjK7qw79z1x0K4jJQ3DjEWZjS61FpK5sS11OwfSoPQlegdW3TRGkOx400AOU8vqNq",

