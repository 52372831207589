// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updates-box {
  background-color: #f4f4f4;
  padding: 10px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/saved-search-edit/saved-search-edit.component.scss"],"names":[],"mappings":"AAAA;EAEE,yBAAA;EACA,aAAA;EACA,gBAAA;AAAF","sourcesContent":[".updates-box {\n\n  background-color: #f4f4f4;\n  padding: 10px;\n  margin-top: 20px;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
