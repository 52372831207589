import {BaseSearchDTO} from "./baseSearchDTO.interface";

export interface AdvertSearchFiltersDTO extends BaseSearchDTO
{
  filters?: {
    id?: string,
    ids?: string[];
    saleName?: string;
    addressId?: string;
    addressIds?: string[];
    statusId?: number;
    keywords?: string;
    makeIds?: number[];
    makeId?: number;
    modelIds?: number[];
    modelId?: number;
    derivativeIds?: number[];
    derivativeId?: number;
    fuelTypeIds?: number[];
    fuelTypeId?: number;
    transmissionTypeIds?: number[];
    transmissionTypeId?: number;
    bodyTypeIds?: number[];
    bodyTypeId?: number;
    mileageIds?: number[];

    // special property since it can't be a lookup (relative to current date)
    minAge?: number;
    maxAge?: number;

    capacityIds?: number[];
    doors?: number[];
    plateId?: number;
    plateIds?: number[];
    priceIds?: number[];
    vatStatusId?: number;
    vatStatusIds?: number[];
    currentSearchField?: number;
    customerId?: string;
    customerIds?: string[];
    soldStatus?: number;
    soldStatuses?: number[];
    advertStatus?: number;
    advertStatuses?: number[];
    colourIds?: number[];
    saleTypeId?: number;
    searchSortingEnum?: number;
    sortingFilterEnum?: number;
    runner?: number;
    catStatus?: number;
  }
}
