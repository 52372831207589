import {Injectable} from "@angular/core";
import {HttpHeaders} from '@angular/common/http';
import {SessionStorageService} from './sessionstorage.service';
import {compare} from "fast-json-patch";
import {ApiService, LoggerService} from "../global/services/index";
import {DataService} from "./data.service";
import {SavedSearchDTO, SearchDTO, SearchResultDTO, SearchSearchDTO} from "../global/interfaces";

@Injectable()
export class SearchService {
  private serviceUrl = "/api/search";
  private savedServiceUrl = "/api/savedsearch";
  private contactSavedServiceUrl = "/api/contact";
  private storageKey = "currentSearch";

  constructor(private apiClient: ApiService, private data: DataService,
              private sessionStorageService: SessionStorageService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  getRequestOptions() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'X-Requested-With,Content-Type'
    };
    return {
      headers: new HttpHeaders(headers),
    };
  }

  saveUnnamedSearch(searchDTO: SearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`; // post using crud controller

    this.logger.info("Saving unnamed search url: ", url);

    const data = JSON.stringify(searchDTO);

    return this.apiClient.post({url, data, headers: { "Content-Type": "application/json"}}) as Promise<any>;
  }

  saveNamedSearch(savedSearchDTO: SavedSearchDTO): Promise<any> {
    const url = `${this.data.apiUrl}${this.savedServiceUrl}`; // post using crud controller

    const data = JSON.stringify(savedSearchDTO);
    return this.apiClient.post({url, data, headers: { "Content-Type": "application/json"}}) as Promise<any>;
  }

  updateNamedSearch(savedSearchDTO: SavedSearchDTO): Promise<any> {

    this.logger.info("update named search", savedSearchDTO);

    const blankDTO: SavedSearchDTO = {};

    const patch = compare(blankDTO, savedSearchDTO);

    const url = `${this.data.apiUrl}${this.savedServiceUrl}/${savedSearchDTO.id}`; // put using crud controller
    return this.apiClient.patch({url, data: patch, headers: { "Content-Type": "application/json"}}) as Promise<any>;
  }

  deleteSearch(searchId: string): Promise<any> {

    const url = `${this.data.apiUrl}${this.savedServiceUrl}/${searchId}`;

    return this.apiClient.delete({url}) as Promise<any>;
  }

  saveSearchToSession(jsonValue: string) {
    if (!this.sessionStorageService.set(this.storageKey, jsonValue)) {
      this.logger.error("Could not save search to session storage, check it is enabled");
    } else {
      this.logger.info("Search saved to session");
    }
  }

  loadSearchFromSession() {
    return JSON.parse(this.sessionStorageService.get(this.storageKey));
  }

  getSavedSearches(contactId: string): Promise<SavedSearchDTO[]> {
    const url = `${this.data.apiUrl}${this.contactSavedServiceUrl}/${contactId}/savedSearches`;

    this.logger.log("URL " + url);
    return this.apiClient.get({url}) as Promise<SavedSearchDTO[]>;
  }

  getSavedProfileSearches(): Promise<SavedSearchDTO[]> {
    const url = `${this.data.apiUrl}${this.savedServiceUrl}`;

    return this.apiClient.get({url}) as Promise<SavedSearchDTO[]>;
  }

  getUpdateTypesForDropdown(enumeration: any) {
    const data = Array.from(this.enumToMap(enumeration).entries()).map(m => ({id: m[1], name: m[0]}));
    return data;
  }

  public getTopSearches(searchDTO: SearchSearchDTO): Promise<SearchResultDTO> {

    const dto = JSON.stringify(searchDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}/top-searchers`;
    return this.apiClient.get({url, params: {query: dto}}) as Promise<SearchResultDTO>;
  }

  getSearches(searchDTO: SearchSearchDTO): Promise<SearchResultDTO> {
    const dto = JSON.stringify(searchDTO);
    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    return this.apiClient.get({url, params: {query: dto}}) as Promise<SearchResultDTO>;
  }

  getSearchForUnsubscribe(searchId: string): Promise<SavedSearchDTO> {
    const url = `${this.data.apiUrl}${this.savedServiceUrl}/${searchId}/unsubscribe-info`;
    return this.apiClient.get({url,
      headers: {'Content-Type': 'application/json', "X-Skip-Interceptor": "true"}}) as Promise<SavedSearchDTO>;
  }

  setAlertOptions(searchId: string, option: string): Promise<SavedSearchDTO> {
    const url = `${this.data.apiUrl}${this.savedServiceUrl}/${searchId}/set-alert-option/${option}`;
    return this.apiClient.get({url,
      headers: {'Content-Type': 'application/json', "X-Skip-Interceptor": "true"}}) as Promise<SavedSearchDTO>;
  }

  enumToMap(enumeration: any): Map<string, string | number> {
    const map = new Map<string, string | number>();
    for (const key in enumeration) {
      // TypeScript does not allow enum keys to be numeric
      if (!isNaN(Number(key))) {
        continue;
      }

      const val = enumeration[key] as string | number;

      // TypeScript does not allow enum value to be null or undefined
      if (val !== undefined && val !== null) {
        map.set(key, val);
      }
    }

    return map;
  }
}
