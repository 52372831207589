import {CustomerDTO} from "./customerDTO.interface";
import {ContactDTO} from "./contactDTO.interface";
import {AdvertNoteDTO, BaseDTO, BaseIntDTO, CustomerNoteDTO} from "./";

export interface AdminTaskDTO extends BaseDTO {

  customerId?: string;
  customer?: CustomerDTO;
  contactId?: string;
  contact?: ContactDTO;
  taskForContactId?: string;
  taskForContact?: ContactDTO;
  taskFromContactId?: string;
  taskFromContact?: ContactDTO;
  taskType?: number;
  priority?: number;
  sleepUntil?: Date | string;
  advertNoteId?: string;
  advertNote?: AdvertNoteDTO;
  customerNoteId?: number;
  customerNote?: CustomerNoteDTO;
  overdue?: boolean;
  lastUpdateByContact?: ContactDTO;
  lastUpdateByContactId?: string;
}
