import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {environment} from "../../remarq/src/environments/environment";

const level = environment?.logging?.level && environment.logging.level
  ? environment.logging.level : NgxLoggerLevel.OFF;

const disableConsoleLogging = environment?.logging?.disableConsoleLogging;

@NgModule({
  imports: [
    CommonModule,
    LoggerModule.forRoot({
      level,
      disableFileDetails: true,
      disableConsoleLogging,
      timestampFormat: 'h:mm:ss.SSS',
      colorScheme: ['purple', 'blue', 'gray', 'gray', 'red', 'red', 'red']
    })
  ],
  exports: [LoggerModule],
})
export class RemarqLoggerModule {
}
