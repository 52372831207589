// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tyre-depth div:not(:last-of-type) {
  border-bottom: 1px solid rgba(100, 100, 100, 0.1);
}

.tyre-depth-img {
  width: 30px;
  height: 18px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
  background-image: url("/assets/images/svg/Tire-Tracks2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: text-bottom;
}

.tyre-depth-1 {
  filter: opacity(12%);
}

.tyre-depth-1 {
  filter: opacity(25%);
}

.tyre-depth-2 {
  filter: opacity(37%);
}

.tyre-depth-3 {
  filter: opacity(50%);
}

.tyre-depth-4 {
  filter: opacity(62%);
}

.tyre-depth-5 {
  filter: opacity(75%);
}

.tyre-depth-7 {
  filter: opacity(87%);
}

.tyre-depth-8 {
  filter: opacity(100%);
}

.tyre-label {
  font-weight: 500;
  color: var(--textLabelColour);
  font-size: 0.8rem;
  line-height: 2.2rem;
}

.tyre-value {
  font-weight: 500;
  color: var(--textColour);
  font-size: 0.8rem;
  line-height: 2.2rem;
}

.spare-label {
  margin-top: 18px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/vehicle/vehicle-tyres/vehicle-tyres.component.scss"],"names":[],"mappings":"AAEE;EACE,iDAAA;AADJ;;AAKA;EACE,WAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,kBAAA;EACA,4DAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,oBAAA;AAFF;;AAKA;EACE,qBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,6BAAA;EACA,iBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,wBAAA;EACA,iBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF","sourcesContent":[".tyre-depth {\n\n  div:not(:last-of-type) {\n    border-bottom: 1px solid rgba(100, 100, 100, 0.1);\n  }\n}\n\n.tyre-depth-img {\n  width: 30px;\n  height: 18px;\n  display: inline-block;\n  margin-right: 10px;\n  border-radius: 4px;\n  background-image: url(\"/assets/images/svg/Tire-Tracks2.svg\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  vertical-align: text-bottom;\n}\n\n.tyre-depth-1 {\n  filter: opacity(12%)\n}\n\n.tyre-depth-1 {\n  filter: opacity(25%)\n}\n\n.tyre-depth-2 {\n  filter: opacity(37%)\n}\n\n.tyre-depth-3 {\n  filter: opacity(50%)\n}\n\n.tyre-depth-4 {\n  filter: opacity(62%)\n}\n\n.tyre-depth-5 {\n  filter: opacity(75%)\n}\n\n.tyre-depth-7 {\n  filter: opacity(87%)\n}\n\n.tyre-depth-8 {\n  filter: opacity(100%)\n}\n\n.tyre-label {\n  font-weight: 500;\n  color: var(--textLabelColour);\n  font-size: 0.8rem;\n  line-height: 2.20rem;\n}\n\n.tyre-value {\n  font-weight: 500;\n  color: var(--textColour);\n  font-size: 0.8rem;\n  line-height: 2.20rem;\n}\n\n.spare-label {\n  margin-top: 18px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
