import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {LookupService} from "../../../../../services";
import {MakeModelDerivService} from "../../../../../services";
import {MDBModalService, ModalDirective} from "ng-uikit-pro-standard";
import {AltMaintListDTO} from "../../../../../global/interfaces";
import {StatusEnum, VehicleTypeEnum} from "../../../../../global/enums";
import {LoggerService} from "../../../../../global/services";

@Component({
  selector: 'app-alt-mmd',
  templateUrl: './mmd.component.html',
  styleUrls: ['./mmd.component.scss'],
  providers: []
})


export class MmdComponent implements OnInit, OnDestroy {

  @ViewChild('altModal') altModal: ModalDirective;

  vehicleTypeId: number;
  makeId: number;
  modelId: number;
  derivId: number;
  vehicleTypes: any[];
  makes: AltMaintListDTO[];
  models: AltMaintListDTO[];
  derivs: AltMaintListDTO[];
  records: any[];
  makeName: {};
  modelName: {};
  recordStatus: {} = {};
  recordMergeTo: {} = {};
  recordValue: {} = {};
  recordNameSaving: any;
  mergeMake: {} = {};
  mergeModel: {} = {};
  mergeDeriv: {} = {};
  derivName: {};
  derivStatus: {};
  statusEnum = StatusEnum;
  modelStatus: {};
  makeStatus: {};
  havePendingMakes = false;
  havePendingModels: boolean;
  havePendingDerivs: boolean;
  pendings: {}[];
  showTable: any = "make";
  vehicleTypeName: {} = {};
  altList: any[] = [];
  parentValue: string;
  altModalTable: string;
  showDeleteConfirm: any;
  showDeleteAlt: any;
  private deletingAltId: any;
  private deletingAltTable: any;
  public toggleDerivs = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private lookupService: LookupService,
    private modalService: MDBModalService,
    private makeModelDerivService: MakeModelDerivService,
    private logService: LoggerService
  ) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  async ngOnInit() {

    this.vehicleTypeId = 1;

    for (const [propertyKey, propertyValue] of Object.entries(VehicleTypeEnum)) {
      this.vehicleTypeName[propertyValue] = propertyKey;
    }

    this.loadPendingCounts();
    this.loadVehicleTypes();
  }

  selectVehicleTypeId(vehicleTypeId: number) {

    this.vehicleTypeId = vehicleTypeId;
    this.loadMakes(vehicleTypeId).then(() => {
    });
    this.makeId = null;
    this.modelId = null;
    this.derivId = null;
  }

  selectMakeId(makeId: number) {

    this.makeId = makeId;
    this.loadModels(makeId).then(() => {
    });
    this.modelId = null;
    this.derivId = null;

    this.scrollToTop();
  }

  selectModelId(modelId: number) {

    this.modelId = modelId;
    this.loadDerivs(modelId).then(() => {
    });
    this.derivId = null;
    this.scrollToTop();
  }

  selectDerivId(derivId: number) {

    this.derivId = derivId;
    this.scrollToTop();
  }

  loadVehicleTypes() {

    this.lookupService.getAll("vehicleType", {
      filters: {
        statusId: StatusEnum.Active
      }
    }).then((x) => {
      this.vehicleTypes = x;
    });
  }

  loadMakes(vehicleTypeId: number) {

    return this.makeModelDerivService.getRecords("make", vehicleTypeId, null).then((results: AltMaintListDTO[]) => {

      this.mergeMake = {};
      this.makeName = {};
      this.makeStatus = {};
      this.havePendingMakes = false;

      results.forEach((x: AltMaintListDTO) => {

        this.mergeMake[x.id] = null;
        this.makeName[x.id] = x.value;

        if (x.statusId === StatusEnum.Pending) {
          this.havePendingMakes = true;
          this.makeStatus[x.id] = false;
        }
      });

      this.makes = results;
      this.makes.sort((x, y) => {
        return y.statusId - x.statusId || x.value.localeCompare(y.value);
      });

    });
  }

  loadModels(makeId: number) {

    return this.makeModelDerivService.getRecords("model", null, makeId).then((results: AltMaintListDTO[]) => {

      this.mergeModel = {};
      this.modelName = {};
      this.modelStatus = {};
      this.havePendingModels = false;

      results.forEach((x: AltMaintListDTO) => {
        this.mergeModel[x.id] = null;
        this.modelName[x.id] = x.value;

        if (x.statusId == StatusEnum.Pending) {
          this.modelStatus[x.id] = false;
          this.havePendingModels = true;
        }
      });

      this.models = results;
      this.models.sort((x, y) => {
        return y.statusId - x.statusId || x.value.localeCompare(y.value);
      });
    });
  }

  loadDerivs(modelId: number) {

    return this.makeModelDerivService.getRecords("deriv", null, modelId).then((results: AltMaintListDTO[]) => {

      this.mergeDeriv = {};
      this.derivName = {};
      this.derivStatus = {};
      this.havePendingDerivs = false;

      results.forEach((x: AltMaintListDTO) => {
        this.mergeDeriv[x.id] = null;
        this.derivName[x.id] = x.value;
        if (x.statusId === StatusEnum.Pending) {
          this.havePendingDerivs = true;
          this.derivStatus[x.id] = false;
        }
      });

      this.derivs = results;

      this.derivs.sort((x, y) => {
        return y.statusId - x.statusId || x.value.localeCompare(y.value);
      });
    });
  }

  ngOnDestroy(): void {
  }

  refreshTables(altTable: string, vehicleTypeId, nextPending?: boolean) {

    if (["make", "model", "deriv"].includes(altTable)) {

      if (!["make", "model", "deriv"].includes(this.showTable)) {
        this.showTable = altTable;
      }

      if (nextPending) {

        this.makeModelDerivService.getNextPending(altTable, vehicleTypeId).then((x) => {

          this.makeId = null;
          this.modelId = null;
          this.derivId = null;

          if (x.vehicleTypeId != null) {
            this.vehicleTypeId = x.vehicleTypeId;
            vehicleTypeId = x.vehicleTypeId;
          }
          if (x.makeId != null) {
            this.makeId = x.makeId;
          }
          if (x.modelId != null) {
            this.modelId = x.modelId;
          }
          if (x.derivId != null) {
            this.derivId = x.derivId;
          }

          this.showTable = altTable;

          this.loadMMD(vehicleTypeId);
        });
      } else {
        this.loadMMD(vehicleTypeId);
      }
    } else {

      this.loadNonMMDRecords(altTable, vehicleTypeId);
    }
  }

  loadMMD(vehicleTypeId: number) {

    this.loadMakes(vehicleTypeId).then(() => {
      if (this.makeId) {
        this.loadModels(this.makeId).then(() => {
          if (this.modelId) {
            this.loadDerivs(this.modelId).then(() => {
            });
          }
        });
      }
    });
  }

  mergeRecord(altTable: string, vehicleTypeId: number, fromId: any) {

    let toId = null;

    this.vehicleTypeId = vehicleTypeId;

    if (['make', 'model', 'deriv'].includes(altTable)) {

      if (altTable == "make") {
        toId = this.mergeMake[fromId];
      } else if (altTable == "model") {
        toId = this.mergeModel[fromId];
      } else if (altTable == "deriv") {
        toId = this.mergeDeriv[fromId];
      }
    } else {

      toId = this.recordMergeTo[fromId];
    }

    this.makeModelDerivService.mergeRecord(altTable, vehicleTypeId, fromId, toId).then(() => {
      this.refreshTables(altTable, vehicleTypeId);
      this.loadPendingCounts();
    });
  }

  scrollToTop() {

    window.scroll(0, 0);
  }

  approveRecord(altTable: string, recordId: number, vehicleTypeId: number) {

    this.makeModelDerivService.approveReject(altTable, recordId, "approve").then((x) => {

      this.refreshTables(altTable, vehicleTypeId);
      this.loadPendingCounts();
    });
  }

  private loadPendingCounts() {

    this.makeModelDerivService.getPendingCounts().then((x) => {
      this.pendings = [];
      x.forEach((z, y) => {
        this.pendings.push({altTable: z.altTable, vehicleTypeId: z.vehicleTypeId, count: z.count});
      });
    });
  }

  private loadNonMMDRecords(altTable: string, vehicleTypeId?: number) {

    this.makeModelDerivService.getRecords(altTable, vehicleTypeId).then((result) => {

      this.records = result.sort((b, a) => a.statusId - b.statusId);

      this.recordStatus = {};
      this.recordMergeTo = {};
      this.recordValue = {};

      result.forEach((x, y) => {

        this.recordStatus[x.id] = false;
        this.recordMergeTo[x.id] = null;
        this.recordValue[x.id] = x.value;
      });

      this.showTable = altTable;
    });
  }

  deleteRecord(altTable: string, id: any) {

  }

  removeAlt(altItem: AltMaintListDTO) {

    this.makeModelDerivService.removeAlt(altItem.id).then((x) => {

      this.refreshTables(this.altModalTable, this.vehicleTypeId);

    });
  }

  recordNameChanged(altTable: string, id: any) {

    var newValue = '';

    if (altTable == "make") {
      newValue = this.makeName[id];
    } else if (altTable == "model") {
      newValue = this.modelName[id];
    } else if (altTable == "deriv") {
      newValue = this.derivName[id];
    } else {
      newValue = this.recordValue[id];
    }

    this.recordNameSaving = id;

    this.makeModelDerivService.changeRecord(altTable, id, newValue).then((x) => {

      this.recordNameSaving = null;

      this.refreshTables(altTable, this.vehicleTypeId);

    });
  }

  showAltsForRealId(altTable: string, altValue: string, altRealId: number) {

    this.parentValue = altValue;

    return this.makeModelDerivService.getAltsByRealId(altTable, altRealId).then((x) => {
      this.altList = x;
      this.altModalTable = altTable;
      this.altModal.show();
    });

  }

  async deleteAlt(table: string, id: number, parentId: number, vehicleTypeId: number) {

    await this.makeModelDerivService.approveReject(table, id, 'reject');
  }

  async approveRecords(altTable: string) {

    let parse = {};

    if (altTable == "deriv") {
      parse = this.derivStatus;
    } else if (altTable == "model") {
      parse = this.modelStatus;
    } else if (altTable == "make") {
      parse = this.makeStatus;
    } else {
      parse = this.recordStatus;
    }

    for (const [propertyKey, propertyValue] of Object.entries(parse)) {
      if (propertyValue == true) {
        await this.makeModelDerivService.approveReject(altTable, propertyKey, 'approve');
      }
    }

    this.toggleDerivs = false;

    this.refreshTables(altTable, this.vehicleTypeId);
    this.loadPendingCounts();
  }

  deleteType(showTable: any, id) {

    this.deletingAltId = id;
    this.deletingAltTable = showTable;
    this.showDeleteAlt = true;

  }

  async confirmDeleteAlt() {

    await this.makeModelDerivService.approveReject(this.deletingAltTable, this.deletingAltId, 'reject').then(() => {

      this.refreshTables(this.deletingAltTable, this.vehicleTypeId);
      this.loadPendingCounts();

    });

  }

  async autofixParent() {

    await this.makeModelDerivService.autoFixParent();
  }

  toggleCheckbox(table: string) {

    if (table == "deriv") {
      this.derivs.filter(x => x.statusId == 2).forEach((x) => {
        this.derivStatus[x.id] = !this.toggleDerivs;
      });
      this.toggleDerivs = !this.toggleDerivs;
    }
  }
}
