import {BaseSearchDTO} from "./baseSearchDTO.interface";

export interface AddressSearchDTO extends BaseSearchDTO
{
  filters?: {
    addressName?: string;
    addressId?: string;
    customerId?: string;
    saleId?: string;
    statusId?: number;
  }
}
