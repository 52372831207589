import {BaseDTO} from "../index";
import {CustomerDTO} from "../customerDTO.interface";
import { BaseSearchDTO } from "../API";

export interface MovementAddressDTO extends BaseDTO {

  temporary?: boolean;
  customerId?: string;
  customer?: CustomerDTO;
  postcode?: string;
  address1?: string;
  address2?: string;
  town?: string;
  county?: string;
  contactName?: string;
  locationName?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  wtw?: string;
  lat?: number;
  lon?: number;
}

export interface MovementAddressSearchDTO extends BaseSearchDTO {
  filters?: {
    id: number
  };
}
