import {ContactDTO} from "../../contactDTO.interface";
import {ProspectStateEnum} from "../../../../enums";
import {BaseDTO} from "../../_baseDTO.interface";
import {AdvertNoteDTO} from "./advert-note.dto.interface";

export interface ProspectDTO extends BaseDTO {
  brokerageId: string;
  prospectContactId: string;
  prospectContact: ContactDTO;
  prospectStateId: ProspectStateEnum;
  manuallyAdded: boolean;
  fromBidder: boolean;
  fromWatcher: boolean;
  fromViewer: boolean;
  fromAlert: boolean;
  fromEnquiry: boolean;
  advertNotes: AdvertNoteDTO[];
  assignedTo: string;
  isOpen: boolean; // used by UI only
}
