// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-input, .time-input {
  padding-left: 13px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/sale/sale-edit/sale-edit.component.scss"],"names":[],"mappings":"AAAA;EAA2B,kBAAA;AAE3B","sourcesContent":[".date-input, .time-input { padding-left: 13px; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
