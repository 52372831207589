import {WhosWhoStatusEnum} from "../../../enums";
import {BidLimitedCustomerDTO} from "./bid-limited-customer.dto.interface";

export interface BidLimitedCustomerInfoDTO {
  customerId?: string;
  customer?: BidLimitedCustomerDTO;
  assignedTo?: string;
  whosWhoStatus?: WhosWhoStatusEnum;
  incompleteDealCount?: number;
}
