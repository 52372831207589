import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  CommsTemplateDTO,
  CommsTemplatePreviewRequestDTO,
  CommsTemplatePreviewResponseDTO,
  CommsTemplateSearchDTO,
  SearchResultDTO
} from "../global/interfaces";
import {ApiService} from "../global/services";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root',
})
export class CommsTemplateService {

  url = '/api/commsTemplate';
  entityTitle = 'Comms Template';

  constructor(http: HttpClient, private apiClient: ApiService, private data: DataService) {
  }

  getCommsTemplates(filter?: CommsTemplateSearchDTO): Promise<SearchResultDTO> {

    const newUrl = this.data.apiUrl + this.url;

    return this.apiClient.get({url: newUrl}, {query: filter});
  }

  fetchExcludedCustomers(templateId: number): Promise<SearchResultDTO> {

    const newUrl = this.data.apiUrl + this.url + '/getExcludedCustomers/' + templateId;

    return this.apiClient.get({url: newUrl});
  }

  fetchTemplateWrappers() {

    const newUrl = this.data.apiUrl + this.url + '/getTemplateWrappers';

    return this.apiClient.get({url: newUrl});
  }

  sendTest(request: CommsTemplatePreviewRequestDTO) {

    const newUrl = this.data.apiUrl + this.url + '/sendTest';

    return this.apiClient.post({
      url: newUrl, data: {
        commsTemplate: request.commsTemplate,
        previewId: request.previewId,
        previewRecipient: request.previewRecipient
      }
    });
  }

  previewTemplate(request: CommsTemplatePreviewRequestDTO) {

    const newUrl = this.data.apiUrl + this.url + '/preview';

    return this.apiClient.post({
      url: newUrl, data: {
        commsTemplate: request.commsTemplate,
        previewId: request.previewId,
        previewRecipient: request.previewRecipient
      }
    });
  }

  previewModel(request: CommsTemplatePreviewRequestDTO): Promise<CommsTemplatePreviewResponseDTO> {

    const newUrl = this.data.apiUrl + this.url + '/previewModel';

    return this.apiClient.post({
      url: newUrl, data: {
        commsTemplate: request.commsTemplate,
        previewId: request.previewId,
        previewRecipient: request.previewRecipient
      }
    });
  }

  deleteTemplate(id) {

    const newUrl = this.data.apiUrl + this.url + "/" + id;

    return this.apiClient.delete({url: newUrl});
  }

  addCommsTemplateExclude(commsTemplateId: number, customerId: number) {

    const newUrl = this.data.apiUrl + this.url + "/addExcludedCustomer";

    return this.apiClient.post({url: newUrl, data: {commsTemplateId, customerId}});
  }

  removeCommsTemplateExclude(commsTemplateId: number, customerId: number) {

    const newUrl = this.data.apiUrl + this.url + "/removeExcludedCustomer/" + commsTemplateId + "/" + customerId;

    return this.apiClient.delete({url: newUrl});
  }

  saveTemplate(commsTemplate: CommsTemplateDTO): Promise<CommsTemplateDTO> {

    if (commsTemplate.id === 0 || commsTemplate.id == null) {

      return this.apiClient.post({url: this.data.apiUrl + this.url, data: commsTemplate});

    } else {

      const newUrl = this.data.apiUrl + this.url + "/" + commsTemplate.id;

      return this.apiClient.put({url: newUrl, data: commsTemplate});
    }
  }
}
