import {AttribvalDTO} from './attribvalDTO.interface';
import {ContactAttribDTO} from './contact-attribDTO.interface';
import {BaseIntDTO} from "./_baseIntDTO.interface";
import {VehicleTypeEnum} from "../../enums/vehicletype.enum";

export interface AttribDTO extends BaseIntDTO {
  attribName?: string;
  attribCode?: string;
  attribType?: string;
  attribDesc?: string;
  defaultVal?: string;
  attribvals?: AttribvalDTO[];
  contactAttrib?: ContactAttribDTO;
}
