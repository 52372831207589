import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { MomentInput } from 'moment';

@Pipe({
  name: 'datex',
})
export class DatexPipe implements PipeTransform {
  transform(value: MomentInput, format: string = ''): string {
    if (!value || value === '') return '';
    return moment(value).format(format);
  }
}
