import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {URLHelperService} from '../global/services/index';

@Injectable({providedIn: 'root'})
export class URLService {

  constructor(private router: Router, private urlHelperService: URLHelperService) {
  }

  redirect(path: string, params = null, replaceUrl = false) {

    this.router.navigate([path], {replaceUrl, queryParams: params}).then(() => {
    });
  }

  homepage(returnOnly: boolean = false) {

    const path = "/";

    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  stock(tab, returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/selling/" + tab, returnOnly);
  }

  myBids(directionId = "1", bidStateId = "0", returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom(`/main/my-bids/${directionId}/${bidStateId}`, returnOnly);
  }

  bids(active: number, returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/bids/" + active, returnOnly);
  }

  userChecks(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/user-checks", returnOnly);
  }

  dashboard(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/dashboard", returnOnly);
  }

  search(returnOnly: boolean = false, dto = null) {

    const path = "/main/search";

    if (returnOnly) {
      return path;
    } else {
      this.redirect(path, (dto != null) ? dto.filters : null, false);
    }
  }

  advertCreate(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/listing/create", returnOnly);
  }

  advertView(advertId: string, returnOnly: boolean = false) {

    const path = "/main/listing/" + advertId;
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  vehicleView(vehicleId: string, returnOnly: boolean = false) {
    const path = "/main/vehicle/" + vehicleId;
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  contactCreate(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/contacts/create", returnOnly);
  }

  contacts(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/contacts", returnOnly);
  }

  contactEdit(contactId: string, returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/contacts/" + contactId + "/edit", returnOnly);
  }

  advertEdit(advertId: string, returnOnly: boolean = false, replaceUrl: boolean = false) {
    const path = "/main/listing/" + advertId + "/edit";
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path, replaceUrl);
    }
  }

  register(returnOnly: boolean = false): string {
    return this.urlHelperService.urlFrom("/register", returnOnly);
  }

  login(returnOnly: boolean = false, toUrl = false): string {
    return this.urlHelperService.urlFrom("/login", returnOnly);
  }

  signup(returnOnly: boolean = false): string {
    return this.urlHelperService.urlFrom("/signup", returnOnly);
  }

  userPending(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/account-pending", returnOnly);
  }

  userDeleted(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/account-deleted", returnOnly);
  }

  userPaused(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/account-paused", returnOnly);
  }

  // URL stub, for individual tabs see below
  invoices(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/invoices", returnOnly);
  }

  invoiceTab(tab: number, returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom(this.invoices(true) + "/" + tab, returnOnly);
  }

  myOrders(returnOnly: boolean = false) {
    const path = this.invoiceTab(1, true);
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  mySales(returnOnly: boolean = false) {
    const path = this.invoiceTab(2, true);
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  myPurchases(returnOnly: boolean = false) {
    const path = this.invoiceTab(3, true);
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  myInvoices(returnOnly: boolean = false) {
    const path = this.invoiceTab(4, true);
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
    }
  }

  queryParamsFromDTO(dto: any) {
    const qstring = JSON.stringify(dto);
    return new HttpParams().set("query", qstring);
  }

  admin(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/admin", returnOnly);
  }

  mySearches(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/my-searches", returnOnly);
  }

  myWatchList(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/my-watchlist", returnOnly);
  }

  settings(returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/settings", returnOnly);
  }

  settings_tab(setTab: number, returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom(`/main/settings/${setTab}`, returnOnly);
  }


  help(returnOnly: boolean = false) {
    return "https://www.tradesales.com/customer-support";
  }

  mail(folder: number, returnOnly: boolean = false) {
    return this.urlHelperService.urlFrom("/main/inmail/" + folder, returnOnly);
  }


  sale(id: string, returnOnly: boolean = false) {
    const path = "/main/sales/" + id;
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
      return true;
    }
  }

  contact(contactId: string, returnOnly: boolean = false) {
    const path = "/main/contacts/" + contactId;
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
      return true;
    }
  }

  vehicle(vehicleId: string, b: boolean = false) {

  }

  customer(customerId: string, returnOnly: boolean = false) {
    const path = "/main/customer/" + customerId;
    if (returnOnly) {
      return path;
    } else {
      this.redirect(path);
      return true;
    }
  }


  faultReportView(vehicleFaultCheckId, returnOnly: boolean = false, newWindow: boolean = false) {

    const path = "/fault-check-report/" + vehicleFaultCheckId;

    if (returnOnly) {
      return path;
    } else {
      if (newWindow) {
        window.open(path, '_blank');
      } else {
        this.redirect(path, newWindow);
      }
    }
  }
}
