import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DataService, URLService} from '../../../services';
import {
  FaultCategoryDTO, FaultCheckTypeCategoryDTO,
  FaultCheckTypeDTO, FaultItemDTO, FaultStatusDTO,
  TypedValidatedResultDTO, VehicleFaultCheckItemDTO,
} from "../../../global/interfaces";
import {ApiService} from "../../../global/services";
import {} from "../../../global/enums";
import {compare} from "fast-json-patch";

@Injectable()
export class AdminFaultCheckService {

  constructor(
    private router: Router,
    private apiClient: ApiService,
    private data: DataService,
    public urlService: URLService
  ) {
  }

  private serviceUrl = '/api/vehicle-fault';

  public deleteCategory(id: number):
    Promise<TypedValidatedResultDTO<FaultCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/category/${id}`;

    return this.apiClient.delete({
      url,
      headers: {accept: 'application/json'}
    }) as Promise<TypedValidatedResultDTO<FaultCategoryDTO>>;
  }

  public patchCategory(id: number, patch: any): Promise<TypedValidatedResultDTO<FaultCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/category/${id}`;

    return this.apiClient.patch({
      url,
      headers: {accept: 'application/json'},
      data: patch
    }) as Promise<TypedValidatedResultDTO<FaultCategoryDTO>>;
  }

  public createCategory(data: FaultCategoryDTO): Promise<TypedValidatedResultDTO<FaultCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/category`;

    return this.apiClient.post({
      url,
      data,
      headers: {accept: 'application/json'},
    }) as Promise<TypedValidatedResultDTO<FaultCategoryDTO>>;
  }

  public deleteCheckType(id: number):
    Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type/${id}`;

    return this.apiClient.delete({
      url,
      headers: {accept: 'application/json'}
    }) as Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>>;
  }

  public patchCheckType(id: number, patch: any): Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type/${id}`;

    return this.apiClient.patch({
      url,
      headers: {accept: 'application/json'},
      data: patch
    }) as Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>>;
  }

  public createCheckType(data: FaultCheckTypeDTO): Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type`;

    return this.apiClient.post({
      url,
      data,
      headers: {accept: 'application/json'},
    }) as Promise<TypedValidatedResultDTO<FaultCheckTypeDTO>>;
  }

  public createCheckTypeCategory(data: FaultCheckTypeCategoryDTO): Promise<TypedValidatedResultDTO<FaultCheckTypeCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type/${data.faultCheckTypeId}/category`;

    return this.apiClient.post({
      url,
      data,
      headers: {accept: 'application/json'},
    }) as Promise<TypedValidatedResultDTO<FaultCheckTypeCategoryDTO>>;
  }

  public deleteCheckTypeCategory(id: number):
    Promise<TypedValidatedResultDTO<FaultCheckTypeCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type-category/${id}`;

    return this.apiClient.delete({
      url,
      headers: {accept: 'application/json'}
    }) as Promise<TypedValidatedResultDTO<FaultCheckTypeCategoryDTO>>;
  }

  public patchCheckTypeCategory(id: number, patch: any): Promise<TypedValidatedResultDTO<FaultCheckTypeCategoryDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/check-type-category/${id}`;

    return this.apiClient.patch({
      url,
      headers: {accept: 'application/json'},
      data: patch
    }) as Promise<TypedValidatedResultDTO<FaultCheckTypeCategoryDTO>>;
  }

  public deleteFaultStatus(id: number):
    Promise<TypedValidatedResultDTO<FaultStatusDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/status/${id}`;

    return this.apiClient.delete({
      url,
      headers: {accept: 'application/json'}
    }) as Promise<TypedValidatedResultDTO<FaultStatusDTO>>;
  }

  public patchFaultStatus(id: number, patch: any): Promise<TypedValidatedResultDTO<FaultStatusDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/status/${id}`;

    return this.apiClient.patch({
      url,
      headers: {accept: 'application/json'},
      data: patch
    }) as Promise<TypedValidatedResultDTO<FaultStatusDTO>>;
  }

  public createFaultStatus(data: FaultStatusDTO): Promise<TypedValidatedResultDTO<FaultStatusDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/status`;

    return this.apiClient.post({
      url,
      data,
      headers: {accept: 'application/json'},
    }) as Promise<TypedValidatedResultDTO<FaultStatusDTO>>;
  }

  public deleteFaultItem(id: number):
    Promise<TypedValidatedResultDTO<FaultItemDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/item/${id}`;

    return this.apiClient.delete({
      url,
      headers: {accept: 'application/json'}
    }) as Promise<TypedValidatedResultDTO<FaultItemDTO>>;
  }

  public patchFaultItem(id: number, patch: any): Promise<TypedValidatedResultDTO<FaultItemDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/item/${id}`;

    return this.apiClient.patch({
      url,
      headers: {accept: 'application/json'},
      data: patch
    }) as Promise<TypedValidatedResultDTO<FaultItemDTO>>;
  }

  public createFaultItem(data: FaultItemDTO): Promise<TypedValidatedResultDTO<FaultItemDTO>> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/item`;

    return this.apiClient.post({
      url,
      data,
      headers: {accept: 'application/json'},
    }) as Promise<TypedValidatedResultDTO<FaultItemDTO>>;
  }
}
