import {Injectable} from '@angular/core';
import {ApiService, LoggerService} from "../global/services/index";
import {LocalStorageService} from './localstorage.service';
import {DataService} from "./data.service";
import {SetWatchlistStatusDTO, WatchlistAdvertDTO, WatchlistDTO} from '../global/interfaces/index';
import {StatusEnum} from '../global/enums';

@Injectable()
export class WatchlistService {
  private serviceUrl = "/api/watchlist";

  watchlistItems: WatchlistDTO[];
  public watchlistLoaded = false;

  constructor(private apiClient: ApiService,
              private data: DataService,
              private localStorageService: LocalStorageService,
              private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  public setWatchlistStatus(watchlist: WatchlistDTO): Promise<any> {

    let dto = {} as SetWatchlistStatusDTO;

    dto.advertId = watchlist.advertId;
    dto.statusId = watchlist.statusId;

    this.logger.info("Watchlist item: ", watchlist);
    const url = `${this.data.apiUrl}${this.serviceUrl}/advert/${dto.advertId}/setStatus/${dto.statusId}`;

    return this.apiClient.get({url}) as Promise<any>;
  }

  public saveWatchlistToLocalStorage(contactId: string) {
    const watchlistKey = "watchlist-" + contactId;

    if (!this.localStorageService.set(watchlistKey, this.watchlistItems)) {
      this.logger.warn("Unable to store watchlist in localStorage, check it is enabled");
    }
  }

  public updateWatchlist(advertId: string, contactId: string, isActive: boolean) {

    let item = this.watchlistItems.find(x => x.advertId === advertId && x.contactId === contactId);

    if (!item) {
      item = {
        advertId,
        contactId
      };

      item.statusId = isActive ? StatusEnum.Active : StatusEnum.Deleted;
      this.watchlistItems.push(item);
    }

    item.statusId = isActive ? StatusEnum.Active : StatusEnum.Deleted;

    this.saveWatchlistToLocalStorage(contactId);

    // save to db (will update if it exists)
    this.setWatchlistStatus(item).then(() => {
      this.logger.info("Watchlist item saved to db: ", item);
    });
  }

  public advertIsWatched(advertId: string, contactId: string): boolean {
    if (!this.watchlistItems || this.watchlistItems.length == 0) {
      return false;
    }
    const item = this.watchlistItems.find(x => x.advertId === advertId && x.contactId === contactId);
    return item && item.statusId === StatusEnum.Active;
  }

  public getWatchlistWithAdverts(): Promise<WatchlistAdvertDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/watchlistWithAdverts`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<WatchlistAdvertDTO[]>;
  }


  public loadWatchlistItems(contactId: string, loadFromDB: boolean = false): Promise<any> {

    this.watchlistLoaded = false;

    const watchlistKey = "watchlist-" + contactId;

    // get from local storage if available, otherwise load from db
    if (!loadFromDB) {
      const items = this.localStorageService.get(watchlistKey);

      if (items) {

        this.watchlistLoaded = true;
        this.watchlistItems = items;
        return new Promise(resolve => {
          resolve(items);
        });
      }
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}`;

    const promise = this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<WatchlistDTO[]>;

    promise.then(watchlist => {

      this.watchlistItems = watchlist;
      this.saveWatchlistToLocalStorage(contactId);
      this.watchlistLoaded = true;
    });

    return promise;
  }
}
