import {Injectable} from '@angular/core';
import {TimeLeftEnum} from "../enums";
import * as moment from "moment";

@Injectable(
  {providedIn: 'root'}
)
export class TimerService {

  public dateAdjustmentSeconds = 0;

  constructor() {
  }

  setDateAdjustment(adjustment) {

    console.log("SETTING DATE ADJUSTMENT ", adjustment);
    this.dateAdjustmentSeconds = adjustment;
  }

  getDateAdjustment() {

    return this.dateAdjustmentSeconds || 0;
  }

  updateServerTime(serverTime: any) {

    // console.log("SERVER TIME ", serverTime);
    // console.log("JAVASCRIPT TIME NOW", new Date().getTime());

    const adjustment = (serverTime - new Date().getTime());

    this.setDateAdjustment(adjustment);
  }


  public timeLeft(endDate: Date, source: string) {

    // console.log("Input date: ", endDate);

    const adjustment = this.getDateAdjustment();
    const browserTime = new Date().getTime();
    const adjustedBrowserTime = browserTime + adjustment;

    const ends = moment(endDate).toDate().getTime();

    // Server Time                   Advert  Browser   Server
    // David                         13:00   11:00     12:00    Adjustment 3600
    // Pete                          13:00   12:00     12:00    Adjustment 0

    const timeDifference = (ends - adjustedBrowserTime);
    const timerSeconds = Math.floor((timeDifference) / (1000) % 60);
    const timerMinutes = Math.floor((timeDifference) / (1000 * 60) % 60);
    const timerHours = Math.floor((timeDifference) / (1000 * 60 * 60) % 24);
    const timerDays = Math.floor((timeDifference) / (1000 * 60 * 60 * 24));
    const totalSeconds = Math.floor((timeDifference) / (1000));

    // console.log("Browser time: ", browserTime, " Adjusted Browser Time:",
    // adjustedBrowserTime, " Advert EndTime: ", ends, " Difference ", " EndDate:",
    // endDate, " Adjustment: ", adjustment, " SecondsLeft: ", totalSeconds, " Source: ", source);

    return [timerDays, timerHours, timerMinutes, timerSeconds, totalSeconds];
  }

  public convertToWords(endTime: string | Date, expiredString: string, significantWords: number): string | null {

    let end: Date;

    if (typeof endTime === "string") {
      end = new Date(endTime);
    } else {
      end = endTime;
    }

    const timeUnits = this.timeLeft(end, 'Convert To Words');
    const secondsLeft = Math.max(timeUnits[TimeLeftEnum.Seconds], 0);

    const timerLeftWords = this.timerWords(timeUnits, expiredString, significantWords);
    return timerLeftWords;
  }

  public timerWords(unitArray, expiredString: string, significantWords: number) {

    if (unitArray != null && unitArray[TimeLeftEnum.TotalSeconds] != null && unitArray[TimeLeftEnum.TotalSeconds] <= 0) {
      return expiredString;
    }

    let wordArray = [];

    if (unitArray[TimeLeftEnum.Days] > 0) {
      wordArray.push(unitArray[TimeLeftEnum.Days] + "d");
    }
    if (unitArray[TimeLeftEnum.Hours] > 0) {
      wordArray.push(unitArray[TimeLeftEnum.Hours] + "h");
    }
    if (unitArray[TimeLeftEnum.Minutes] > 0) {
      wordArray.push(unitArray[TimeLeftEnum.Minutes] + "m");
    }
    if (unitArray[TimeLeftEnum.Seconds] > 0) {
      wordArray.push(unitArray[TimeLeftEnum.Seconds] + "s");
    }

    return (wordArray.slice(0, significantWords).join(" ") || "--");
  }
}
