// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav .collapsible .card .card-header a h5 {
  font-size: 0.9rem !important;
}

.side-nav .collapsible .card-body li a {
  font-size: 0.8rem !important;
}

.side-nav .nav-line {
  border-top: 1px solid #4e4f53;
}

.selected {
  background-color: #93C4D5 !important;
}

.side-nav .collapsible .nav-item:hover {
  background-color: var(--sideNavHoverBackgroundColour);
  color: #fff !important;
}
.side-nav .collapsible .nav-item:hover .nav-item-link {
  color: #fff !important;
}

.menu-badge {
  padding-right: 14px;
}

.badge-danger {
  background-color: var(--errorColour);
  color: #fff;
  display: inline-block;
  padding: 0 0.4em;
  line-height: 18px;
  border-radius: 9px;
}`, "",{"version":3,"sources":["webpack://./projects/common/components/app-side-menu/app-side-menu.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EAEE,qDAAA;EACA,sBAAA;AAAF;AAEE;EACE,sBAAA;AAAJ;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,oCAAA;EACA,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADF","sourcesContent":[".side-nav .collapsible .card .card-header a h5 {\n  font-size: 0.9rem !important;\n}\n\n.side-nav .collapsible .card-body li a {\n  font-size: 0.8rem !important;\n}\n\n.side-nav .nav-line {\n  border-top: 1px solid #4e4f53;\n}\n\n.selected {\n  background-color: #93C4D5 !important;\n}\n\n.side-nav .collapsible .nav-item:hover {\n\n  background-color: var(--sideNavHoverBackgroundColour);\n  color: #fff !important;\n\n  .nav-item-link {\n    color: #fff !important;\n  }\n}\n\n.menu-badge {\n  padding-right: 14px;\n}\n\n.badge-danger {\n  background-color: var(--errorColour);\n  color: #fff;\n  display: inline-block;\n  padding: 0 0.4em;\n  line-height: 18px;\n  border-radius: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
