import {Injectable, ViewChild} from "@angular/core";
import {
  ContactSearchDTO,
  CustomerSearchDTO,
  CustomerSearchResultDTO,
  SearchResultDTO, WhosWhoAdminSearchDTO
} from "../../interfaces";
import {HttpParams} from "@angular/common/http";
import {BidDTO, CustomerDTO, CustomerOrderDTO, PlatformDTO} from "../../interfaces";
import {VehicleDTO} from "../../interfaces";
import {compare} from "fast-json-patch";
import {LoggerService} from "../logger.service";
import {AuthGuardServiceInterface} from "../interfaces";
import {DataServiceInterface} from "../interfaces";
import {ApiService} from "../api.service";
import {ModalDirective} from "ng-uikit-pro-standard";

@Injectable()
export class CustomerService {

  @ViewChild('statusModal') statusModal: ModalDirective;

  private serviceUrl = "/api/customer";

  constructor(
    private auth: AuthGuardServiceInterface,
    private data: DataServiceInterface,
    private apiClient: ApiService,
    private logService: LoggerService) {
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  search(customerSearchDTO?: CustomerSearchDTO): Promise<CustomerSearchResultDTO> {

    let queryParams = "";

    if (customerSearchDTO) {

      const dtoString = JSON.stringify(customerSearchDTO);

      this.logger.log(dtoString);
      queryParams = "?";
      queryParams += new HttpParams().set("query", dtoString).toString();
    }

    const url = `${this.data.apiUrl}${this.serviceUrl}s${queryParams}`;

    console.log("CUSTOMER URL ", url);
    return this.apiClient.get({url}) as Promise<CustomerSearchResultDTO>;
  }

  getContacts(customerId?: string, query?: ContactSearchDTO): Promise<SearchResultDTO> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/contacts`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  setPending(customerId?: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/setPending`;
    return this.apiClient.put({url, headers: {accept: 'application/json'}}) as Promise<any>;
  }

  getStatus(customerId?: string) {

    let url = `${this.data.apiUrl}${this.serviceUrl}/status`;

    if (customerId !== undefined) {

      url += "/" + customerId;
    }

    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<{ statusId: number }>;
  }

  getBidsReceived(customerId: string, withJoins?: string): Promise<BidDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/bidsReceived`;

    return this.apiClient.get({url}) as Promise<BidDTO[]>;
  }

  getVehicles(customerId: string, withJoins?: {}[]): Promise<VehicleDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/vehicles`;

    return this.apiClient.get({url}) as Promise<VehicleDTO[]>;
  }

  updateCustomer(customerDTO: any): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + customerDTO.id;
    return this.apiClient.put({url, data: customerDTO});
  }

  patchCustomer(customerId: string, dto: CustomerDTO, previousDTO: CustomerDTO = {}): Promise<any> {

    const patch = compare(previousDTO, dto);

    const url = `${this.data.apiUrl}${this.serviceUrl}/` + customerId;
    return this.apiClient.patch({url, data: patch});
  }

  createCustomer(customerDTO: any): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: customerDTO});
  }

  getPlatform(customerId: string): Promise<PlatformDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/platform`;
    return this.apiClient.get({url}) as Promise<PlatformDTO>;
  }


  getCustomerOrders(customerId: string): Promise<CustomerOrderDTO[]> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/customerOrders`;

    return this.apiClient.get({url}) as Promise<CustomerOrderDTO[]>;
  }

  // basic company details, name, email, VAT number etc.
  getDetails(customerId): Promise<CustomerDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}`;
    return this.apiClient.get({url}) as Promise<CustomerDTO>;
  }

  getAdvertisingTotals(searchDTO: WhosWhoAdminSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/advertising-totals`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: searchDTO}) as Promise<SearchResultDTO>;

  }

  customerHasAddress(customerId: string): Promise<boolean> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${customerId}/has-address`;
    return this.apiClient.get({url}) as Promise<boolean>;
  }
}
