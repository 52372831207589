import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';
import {compare} from "fast-json-patch";
import {ApiService} from "../api.service";
import {DataServiceInterface} from "../interfaces";
import {UInspectFormatSearchDTO} from "../../interfaces/DTOs/UInspect/API";
import {SearchResultDTO, ValidatedResultDTO} from "../../interfaces/DTOs/API";
import {UInspectFormatDTO} from "../../interfaces/DTOs/UInspect";

@Injectable()
export class UInspectFormatService {

  private serviceUrl = '/api/uinspect/format';

  constructor(
    private apiClient: ApiService, private data: DataServiceInterface,
    private toast: ToastService,
  ) {
    // PC this.logging.componentName = "UInspectFormatService";
  }

  public search(query: UInspectFormatSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}s`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query}) as Promise<SearchResultDTO>;
  }

  public get(id: number, query: UInspectFormatSearchDTO = {}): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.get({url}, {query}) as Promise<ValidatedResultDTO>;
  }

  public add(dto: UInspectFormatDTO): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.post({url, data: dto}) as Promise<ValidatedResultDTO>;
  }

  public patch(id: number, patchData: UInspectFormatDTO, previousValue: UInspectFormatDTO = {}):
    Promise<ValidatedResultDTO> {
    const data = compare(previousValue, patchData);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.patch({url, data}) as Promise<ValidatedResultDTO>;
  }

  public delete(id: number): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${id}`;
    return this.apiClient.delete({url}) as Promise<ValidatedResultDTO>;
  }

  public getDocumentCategories(): Promise<SearchResultDTO>  {
    var search = {component: 'lead-document-categories'};
    const url = `${this.data.apiUrl}/api/lead-document-categories`;

    return this.apiClient.get(
      {url, headers: {accept: 'application/json'}}, {query: search}) as Promise<SearchResultDTO>;
  }
}
