import {ContactDTO} from "../../contactDTO.interface";
import {BaseDTO} from "../../_baseDTO.interface";

export interface BrokerageDTO extends BaseDTO {
  advertId: string;
  // manager for the advert
  contactId?: string;
  contact?: ContactDTO;
  brokerageStateId: number;
}
