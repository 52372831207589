export interface TypedValidatedResultDTO<T> {
  isValid: boolean;
  message?: string;
  dto: T;
}

export interface ValidatedResultDTO {
  isValid: boolean;
  message?: string;
  dto: any;
}
