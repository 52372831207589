import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-privacy-policy-wrapper',
  templateUrl: './privacy-policy-wrapper.component.html',
  styleUrls: ['./privacy-policy-wrapper.component.css']
})
export class PrivacyPolicyWrapperComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }
}
