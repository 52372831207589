import {ContactDTO} from "./contactDTO.interface";
import {BaseIntDTO} from "./_baseIntDTO.interface";
import {AdvertSearchFieldEnum} from '../../enums/advert-enums/advertsearchfield.enum';
import {AdvertSearchDTO} from './API/advert-search/advert-search-dto.interface';

export interface SearchDTO extends BaseIntDTO {
  contactId?: string;
  contact?: ContactDTO;
  searchJSONValue?: string;
  description?: string; // this is a list of all the selected items and ranges for easy display
  adSearchDTO: AdvertSearchDTO;
  jsonDesc?: any; // for UI use
}

// when the searchJSONValue gets expanded it becomes this type
export interface SearchDataDTO {
  searchField: AdvertSearchFieldEnum;
  filterItems?: SearchItemDTO[];
  rangeMin?: number;
  rangeMax?: number;
  lookupString: string;
}

export interface SearchItemDTO {
  id: number;
  name: string;
}
