import {Component, OnInit} from '@angular/core';
import {IndustryNewsService} from "../../../../services";

@Component({
  selector: "app-industry-news",
  templateUrl: "./industry-news.html",
  styleUrls: ["./industry-news.scss"],
})
export class IndustryNewsComponent implements OnInit {

  public xmlItems: any[] = [];

  constructor(private industryNewsService: IndustryNewsService) {
  }

  async ngOnInit() {

    await this.industryNewsService.getNews().then((response) => {

      let counter = 0;

      response.forEach((item) => {

        item.counter = counter;

        this.xmlItems.push(item);

        counter++;
      });
    });
  }

  visitUrl(url) {
    window.open(url, "_blank");
  }

  imageLoaded(evt, item) {

    if (evt && evt.target) {

      const width = evt.target.naturalWidth;
      const height = evt.target.height;

      if (width < 200) {

        item.hidden = true;

      }
    }
  }
}
