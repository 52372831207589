import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {VehicleService} from '../../../../services';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {ActivatedRoute} from "@angular/router";
import {AdminUrlService} from "../../services";
import {VehicleActionHistoryDTO, VehicleBasicDTO, VehicleDTO} from "../../../../global/interfaces";
import {ImageService, HelpersService} from "../../../../global/services";
import {AdvertStatusEnum, SoldStatusEnum, StatusEnum} from "../../../../global/enums";

@Component({
  selector: 'app-vehicle-history',
  templateUrl: './vehicle-history.component.html',
  styleUrls: ['./vehicle-history.component.scss'],
  providers: []
})
export class VehicleHistoryComponent implements OnInit {

  @ViewChild('vehicleModal') vehicleModal: ModalDirective;

  statusOptions: any;
  statusName: {} = {};
  audit: VehicleActionHistoryDTO[];

  constructor(
    private fb: UntypedFormBuilder,
    private vehicleService: VehicleService,
    public imageService: ImageService,
    public adminUrl: AdminUrlService,
    public route: ActivatedRoute,
    public helperService: HelpersService,
    private toast: ToastService) {

    if (this.route?.snapshot?.params?.vehicleId) {
      this.loadActionHistory(this.route.snapshot.params.vehicleId);
    }
  }

  toastOpts = {opacity: 0.98};

  form: UntypedFormGroup;
  vehicles: VehicleBasicDTO[] = [];
  vehicle: VehicleDTO;


  isLoading = false;
  selectedVehicle: VehicleBasicDTO;
  advertStatusName: {} = {};
  soldStatusName: {} = {};
  saleName: any;
  selectedRowIndex?: number;

  ngOnInit(): void {
    this.form = this.fb.group({vrm: new UntypedFormControl('', Validators.required)});
    this.statusOptions = this.helperService.getLabelsAndValues(StatusEnum);
    this.statusOptions.forEach(x => {
      this.statusName[x.value] = x.label;
    });

    for (const value in Object.keys(SoldStatusEnum)) {
      this.soldStatusName[value] = SoldStatusEnum[value];
    }

    for (const value in Object.keys(AdvertStatusEnum)) {
      this.advertStatusName[value] = AdvertStatusEnum[value];
    }
  }

  loadActionHistory(vehicleId: string) {

    this.isLoading = true;

    return this.vehicleService.search({
      component: 'vehicleHistory',
      filters: {vehicleId: vehicleId}
    }).then((result: VehicleDTO[]) => {

      this.isLoading = false;

      if (result && result.length > 0) {

        this.vehicle = result[0];
        this.audit = this.vehicle.actionHistory;

        if (this.vehicle.adverts.length > 0) {
          this.vehicle.adverts = this.vehicle.adverts.sort((b, a) => (a.updated > b.updated) ? 1 : ((b.updated > a.updated) ? -1 : 1));
          this.vehicle.latestAdvert = this.vehicle.adverts[0];
        }
      } else {
        this.vehicle = null;
      }
    });
  }

  async searchVehicles() {

    if (this.form.invalid) {
      this.toast.error("Enter a full or partial VRM to search", "Error", this.toastOpts);
      return;
    }

    // return a list of vehicles that match the vrm input, if there is only one use that to get the vehicle
    this.isLoading = true;
    this.vehicles = await this.vehicleService.getMatchingVehicles_Basic(
      {component: "adminVehicleHistory", filters: {vrmMatches: this.form.value.vrm}}
    );
    this.isLoading = false;

    if (this.vehicles && this.vehicles.length == 1) {
      this.selectedVehicle = this.vehicles[0];
      this.loadActionHistory(this.vehicles[0].id);
    } else if (!this.vehicles || this.vehicles.length == 0) {
      this.toast.error("No vehicles match the provided VRM", "Error", this.toastOpts);
    } else {
      // show modal to select vehicle
      this.vehicleModal.show();
    }
  }

  selectVehicle(vehicle: VehicleBasicDTO) {
    this.selectedVehicle = vehicle;
    this.loadActionHistory(this.selectedVehicle.id);
    this.vehicleModal.hide();
    this.adminUrl.adminVehicleHistory(this.selectedVehicle.id);
  }

  filterAdvert(id?: string, i?: number) {

    this.selectedRowIndex = i;

    if (id != null) {
      this.audit = this.vehicle.actionHistory.filter(x => x.advertId == id);
    } else {
      this.audit = this.vehicle.actionHistory;
    }
  }
}
