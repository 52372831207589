import {Injectable} from '@angular/core';
import {ApiService} from "../global/services/index";
import {DataService} from "./data.service";

@Injectable()
export class StatusService {

  private serviceUrl = '/api/status';

  constructor(private apiClient: ApiService, private data: DataService) { }

  getAll(): Promise<any> {
    const url = `${this.data.apiUrl}/api/statuses`;
    return this.apiClient.get({url}) as Promise<any>;
  }

  delete(id): Promise<any> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/` + id;
    return this.apiClient.delete({url}) as Promise<any>;
  }
}
