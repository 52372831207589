import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from "../global/services";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root',
})
export class AIService {

  url = '/api/ai';

  constructor(private http: HttpClient, private apiClient: ApiService, private data: DataService) {
  }

  askQuestion(question: string) {
    const payload = {
      question: question
    }

    const url = `${this.data.apiUrl}${this.url}`;
    return this.apiClient.post({url, data: payload}) as Promise<any>;
  }
}
