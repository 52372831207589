// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attention-box {
  margin-bottom: 15px;
}
.attention-box label {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/admin/whos-who/whos-who.component.scss"],"names":[],"mappings":"AAAA;EAOE,mBAAA;AALF;AAAE;EACE,mBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".attention-box {\n\n  label {\n    font-size: 0.875rem;\n    margin-bottom: 0.125rem;\n  }\n\n  margin-bottom: 15px;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
