import {Component} from "@angular/core";
import {AdminExtLeadService} from "../services/admin-ext-lead.service";
import { AdminExtLeadDTO } from "../../../global/interfaces";

@Component({
  templateUrl: './admin-ext-lead.component.html',
  styleUrls: ['./admin-ext-lead.component.scss'],
})

export class AdminExtLeadComponent {
  uploading = false;
  uploaded = false;
  jsonText = '';
  response: AdminExtLeadDTO[] = [];

  constructor(private extLeadService: AdminExtLeadService) {

  }

  uploadFile() {

    this.uploading = true;
    this.uploaded = false;

    this.extLeadService.parseFile(this.jsonText)
      .then(res => {
        this.uploading = false;
        this.uploaded = true;
        this.response = res;

        // Extract headers dynamically from the first DTO
        if (this.response.length > 0) {
          // this.dtoHeaders = Object.keys(this.resultingDtos[0]);
        }
      })
      .catch(err => {
        this.uploading = false;
      });
  }
}
