import {BaseIntDTO} from "../_baseIntDTO.interface";
import {UInspectQuestionDTO} from "./uinspect-question-dto.interface";
import {UInspectQuestionOptionDTO} from "./uinspect-question-option-dto.interface";
import {UInspectDTO} from "./uinspect-dto.interface";

export interface UInspectAnswerDTO extends BaseIntDTO {
  sectionId?: number;
  uInspect?: UInspectDTO;
  uInspectId?: string;
  uInspectQuestionId?: string;
  uInspectQuestion?: UInspectQuestionDTO;
  answerOptionId?: string;
  answerOption?: UInspectQuestionOptionDTO;
  answer?: string;
  answerBool?: boolean;
  answerInt?: number;
  answerDate?: string;
  questionTest?: string; // Should this be text ?
  ipAddress?: string;
  longitude?: number;
  latitude?: number;
}

export interface UInspectAnswerSyncDTO extends UInspectAnswerDTO {
  synced?: boolean;
}
