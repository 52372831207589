export interface CustomerAdvertisingTotalsDTO {
  customerId: string;
  customerName: string;
  customerEmail: string;
  contactPhone: string;
  contactName: string;
  totalAdverts: number;
  liveAdverts: number;
  lastLoginDate?: Date;
  lastAdvertDate?: Date;
  isOpen: boolean; // for UI
}
