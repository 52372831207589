import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, HostListener, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {EventService, URLService} from "../../../services";
import {MessageDTO, User} from "../../../global/interfaces";
import {ApiService, ContactService, LoggerService, UserService} from "../../../global/services";
import {MessageTypeEnum, StatusEnum} from "../../../global/enums";
import {environment} from "../../../../environments/environment";

// import {PwaService} from '../../../services/PwaService.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app-topbar.component.html',
  styleUrls: ['./app-topbar.component.scss']
})
export class AppTopbarComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() hideSearchBar: boolean;

  public dropDownMenu = [];
  public navbarCollapsed = true;
  public vehicleSearchInput: string;
  public user: User;
  public isLoggedIn = false;
  public isLive = environment.production;

  messageEventSub: Subscription;
  folderCounts: any;

  logger = this.logService.taggedLogger(this.constructor?.name);

  constructor(@Inject(DOCUMENT)
              public document: Document,
              private eventService: EventService,
              private userService: UserService,
              public url: URLService,
              public router: Router,
              private logService: LoggerService,
              private apiClient: ApiService,
              //              public Pwa: PwaService,
              private contactService: ContactService
  ) {
    this.router = router;
  }

  async ngOnInit() {

    await this.userService.loadCurrentUser().then((x) => {

      if (x == null) {
        this.isLoggedIn = false;
        return;
      }

      this.user = this.userService.CurrentUser;
      this.isLoggedIn = this.user.isLoggedIn;

      if (this.user.contactId && this.user.customerId && this.user.statusId === StatusEnum.Active) {

        this.dropDownMenu = [
          {itemRoute: this.url.dashboard(true), itemLabel: 'Dashboard'},
          {itemRoute: this.url.stock(1, true), itemLabel: 'Stock'},
          {itemRoute: this.url.myInvoices(true), itemLabel: 'Invoices'},
          {itemRoute: this.url.settings(true), itemLabel: 'Settings'},
          {itemRoute: this.url.help(true), itemLabel: "Support", isURL: true},
        ];
        if (this.user.isAdmin) {
          this.dropDownMenu.push({itemRoute: this.url.admin(true), itemLabel: 'Admin'});
          this.dropDownMenu.push({itemRoute: this.url.settings_tab(1, true), itemLabel: 'Users'});
        }
      }
    }).catch(() => {

      this.logger.error("TOPBAR: CAUGHT: USER NOT LOGGED IN");

    });
  }

  async ngAfterViewInit() {

    this.messageEventSub = this.eventService.InMailEvent.subscribe((data: MessageDTO) => {

      this.logger.info("Received InMail event", data);

      if (data.messageType === MessageTypeEnum.InMailGetCounts) {
        const messageCounts = data.messageData;
        this.logger.info("** MESSAGE: ", messageCounts);


        this.folderCounts = messageCounts.folderCounts;
        this.logger.info("Message counts received", this.folderCounts);
      }
    });
  }

  onVehicleSearch() {
    const obj = {filters: {keywords: this.vehicleSearchInput}};
    this.router.navigate([this.url.search(true)], {queryParams: obj.filters});
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.messageEventSub) {
      this.messageEventSub.unsubscribe();
    }
  }

  logout() {

    this.user.isLoggedIn = false;
    this.contactService.notifyLogout(this.user.contactId).then(async () => {

      await this.userService.logout();

    }).catch(async () => {

      await this.userService.logout();

    });
  }

//  installPwa() {
//    this.Pwa.promptEvent.prompt();
//  }
}
