import {BaseSearchDTO} from "./baseSearchDTO.interface";

export interface VehicleSearchDTO extends BaseSearchDTO
{
  filters?: {
    vrm?: string;
    vrmMatches?: string;
    customerId?: string;
    statusId?: number;
  }
}
