import { BaseSearchDTO } from "../../../../../uinspect/src/app/global/interfaces";

export interface UInspectSectionSearchDTO extends BaseSearchDTO {
  filters?: {
    id?: number,
    doors?: number,
    uInspectFormatId?: number;
    fromDate?: Date;
    toDate?: Date;
  };
}
