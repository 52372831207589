import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AdminSimpleImportService} from "../../services/admin-simple-import.service";
import {ImportCreateAdvertDTO} from "../../interfaces/import-create-advert.dto.interface";

@Component({
  selector: 'app-simple-import',
  templateUrl: 'simple-importer.component.html',
  styleUrls: ['simple-importer.component.scss'],
})
export class SimpleImporterComponent implements OnInit {
  vendorName: string = ''; // Vendor name from the URL
  selectedFile: File | null = null;
  isLoading = false;
  isImporting = false;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  resultingDtos: ImportCreateAdvertDTO[] = [];
  dtoHeaders: string[] = []; // Headers derived from DTO properties

  constructor(
    private route: ActivatedRoute,
    private importService: AdminSimpleImportService
  ) {}

  ngOnInit(): void {
    // Read vendor name from the URL parameter
    this.route.paramMap.subscribe((params) => {
      this.vendorName = params.get('vendor') || 'Unknown Vendor';
    });
  }

  // Handle file selection
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.selectedFile = input.files[0];
      this.errorMessage = null; // Clear any previous error messages
      this.successMessage = null; // Clear success message
    }
  }

  // Submit the selected file
  onSubmit(): void {
    if (!this.selectedFile) {
      this.errorMessage = 'Please select a file.';
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;
    this.successMessage = null;

    // Send file and vendor to the service
    this.importService
      .processImport(this.selectedFile, this.vendorName)
      .then(res => {
        this.isLoading = false;
        this.successMessage = 'File processed successfully!';
        this.resultingDtos = res;

        // Extract headers dynamically from the first DTO
        if (this.resultingDtos.length > 0) {
          this.dtoHeaders = Object.keys(this.resultingDtos[0]);
        }
      })
      .catch(err => {
        this.isLoading = false;
        this.errorMessage =
          err?.error?.message || 'An error occurred during the import.';
      });
  }

  async importData() {
    this.isImporting = true;
    // iterate the advert data
    for (let ad of this.resultingDtos) {
      ad.importing = true;
      try {
        const res = await this.importService.importAdvert(this.vendorName, ad);
        ad.message = res.message;
        ad.advertId = res.advertId;
      } catch (error) {
        // Handle error and store error message in ad.message
        ad.message = error?.error?.toString() || 'An error occurred during the import.';

        if (typeof ad.message === 'string' && ad.message.includes("specified VRM")) {
          ad.message = "VRM is already part of a live listing";
        }

        // You might want to log the error for debugging
        console.error(`Error importing ad:`, error);
      } finally {
        await new Promise(resolve => setTimeout(resolve, 0)); // Force next event loop tick
        ad.importing = false;
      }
    }
    this.isImporting = false;
  }
}
