import {Injectable} from '@angular/core';
import {ExternalEventSearchDTO} from "../interfaces/DTOs/Valuations/external-event-search-dto.interface";
import {SearchResultDTO, ValidatedResultDTO} from "../interfaces/DTOs/API";
import {ApiService} from "./api.service";
import {DataServiceInterface} from "./interfaces/data.service.interface";

@Injectable()
export class ExternalEventQueueService {

  private serviceUrl = '/api/integration';

  constructor(private apiClient: ApiService, private data: DataServiceInterface) {
  }

  triggerEvent(service: string, eventType: string, eventId: string): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${service}/event/${eventType}/${eventId}`;
    return this.apiClient.put({url, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  processEvent(eventId: number): Promise<ValidatedResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/processEvent/${eventId}`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}) as Promise<ValidatedResultDTO>;
  }

  searchEvent(dto: ExternalEventSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/events`;
    return this.apiClient.get({url, headers: {accept: 'application/json'}}, {query: dto}) as Promise<SearchResultDTO>;
  }
}
