import {Injectable} from '@angular/core';
import {BrokerageDTO, BrokerageSearchDTO, TypedSearchResultDTO, TypedValidatedResultDTO} from '../../../global/interfaces';
import {ApiService} from '../../../global/services';
import {DataService} from "../../../services/index";

@Injectable()
export class AdminBrokerageService {
  private serviceUrl = '/api/brokerage';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  getAdvertBrokerage(advertId: string, query: BrokerageSearchDTO): Promise<TypedValidatedResultDTO<BrokerageDTO>> {
    const url = `${this.data.apiUrl}/api/listing/${advertId}/brokerage`;
    return this.apiClient.get({url}, {query}) as Promise<TypedValidatedResultDTO<BrokerageDTO>>;
  }

  create(dto: BrokerageDTO): Promise<TypedValidatedResultDTO<BrokerageDTO>> {
    const url = `${this.data.apiUrl}/api/brokerage`;
    return this.apiClient.post({url, data: dto}) as Promise<TypedValidatedResultDTO<BrokerageDTO>>;
  }

  get(brokerageId: string): Promise<TypedValidatedResultDTO<BrokerageDTO>> {
    const url = `${this.data.apiUrl}/${brokerageId}`;
    return this.apiClient.get({url}) as Promise<TypedValidatedResultDTO<BrokerageDTO>>;
  }

  search(query: BrokerageSearchDTO): Promise<TypedSearchResultDTO<BrokerageDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url}, {query}) as Promise<TypedSearchResultDTO<BrokerageDTO>>;
  }

  patchBrokerage(brokerageId: string, data: any): Promise<TypedValidatedResultDTO<BrokerageDTO>> {
    const url = `${this.data.apiUrl}${this.serviceUrl}/${brokerageId}`;
    return this.apiClient.patch({url, data}) as Promise<TypedValidatedResultDTO<BrokerageDTO>>;
  }
}
