import {BidStatusEnum, BidViewTypeEnum} from "../../../enums/index";
import {BaseSearchDTO} from "./baseSearchDTO.interface";

export interface BidSearchDTO extends BaseSearchDTO {

  filters?: {
    advertId?: string;
    bidStatuses?: BidStatusEnum[];
    sent?: boolean;
    bidViewType?: BidViewTypeEnum;
  };
}
