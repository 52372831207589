import {Injectable} from "@angular/core";
import {CommsHistoryDTO, CommsHistorySearchDTO, CommsTemplateSearchDTO, SearchResultDTO} from "../global/interfaces";
import {ApiService} from "../global/services";
import {DataService} from "./data.service";

@Injectable({
  providedIn: 'root',
})
export class CommsHistoryService {

  url = '/api/commsHistory';
  entityTitle = 'Comms History';

  constructor(private apiClient: ApiService, private data: DataService) {
  }

  getCommsHistory(dto: CommsHistorySearchDTO): Promise<SearchResultDTO> {

    const newUrl = this.data.apiUrl + this.url;

    return this.apiClient.get({url: newUrl}, {query: dto});
  }
}
