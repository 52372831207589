import {CustomerDTO} from './customerDTO.interface';
import {BaseDTO} from './_baseDTO.interface';
import {CustomerOrderDTO} from './customer-orderDTO.interface';

export interface BillDTO extends BaseDTO {
  customerId: string;
  customer: CustomerDTO;
  totalNetAmt: number;
  totalTaxAmt: number;
  totalGrossAmt: number;
  invoiceReference: string;
  invoiceNumber: string;
  paymentReference: string;
  issuedDate: Date;
  paidDate?: Date;
  invoiceURL: string;
  customerOrders: CustomerOrderDTO[];
  overdue?: string;
  paymentMethodId?: string;
  paymentCustomerId?: string;
}
