import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {DataService, URLService} from "../../../services";
import {ApiService} from "../../../global/services";
import {compare} from "fast-json-patch";
import {
  AdminTaskDTO,
  AdminTaskSearchDTO,
  CustomerDTO,
  CustomerNoteDTO,
  CustomerNoteSearchDTO,
  NewCustomerNoteDTO, SearchResultDTO
} from "../../../global/interfaces";
import {StatusEnum} from '../../../global/enums';

@Injectable()
export class AdminTaskService {

  private serviceUrl = '/api/admin-task';

  constructor(
    public urlService: URLService,
    private apiClient: ApiService,
    private data: DataService,
  ) {
  }

  patch(adminTaskId: string, dto: AdminTaskDTO, previousDTO: AdminTaskDTO = {}) {

    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${adminTaskId}`;
    return this.apiClient.patch({url, data: patch});
  }

  delete(adminTaskId: string) {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${adminTaskId}`;
    return this.apiClient.delete({url});
  }

  clearTask(adminTaskId) {

    return this.patch(adminTaskId, {sleepUntil: null, statusId: StatusEnum.Deleted});

  }

  search(query: AdminTaskSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}${this.serviceUrl}`;
    return this.apiClient.get({url}, {query}) as Promise<SearchResultDTO>;
  }
}
