// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-centre {
  height: 100%;
  width: calc(100% - 10px);
  display: table;
  vertical-align: middle;
  position: absolute;
  top: 0;
}
.spinner-centre .spinner-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./projects/common/components/loading-spinner/loading-spinner.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,wBAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,MAAA;AAAF;AAEE;EAEE,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;AADJ","sourcesContent":[".spinner-centre {\n\n  height: 100%;\n  width: calc(100% - 10px);\n  display: table;\n  vertical-align: middle;\n  position: absolute;\n  top: 0;\n\n  .spinner-inner {\n\n    display: table-cell;\n    vertical-align: middle;\n    text-align: center;\n    width: 100%;\n\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
