import {EmailDTO} from "./emailDTO.interface";
import {MediaTypeDTO} from "./media-typeDTO.interface";
import {MediumDTO} from "./mediumDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";

export interface EmailMediumDTO extends BaseDTO {
  mediaId: string;
  mediaTypeId: number;
  emailId: string;
  email: EmailDTO;
  media: MediumDTO;
  mediaType: MediaTypeDTO;
}
