import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ContactsWithRoleSearchDTO, SearchResultDTO} from "../../../interfaces/DTOs/API/index";
import {ApiService} from "../../api.service";
import {DataServiceInterface} from "../../interfaces/index";
import {ContactInternalInfoDTO} from "../../../interfaces";
import {compare} from "fast-json-patch";

@Injectable()
export class AdminContactService {

  private customerServiceUrl = '/api/Customer';

  constructor(
    private router: Router,
    private apiClient: ApiService,
    private data: DataServiceInterface
  ) {
  }

  private serviceUrl = '/api/contact';

  impersonateUser(email): Promise<any> {

    const url = this.serviceUrl + '/impersonate/' + encodeURIComponent(email);

    return this.apiClient.get({url: `${this.data.apiUrl}${url}`}) as Promise<any>;
  }

  getContactsWithRole(role: string, searchDTO?: ContactsWithRoleSearchDTO): Promise<SearchResultDTO> {
    const url = `${this.data.apiUrl}/api/contacts/with-role/${role}`;

    return this.apiClient.get({ url }, { query: searchDTO }) as Promise<SearchResultDTO>;
  }

  suspendContact(contactId: string, customerId: string): Promise<any> {
    const url = `${this.data.apiUrl}${this.customerServiceUrl}/${customerId}/contact/${contactId}/suspend`;
    return this.apiClient.put({url}) as Promise<any>;
  }

  deleteUnverifiedContact(contactId: string): Promise<any> {

    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/unverified`;
    return this.apiClient.delete({url}) as Promise<any>;
  }

  patchContactInternalInfo(contactId: string, dto: ContactInternalInfoDTO, previousDTO: ContactInternalInfoDTO = {}): Promise<any> {

    const patch = compare(previousDTO, dto);
    const url = `${this.data.apiUrl}${this.serviceUrl}/${contactId}/internalInfo`;
    return this.apiClient.patch({url, data: patch}) as Promise<any>;
  }
}
