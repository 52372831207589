import {MessageAreaEnum} from '../../../enums/message-area.enum';
import {MessageTypeEnum} from '../../../enums/message-type.enum';
import {MessageMethodEnum} from "../../../enums/message-method.enum";

export interface IncomingMessageDTO {
  messageMethod?: MessageMethodEnum;
  messageArea?: MessageAreaEnum;
  messageType?: MessageTypeEnum;
  messageData?: object; // Somehow message data is already deserialised to an object ??
}
