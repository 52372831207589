// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumb-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.thumb-link-container {
  cursor: pointer;
  position: relative;
  margin-right: 12px;
}

.thumb-link-image {
  width: 105px;
  height: calc(105px * var(--aspectRatio));
  display: inline-block;
  border-radius: 3px;
  outline: 1px solid var(--imageOutlineColour);
  background-position: center center;
  background-repeat: no-repeat;
}
.thumb-link-image.image-cover {
  background-size: var(--vehicleMediaCoverOrContain);
}
.thumb-link-image.image-contain {
  background-size: contain;
}

.thumb-link-container-item {
  padding-bottom: 5px;
}

.thumb-link-container img {
  display: block;
  outline: 1px solid var(--softInputBorderColour);
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  color: rgba(51, 51, 51, 0.9);
  background-color: rgba(244, 244, 244, 0.8);
  width: 20px;
  border-top-right-radius: 3px;
  text-align: center;
  border-bottom-left-radius: 3px;
  color: #888;
}

.thumb-link {
  width: 100%;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/common/media-thumbnail-viewer/media-thumbnail-viewer.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EAEE,YAAA;EACA,wCAAA;EACA,qBAAA;EACA,kBAAA;EACA,4CAAA;EACA,kCAAA;EACA,4BAAA;AAAF;AAEE;EACE,kDAAA;AAAJ;AAGE;EACE,wBAAA;AADJ;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,cAAA;EACA,+CAAA;AAFF;;AAKA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;EACA,4BAAA;EACA,0CAAA;EACA,WAAA;EACA,4BAAA;EACA,kBAAA;EACA,8BAAA;EACA,WAAA;AAFF;;AAQA;EACE,WAAA;EACA,kBAAA;AALF","sourcesContent":[".thumb-container {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 10px;\n}\n\n.thumb-link-container {\n  cursor: pointer;\n  position: relative;\n  margin-right: 12px;\n}\n\n.thumb-link-image {\n\n  width: 105px;\n  height: calc(105px * var(--aspectRatio));\n  display: inline-block;\n  border-radius: 3px;\n  outline: 1px solid var(--imageOutlineColour);\n  background-position: center center;\n  background-repeat: no-repeat;\n\n  &.image-cover {\n    background-size: var(--vehicleMediaCoverOrContain);\n  }\n\n  &.image-contain {\n    background-size: contain;\n  }\n}\n\n.thumb-link-container-item {\n  padding-bottom: 5px;\n}\n\n.thumb-link-container img {\n  display: block;\n  outline: 1px solid var(--softInputBorderColour);\n}\n\n.delete-icon {\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 1;\n  color: rgba(51, 51, 51, 0.9);\n  background-color: rgba(244, 244, 244, 0.8);\n  width: 20px;\n  border-top-right-radius: 3px;\n  text-align: center;\n  border-bottom-left-radius: 3px;\n  color: #888;\n}\n\n.thumb-link-icon {\n}\n\n.thumb-link {\n  width: 100%;\n  border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
