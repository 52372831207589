import {BidDTO} from "./bidDTO.interface";
import {AdvertDTO} from "./advertDTO.interface";
import {BaseDTO} from "./_baseDTO.interface";

export interface TopBidDTO extends BaseDTO {
  advertId: string;
  bidId: string;
  advert: AdvertDTO;
  bid: BidDTO;
}
