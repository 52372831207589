import {DealDTO} from './dealDTO.interface';
import {ContactDTO} from './contactDTO.interface';
import {OrderLineDTO} from './order-lineDTO.interface';
import {CustomerOrderTypeEnum} from '../../enums/customer-order-type.enum';
import {CustomerDTO} from './customerDTO.interface';
import {BaseDTO} from './_baseDTO.interface';
import {BillDTO} from './billDTO.interface';

export interface CustomerOrderDTO extends BaseDTO {
  taxDate?: Date;
  dealId?: string;
  deal: DealDTO;
  customerId: string;
  customer: CustomerDTO;
  contactId: string;
  contact: ContactDTO;
  totalNetAmt: number;
  totalTaxAmt: number;
  totalGrossAmt: number;
  paidAmt: number;
  invoiceReference: string;
  orderLines: OrderLineDTO[];
  issuedDate: Date;
  paidDate?: Date;
  orderType: CustomerOrderTypeEnum;
  imageURL: string;
  description: string;
  vrm: string;
  orderTypeDesc: string;
  invoiceURL: string;
  invoiceNumber: string;
  bill?: BillDTO;
}

