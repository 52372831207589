/* tslint:disable:no-trailing-whitespace */
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {AdvertSearchService, AdvertService, EventService, URLService, WatchlistService} from '../../../../services';
import {CurrencyPipe, DatePipe, DecimalPipe, Location} from '@angular/common';
import {SearchService} from '../../../../services';
import {ActivatedRoute, Router} from '@angular/router';
import {IOption, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {deepClone} from 'fast-json-patch';
import {SearchCountResult} from "../../../../services/models/searchCountResult";
import {FilterListItem} from "../../../../services/models/filterListitem";
import {
  AdvertSearchCount,
  AdvertSearchFiltersDTO,
  SearchDTO,
  SphAdvertDTO_Public,
  User
} from "../../../../global/interfaces";
import {LoggerService, UserService} from "../../../../global/services";
import {
  AdvertFilterValueTypeEnum,
  AdvertSearchFieldEnum,
  SaleTypeFilter,
  SearchFilterTypeEnum, SortingFilter,
  SortingType
} from "../../../../global/enums";

@Component({
  selector: 'app-advert-search',
  templateUrl: './advert-search.component.html',
  styleUrls: ['./advert-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CurrencyPipe, DatePipe, DecimalPipe]
})
export class AdvertSearchComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav', {static: true}) public sideNav: any;
  @ViewChild('flyOut') flyOut: ElementRef;
  @ViewChild('navLinks') navLinks: ElementRef;
  public selectedFilterTop = '102px';

  constructor(
    private eventService: EventService,
    private advertService: AdvertService,
    private userService: UserService,
    private renderer2: Renderer2,
    public watchlistService: WatchlistService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private logService: LoggerService,
    private location: Location,
    public advertSearchService: AdvertSearchService,
    private router: Router,
    private url: URLService
  ) {
    this.user = this.userService.CurrentUser;
  }

  logger = this.logService.taggedLogger(this.constructor?.name);

  saleTypeSelected = '0';

  @ViewChild('conditionItemModal') mdbModal: ModalDirective;
  public showSavedSearchDialog: boolean;

  public keywordSearch: string;

  searchSubscription: Subscription;
  public searching: boolean;
  public filterValues: SearchCountResult = null;
  public resultsCount: number;
  public user: User;
  public filterCustomerId = null;

  // the currently selected filter item to show options for
  public selectedFilter: FilterListItem;

  // use when grouping a filter list by parentName
  private lastParentName: string;
  public showGroupFooter: boolean;

  // all advert results
  public sphAdverts: SphAdvertDTO_Public[];

  public isLoading = true;


  // stringified json of search params
  currentSearchData: string;
  currentSearchDTO: SearchDTO;

  adResultsSubscription: Subscription;
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes: IOption[] = [
    {label: '10', value: '10', icon: ''},
    {label: '20', value: '20', icon: ''},
    {label: '30', value: '30', icon: ''}
  ];

  selectedMakes: Array<number>;
  selectedModels: Array<number>;

  public searchFilterType = SearchFilterTypeEnum;
  public advertSearchField = AdvertSearchFieldEnum;

  searchOptions: { saleFilter: SaleTypeFilter, sorting: SortingType, sortingFilter: SortingFilter };

  pageSize: string = '10';
  filterCustomerName: string;

  back(): void {
    this.location.back();
  }


  public filterList: FilterListItem[] = [
    {
      filterName: 'Vehicle Type', searchField: AdvertSearchFieldEnum.VehicleType, staticList: true,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Make', searchField: AdvertSearchFieldEnum.Make, staticList: true,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Model', searchField: AdvertSearchFieldEnum.Model,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: false
    },
    {
      filterName: 'Derivative', searchField: AdvertSearchFieldEnum.Deriv,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: false
    },
    {
      filterName: 'Fuel Type', searchField: AdvertSearchFieldEnum.Fuel,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Transmission', searchField: AdvertSearchFieldEnum.Transmission,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Body Type', searchField: AdvertSearchFieldEnum.BodyType,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Mileage', searchField: AdvertSearchFieldEnum.Mileage,
      filterType: SearchFilterTypeEnum.Range, items: [], selectedItems: Array<AdvertSearchCount>()
      , valueType: AdvertFilterValueTypeEnum.Decimal
      , isLookup: true, enabled: true
    },
    {
      filterName: 'Plate', searchField: AdvertSearchFieldEnum.Plate,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Age', searchField: AdvertSearchFieldEnum.Age,
      filterType: SearchFilterTypeEnum.Range, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Engine Capacity', searchField: AdvertSearchFieldEnum.Capacity,
      filterType: SearchFilterTypeEnum.Range, items: [], selectedItems: Array<AdvertSearchCount>(),
      isLookup: true, enabled: true
    },
    {
      filterName: 'Doors', searchField: AdvertSearchFieldEnum.Doors,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'VAT Status', searchField: AdvertSearchFieldEnum.VATStatus,
      filterType: SearchFilterTypeEnum.Checkbox, items: [], selectedItems: Array<AdvertSearchCount>(), enabled: true
    },
    {
      filterName: 'Guide Price',
      searchField: AdvertSearchFieldEnum.Price,
      filterType: SearchFilterTypeEnum.Range,
      items: [],
      selectedItems: Array<AdvertSearchCount>(),
      valueType: AdvertFilterValueTypeEnum.Currency,
      isLookup: true, enabled: true
    },
    {
      filterName: 'Colour',
      searchField: AdvertSearchFieldEnum.Colour,
      filterType: SearchFilterTypeEnum.Checkbox,
      items: [],
      selectedItems: Array<AdvertSearchCount>(), enabled: true
    }
  ];


  @HostListener('swiperight', ['$event'])
  public swipePrev(event: any) {
    this.sideNav.show();
  }


  async ngOnInit() {

    this.logger.debug('On init');

    this.searchOptions = {
      saleFilter: SaleTypeFilter.All,
      sorting: SortingType.EndingSoonest,
      sortingFilter: SortingFilter.AnyTime
    };

    await this.userService.loadCurrentUser().then(() => {
      this.user = this.userService.CurrentUser;
      this.logger.debug('Loaded user', this.user);
    });

    // await this.advertSearchService.loadRangeLookups();

    // if we have a saved query, load it into the URL now
    // if (AdvertSearchService.CurrentSearchQuery) {
    //   this.navigateURL(AdvertSearchService.CurrentSearchQuery); // triggers activated route sub below
    // }

    this.route.queryParams.subscribe(params => {

      this.logger.debug("ROUTE QUERY", params);

      // this.keywordSearch = params['keywords'];
      // this.filterCustomerId = params['customerId'];
      // this.filterCustomerName = params['customerName'];

      this.isLoading = true;
      this.filterList.forEach(x => {
        x.selectedItems = [];
      });

      // load Make search as if it had been clicked by user
      this.fetchCounts(null, this.filterList.find(x => x.searchField == AdvertSearchFieldEnum.Make));

      // load watchlist items in the service
      this.watchlistService.loadWatchlistItems(this.user.contactId).then((result) => {
        this.logger.info('Loaded watchlist items into advert search');
      });

      // const search = this.searchService.loadSearchFromSession();
      //
      // if (search) {
      //   // reconstruct the filter list from the search dto
      //   this.createFilterListFromSearchData(search);
      // } else {
      // }

      let sq = params;

      if (sq) {
        let decoded = sq; // decodeURIComponent(sq);
        let searchQuery = {filters: sq}
        // let searchQuery = JSON.parse(decoded);

        this.keywordSearch = searchQuery.filters.keywords;

        // if (this.keywordSearch) {
        //   this.clearAllFilters();
        // }

        // this.logger.debug("SEARCH QUERY: ", searchQuery);

        // construct search DTO from query
        this.constructSearchDTOFromQuery(searchQuery);

        // this.createFilterListFromSearchData(searchQuery);

        this.viewResults(false);
      }
    });

  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.adResultsSubscription) {
      this.adResultsSubscription.unsubscribe();
    }
  }

  constructSearchDTOFromQuery(searchQuery) {
    let data = searchQuery.filters;

    this.constructItems(AdvertSearchFieldEnum.BodyType, data.bodyTypeIds);
    this.constructItems(AdvertSearchFieldEnum.Capacity, data.capacityIds);
    this.constructItems(AdvertSearchFieldEnum.Colour, data.colourIds);
    this.constructItems(AdvertSearchFieldEnum.Deriv, data.derivativeIds);
    this.constructItems(AdvertSearchFieldEnum.Doors, data.doors);
    this.constructItems(AdvertSearchFieldEnum.Fuel, data.fuelTypeIds);
    this.constructItems(AdvertSearchFieldEnum.Make, data.makeIds);
    this.constructItems(AdvertSearchFieldEnum.Mileage, data.mileageIds);
    this.constructItems(AdvertSearchFieldEnum.Model, data.modelIds);
    this.constructItems(AdvertSearchFieldEnum.Plate, data.plateIds);
    this.constructItems(AdvertSearchFieldEnum.Price, data.priceIds);
    this.constructItems(AdvertSearchFieldEnum.Transmission, data.transmissionTypeIds);
    this.constructItems(AdvertSearchFieldEnum.VATStatus, data.vatStatusIds);
    this.constructItems(AdvertSearchFieldEnum.VehicleType, data.vehicleTypeIds);

    this.logger.debug("Reconstructed from search: ", this.filterList);
  }

  constructItems(searchField: AdvertSearchFieldEnum, idData) {
    if (!idData || idData.length == 0) {
      return;
    }

    idData = Array.isArray(idData) ? idData : [idData];
    const item = this.filterList.find(fl => fl.searchField == searchField);

    // clone the items in the filter list
    item.selectedItems = deepClone(item.items);
    idData.forEach(x => {
      let thisItem = item.selectedItems.filter(y => y.entityId == x);
      if (!thisItem || thisItem.length == 0) {
        // todo: log this as error
        this.logger.error("Couldn't find corresponding entity from query, field: ", AdvertSearchFieldEnum[item.searchField], " - entityId: ", x);
        return;
      }

      if (item.filterType === SearchFilterTypeEnum.Checkbox) {
        thisItem[0].checked = true;
      }
    });
  }

  updateFlyOut() {

    const flyOutHeight = this.navLinks.nativeElement.parentElement.offsetTop;
    this.selectedFilterTop = (flyOutHeight + 1) + 'px';

  }

  // ** Start of search functions

  createFilterListFromSearchData(searchData) {
    this.logger.debug('SEARCH DATA: ', searchData);

    // populate selected items and ranges from searchData
    searchData.forEach(x => {
      const item = this.filterList.find(fl => fl.searchField == x.searchField);

      if (item) {
        // update the item
        if (item.filterType === SearchFilterTypeEnum.Range) {
          item.min = x.rangeMin;
          item.max = x.rangeMax;
        } else {
          item.selectedItems = x.selectedItems.length > 0 ? x.selectedItems : item.selectedItems;
        }
      } else {
      }
    });

  }

  getSearchDataFromFilterList(): string {
    let selectedMap = this.filterList.flatMap(fi => {
      return {
        searchField: fi.searchField,
        filterType: fi.filterType,
        rangeMin: fi.min,
        rangeMax: fi.max,
        rangeValueString: this.advertSearchService.getRangeValuesString(fi.searchField, fi.min, fi.max),
        selectedItems: fi.selectedItems.filter(item => item.checked),
        lookupString: ''
      };
    }).filter(x => x.selectedItems.length > 0 || x.rangeMin || x.rangeMax);

    // if we have a keyword search, append it to the search data
    if (this.keywordSearch && this.keywordSearch.trim() != '') {
      let searchData = {
        searchField: -1,
        filterType: SearchFilterTypeEnum.Keyword,
        rangeMin: 0,
        rangeMax: 0,
        rangeValueString: '',
        selectedItems: [],
        lookupString: this.keywordSearch
      };

      selectedMap.push(searchData);
    }

    let json = JSON.stringify(selectedMap);
    return json;
  }

  getSearchFiltersDTO(): AdvertSearchFiltersDTO {
    const age = this.getMinMax(AdvertSearchFieldEnum.Age);
    let vehicleTypeIds = this.getSelectedIds(AdvertSearchFieldEnum.VehicleType);
    let makeIds = this.getSelectedIds(AdvertSearchFieldEnum.Make);
    let modelIds = this.getSelectedIds(AdvertSearchFieldEnum.Model);
    let customerId = this.filterCustomerId;

    this.selectedMakes = [];
    this.selectedModels = [];
    this.selectedMakes.push(...makeIds);
    this.selectedModels.push(...modelIds);

    this.logger.info("Selected Makes: ", this.selectedMakes);

    // if we have no models selected, disable the deriv filter
    let model = this.filterList.find(x => x.searchField == AdvertSearchFieldEnum.Model);
    model.enabled = this.selectedMakes && this.selectedMakes.length > 0;

    // if we have no makes selected, disable the model and deriv filters
    let deriv = this.filterList.find(x => x.searchField == AdvertSearchFieldEnum.Deriv);
    deriv.enabled = model.enabled && this.selectedModels && this.selectedModels.length > 0;

    let filterDTO = {
      filters: {
        keywords: this.keywordSearch,
        vehicleTypeIds,
        makeIds,
        modelIds,
        customerId,
        derivativeIds: this.getSelectedIds(AdvertSearchFieldEnum.Deriv),
        fuelTypeIds: this.getSelectedIds(AdvertSearchFieldEnum.Fuel),
        transmissionTypeIds: this.getSelectedIds(AdvertSearchFieldEnum.Transmission),
        bodyTypeIds: this.getSelectedIds(AdvertSearchFieldEnum.BodyType),
        plateIds: this.getSelectedIds(AdvertSearchFieldEnum.Plate),
        minAge: age.min,
        maxAge: age.max,
        doors: this.getSelectedIds(AdvertSearchFieldEnum.Doors),
        vatStatusIds: this.getSelectedIds(AdvertSearchFieldEnum.VATStatus),
        mileageIds: this.getSelectedRangeIds(AdvertSearchFieldEnum.Mileage),
        capacityIds: this.getSelectedRangeIds(AdvertSearchFieldEnum.Capacity),
        priceIds: this.getSelectedRangeIds(AdvertSearchFieldEnum.Price),
        colourIds: this.getSelectedIds(AdvertSearchFieldEnum.Colour),
        saleTypeId: this.searchOptions.saleFilter,
        searchSortingEnum: this.searchOptions.sorting,
        sortingFilterEnum: this.searchOptions.sortingFilter,
        currentSearchField: this.selectedFilter ? this.selectedFilter.searchField : AdvertSearchFieldEnum.Unset
      }
    };

    // mutate existing dto and remove null properties
    Object.keys(filterDTO.filters).forEach((k) => filterDTO.filters[k] == null && delete filterDTO.filters[k]);

    // remove null from rangeId properties
    filterDTO.filters.mileageIds = filterDTO.filters.mileageIds.filter(x => x);
    filterDTO.filters.capacityIds = filterDTO.filters.capacityIds.filter(x => x);
    filterDTO.filters.priceIds = filterDTO.filters.priceIds.filter(x => x);

    this.logger.info("Filter DTO: ", filterDTO);

    return filterDTO;
  }

  getMinMax(searchField: AdvertSearchFieldEnum): { min: number, max: number } {
    const fl = this.filterList.find(x => x.searchField === searchField);
    return {min: fl.min, max: fl.max};
  }

  getSelectedIds(searchField: AdvertSearchFieldEnum): any[] {
    const fl = this.filterList.find(x => x.searchField === searchField);
    return fl.selectedItems ? fl.selectedItems.filter(x => x.checked).map(x => x.entityId) : [];
  }

  getSelectedRangeIds(searchField: AdvertSearchFieldEnum): any[] {
    // add the dropdown ids as first and last item in the list
    const fl = this.filterList.find(x => x.searchField === searchField);
    return [fl.min || 0, fl.max || null];
  }

  saveSearch() {
    // save the current search to the db as an unnamed search for information purposes
    const search = this.getSearchDTO();

    this.searchService.saveUnnamedSearch(search).then(() => {
      this.logger.info('Saved unnamed search to db');
    }).catch((error) => {
      this.logger.error('Error saving search: ', error.message);
    });
  }

  saveSearchToSession() {
    this.currentSearchData = this.getSearchDataFromFilterList();
    this.currentSearchDTO = this.getSearchDTO();
    this.searchService.saveSearchToSession(this.currentSearchData);
  }

  getSearchDTO(): SearchDTO {
    let dto = this.getSearchFiltersDTO();
    this.logger.info('Getting search dto: ', dto);

    return {
      contactId: this.user.contactId,
      searchJSONValue: this.getSearchDataFromFilterList(), // JSON.stringify(selectedMap) // this.getSearchDataFromFilterList()
      adSearchDTO: dto
    };
  }

  // ** End of search functions

  showGroupHeader(item: AdvertSearchCount): boolean {
    if (item.groupName && item.groupName !== this.lastParentName) {
      this.lastParentName = item.groupName;
      this.showGroupFooter = true;
      return true;
    } else {
      this.showGroupFooter = false;
      return false;
    }
  }

  async fetchCounts(event, filterItem: FilterListItem) {

    this.selectedFilter = filterItem;

    if (event != null) {
      this.updateFlyOut();
    }

    await this.getSearchCounts(filterItem);
  }

  noSearchDefined(): boolean {
    const search = this.getSearchDataFromFilterList(); // this.getCurrentSearchString();

    return (!search || search.trim() === '' || search.length === 0);
  }

  getResultCount(filterItem: FilterListItem) {

    this.logger.debug('Getting search result count', filterItem);

    // save current search to session storage
    //this.saveSearchToSession();

    // get only the resulting count of records
    const dto = this.getSearchFiltersDTO();
    this.advertSearchService.getResultsCount(dto).then(result => {
      this.resultsCount = result;
    });
  }

  getSearchCounts(filterItem: FilterListItem) {

    const dto = this.getSearchFiltersDTO();

    this.advertSearchService.getSearchCounts(dto).then(result => {

      filterItem.items = result;

      // do nothing for lookups, they are populated using resetRangeDropdown
      if (!filterItem.isLookup) {
        // push all values with defaults to selection array if they don't exist
        filterItem.items.forEach(lItem => {
          const selectedItem = filterItem.selectedItems.find(x => x.entityId === lItem.entityId);

          if (!selectedItem) {
            if (filterItem.filterType === SearchFilterTypeEnum.Checkbox) {
              filterItem.selectedItems.push({...lItem, checked: false});
            } else if (filterItem.filterType === SearchFilterTypeEnum.Range) {
              filterItem.selectedItems.push({...lItem});
            }
          } else {
            // update the selected item count
            selectedItem.count = lItem.count;
          }
        });
      }

      // remove items from selectedItems if not in items
      if (!filterItem.staticList && filterItem.filterType !== SearchFilterTypeEnum.Range) {
        filterItem.selectedItems = filterItem.selectedItems.filter(x => filterItem.items.findIndex(y => y.entityId === x.entityId) >= 0);
      }

      filterItem.selectedItems.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      this.resetRangeDropdown(filterItem);

      this.isLoading = false;
    });
  }

  populateLookupDropdown(filterItem: FilterListItem) {
    let filtered = this.getRangeLookup(filterItem.searchField);

    let filteredMin = deepClone(filtered);
    let filteredMax = deepClone(filtered);

    // filtered = filtered.filter(x => filterItem.items.map(y => y.entityId).includes(x.id));

    // filter out prices below current min
    if (filterItem.min) {
      const minCost = filteredMax.filter(y => y.id == filterItem.min);
      if (minCost && minCost.length > 0) {
        filteredMax = filteredMax.filter(x => x.max > minCost[0].min);
      }
    }

    // filter out values above current max
    if (filterItem.max) {
      const maxCost = filteredMin.filter(y => y.id == filterItem.max);
      if (maxCost && maxCost.length > 0) {
        filteredMin = filteredMin.filter(x => x.min < maxCost[0].max);
      }
    }

    switch (filterItem.searchField) {
      case AdvertSearchFieldEnum.Price:
        filterItem.dropdownItemsMin = filteredMin.map(x => {
            const count = this.getDropdownItemCount(filterItem, x);
            return {label: this.advertSearchService.transformPrice(x.min) + ' (' + count + ')', value: x.id};
          }
        );

        filterItem.dropdownItemsMax = filteredMax.map(x => {
            const count = this.getDropdownItemCount(filterItem, x);
            return {label: this.advertSearchService.transformPrice(x.max) + ' (' + count + ')', value: x.id};
          }
        );
        break;
      case AdvertSearchFieldEnum.Mileage:
        filterItem.dropdownItemsMin = filteredMin.map(x => {
            const count = this.getDropdownItemCount(filterItem, x);
            return {label: this.advertSearchService.transformMileage(x.min) + ' (' + count + ')', value: x.id};
          }
        );

        filterItem.dropdownItemsMax = filteredMax.map(x => {
            const count = this.getDropdownItemCount(filterItem, x);
            return {label: this.advertSearchService.transformMileage(x.max) + ' (' + count + ')', value: x.id};
          }
        );
        break;
      case AdvertSearchFieldEnum.Capacity:
        filterItem.dropdownItemsMin = filteredMin.map(x => {
            const count = this.getDropdownItemCount(filterItem, x);
            return {label: this.advertSearchService.transformCapacity(x.min) + ' (' + count + ')', value: x.id};
          }
        );

        filterItem.dropdownItemsMax = filteredMax.map(x => {
            const count = this.getDropdownItemCount(filterItem, x);
            return {label: this.advertSearchService.transformCapacity(x.max) + ' (' + count + ')', value: x.id};
          }
        );
        break;
    }
  }

  private getDropdownItemCount(filterItem: FilterListItem, x) {
    let item = filterItem.items.filter(y => y.entityId == x.id);
    let count = 0;
    if (item && item.length > 0) {
      count = item[0].count;
    }
    return count;
  }

  getRangeLookup(searchField: AdvertSearchFieldEnum) {
    let filtered: any;
    // switch (searchField) {
    //   case AdvertSearchFieldEnum.Price:
    //     filtered = this.advertSearchService.PriceRangeLookup;
    //     break;
    //   case AdvertSearchFieldEnum.Capacity:
    //     filtered = this.advertSearchService.CapacityRangeLookup;
    //     break;
    //   case AdvertSearchFieldEnum.Mileage:
    //     filtered = this.advertSearchService.MileageRangeLookup;
    //     break;
    // }

    return filtered;
  }

  resetRangeDropdown(filterItem: FilterListItem) {
    if (filterItem.isLookup) {
      this.populateLookupDropdown(filterItem);
      return;
    }

    filterItem.dropdownItemsMin = [{value: 0, label: 'Any'}];
    filterItem.dropdownItemsMax = [{value: 0, label: 'Any'}];
    filterItem.items.forEach(item => {
      let label;

      switch (filterItem.valueType) {
        case AdvertFilterValueTypeEnum.Currency:
          label = this.advertSearchService.currencyPipe.transform(item.name);
          break;
        case AdvertFilterValueTypeEnum.Date:
          label = this.advertSearchService.datePipe.transform(item.name);
          break;
        case AdvertFilterValueTypeEnum.Decimal:
          label = this.advertSearchService.decimalPipe.transform(item.name);
          break;
        default:
          label = item.name;
          break;
      }

      filterItem.dropdownItemsMin.push({
        value: item.entityId, label
      });
      if (!filterItem.min || filterItem.min < item.entityId) {
        filterItem.dropdownItemsMax.push({
          value: item.entityId, label
        });
      }
    });

    filterItem.dropdownItemsMin.sort((a, b) => {
      return a.value - b.value;
    });
    filterItem.dropdownItemsMax.sort((a, b) => {
      return a.value - b.value;
    });
  }

  resetKeywordFilter() {
    this.keywordSearch = null;

    this.logger.info('Resetting results');

    this.getResultCount(this.filterList[0]);
    // this.viewResults(true);
    this.setQueryURL();
  }

  resetFilter(filterItem: FilterListItem) {
    filterItem.selectedItems.forEach(x => {
      x.checked = false;
    });
    filterItem.min = undefined;
    filterItem.max = undefined;

    this.getResultCount(filterItem);
    this.setQueryURL();
  }

  resetSelectedItem(filterItem: FilterListItem, selected: AdvertSearchCount) {
    // if the filter item is a checkbox list, uncheck selected, otherwise reset the filter
    if (filterItem.filterType === SearchFilterTypeEnum.Checkbox) {
      selected.checked = false;
      this.getResultCount(filterItem);
      this.setQueryURL();
    } else {
      this.resetFilter(filterItem);
    }
  }

  isRange(filterItem: FilterListItem) {
    return filterItem.filterType === SearchFilterTypeEnum.Range;
  }

  isCheckbox(filterItem: FilterListItem) {
    return filterItem.filterType === SearchFilterTypeEnum.Checkbox;
  }

  closeFilter() {
    this.selectedFilter = null;
  }

  viewResults(saveSearch: boolean = false) {

    const dto = this.getSearchFiltersDTO();

    // we should only save the search when user actively clicks to show results
    if (saveSearch) {
      this.saveSearch();
    }

    this.selectedFilter = null;

    this.advertSearchService.getSearchResults(dto).then(sphAdverts => {
      this.sphAdverts = sphAdverts;
      this.resultsCount = this.sphAdverts.length;
      this.isLoading = false;
    });
  }

  setQueryURL() {

    const dto = this.getSearchFiltersDTO();

    // set the route query to match the current search dto (+ page number)
    this.navigateURL(dto);
  }

  navigateURL(dto) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: dto.filters,
      queryParamsHandling: 'merge'
    });
  }

  hideFilter() {

    if (this.selectedFilter) {
      this.selectedFilter = null;
    }
  }

  hideFilterThenRunSearch() {

    this.hideFilter();
    // this.viewResults(true);
    this.setQueryURL();

  }

  clearAllFilters() {
    this.filterList.forEach(fs => {
      fs.selectedItems.forEach(item => {
        item.checked = false;
      });

      fs.min = undefined;
      fs.max = undefined;
    });

    this.getSearchCounts(this.filterList[0]);
    this.getResultCount(this.filterList[0]);
  }

  hasFilterSelected(filterItem: FilterListItem) {
    if (filterItem.filterType === SearchFilterTypeEnum.Checkbox) {
      return filterItem.selectedItems && filterItem.selectedItems.filter(x => x.checked).length > 0;
    }

    return filterItem.min || filterItem.max;
  }

  checkboxChanged() {
    this.getResultCount(this.selectedFilter);
  }

  rangeMinChanged(selectedFilter: FilterListItem) {
    this.getResultCount(this.selectedFilter);
    this.resetRangeDropdown(selectedFilter);
  }

  rangeMaxChanged(selectedFilter: FilterListItem) {
    this.getResultCount(this.selectedFilter);
    this.resetRangeDropdown(selectedFilter);
  }

  showSaveSearchDialog() {
    if (this.noSearchDefined()) {
      this.toast.warning(`Select some search filters`, 'No filters selected', {opacity: 0.94});
      return;
    }

    this.showSavedSearchDialog = true;
  }

  onTableSizeChange(event) {

    this.tableSize = event;
    this.page = 1;
    // this.viewResults();
    this.setQueryURL();
  }

  onTableDataChange(event: number) {

    this.page = event;
    // this.viewResults();
    this.setQueryURL();
    this.scrollToTop();
  }

  scrollToTop() {

    const topOfPage = document.getElementById('main-content');
    topOfPage.scrollIntoView();

  }

  saleFilterSelected(option: any) {

    this.searchOptions.saleFilter = option.value;
    this.saleTypeSelected = option.value.toString();

    // this.viewResults(false);
    this.setQueryURL();
  }

  getSearchHeader() {
    const items = this.sphAdverts ? this.sphAdverts.length : 0;
    return `Results 1-${Math.min(10, items)} of ${items}`;
  }

  sortingOptionChanged() {
    // this.viewResults(false);
    this.setQueryURL();
  }

  sortingFilterOptionChanged() {
    // this.viewResults(false);
    this.setQueryURL();
  }
}

