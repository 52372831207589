// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdb-option {
  height: 26px !important;
}

.status {
  text-align: center;
}

.status-invited {
  background-color: var(--warningColour);
  color: #fff;
}

.status-registered.status-1 {
  color: var(--successColour);
}
.status-registered.status-3 {
  color: var(--dangerColour);
}

.change-error {
  font-weight: 600;
  font-size: 0.75rem;
}

.input-label {
  font-weight: 500;
  font-size: 0.875rem;
}

.role-info {
  font-size: 0.6rem;
  background-color: #ddd;
  font-weight: 500;
  padding: 3px 5px;
  border-radius: 5px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./projects/remarq/src/app/components/main/contact/contact-search/contact-search.component.scss"],"names":[],"mappings":"AAAA;EAAc,uBAAA;AAEd;;AAAA;EAAU,kBAAA;AAIV;;AAFA;EAAkB,sCAAA;EAAwC,WAAA;AAO1D;;AAHE;EACE,2BAAA;AAMJ;AAJE;EACE,0BAAA;AAMJ;;AAFA;EACE,gBAAA;EACA,kBAAA;AAKF;;AAFA;EAAe,gBAAA;EAAkB,mBAAA;AAOjC;;AALA;EAEE,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AAOF","sourcesContent":[".mdb-option { height: 26px !important; }\n\n.status { text-align: center;}\n\n.status-invited { background-color: var(--warningColour); color: #fff; }\n\n.status-registered {\n\n  &.status-1 {\n    color: var(--successColour);\n  }\n  &.status-3 {\n    color: var(--dangerColour);\n  }\n}\n\n.change-error {\n  font-weight: 600;\n  font-size: 0.75rem;\n}\n\n.input-label { font-weight: 500; font-size: 0.875rem;}\n\n.role-info {\n\n  font-size: 0.6rem;\n  background-color: #ddd;\n  font-weight: 500;\n  padding: 3px 5px;\n  border-radius: 5px;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
